// FileDropZone.js
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const FileDropZone = ({ onFileUpload }) => {
  const [droppedImage, setDroppedImage] = useState(null);

  const onDrop = acceptedFiles => {

    if (acceptedFiles.length === 1 && acceptedFiles[0].type.startsWith('image/')) {
        const imageFile = acceptedFiles[0];
        // Display the dropped image
        setDroppedImage(URL.createObjectURL(imageFile));
        // Call the onFileUpload function with the dropped image file
        onFileUpload(imageFile);
      } else {
        toast.error("Please upload only image files.", {
            autoClose: 3000, // 3 seconds in this example
          })
        alert('Please upload only image files.');
      }
    // const imageFile = acceptedFiles[0];
    // // Display the dropped image
    // setDroppedImage(URL.createObjectURL(imageFile));
    // // Call the onFileUpload function with the dropped image file
    // onFileUpload(imageFile);
  };

  const removeImage = () => {
    setDroppedImage(null);
    onFileUpload(null); // Notify parent component about the removal
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
  });

  return (
    <div>
       
      <div {...getRootProps()}  style={dropZoneStyles}>
        <input {...getInputProps()} />
        {/* <p>Drag and drop or browse to choose a file</p> */}
      
      {droppedImage ? (
        <div style={droppedImageContainerStyles}>
          <img src={droppedImage} alt="Dropped" style={droppedImageStyles} />
          
          <i className="mdi mdi-minus-circle" onClick={removeImage}  style={removeButtonStyles}></i>
          {/* <button onClick={removeImage} style={removeButtonStyles}>Remove</button> */}
        </div>
      ) : (<p>Drag and drop or browse to choose a file</p>)}
      </div>
    </div>
  );
};

const dropZoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  };
  
  const droppedImageContainerStyles = {
    marginTop: '20px',
    position: 'relative',
  };
  
  const droppedImageStyles = {
    maxWidth: '100%',
    maxHeight: '200px',
  };
  
  const removeButtonStyles = {
    position: 'absolute',
    fontSize: 'x-large', // Adjust font size here
  };

export default FileDropZone;
