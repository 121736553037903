
import React, { useState,useEffect,useCallback, useRef } from "react"
import {
Container,
Button,
Modal,
ModalBody,
ModalHeader,
ModalFooter,
Nav,
NavItem,
NavLink,
TabContent,
TabPane,
Label,
Input,
} from "reactstrap"

import "react-multi-carousel/lib/styles.css"

import { Link,useParams, useLocation, useNavigate  } from "react-router-dom"
import classnames from "classnames"

import awicon from "../../assets/images/services-icon/coin.png"
import Select from "react-select"


import dsmile from "../../assets/images/services-icon/dog_smile.png"

//i18n
import axios from "axios";
// import { BASE_URL } from "../../global";
import { SECURITY_KEY, BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";

import Loader from "react-spinner-loader";
// import Comprehension from "./diagnostics/Comprehension";
// import Pagination from "./diagnostics/Pagination";
//emoje inactive
import em1 from "../../assets/images/services-icon/em1.svg"
import em2 from "../../assets/images/services-icon/em2.svg"
import em3 from "../../assets/images/services-icon/em3.svg"
import em4 from "../../assets/images/services-icon/em4.svg"
import em5 from "../../assets/images/services-icon/em5.svg"

//emoje active
import ema1 from "../../assets/images/services-icon/ema1.svg"
import ema2 from "../../assets/images/services-icon/ema2.svg"
import ema3 from "../../assets/images/services-icon/ema3.svg"
import ema4 from "../../assets/images/services-icon/ema4.svg"
import ema5 from "../../assets/images/services-icon/ema5.svg"
// // Timer component that manages timer state and logic
// const TimerComponent = () => {
//   const [timer, setTimer] = useState(0);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setTimer(prevTimer => prevTimer + 1);
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   return timer; // Return timer value instead of JSX
// };

// // Receiving component that displays the timer value
// const TimerDisplay = React.memo(({ timerValue }) => {
//   return <div>Timer Value: {timerValue}</div>;
// });
import { MathJax, MathJaxContext } from "better-react-mathjax";
const FlagDiagnosticsDetails = React.memo(() => {
  document.title = "MyGooRoo Tutor App"
  const { key } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // let pk_activity_key1 = null;
  // let IsType = null;
  // //let IsHomework = null;
  // let IsQuestionNo = 1;
  // let IsStKey = null;
  const [pk_activity_key1, set_pk_activity_key1] = useState(null);
  const [IsType, setIsType] = useState(null);
  const [IsQuestionNo, setIsQuestionNo] = useState(1);
  const [IsStKey, setIsStKey] = useState(null);
  const [IsFlagReason, setFlagReason] = useState(null);
  const [IsFlagText, setFlagText] = useState(null);
  useEffect(() => {
      
  const { type, activity_key, st_key, flag_reason, flag_text } = location.state || {};
 
      if (key && key!='' && type && type!=null && st_key && st_key!='' && activity_key && activity_key!='') {
        set_pk_activity_key1(activity_key);
        setIsType(type);
        setIsQuestionNo(key);
        setIsStKey(st_key);
        setFlagText(flag_text || null);
        setFlagReason(flag_reason || null);
        // pk_activity_key1 = activity_key;
        // IsType = type;
        // //IsHomework = homework;
        // IsQuestionNo = key;
        // IsStKey = st_key;
      }else{
          toast.error("Sorry, Key missing!", {
            autoClose: 3000, // 3 seconds in this example
          });
          window.history.back()
      }
  }, [location]);


//common

  const [loader, setLoader] = useState(false);
  const [ActivityData, setActivityData] = useState([]);
  const [TopicData, setTopicData] = useState(null);
    
  const [isSubmitMark, setSubmitMark] = useState(false);
 // console.log(otherData);
 const authUser = JSON.parse(localStorage.getItem('authUser')) || null;
  
 // console.log(otherData);
 // const authUser = JSON.parse(localStorage.getItem('authStudentUser')) || null;

  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
    
    
  }, [pk_activity_key1])


  const fetchData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (
        pk_activity_key1 &&
        pk_activity_key1 != null &&
        pk_activity_key1 !=''
      ) {
        setLoader(true);
        setActivityData([]);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL+"session/MarkAssessmentQuestions", {
            pk_activity_key: pk_activity_key1,
            subject_id : IsType,
            pk_student_key : IsStKey,
            question_no: IsQuestionNo
          })
          .then(responseActivity => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            setLoader(false);
           
            if (
              responseActivity.data.LessonActivitiesQuestions.length > 0
            ) {
              setActivityData(responseActivity.data);
              const topicNames = responseActivity.data.topics.flat().map(obj => obj.topic_name);
                const mergedTopics = topicNames.join(', ');
                //console.log(mergedTopics);
              setTopicData(mergedTopics);
             
            } else {
              setActivityData([]);
              setTopicData(null)
            }
           

          })
          .catch(error => {
             setLoader(false);
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
         setLoader(false);
         //window.history.back()
      }
    } else {
       setLoader(false);
      navigate("/login")
    }
  }


  const [selectedOptions, setSelectedOptions] = useState({}); // State to store selected options for each quspart
  const [selectedOptionsMark, setSelectedOptionsMark] = useState({});
  const handleCheckboxChange = (quspartId, optionId, mark) => {
      setSelectedOptions(prevState => ({
          ...prevState,
          [quspartId]: optionId,
      }));
      setSelectedOptionsMark(prevState => ({
        ...prevState,
        [quspartId]: mark,
    }));


  };
  
  const isAtLeastOneOptionChecked = (quspartId) => {
      return !!selectedOptions[quspartId];
  };

  const [markOverOptionCount, setMarkOverOptionCount] = useState(0);

  const [IsSubmitbtn, setSubmitbtn] = useState(false);

  useEffect(() => {
      // Update the count whenever the component mounts or the list changes
      const count = document.querySelectorAll('[data_count="mark_section"]').length;
      setMarkOverOptionCount(count);
      //console.log(Object.keys(selectedOptions).length)
      if(Object.keys(selectedOptions).length === count){
        setSubmitbtn(true);
      }else{
        setSubmitbtn(false);
      }
  }, [isAtLeastOneOptionChecked])
   console.log(selectedOptions)
 console.log(selectedOptionsMark)
  // console.log(markOverOptionCount)

  // console.log(pk_activity_key1)
const sumitedData = async () => {
  if (authUser && authUser != "" && authUser != null) {
   // console.log(pk_activity_key1)
    if (
      pk_activity_key1 &&
      pk_activity_key1 != null &&
      pk_activity_key1 !=''
    ) {
      setSubmitMark(true);
      const sum = Object.values(selectedOptionsMark).reduce((acc, curr) => acc + curr, 0);
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL+"session/FlagOverRide", {
          pk_activity_key: pk_activity_key1,
          subject_id : IsType,
          pk_student_key : IsStKey,
          question_no: IsQuestionNo,
          selectedOptionsMark : selectedOptionsMark,
          mark: sum,
          lesson_key: ActivityData.fk_lesson_key,
          fk_sest_key: ActivityData.fk_sest_key
        })
        .then(responseMark => {
          // Assuming the API response is an array of objects with 'id' and 'value' properties
          
         
          if(responseMark.data == '1'){
            navigate("/AllNotification")
            toast.success("Mark Updated successfully", {
              autoClose: 3000, // 3 seconds in this example
            })
          }else{
            setSubmitMark(false);
            toast.error("Failure! Unable to mark updated. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            })
          }
         

        })
        .catch(error => {
          setSubmitMark(false);
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      setSubmitMark(false);
      // window.history.back()
    }
  } else {
    setSubmitMark(false);
    navigate("/login")
  }
}

 


const CountTotalCoins = question => {
  let total_coins = 0;

  if (question && question.length > 0) {
      question.forEach(activity => {
          if (activity.fk_sub_id === '1') {
              total_coins += activity.english_question.length * 2;
          } else if (activity.fk_sub_id === '2' || activity.fk_sub_id === '3') {
              total_coins += activity.question.length * 2;
          }
      });
  }
 // setTotalCoins(total_coins);
  return total_coins;
};


// useEffect(() => {

// if(ActivityData && ActivityData.activity_node && ActivityData.activity_node.length > 0){
//   setTopicData(ActivityData.activity_node[0]);
// }else{
//   setTopicData(null)
// }

  
// }, [ActivityData, key])


//const timerValue = TimerComponent(); // Get timer value from TimerComponent

const [customActiveTab, setcustomActiveTab] = useState(IsQuestionNo)

function toggleCustom(tab) {
  // console.log(tab);
  if (customActiveTab !== tab) {
    setcustomActiveTab(tab)
  }
}

const [ResonTextData, setResonText] = useState('');
    const [modal_standard, setmodal_standard] = useState(false);

    const [isFlagReport, setFlagReport] = useState(false);
    
    const [selectedGroup, setselectedGroup] = useState(null);

    const [selectQuestion, setselectQuestion] = useState(null);
    
    function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
    }
    
    const optionGroup = [
    // {
    // label: "Select Subject",
    // options: [
    { label: "Spelling error / Typo", value: "Spelling error / Typo" },
    { label: "The solution is wrong", value: "The solution is wrong" },
    {
    label: "The question is not relevant to the topic",
    value: "The question is not relevant to the topic",
    },
    {
    label: "The question cannot be solved",
    value: "The question cannot be solved",
    },
    {
    label: "The question is incomplete",
    value: "The question is incomplete",
    },
    { label: "Others", value: "Others" },
    // ],
    // },
    ]

function textareachange(event) {
  const text = event.target.value;
 // if (count > 0) {
  setResonText(text);
 // }
  //settextcount(event.target.value.length)
  }
  const tog_standard = (data) => {
    //console.log(data);
    setmodal_standard(!modal_standard);
    setselectedGroup(null);
    setResonText('');
    setselectQuestion(data ? data : null);
    }



    function submitFlagReport(){
      // if (authUser && authUser != "" && authUser != null) {

      //   setFlagReport(true);
      //     axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
      //     axios.post(BASE_URL+'studentSession/flagReport', {
      //       fk_lesson_activity_key: selectQuestion.fk_lesson_activity_key,
      //       fk_lesson_activity_question_key : selectQuestion.pk_lesson_activity_question_key,
      //       fk_sub_id: selectQuestion.fk_sub_id,
      //       question_id: selectQuestion.fk_question_id,
      //       reson_text: ResonTextData,
      //       reson: selectedGroup
      //     }).then(responseFlag => {
      //       //console.log(responseSessionEnd.data);
      //       // Assuming the API response is an array of objects with 'id' and 'value' properties
      //       if (parseInt(responseFlag.data) === 1) {
      //         tog_standard();
      //         setFlagReport(false);
      //         toast.success("Report submitted successfully.", {
      //           autoClose: 3000, // 3 seconds in this example
      //         });
      //       } else {
      //         tog_standard();
      //         setFlagReport(false);
      //         toast.error("Failure! Unable to insert data. Try again later", {
      //           autoClose: 3000, // 3 seconds in this example
      //         });
      //       }
  
  
  
      //     })
      //       .catch(error => {
      //         //console.log(error)
      //         tog_standard();
      //         setFlagReport(false);
      //         if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
      //           localStorage.clear();
      //           navigate("/login");
      //         }
      //       });
  
      
      // }
      // else {
      //   setFlagReport(false);
      //   navigate("/login");
      // }
  

    }


    
    const [SumTotalExtraMark, setSumTotalExtraMark] = useState(0);
    const [SumTotalExtraMarkQuestion, setSumTotalExtraMarkQuestion] = useState(0);
    const [modal_standard1, setmodal_standard1] = useState(false);
    const tog_standard1 = () => {
      setmodal_standard1(!modal_standard1);
      const sum = Object.values(selectedOptionsMark).reduce((acc, curr) => acc + curr, 0);
      setSumTotalExtraMark(sum);
      // Convert selectedOptionsMark object to an array of objects
        const selectedOptionsArray = Object.entries(selectedOptionsMark).map(([key, value]) => ({ [key]: value }));

        const filteredArray = selectedOptionsArray.filter(obj => {
          // Iterate through each object's values
          for (const key in obj) {
            // Check if the value equals 5
            if (obj[key] === 5) {
              return true; // Return true if value is 5
            }
          }
          return false; // Return false if no value is 5
        });
      setSumTotalExtraMarkQuestion(filteredArray.length)
      }
 
//console.log(ActivityData);
return (
<React.Fragment>
  <MathJax inline dynamic>
  <Loader
      show={loader}
      type="body"
    // stack="vertical"
    // message="Loading Data"
    />
    <ToastContainer autoClose={3000} />

    <Modal isOpen={modal_standard1} toggle={tog_standard1}>
                        <ModalHeader toggle={tog_standard1}></ModalHeader>
                        <ModalBody>
                          <p className="text-center fw-bold text-dark mb-0 font-size-18">
                          {parseInt(SumTotalExtraMarkQuestion) === 0 ? 'Since no questions were found to be incorrect, no extra points are added.'
                          :
                          'Found'+SumTotalExtraMarkQuestion+' question incorrect, so add '+SumTotalExtraMark+' extra points.'}
                          </p>
                        </ModalBody>
                        <ModalFooter className="justify-content-center">
                        
                          <Button color="danger"  onClick={() => {
                              tog_standard1()
                            }}>
                           CANCEL
                          </Button>
                          <Button
                            color="success"
                            className="mr-3"
                            disabled={isSubmitMark}
                            onClick={()=> sumitedData()}
                          >
                            CONFIRM
                          </Button>
                          
                        </ModalFooter>
                      </Modal>
  <div className="page-wrapper">
    <div className="cs-pg-header bg-dark-blue py-4">
      <Container className="custom-wt">
        <div className="d-flex align-items-center">
          <div className="mt-0 border-right me-4" style={{ width: "20%" }}>
          
            <div className="text-white font-size-14 text-uppercase"  to={"#"} onClick={() => history.back()}> {" "}
            <span>
              <i className=" fas fa-chevron-left me-1"></i>
            </span>{" "}
            back
            </div>
          
          </div>
          <div className="d-flex align-items-center justify-content-between flex-grow-1">
            <h6 className="mb-0 font-size-20 text-white w-60 fw-regular">{TopicData ? TopicData : null}
            </h6>
            <p className="mb-0 text-uppercase luminous-wht">
              {" "}
              <span className="text-yellow me-1">{ActivityData && ActivityData.subject_name ? ActivityData.subject_name : null}</span> Diagnostics{" "}
            </p>
            {/* <div className="luminous-wht font-size-16">
              <img width={24} className="" src={awicon} alt="" /> {ActivityData && ActivityData.coins_earned ? ActivityData.coins_earned : 0}/{ActivityData && ActivityData.LessonActivitiesQuestions && ActivityData.LessonActivitiesQuestions.length > 0 ? CountTotalCoins(ActivityData.LessonActivitiesQuestions) : 0}{' '}
              coins earned
            </div> */}
          </div>
        </div>
      </Container>
    </div>
    <Container className="custom-wt">
    <div className="assessement-section summary-width-hold mt-4">
    
        <Nav tabs className="nav-tabs-custom mb-3">
       

        {/* {ActivityData && ActivityData.LessonActivitiesQuestions && ActivityData.LessonActivitiesQuestions.length > 0 ?
            
            ActivityData.LessonActivitiesQuestions.map(
             (activity, index) => {
               if (
                 activity.english_question &&
                 activity.english_question.length > 0 &&
                 parseInt(activity.fk_sub_id) === 1
               ) {
                   
                     return activity.english_question.map(
                       (question, index1) => {
                        
                           if (
                             parseInt(question.diagnostic_category_id) === 1 
                             
                           ) {
     
                             let filteredManualMark = [];
                             //let filteredManualMark = question.english_question_parts.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0))) 
                             let question_status = 1;
                             
                                   let total_mark = 0;
                                     if (question.StudentAnsAttempts && question.StudentAnsAttempts.length > 0) {
                                       
                                       question.StudentAnsAttempts.forEach((stuAnswer, index3) => {
                                         
                                         if (stuAnswer.points_obtained!=null) {
                                           total_mark += parseInt(stuAnswer.points_obtained); // Increment count_answer by 1 for each occurrence
                                         }
                                       });
       
                                     if(question.StudentAnsAttempts.length > 0 && total_mark === 0){
                                       question_status = 3;
                                     }else if(question.StudentAnsAttempts.length > 0 && total_mark >= 1){
                                       question_status = 4;
                                       
                                    
                                     }else{
                                      
                                         question_status = 2;
                                       
                                     }
       
                                     }else{

                                         question_status = 2;
                                       
                                      
                                     }
                                  
                                
                                 return (
                                   <NavItem key={index}>
                                    
                                     <NavLink style={{ cursor: "pointer" }}
                                     
                                    onClick={() => {
                                      toggleCustom(index+1)
                                    }}
                                     className={ classnames({ active: customActiveTab=== index+1 })
                                       + question_status && question_status === 1 ? " display-hold_active" : 
                                         question_status &&  question_status === 2 ? " display-number" : 
                                         question_status && question_status === 3 ? " display-attempt_fail" :
                                         question_status && question_status === 4 ? " display-attempt1" :
                                         question_status && question_status === 5 ? " display-attempt2" :
                                          " display-number"} 
                                       >
                                         {question_status && question_status === 1 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                        question_status && question_status === 2 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                        question_status && question_status === 3 ? filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="mdi mdi-close-thick text-danger font-size-20"></i> :
                                        question_status && question_status === 4 ?  filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="fas fa-check text-success font-size-20"></i> :
                                        question_status && question_status === 5 ? filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="fas fa-check text-warning font-size-20"></i> :
                                        <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span>}
                                       
                                     </NavLink>
     
     
     
     
                                   </NavItem>
                               )
       
                           }
                         
                          })
       
       
       
               }else if(activity.question &&
                 activity.question.length > 0 &&
                 parseInt(activity.fk_sub_id) === 2){
                   //maths
                   return activity.question.map(
                     (question, index1) => {
                      // console.log('enter2')
                         if (
                           question.diagnostic_category_id == '0' 
                         ) {
                           
                           let filteredManualMark = [];
                           //let filteredManualMark = question.question_parts.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0))) 
                           let question_status = 1;
                    
                                
                                 let total_mark = 0;
                                   if (question.StudentAnsAttempts && question.StudentAnsAttempts.length > 0) {
                                     
                                     question.StudentAnsAttempts.forEach((stuAnswer, index3) => {
                                       
                                       if (stuAnswer.points_obtained!=null) {
                                         total_mark += parseInt(stuAnswer.points_obtained); // Increment count_answer by 1 for each occurrence
                                       }
                                     });
     
                                   if(question.StudentAnsAttempts.length > 0 && total_mark === 0){
                                     question_status = 3;
                                   }else if(question.StudentAnsAttempts.length > 0 && total_mark >= 1){
                                     question_status = 4;
                                 
                                    
                                   }else{
                                   
                                       question_status = 2;
                                     
                                   }
     
                                   }else{
                                     
                                       question_status = 2;
                                     
                                    
                                   }
                                
                               return (
                                 <NavItem key={index}>
                                 
                                   <NavLink style={{ cursor: "pointer" }}  
                                   onClick={() => {
                                      toggleCustom(index+1)
                                    }} className={ classnames({ active: customActiveTab=== index+1 })
                                     + question_status && question_status === 1 ? " display-hold_active" : 
                                       question_status &&  question_status === 2 ? " display-number" : 
                                       question_status && question_status === 3 ? " display-attempt_fail" :
                                       question_status && question_status === 4 ? " display-attempt1" :
                                       question_status && question_status === 5 ? " display-attempt2" :
                                        " display-number"} 
                                     >
                                       {question_status && question_status === 1 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                      question_status && question_status === 2 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                      question_status && question_status === 3 ? filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="mdi mdi-close-thick text-danger font-size-20"></i> :
                                      question_status && question_status === 4 ?  filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="fas fa-check text-success font-size-20"></i> :
                                      question_status && question_status === 5 ? filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="fas fa-check text-warning font-size-20"></i> :
                                      <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span>}
                                     
                                   </NavLink>
     
                                
     
     
     
                                 </NavItem>
                             )
       
                             }
       
       
                            
       
                           })
       
       
       
       
       
               }else{
                 //else
               }
             })
             : null} */}
       
       
      </Nav>
      
      <TabContent activeTab={customActiveTab} className="text-muted">

      {ActivityData && ActivityData.LessonActivitiesQuestions && ActivityData.LessonActivitiesQuestions.length > 0 ?
            
            ActivityData.LessonActivitiesQuestions.map(
             (ActivityData1, activeindex) => {
              if (
                ActivityData1.english_question &&
                ActivityData1.english_question.length > 0 &&
                parseInt(ActivityData1.fk_sub_id) === 1
              ) {
               
                return ActivityData1.english_question.map(
                
                (InnerActivityData, index) => {
                  if (index === 0) {
                   
                    if(InnerActivityData && InnerActivityData.diagnostic_category_id && parseInt(InnerActivityData.diagnostic_category_id) === 1){
                      //check box start
                      const quspartId = InnerActivityData.diagnostic_id; // Assuming you have some unique identifier for quspart
                      let isOptionNoChecked = '';
                      let isOptionYesChecked = '';
                      isOptionNoChecked = selectedOptions[quspartId] === quspartId+'-'+'0';
                      isOptionYesChecked = selectedOptions[quspartId] === quspartId+'-'+'1';
                      //check box end

                      const stuAnswersarray = InnerActivityData.StudentAnsAttempts.map(stuAns => {
                        try {
                            return JSON.parse(stuAns.student_answer);
                            } catch (error) {
                                console.error('Error parsing JSON:', error);
                                return null; // or handle the error in an appropriate way
                            }
                        }) || []; // Extracting session keys
                      
                        // Assuming stuAnswersarray is a nested array
                        const flattenedStuAnswers = [].concat(...stuAnswersarray);
                       // console.log(activeindex + 1);
                      return(
                        <TabPane tabId={activeindex + 1} key={activeindex + 1} className={parseInt(customActiveTab) === activeindex + 1? 'active' : ''}>
                      <div className="hw-list mt-4 pb-100">
                       
                        <ul className="list-unstyled mb-0">
                          <li className="justify-content-between">
                            <div className="d-flex align-items-start gap-4 justify-content-between">
                              <p className="mb-0 text-uppercase font-size-12">
                                {InnerActivityData.english_diagnostic_skill && InnerActivityData.english_diagnostic_skill.level_name ? 
                                InnerActivityData.english_diagnostic_skill.level_name +' /' : null}
                                
                                {InnerActivityData.english_diagnostic_skill && InnerActivityData.english_diagnostic_skill.strand_name ? 
                                InnerActivityData.english_diagnostic_skill.strand_name +' /' : null}

                                {InnerActivityData.english_diagnostic_skill && InnerActivityData.english_diagnostic_skill.topic_name ? 
                                InnerActivityData.english_diagnostic_skill.topic_name +' /' : null}

                                {InnerActivityData.english_diagnostic_skill && InnerActivityData.english_diagnostic_skill.sub_topic_name ? 
                                InnerActivityData.english_diagnostic_skill.sub_topic_name : null}
                            
                              </p>
                              <div className="mb-0 d-flex align-items-center gap-2">
                                <p className="mb-0">#{InnerActivityData.diagnostic_id ? 
                                InnerActivityData.diagnostic_id : null}</p>
                                <span >
                                  <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                                </span>
                              
                              </div>
                            </div>

                            <div className="mt-4" dangerouslySetInnerHTML={{
                                __html: InnerActivityData.diagnostic_text ? InnerActivityData.diagnostic_text.replace(/\[(\d+)Answer char=\d+\]/g, '').replace(/\[Answer char=\d+\]/g, '') : null,
                              }}>
                              </div>


                            <ul className="dg-selector width-controll list-unstyled mb-0">
                            { 
                                    InnerActivityData.english_diagnostic_answers && InnerActivityData.english_diagnostic_answers.map(
                                    (qusAns, index1) => {
                                        let filteredStuAns = flattenedStuAnswers.filter(stuAns => ((parseInt(stuAns.part_id) === parseInt(qusAns.answer_diagnostic_id)) 
                                        && (parseInt(stuAns.answer_id) === parseInt(qusAns.answer_id)))
                                        ) || [];
                                  
                                      var status_color = 'selector-general cursor-pointer';
                                      var border_color = 'border-start-secondary';
                                      if(filteredStuAns.length > 0){
                                        if(parseInt(qusAns.answer_is_correct) === 1){
                                          status_color = 'selector-success';
                                          border_color = 'border-start-success';
                                        }else{
                                          status_color = 'selector-wrong ';
                                          border_color = 'border-start-danger';
                                        }
                                      }
                                    //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                    return(<li className={status_color+ ' choose e_choose'}  key={index1} >
                                      <div className="p-2 px-3 font-size-18">{index1 + 1}</div>
                                      <div className={"p-2 dg-media "+{border_color}} dangerouslySetInnerHTML={{
                                            __html: qusAns.answer_text ? qusAns.answer_text : null,
                                          }}>
                                        
                                      </div>
                                    </li>)
                                    })
                                    

                                
                              }
                            
                            </ul>


                           { InnerActivityData.StudentAnsAttempts.length > 0 ? 
                           InnerActivityData.StudentAnsAttempts.map((stuAns, stuindex) => {
                            return( <>
                           <div key={stuindex}>
                            <h4 className="font-size-20 fw-bold mb-4 mt-3">
                                Why did you choose this option?
                              </h4>
                              <Input
                                style={{
                                  height: "150px",
                                  border: "1px solid #1a1a1a",
                                  borderRadius: "4px",
                                  fontSize: "18px",
                                }}
                                type="textarea"
                                value={stuAns.answer_reason}
                                id="textarea"
                                onChange={e => {
                                  
                                }}
                                disabled ={true}
                                maxLength="225"
                                rows="3"
                              />
                              </div> 

                             
                              <div>
                              <h4 className="font-size-20 fw-bold mb-4 mt-4">
                                How confident are you in your answer?
                              </h4>

                              <div className="emotion-wrapper pb-50">
                                <ul className="list-unstyled mb-0">
                                  <li>
                                    <img className={parseInt(stuAns.answer_confident) === 1 ? 'active-img' : ''} src={parseInt(stuAns.answer_confident) === 1 ? ema1 : em1} alt="" />
                                    {parseInt(stuAns.answer_confident) === 1 ? <p className="text-center subject-float">I completely don’t know.</p> : null}
                                  </li>
                                  <li >
                                    <img className={parseInt(stuAns.answer_confident) === 2 ? 'active-img' : ''} src={parseInt(stuAns.answer_confident) === 2 ? ema2 : em2} alt="" />
                                    {parseInt(stuAns.answer_confident) === 2 ? <p className="text-center subject-float">I'm not sure if this is correct.</p> : null}
                                  </li>
                                  <li >
                                    <img className={parseInt(stuAns.answer_confident) === 3 ? 'active-img' : ''} src={parseInt(stuAns.answer_confident) === 3 ? ema3 : em3} alt="" />
                                    {parseInt(stuAns.answer_confident) === 3 ? <p className="text-center subject-float">I think I might be wrong.</p> : null}
                                  </li>
                                  <li>
                                    <img className={parseInt(stuAns.answer_confident) === 4 ? 'active-img' : ''} src={parseInt(stuAns.answer_confident) === 4 ? ema4 : em4} alt="" />
                                    {parseInt(stuAns.answer_confident) === 4 ? <p className="text-center subject-float">I think this is the right answer.</p> : null}
                                  </li>
                                  <li>
                                    <img className={parseInt(stuAns.answer_confident) === 5 ? 'active-img' : ''} src={parseInt(stuAns.answer_confident) === 5 ? ema5 : em5} alt="" />
                                    {parseInt(stuAns.answer_confident) === 5 ? <p className="text-center subject-float">This is definitely the right answer!</p> : null}
                                  </li>
                                </ul>
                              </div>

                              </div> 
                              </>);
                            })

                          :

                          <>
                           <div>
                            <h4 className="font-size-20 fw-bold mb-4 mt-3">
                                Why did you choose this option?
                              </h4>
                              <Input
                                style={{
                                  height: "150px",
                                  border: "1px solid #1a1a1a",
                                  borderRadius: "4px",
                                  fontSize: "18px",
                                }}
                                type="textarea"
                                value={''}
                                id="textarea"
                                onChange={e => {
                                  
                                }}
                                disabled ={true}
                                maxLength="225"
                                rows="3"
                              />
                              </div> 

                             
                              <div>
                              <h4 className="font-size-20 fw-bold mb-4 mt-4">
                                How confident are you in your answer?
                              </h4>

                              <div className="emotion-wrapper pb-50">
                                <ul className="list-unstyled mb-0">
                                  <li>
                                    <img className={''} src={em1} alt="" />
                                   
                                  </li>
                                  <li >
                                    <img className={''} src={em2} alt="" />
                                    
                                  </li>
                                  <li >
                                    <img className={''} src={em3} alt="" />
                                    
                                  </li>
                                  <li>
                                    <img className={''} src={em4} alt="" />
                                    
                                  </li>
                                  <li>
                                    <img className={''} src={em5} alt="" />
                                   
                                  </li>
                                </ul>
                              </div>

                              </div> 
                              </>
                          }



                            {/* {!nextQuestion ? <><h4 className="font-size-16 fw-bold mb-2 mt-3">
                              Solution
                            </h4>
                            <div className="e_feedBack" style={{
                                    
                                    border: "1px solid #1a1a1a",
                                    borderRadius: "4px",
                                    fontSize: "16px",
                                  }} 
                            >
                              { ActivityData.english_diagnostic_answers
                                .filter(CorrAns => parseInt(CorrAns.answer_is_correct) === 1)
                                .map((qusAns, index1) => (
                                  <div key={index1} dangerouslySetInnerHTML={{
                                    __html: qusAns.answer_text,
                                  }}></div>
                                ))}


                              </div></> : null } */}


                         
                          <div className="mark_over_option mb-4 mt-4 p-2 gap-3 border" data_count="mark_section">
                              <h6 className="mb-3">Is this question incorrect, or does it contain any errors?</h6>
                                <div className="d-flex gap-3 ">

                          
                                        <>
                                            <div key={quspartId+''+'0'} className="d-flex gap-3">
                                          
                                              <input type="checkbox" id={`checkbox${quspartId+''+'0'}}`} name={`checkbox${quspartId}`}
                                              checked={isOptionNoChecked}
                                              onChange={() => handleCheckboxChange(quspartId, quspartId+'-'+'0', 0)}
                                                />
                                              <label htmlFor={`checkbox${quspartId}`}> No</label>
                                            </div> 
                                              
                                                <div key={quspartId+''+'1'} className="d-flex gap-3">
                                                      <input type="checkbox" id={`checkbox${quspartId+''+'1'}`} name={`checkbox${quspartId+''+'1'}`} 
                                                      checked={isOptionYesChecked}
                                                      onChange={() => handleCheckboxChange(quspartId, quspartId+'-'+'1', 5)}/>
                                                      <label htmlFor={`checkbox${quspartId+''+'1'}`}>Yes</label>
                                                  </div>
                                              
                                              </>
                                          
                                  {!isAtLeastOneOptionChecked(InnerActivityData.diagnostic_id) && (
                                      <p style={{ color: 'red' }}>Please select one option</p>
                                  )}
                                  </div>
                            </div>
                            <div className="action-btns d-flex justify-content-end gap-4 mt-5">
                                      {IsSubmitbtn ? 
                                        <Button className="btn btn-danger cs-btn-danger mt-1 w-20" onClick={() => {
                                          tog_standard1()
                                        }}>
                                          SUBMIT
                                        </Button>
                                      : null}
                                    </div>
                            </li>
                        </ul>

                        <div className="action-btns d-flex justify-content-between gap-4 mt-5">
                            {/* Render a "Prev" button if the active index is greater than 0 */}
                            {activeindex + 1 > 1 ? 
                              <Button className="btn btn-danger cs-btn-danger mt-1" onClick={() => {
                                toggleCustom(activeindex)
                              }}>
                                Prev
                              </Button>
                             : null}

                            {/* Render a "Next" button if the active index is less than the total length of LessonActivitiesQuestions array */}
                            {activeindex + 1 <  ActivityData.LessonActivitiesQuestions.length ? (
                              <Button className="btn btn-danger cs-btn-danger mt-1" onClick={() => {
                                toggleCustom((activeindex + 1)+ 1)
                              }}>
                                Next
                              </Button>
                            ) : null}
                          </div>

                      </div>
                      </TabPane>)

                    }else{
                      return(<p className="text-center">No question</p>)
                    }
                  }
              })
            }else if(ActivityData1 && ActivityData1.fk_sub_id && parseInt(ActivityData1.fk_sub_id) === 2){
              return ActivityData1.question.map(
                (InnerActivityData, index) => {
                  if (index === 0) {
                    if(InnerActivityData && parseInt(InnerActivityData.diagnostic_category_id) === 0){
                      //check box start
                      const quspartId = InnerActivityData.diagnostic_id; // Assuming you have some unique identifier for quspart
                      let isOptionNoChecked = '';
                      let isOptionYesChecked = '';
                      isOptionNoChecked = selectedOptions[quspartId] === quspartId+'-'+'0';
                      isOptionYesChecked = selectedOptions[quspartId] === quspartId+'-'+'1';
                      //check box end
                      const stuAnswersarray = InnerActivityData.StudentAnsAttempts.map(stuAns => {
                        try {
                            return JSON.parse(stuAns.student_answer);
                            } catch (error) {
                                console.error('Error parsing JSON:', error);
                                return null; // or handle the error in an appropriate way
                            }
                        }) || []; // Extracting session keys
                       
                        // Assuming stuAnswersarray is a nested array
                        const flattenedStuAnswers = [].concat(...stuAnswersarray);
                       // console.log(activeindex + 1)
                      return(
                        <TabPane tabId={activeindex + 1} key={activeindex + 1} className={parseInt(customActiveTab) === activeindex + 1 ? 'active' : ''}>
                      <div className="hw-list mt-4 pb-100">
                            
                        <ul className="list-unstyled mb-0">
                          <li className="justify-content-between">
                            <div className="d-flex align-items-start gap-4 justify-content-between">
                              <p className="mb-0 text-uppercase font-size-12">
                                {InnerActivityData.diagnostic_new_topic && InnerActivityData.diagnostic_new_topic.level_name ? 
                                InnerActivityData.diagnostic_new_topic.level_name +' /' : null}
                                
                                {InnerActivityData.diagnostic_new_topic && InnerActivityData.diagnostic_new_topic.topic_name ? 
                                InnerActivityData.diagnostic_new_topic.topic_name +' /' : null}
        
                                {InnerActivityData.diagnostic_new_topic && InnerActivityData.diagnostic_new_topic.sub_topics_name ? 
                                InnerActivityData.diagnostic_new_topic.sub_topics_name +' /' : null}
        
                              </p>
                              <div className="mb-0 d-flex align-items-center gap-2">
                                <p className="mb-0">#{InnerActivityData.diagnostic_id ? 
                                InnerActivityData.diagnostic_id : null}</p>
                                <span  >
                                  <i className="mdi mdi-flag-variant-outline font-size-18"></i>
                                </span>
                              
                              </div>
                            </div>
        
                            <div className="mt-4" dangerouslySetInnerHTML={{
                                __html: InnerActivityData.diagnostic_text ? InnerActivityData.diagnostic_text.replace(/\[(\d+)Answer char=\d+\]/g, '').replace(/\[Answer char=\d+\]/g, '') : null,
                              }}>
                              </div>
        
                               
        
                              <ul className="dg-selector width-controll list-unstyled mb-0">
                            { //ActivePartData && ActivePartData.map(
                                // (quspart, index) => {
                                  //const stuAnswersarray = quspart.StudentAnsAttempts.map(stuAns => stuAns.student_answer);
        
                                  
                                    //const stuAnswersarray = stuAnswersJson.length > 0 ? JSON.parse(stuAnswersJson) : [];
                                    InnerActivityData.diagnostic_answers && InnerActivityData.diagnostic_answers.map(
                                    (qusAns, index1) => {
                                        let filteredStuAns = flattenedStuAnswers.filter(stuAns => ((parseInt(stuAns.part_id) === parseInt(qusAns.answer_diagnostic_id)) 
                                        && (parseInt(stuAns.answer_id) === parseInt(qusAns.answer_id)))
                                        ) || [];
                                   
                                      var status_color = 'selector-general cursor-pointer';
                                      var border_color = 'border-start-secondary';
                                      if(filteredStuAns.length > 0){
                                        if(parseInt(qusAns.answer_is_correct) === 1){
                                          status_color = 'selector-success';
                                          border_color = 'border-start-success';
                                        }else{
                                          status_color = 'selector-wrong ';
                                          border_color = 'border-start-danger';
                                        }
                                      }
                                    //const sesKeys = quspart.StudentAnsAttempts.map(stuAns => stuAns.part_id); // Extracting session keys
                                    return(<li className={status_color+ ' choose e_choose'}  key={index1} >
                                      <div className="p-2 px-3 font-size-18">{index1 + 1}</div>
                                      <div className={"p-2 dg-media "+{border_color}} dangerouslySetInnerHTML={{
                                            __html: qusAns.answer_text ? qusAns.answer_text : null,
                                          }}>
                                        
                                      </div>
                                    </li>)
                                    })
                                    
        
                                //})
                              }
                            
                            </ul>
                            
                           
                            {InnerActivityData.StudentAnsAttempts.length > 0 ? 
                            InnerActivityData.StudentAnsAttempts.map((stuAns, stuindex) => {
                              return(<>
                           <div key={stuindex}>
                            <h4 className="font-size-20 fw-bold mb-4 mt-3">
                                Why did you choose this option?
                              </h4>
                              <Input
                                style={{
                                  height: "150px",
                                  border: "1px solid #1a1a1a",
                                  borderRadius: "4px",
                                  fontSize: "18px",
                                }}
                                type="textarea"
                                value={stuAns.answer_reason}
                                id="textarea"
                                onChange={e => {
                                  
                                }}
                                disabled ={true}
                                maxLength="225"
                                rows="3"
                              />
                              </div> 

                             
                              <div>
                              <h4 className="font-size-20 fw-bold mb-4 mt-4">
                                How confident are you in your answer?
                              </h4>

                              <div className="emotion-wrapper pb-50">
                                <ul className="list-unstyled mb-0">
                                  <li>
                                    <img className={parseInt(stuAns.answer_confident) === 1 ? 'active-img' : ''} src={parseInt(stuAns.answer_confident) === 1 ? ema1 : em1} alt="" />
                                    {parseInt(stuAns.answer_confident) === 1 ? <p className="text-center subject-float">I completely don’t know.</p> : null}
                                  </li>
                                  <li >
                                    <img className={parseInt(stuAns.answer_confident) === 2 ? 'active-img' : ''} src={parseInt(stuAns.answer_confident) === 2 ? ema2 : em2} alt="" />
                                    {parseInt(stuAns.answer_confident) === 2 ? <p className="text-center subject-float">I'm not sure if this is correct.</p> : null}
                                  </li>
                                  <li >
                                    <img className={parseInt(stuAns.answer_confident) === 3 ? 'active-img' : ''} src={parseInt(stuAns.answer_confident) === 3 ? ema3 : em3} alt="" />
                                    {parseInt(stuAns.answer_confident) === 3 ? <p className="text-center subject-float">I think I might be wrong.</p> : null}
                                  </li>
                                  <li>
                                    <img className={parseInt(stuAns.answer_confident) === 4 ? 'active-img' : ''} src={parseInt(stuAns.answer_confident) === 4 ? ema4 : em4} alt="" />
                                    {parseInt(stuAns.answer_confident) === 4 ? <p className="text-center subject-float">I think this is the right answer.</p> : null}
                                  </li>
                                  <li>
                                    <img className={parseInt(stuAns.answer_confident) === 5 ? 'active-img' : ''} src={parseInt(stuAns.answer_confident) === 5 ? ema5 : em5} alt="" />
                                    {parseInt(stuAns.answer_confident) === 5 ? <p className="text-center subject-float">This is definitely the right answer!</p> : null}
                                  </li>
                                </ul>
                              </div>

                              </div> 
                              </>);
                            })
                            :

                          <>
                           <div>
                            <h4 className="font-size-20 fw-bold mb-4 mt-3">
                                Why did you choose this option?
                              </h4>
                              <Input
                                style={{
                                  height: "150px",
                                  border: "1px solid #1a1a1a",
                                  borderRadius: "4px",
                                  fontSize: "18px",
                                }}
                                type="textarea"
                                value={''}
                                id="textarea"
                                onChange={e => {
                                  
                                }}
                                disabled ={true}
                                maxLength="225"
                                rows="3"
                              />
                              </div> 

                             
                              <div>
                              <h4 className="font-size-20 fw-bold mb-4 mt-4">
                                How confident are you in your answer?
                              </h4>

                              <div className="emotion-wrapper pb-50">
                                <ul className="list-unstyled mb-0">
                                  <li>
                                    <img className={''} src={em1} alt="" />
                                   
                                  </li>
                                  <li >
                                    <img className={''} src={em2} alt="" />
                                    
                                  </li>
                                  <li >
                                    <img className={''} src={em3} alt="" />
                                    
                                  </li>
                                  <li>
                                    <img className={''} src={em4} alt="" />
                                    
                                  </li>
                                  <li>
                                    <img className={''} src={em5} alt="" />
                                   
                                  </li>
                                </ul>
                              </div>

                              </div> 
                              </>
                          }
        
                            {/* {!nextQuestion ? <><h4 className="font-size-16 fw-bold mb-2 mt-3">
                              Solution
                            </h4>
                            <div className="e_feedBack" style={{
                                    
                                    border: "1px solid #1a1a1a",
                                    borderRadius: "4px",
                                    fontSize: "16px",
                                  }} 
                            >
                              { ActivityData.diagnostic_answers
                                .filter(CorrAns => parseInt(CorrAns.answer_is_correct) === 1)
                                .map((qusAns, index1) => (
                                  <div key={index1} dangerouslySetInnerHTML={{
                                    __html: qusAns.answer_text,
                                  }}></div>
                                ))}
        
        
                              </div></> : null } */}
        
                            {/* <div className="text-end mt-3 mb-5">
                            {ConfidentEM && nextQuestion && ConfidentEM!=null && ConfidentEM!='' && ConfidentEM > 0 ? <Button className="btn btn-danger cs-btn-danger mt-1" disabled={IsSubmited} onClick={() => {triggerSubmitFunction([{Answer:selectedAnswerText, ResonTestAnswer: ResonAnswerTextData, Confident : ConfidentEM}]); setIsSubmited(true);}}>
                                Submit
                              </Button> : null }
                            </div> */}
        
                          
                          <div className="mark_over_option mb-4 mt-4 p-2 gap-3 border" data_count="mark_section">
                              <h6 className="mb-3">Is this question incorrect, or does it contain any errors?</h6>
                                <div className="d-flex gap-3 ">

                          
                                        <>
                                            <div key={quspartId+''+'0'} className="d-flex gap-3">
                                          
                                              <input type="checkbox" id={`checkbox${quspartId+''+'0'}}`} name={`checkbox${quspartId}`}
                                              checked={isOptionNoChecked}
                                              onChange={() => handleCheckboxChange(quspartId, quspartId+'-'+'0', 0)}
                                                />
                                              <label htmlFor={`checkbox${quspartId}`}> No</label>
                                            </div> 
                                              
                                                <div key={quspartId+''+'1'} className="d-flex gap-3">
                                                      <input type="checkbox" id={`checkbox${quspartId+''+'1'}`} name={`checkbox${quspartId+''+'1'}`} 
                                                      checked={isOptionYesChecked}
                                                      onChange={() => handleCheckboxChange(quspartId, quspartId+'-'+'1', 5)}/>
                                                      <label htmlFor={`checkbox${quspartId+''+'1'}`}>Yes</label>
                                                  </div>
                                              
                                              </>
                                          
                                  {!isAtLeastOneOptionChecked(InnerActivityData.diagnostic_id) && (
                                      <p style={{ color: 'red' }}>Please select one option</p>
                                  )}
                                  </div>
                            </div>
                            <div className="action-btns d-flex justify-content-end gap-4 mt-5">
                                      {IsSubmitbtn ? 
                                        <Button className="btn btn-danger cs-btn-danger mt-1 w-20" onClick={() => {
                                          tog_standard1()
                                        }}>
                                          SUBMIT
                                        </Button>
                                      : null}
                                    </div>
                            </li>
                        </ul>
                        <div className="action-btns d-flex justify-content-between gap-4 mt-5">
                            {/* Render a "Prev" button if the active index is greater than 0 */}
                            {activeindex + 1 > 1 ? 
                              <Button className="btn btn-danger cs-btn-danger mt-1" onClick={() => {
                                toggleCustom(activeindex)
                              }}>
                                Prev
                              </Button>
                             : null}

                            {/* Render a "Next" button if the active index is less than the total length of LessonActivitiesQuestions array */}
                            {activeindex + 1 <  ActivityData.LessonActivitiesQuestions.length ? (
                              <Button className="btn btn-danger cs-btn-danger mt-1" onClick={() => {
                                toggleCustom((activeindex + 1)+ 1)
                              }}>
                                Next
                              </Button>
                            ) : null}
                          </div>

                      </div></TabPane>)
        
                    }else{
                      return(<p className="text-center">No question</p>)
                    }
                  }
              })
            }
        })
    : null}
             
          </TabContent>

       {/* <Comprehension triggerFunction={handleFunctionTrigger} triggerSubmitFunction={handleSubmitFunction} data={ActiviteQAData} part={ActivityPart} responceData={submitOtherData} key={activityKey}/> */}
      </div>
    </Container>
  </div>
  </MathJax>
</React.Fragment>
)
})


export default FlagDiagnosticsDetails