import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import jwtDecode from 'jwt-decode';
// users
import user1 from "../../../assets/images/users/user-4.jpg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");

  function decodeAccessToken(token) {
    try {
      const decoded = jwtDecode(token);
      return { success: true, data: decoded };
    } catch (error) {
      return { success: false, error: error.message };
    }
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      //   const obj = JSON.parse(localStorage.getItem("authUser"));
      //   setusername(obj.displayName);
      // } else if (
      //   process.env.REACT_APP_DEFAULTAUTH === "fake" ||
      //   process.env.REACT_APP_DEFAULTAUTH === "jwt"
      // ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const jwtdecode=decodeAccessToken(obj);

      
        setusername(jwtdecode.data);
      //}
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        {/* <DropdownToggle
          className="btn header-item waves-effect custom-hd-dd"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
         <div className="text-start">
         <h5 className="mb-0">{username ? username.tut_fname ? username.tut_fname+' '+username.tut_surname : '' : ''}</h5>
          <small>{username ? username.ta_login_id ? username.ta_login_id : '' : ''}</small>
         </div>
        </DropdownToggle> */}
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="mdi mdi-account-circle font-size-17 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>


          <div className="dropdown-divider m-0" />
          <Link to="/logout" className="dropdown-item text-danger">
            <i className="bx bx-power-off font-size-17 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
