import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Form,
  FormFeedback,
  Label,
  Input
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { connect, useDispatch } from "react-redux";
import withRouter from 'components/Common/withRouter';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/user-4.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

//Import Editable
import Editable from "react-bootstrap-editable";




const UserProfile = props => {
  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid || 1);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username);
        setemail(obj.email);
        setidx(obj.uid || 1);
      }
      setTimeout(() => {
        props.resetProfileFlag();
      }, 3000);
    }
  }, [props.success]);


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || '',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your User Name"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    }
  });

  document.title = "Profile | Tutor";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Veltrix" breadcrumbItem="Profile" />

          <Row>
            <Col lg="3">
              <Row className=''>
                  
              <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-1">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>

                  <div className="mx-1 mt-3">

                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h6>Preferred name</h6>
                        <Editable
                              alwaysEditing={false}
                              disabled={false}
                              editText="edit"
                              id={null}
                              isValueClickable={false}
                              mode="inline"
                              placement="top"
                              showText
                              type="textfield"
                           />                    
                      </div>
                    </div>
                    
                    <div className="align-self-center flex-1 mt-3">
                      <div className="text-muted">
                        <h6>Summary</h6>
                        <Editable
                              alwaysEditing={false}
                              disabled={false}
                              editText="edit"
                              id={null}
                              isValueClickable={false}
                              mode="inline"
                              placement="top"
                              showText
                              type="textfield"
                           />                    
                      </div>
                    </div>

                  </div>

                </CardBody>
              </Card>
              </Col>
              
              <Col lg="12 mt-2 mb-2">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-1">

                    <div className="align-self-center">
                      <div className="text-muted">
                        <h5>About Tutor</h5>
                        
                        <div className='mt-2'>

                        <textarea id="txtAddress1Billing" name="txtAddress1Billing"
                        rows="5" cols="100" className="form-control" placeholder="tutor's notes"></textarea>
                        </div>

                        
                     
                      </div>
                    </div>
                     
                    </div>
                    
                  </div>

                 

                </CardBody>
              </Card>
              </Col>

              </Row>
            </Col>

            <Col lg="9">          
              <Card>            
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="form-group">
                    <h4 className="card-title">Change User Name</h4>
                    <h6 className='mt-3 mb-3 border-top pt-2'>Account Information</h6>

                    <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-lg-2 col-md-2 col-sm-12  col-form-label"
                            >
                              User Name
                            </label>
                            <div className="col-lg-10 col-md-9 col-sm-12 ">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Artisanal kale"
                              />
                            </div>
                          </Row> 

                      <h6 className='mt-3 mb-3 border-top pt-2'>Personal Information</h6>   

                      <Row className="mb-3">

                        <Col className='col-6'>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-lg-3 col-md-4 col-sm-12  col-form-label"
                            >
                              Salutation
                            </label>
                            <div className="col-lg-8 col-md-8 col-sm-12 ">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="-"
                              />
                            </div>
                          </Row> 
                        </Col>
                        <Col className='col-6'>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-lg-3 col-md-4   col-sm-12 col-form-label"
                            >
                             First Name
                            </label>
                            <div className="col-lg-8 col-md-8 col-sm-12 ">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="First Name"
                              />
                            </div>
                          </Row> 
                          

                        </Col>

<Col className='col-6'>

  <Row className="mb-3">
    <label
      htmlFor="example-text-input"
      className="col-lg-3 col-md-4  col-sm-12 col-form-label"
    >
     Last Name
    </label>
    <div className="col-lg-8 col-md-8 col-sm-12 ">
      <input
        className="form-control"
        type="text"
        placeholder="Last Name"
      />
    </div>
  </Row> 
  

</Col>
<Col className='col-6'>

  <Row className="mb-3">
    <label
      htmlFor="example-text-input"
      className="col-lg-3 col-md-4  col-form-label"
    >
      Birth date
    </label>
    <div className="col-lg-8 col-md-8 col-sm-12 ">
      <input
        className="form-control"
        type="text"
        placeholder="Birth date"
      />
    </div>
  </Row> 
  

</Col>



                      </Row>    

                      
                      <h6 className='mt-3 mb-3 border-top pt-2'>Address Information</h6>   

                      <Row className="mb-3">

                        <Col className='col-6'>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-lg-3 col-md-4 col-sm-12  col-form-label"
                            >
                              Country
                            </label>
                            <div className="col-lg-8 col-md-8 col-sm-12 ">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Country"
                              />
                            </div>
                          </Row> 
                        </Col>
                        <Col className='col-6'>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-lg-3 col-md-4 col-sm-12 col-form-label"
                            >
                              State
                            </label>
                            <div className="col-lg-8 col-md-8 col-sm-12 ">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="State"
                              />
                            </div>
                          </Row> 
                          

                        </Col>

<Col className='col-6'>

  <Row className="mb-3">
    <label
      htmlFor="example-text-input"
      className="col-lg-3 col-md-4 col-sm-12 col-form-label"
    >
      City
    </label>
    <div className="col-lg-8 col-md-8 col-sm-12 ">
      <input
        className="form-control"
        type="text"
        placeholder="City"
      />
    </div>
  </Row> 
  

</Col>
<Col className='col-6'>

  <Row className="mb-3">
    <label
      htmlFor="example-text-input"
      className="col-lg-3 col-md-4 col-sm-12 col-form-label"
    >
      Postcode
    </label>
    <div className="col-lg-8 col-md-8 col-sm-12 ">
      <input
        className="form-control"
        type="text"
        placeholder="Postcode"
      />
    </div>
  </Row> 
  

</Col>



                      </Row>   


                                            <h6 className='mt-3 mb-3 border-top pt-2'>Contact Information</h6>   

                      <Row className="mb-3">

                        <Col className='col-6'>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-lg-3 col-md-4  col-sm-12 col-form-label">
                              Home Phone
                            </label>
                            <div className="col-lg-8 col-md-8 col-sm-12 ">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Home Phone"
                              />
                            </div>
                          </Row> 
                        </Col>
                        <Col className='col-6'>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-lg-3 col-md-4 col-sm-12 col-form-label"
                            >
                              Work Phone
                            </label>
                            <div className="col-lg-8 col-md-8 col-sm-12 ">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Work Phone"
                              />
                            </div>
                          </Row> 
                          

                        </Col>

<Col className='col-6'>

  <Row className="mb-3">
    <label
      htmlFor="example-text-input"
      className="col-lg-3 col-md-4  col-sm-12 col-form-label"
    >
     Mobile
    </label>
    <div className="col-lg-8 col-md-8 col-sm-12 ">
      <input
        className="form-control"
        type="text"
        placeholder="Mobile"
      />
    </div>
  </Row> 
  

</Col>
<Col className='col-6'>

  <Row className="mb-3">
    <label
      htmlFor="example-text-input"
      className="col-lg-3 col-md-4 col-sm-12 col-form-label"
    >
      Email
    </label>
    <div className="col-lg-8 col-md-8 col-sm-12 ">
      <input
        className="form-control"
        type="text"
        placeholder="Email"
      />
    </div>
  </Row> 
  

</Col>



                      </Row>                   
                

                    </div>



                    
                     
                    <div className="text-right mt-4">
                      <Button type="submit" color="danger">
                        Edit User Name
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>  

          </Row>









        </Container>
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
);
