import PropTypes from 'prop-types';
import React, { useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Table
} from "reactstrap";

import Switch from "react-switch";
import "chartist/dist/scss/chartist.scss";
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import st1 from "../../assets/images/services-icon/st1.png";
import st2 from "../../assets/images/services-icon/st2.png";
import duration from "../../assets/images/services-icon/duration.png";
import evelaa from "../../assets/images/services-icon/evaluation.png";
import vid from "../../assets/images/services-icon/video.png";
import reel from "../../assets/images/services-icon/reel.png";

import awicon from "../../assets/images/services-icon/coin.png";

const SessionHomework = props => {
  const [singlebtn, setSinglebtn] = useState(false);
  const [textareabadge, settextareabadge] = useState(0);
  const [textcount, settextcount] = useState(0);
  const [modal_center, setmodal_center] = useState(false);

  const [switch2, setswitch2] = useState(true);
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}

      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}

      </div>
    );
  };

  const tog_center = () => {
    setmodal_center(!modal_center);
    removeBodyCss();
  };

  function textareachange(event) {
    const count = event.target.value.length;
    if (count > 0) {
      settextareabadge(true);
    } else {
      settextareabadge(false);
    }
    settextcount(event.target.value.length);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box mb-4">
            <div className='custom-pg_header flex-wrap gap-3'>
              <div>
                <div className='d-flex align-items-center gap-2'>
                  <Button color="light" className='bck-btn'>
                    <i className='fas fa-chevron-left'></i>
                  </Button>
                  <div >
                    <h6 className="page-title mb-2"> Edit Session Plan</h6>
                    <p className='mb-0'>In Center</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className='d-flex align-items-center justify-content-between p-2 bg-white mb-3'>
            <div className='d-flex gap-5 align-items-center'>
              <div className=' pe-3'>
                <h6 class="mb-0 font-size-14 text-dark">David Mon 10:00</h6>
                <p class="mb-0 font-size-12">In centre</p>

              </div>
              <div className='text-end pe-3'>
                <h6 class="mb-0 font-size-14 text-dark">05 Feb 2024 (Mon)</h6>
                <p class="mb-0 font-size-12">10am - 11am (60 mins)</p>

              </div>
            </div>

            <div className='d-flex align-items-center gap-2'>
              <div>
                <img className='rounded-circle avatar-xs' src={st2} alt="" />
              </div>
              <div>
                <h6 class="mb-0 font-size-16">Jimmy Carter</h6>
                <p class="mb-0">10 School A</p>
              </div>
            </div>
          </div>
          <div className='ehp-height'>

            <div className="kanban-wrapper d-flex gap-3 overflow-auto">
              <div className='kb-child ehp-ch_height ehp-w p-0'>
                <div className='ss-details border-bottom1 pb-3 bg-white p-2 mb-3'>
                  <h5 className='mb-0 text-dark'>Past Session</h5>
                  <div className='navigator d-flex align-items-center justify-content-between mb-3'>
                    <span className='bg-white p-1 px-2 rounded-4'><i className='mdi mdi-chevron-left'></i></span>
                    <div className=''>16th Oct 2024</div>
                    <span className='bg-white p-1 px-2 rounded-4'><i className='mdi mdi-chevron-right'></i></span>
                  </div>

                  <div className='d-flex justify-content-between align-items-end'>
                    <div className='align-items-center gap-2 flex-wrap '>
                      <span class="badge bg-danger font-size-12">Missed</span>
                      <h6 className='mb-0 font-size-14'>David Mon 10:00</h6>
                      <p className='font-size-12'>In Center</p>
                    </div>
                    <div className='text-end'>

                      <h6 className='font-size-12'>22 Jan 2024 (Monday)</h6>
                      <p className='font-size-12'>10am - 11am (60 mins )</p>
                    </div>
                  </div>
                </div>

                <div className='lesson-plan-wrapper bg-white p-2'>
                  <div className='section-divider'>
                    <div className='border-bottom pb-3 mb-2'>
                      <h6 className='mb-0 text-dark'>Lesson Plan</h6>
                    </div>
                    <div className='d-flex justify-content-between list-unstyled mb-0'>
                      <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                      <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>

                    </div>

                    <small className='mt-2 mb-2'>All activities completed (100%) </small>

                    <Button className='btn btn-secondary w-100' color="" onClick={tog_center}>  View Lesson Report </Button>
                    <Modal isOpen={modal_center} toggle={tog_center} centered>
                      <ModalHeader className="mt-0" toggle={tog_center}>View Lesson Report</ModalHeader>
                      <ModalBody>
                        <div className="table-responsive">
                          <Table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th>Topic</th>
                                <th>Questions Completed</th>
                                <th>Mastery Score</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Speed</th>
                                <td>16</td>
                                <td>67</td>
                              </tr>
                              <tr>
                                <th scope="row">Numbers up to 100</th>
                                <td>15</td>
                                <td>45</td>
                              </tr>
                              <tr>
                                <th scope="row">Fractions</th>
                                <td>1</td>
                                <td>67</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </ModalBody>
                    </Modal>

                  </div>
                  <div className='events rm-heignt border-bottom1 pb-3 mb-3 mt-0'>
                    <div className='set1 bg-supermild'>

                      <h6 className='text-dark border-bottom pb-2 mb-2 mt-2 font-size-14 lh-1'>Time 10A - Problems involving starting time, ending time and duration [Easy]</h6>

                      <div className=''>

                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={evelaa} alt="" /></span>Assessment</div>
                          <Dropdown
                            isOpen={singlebtn}
                            toggle={() => setSinglebtn(!singlebtn)}
                          >
                            <DropdownToggle className="p-0 bg-white">
                              <i className='mdi mdi-dots-vertical'></i>{" "}

                            </DropdownToggle>
                            <DropdownMenu className='custom-position'>
                              <DropdownItem>Preview</DropdownItem>
                              <DropdownItem>Delete</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className='d-flex justify-content-between'>

                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>

                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                        </div>

                      </div>

                      <div className='question-count mt-2'>

                        <ul className='list-unstyled mb-0 d-flex align-items-center gap-1 mt-1'>
                          <li>01</li>
                          <li>02</li>
                          <li>03</li>
                          <li>04</li>
                          <li>05</li>
                          <li>06</li>
                          <li>07</li>
                          <li>08</li>

                        </ul>
                      </div>
                    </div>
                    <div className='set1 bg-supermild'>

                      <h6 className='text-dark border-bottom pb-2 mb-2 mt-2 font-size-14 lh-1'>Time 10A - Problems involving starting time, ending time and duration [Easy]</h6>

                      <div className=''>

                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={evelaa} alt="" /></span>Assessment</div>
                          <Dropdown
                            isOpen={singlebtn}
                            toggle={() => setSinglebtn(!singlebtn)}
                          >
                            <DropdownToggle className="p-0 bg-white">
                              <i className='mdi mdi-dots-vertical'></i>{" "}

                            </DropdownToggle>
                            <DropdownMenu className='custom-position'>
                              <DropdownItem>Preview</DropdownItem>
                              <DropdownItem>Delete</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className='d-flex justify-content-between'>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                        </div>
                      </div>

                      <div className='question-count mt-2'>
                        <ul className='list-unstyled mb-0 d-flex align-items-center gap-1 mt-1'>
                          <li>01</li>
                          <li>02</li>
                          <li>03</li>
                          <li>04</li>
                          <li>05</li>
                          <li>06</li>
                          <li>07</li>
                          <li>08</li>
                        </ul>
                      </div>
                    </div>
                    <div className='set1 bg-supermild'>

                      <h6 className='text-dark border-bottom pb-2 mb-2 mt-2 font-size-14 lh-1'>Time 10A - Problems involving starting time, ending time and duration [Easy]</h6>

                      <div className=''>

                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={evelaa} alt="" /></span>Assessment</div>
                          <Dropdown
                            isOpen={singlebtn}
                            toggle={() => setSinglebtn(!singlebtn)}
                          >
                            <DropdownToggle className="p-0 bg-white">
                              <i className='mdi mdi-dots-vertical'></i>{" "}

                            </DropdownToggle>
                            <DropdownMenu className='custom-position'>
                              <DropdownItem>Preview</DropdownItem>
                              <DropdownItem>Delete</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className='d-flex justify-content-between'>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                        </div>
                      </div>

                      <div className='question-count mt-2'>
                        <ul className='list-unstyled mb-0 d-flex align-items-center gap-1 mt-1'>
                          <li>01</li>
                          <li>02</li>
                          <li>03</li>
                          <li>04</li>
                          <li>05</li>
                          <li>06</li>
                          <li>07</li>
                          <li>08</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='homework-plan-wrapper bg-white p-2 mt-3'>
                  <div className='section-divider'>
                    <div className='border-bottom pb-3 mb-2'>
                      <h6 className='mb-0 text-dark'>Homework Plan</h6>
                    </div>
                    <div className='d-flex justify-content-between list-unstyled mb-0'>
                      <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                      <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>

                    </div>

                    <small className='mt-2 mb-2'>All activities completed (100%) </small>

                    <Button className='btn btn-secondary w-100' color="" onClick={tog_center}>  View Lesson Report </Button>
                    <Modal isOpen={modal_center} toggle={tog_center} centered>
                      <ModalHeader className="mt-0" toggle={tog_center}>View Lesson Report</ModalHeader>
                      <ModalBody>
                        <div className="table-responsive">
                          <Table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th>Topic</th>
                                <th>Questions Completed</th>
                                <th>Mastery Score</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Speed</th>
                                <td>16</td>
                                <td>67</td>
                              </tr>
                              <tr>
                                <th scope="row">Numbers up to 100</th>
                                <td>15</td>
                                <td>45</td>
                              </tr>
                              <tr>
                                <th scope="row">Fractions</th>
                                <td>1</td>
                                <td>67</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </ModalBody>
                    </Modal>

                  </div>
                  <div className='events rm-heignt border-bottom1 pb-3 mb-3 mt-0'>
                    <div className='set1 bg-supermild'>

                      <h6 className='text-dark border-bottom pb-2 mb-2 mt-2 font-size-14 lh-1'>Time 10A - Problems involving starting time, ending time and duration [Easy]</h6>

                      <div className=''>

                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={evelaa} alt="" /></span>Assessment</div>
                          <Dropdown
                            isOpen={singlebtn}
                            toggle={() => setSinglebtn(!singlebtn)}
                          >
                            <DropdownToggle className="p-0 bg-white">
                              <i className='mdi mdi-dots-vertical'></i>{" "}

                            </DropdownToggle>
                            <DropdownMenu className='custom-position'>
                              <DropdownItem>Preview</DropdownItem>
                              <DropdownItem>Delete</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className='d-flex justify-content-between'>

                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>

                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                        </div>

                      </div>

                      <div className='question-count mt-2'>

                        <ul className='list-unstyled mb-0 d-flex align-items-center gap-1 mt-1'>
                          <li>01</li>
                          <li>02</li>
                          <li>03</li>
                          <li>04</li>
                          <li>05</li>
                          <li>06</li>
                          <li>07</li>
                          <li>08</li>

                        </ul>
                      </div>
                    </div>
                    <div className='set1 bg-supermild'>

                      <h6 className='text-dark border-bottom pb-2 mb-2 mt-2 font-size-14 lh-1'>Time 10A - Problems involving starting time, ending time and duration [Easy]</h6>

                      <div className=''>

                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={evelaa} alt="" /></span>Assessment</div>
                          <Dropdown
                            isOpen={singlebtn}
                            toggle={() => setSinglebtn(!singlebtn)}
                          >
                            <DropdownToggle className="p-0 bg-white">
                              <i className='mdi mdi-dots-vertical'></i>{" "}

                            </DropdownToggle>
                            <DropdownMenu className='custom-position'>
                              <DropdownItem>Preview</DropdownItem>
                              <DropdownItem>Delete</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className='d-flex justify-content-between'>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                        </div>
                      </div>

                      <div className='question-count mt-2'>
                        <ul className='list-unstyled mb-0 d-flex align-items-center gap-1 mt-1'>
                          <li>01</li>
                          <li>02</li>
                          <li>03</li>
                          <li>04</li>
                          <li>05</li>
                          <li>06</li>
                          <li>07</li>
                          <li>08</li>
                        </ul>
                      </div>
                    </div>
                    <div className='set1 bg-supermild'>

                      <h6 className='text-dark border-bottom pb-2 mb-2 mt-2 font-size-14 lh-1'>Time 10A - Problems involving starting time, ending time and duration [Easy]</h6>

                      <div className=''>

                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={evelaa} alt="" /></span>Assessment</div>
                          <Dropdown
                            isOpen={singlebtn}
                            toggle={() => setSinglebtn(!singlebtn)}
                          >
                            <DropdownToggle className="p-0 bg-white">
                              <i className='mdi mdi-dots-vertical'></i>{" "}

                            </DropdownToggle>
                            <DropdownMenu className='custom-position'>
                              <DropdownItem>Preview</DropdownItem>
                              <DropdownItem>Delete</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className='d-flex justify-content-between'>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>
                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                        </div>
                      </div>

                      <div className='question-count mt-2'>
                        <ul className='list-unstyled mb-0 d-flex align-items-center gap-1 mt-1'>
                          <li>01</li>
                          <li>02</li>
                          <li>03</li>
                          <li>04</li>
                          <li>05</li>
                          <li>06</li>
                          <li>07</li>
                          <li>08</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='kb-child ehp-ch_height ehp-w bg-white p-2'>
                <div className='ss-details border-bottom1 mb-2'>

                  <h5 className='mb-0 text-dark'>Lesson Plan</h5>
                  <div className="mt-3">
                    <Label className='mb-1'>Message From Tutor</Label>

                    <Input
                      type="textarea"
                      id="textarea"
                      onChange={e => {
                        textareachange(e);
                      }}
                      maxLength="225"
                      rows="3"
                      value="Tutors Message will be displayed here"
                    />
                    {textareabadge ? (
                      <span className="badgecount badge badge-success">
                        {" "}
                        {textcount} / 225{" "}
                      </span>
                    ) : null}
                  </div>



                </div>

                <div className='lesson-plan-wrapper bg-white p-2'>
                  <div className='section-divider'>

                    <div className='d-flex justify-content-between list-unstyled mb-2'>
                      <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                      <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>

                    </div>
                    <div className='d-flex gap-5 mb-3 align-items-center justify-content-between'>
                      <div className=' pe-3'>
                        <h6 class="mb-0 font-size-14 text-dark">David Mon 10:00</h6>
                        <p class="mb-0 font-size-12">In centre</p>

                      </div>
                      <div className='text-end'>
                        <h6 class="mb-0 font-size-14 text-dark">05 Feb 2024 (Mon)</h6>
                        <p class="mb-0 font-size-12">10am - 11am (60 mins)</p>

                      </div>
                    </div>

                    <div>
                      Random  <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#a2a2a2"
                        onChange={() => {
                          setswitch2(!switch2);
                        }}
                        checked={switch2}
                      />
                    </div>
                    <Link to={"/session-newactivity"} className='btn btn-secondary w-100'>
                    Add New Activity
                    </Link>

                  </div>
                  <div className='events border-bottom1 pb-3 mb-3 mt-0'>

                    <div className='set1 bg-supermild'>
                      <span class="text-success font-size-12">Completed</span>
                      <h6 className='text-dark border-bottom pb-2 mb-2 mt-2 font-size-14 lh-1'>Addition of Whole Numbers 12A - Add two numbers within 100 (no renaming)</h6>

                      <div className='d-flex justify-content-between align-items-center mb-2'>
                        <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={reel} alt="" /></span>Video</div>
                        <Dropdown
                          isOpen={singlebtn}
                          toggle={() => setSinglebtn(!singlebtn)}
                        >
                          <DropdownToggle className="p-0 bg-white">
                            <i className='mdi mdi-dots-vertical'></i>{" "}

                          </DropdownToggle>
                          <DropdownMenu className='custom-position'>
                            <DropdownItem>Preview</DropdownItem>
                            <DropdownItem>Delete</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <div className='d-flex justify-content-between align-items-center mb-2'>
                       

                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>

                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                        

                      </div>

                      <div className='question-count mt-2'>
                       
                        <ul className='list-unstyled mb-0 d-flex align-items-center gap-1 mt-1'>
                          <li>01</li>
                          <li>02</li>
                          <li>03</li>
                          <li>04</li>
                          <li>05</li>
                          <li>06</li>
                          <li>07</li>
                          <li>08</li>
                          <li>09</li>
                          <li>10</li>
                          <li className='bg-warning'>11</li>
                          <li>12</li>
                          <li>13</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

              <div className='kb-child ehp-ch_height ehp-w bg-white p-2'>
                <div className='ss-details border-bottom1 mb-2'>

                  <h5 className='mb-0 text-dark'>HomeWork</h5>
                  <div className="mt-3">
                    <Label className='mb-1'>Message From Tutor</Label>

                    <Input
                      type="textarea"
                      id="textarea"
                      onChange={e => {
                        textareachange(e);
                      }}
                      maxLength="225"
                      rows="3"
                      value="Tutors Message will be displayed here"
                    />
                    {textareabadge ? (
                      <span className="badgecount badge badge-success">
                        {" "}
                        {textcount} / 225{" "}
                      </span>
                    ) : null}
                  </div>



                </div>

                <div className='lesson-plan-wrapper bg-white p-2'>
                  <div className='section-divider'>

                    <div className='d-flex justify-content-between list-unstyled mb-2'>
                      <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                      <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>

                    </div>
                    <div className='d-flex gap-5 mb-3 align-items-center justify-content-between'>
                      <div className=' pe-3'>
                        <h6 class="mb-0 font-size-14 text-dark">David Mon 10:00</h6>
                        <p class="mb-0 font-size-12">In centre</p>

                      </div>
                      <div className='text-end'>
                        <h6 class="mb-0 font-size-14 text-dark">05 Feb 2024 (Mon)</h6>
                        <p class="mb-0 font-size-12">10am - 11am (60 mins)</p>

                      </div>
                    </div>

                    <div>
                      Random  <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#a2a2a2"
                        onChange={() => {
                          setswitch2(!switch2);
                        }}
                        checked={switch2}
                      />
                    </div>
                    <Button className='btn btn-secondary w-100' color=""> Add New Activity </Button>

                  </div>
                  <div className='events border-bottom1 pb-3 mb-3 mt-0'>

                    <div className='set1 bg-supermild'>
                      <span class="text-success font-size-12">Completed</span>
                      <h6 className='text-dark border-bottom pb-2 mb-2 mt-2 font-size-14 lh-1'>Addition of Whole Numbers 12A - Add two numbers within 100 (no renaming)</h6>

                      <div className='d-flex justify-content-between align-items-center mb-2'>
                        <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={reel} alt="" /></span>Video</div>
                        <Dropdown
                          isOpen={singlebtn}
                          toggle={() => setSinglebtn(!singlebtn)}
                        >
                          <DropdownToggle className="p-0 bg-white">
                            <i className='mdi mdi-dots-vertical'></i>{" "}

                          </DropdownToggle>
                          <DropdownMenu className='custom-position'>
                            <DropdownItem>Preview</DropdownItem>
                            <DropdownItem>Delete</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <div className='d-flex justify-content-between align-items-center mb-2'>
                       

                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'><span> <img width={18} src={duration} alt="" /></span>21 mins</div>

                          <div className='d-flex align-items-center gap-2 font-size-12 text-dark fw-400'> <img width={18} src={awicon} alt="" />0/18 </div>
                        

                      </div>

                      <div className='question-count mt-2'>
                       
                        <ul className='list-unstyled mb-0 d-flex align-items-center gap-1 mt-1'>
                          <li>01</li>
                          <li>02</li>
                          <li>03</li>
                          <li>04</li>
                          <li>05</li>
                          <li>06</li>
                          <li>07</li>
                          <li>08</li>
                          <li>09</li>
                          <li>10</li>
                          <li className='bg-warning'>11</li>
                          <li>12</li>
                          <li>13</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>

            <div className='btn-group mt-3'>
            <Button className='btn btn-outline-danger btn-lg bg-white me-2 px-3'>  Back </Button>
            <Button className='btn btn-primary btn-lg' color="primary">  Update </Button>
            </div>
          </div>
        </Container>
      </div>

    </React.Fragment>
  );
};

SessionHomework.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(SessionHomework);
