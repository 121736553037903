import React, { useEffect, useState, useMemo } from "react"

import {
  Container,
  Button,
} from "reactstrap"
import TableComm from "../../dataTableCommon"

import Select from "react-select"
import { useNavigate } from "react-router-dom"
import chevleft from "assets/images/services-icon/chev-left.png"
import { DatePicker, DateRangePicker, Stack } from "rsuite"
const { combine } = DatePicker
import axios from "axios"
import moment from "moment"
// import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from "react-spinner-loader"
import { SECURITY_KEY, BASE_URL } from "../../global";
const Timesheet = () => {
  const [loading, setLoading] = useState(true)
  // const [date, setDate] = useState(new Date());
  const [date, setDate] = useState([null, null])
  const optionGroup = [
    { label: "Select", value: "" },
    { label: "Paid", value: "1" },
    { label: "Not Paid", value: "0" },
  ]

  const [subject, setSubject] = useState("")
  const [type, setType] = useState("")
  const [status, setStatus] = useState("")
  //const [Alldata, setAllData] = useState([]);
  const [dataTableData, setDataTableData] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(5)
  //Kathir start
  const [data, setData] = useState([])
  const [records, setRecords] = useState(data)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [isFilter, setFilter] = useState(false)
  //Kathir end
  const [dateValue, setDateValue] = useState([null, null])
  const handleDateChange = value => {
    // Do something with the selected date range
    setDateValue(value)
  }


  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }
  const navigate = useNavigate()
  const authUser = JSON.parse(localStorage.getItem("authUser")) || null

  const fetchData = async () => {
    try {
      if (authUser && authUser != "" && authUser != null) {
        setLoading(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const response = await axios.post(
          BASE_URL+"timesheet/timeSheet",
          {
            draw: 1,
            start: 0,
            length: rowsPerPage,
            order: [{ column: 0, dir: "asc" }],
            columns: [
              { data: "session_date" },
              { data: "session_time" },
              { data: "sest_name" },
              { data: "total_student" },
              { data: "subject" },
              { data: "sest_type" },
              { data: "ses_is_completed" },
            ],
            search: { value: "" },
            startDate: date[0] || null,
            endDate: date[1] || null,

            subject: subject,
            type: type,
            status: status,

            // filterStartDate: startDate,
            // filterEndDate: endDate,
            filterStartDate: dateValue[0] ? moment.utc(dateValue[0]).format('YYYY-MM-DD') : null,
            filterEndDate: dateValue[1] ? moment.utc(dateValue[1]).format('YYYY-MM-DD') : null,

            filterstatus: selectedGroup,
          }
        )
        //console.log(response)
        if (response.data.length > 0) {
          setData(response.data)
          setRecords(response.data)
          // console.log(dataTableData)
          setLoading(false)
        } else {
          setRecords([])
          // console.log(dataTableData)
          setLoading(false)
        }
      } else {
        navigate("/login")
      }

      // setAllData(response.data);
    } catch (error) {
      setLoading(false)
      //console.error('Error fetching data:', error);
      if (
        error.response.status &&
        error.response.status === 403 &&
        error.response.data.error === "jwt expired"
      ) {
        localStorage.clear()
        navigate("/login")
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  //   const columns = [
  //     {
  //       dataField: 'subject',
  //       text: 'Subject',
  //       sort: true,
  //     },
  //     {
  //       dataField: 'sest_name',
  //       text: 'Sestion Name',
  //       sort: true,

  //       // filter: textFilter(),
  //     },
  //     // Add more columns as needed
  //   ];

  const columns = useMemo(
    () => [
      {
        name: "From Date",
        Header: "From Date",
        accessor: row =>
          row.tp_start_date == null || row.tp_start_date == ""
            ? "-"
            : moment(row.tp_start_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
                "DD-MM-YYYY"
              ),
        // disableFilters: true,
        // disableSortBy: true,
      },
      {
        name: "To Date",
        Header: "To Date",
        accessor: row =>
          row.tp_end_date == null || row.tp_end_date == ""
            ? "-"
            : moment(row.tp_end_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
                "DD-MM-YYYY"
              ),
      },
      {
        name: "Session Student",
        Header: "Session Student",
        accessor: row =>
          row.tp_ses_student_count == null || row.tp_ses_student_count == ""
            ? "-"
            : row.tp_ses_student_count,
      },
      {
        name: "Minimum Student",
        Header: "Minimum Student",
        accessor: row =>
          row.tp_tut_min_student == null || row.tp_tut_min_student == ""
            ? "-"
            : row.tp_tut_min_student,
      },
      {
        name: "Payment Frequency",
        Header: "Payment Frequency",
        accessor: row =>
          row.tp_pay_frequency == null || row.tp_pay_frequency == ""
            ? "-"
            : row.tp_pay_frequency,
      },
      {
        name: "Payment",
        Header: "Payment",
        accessor: row =>
          row.tp_amount == null || row.tp_amount == "" ? "-" : row.tp_amount,
      },
      {
        Header: "Status",
        disableSortBy: true,
        accessor: "tp_status",
        disableFilters: true,
        Cell: ({ row }) => {
          const rowValue = row.values
          // Conditionally render content based on the value of tp_status
          if (parseInt(rowValue.tp_status) === 1) {
            return <button className="tb-success ct-bag">Paid</button>
          } else {
            return (
              <button className="ct-bag" style={{backgroundColor:"#e5e7eb"}}>Not Paid</button>
            )
          }
        },
      },
      //   {
      //         Header: "#",
      //         disableSortBy: true,
      //         accessor: 'pk_student_keys',
      //         disableFilters: true,

      //         Cell: ({ row }) => (<><button className='btn'><i class="mdi mdi-chevron-right-circle-outline" style={{ color: '#979797', fontSize: '20px'}}></i></button></>),

      //   },
      //   {
      //     accessor: 'student_key', disableSortBy: true, disableFilters: true,           isVisible: false,

      //     selector: (row) => row.values.pk_student_key, show: false,
      //   },
      //   {
      //     accessor: 'visibility', disableSortBy: true, disableFilters: true,     isVisible: false,

      //     selector: (row) => row.visibility,

      //   }
    ],
    []
  )

  //   const paginationOptions = {
  //     paginationSize: 5,
  //     pageStartIndex: 1,
  //     firstPage: 'First',
  //     prePage: 'Back',
  //     nextPage: 'Next',
  //     lastPage: 'Last',
  //     nextPage: 'First page',
  //     prePage: 'Pre page',
  //     firstPage: 'Next page',
  //     lastPage: 'Last page',

  //     // page: 2,
  //     // sizePerPageList: [ {
  //     //   text: '5', value: 5
  //     // }, {
  //     //   text: '10', value: 10
  //     // }, {
  //     //   text: 'All', value: []
  //     // } ],
  //     // sizePerPage: 5,
  //     // pageStartIndex: 0,
  //     // paginationSize: 5,
  //     // prePage: 'Prev',
  //     // nextPage: 'Next',
  //     // firstPage: 'First',
  //     // lastPage: 'Last',
  //     // paginationPosition: 'top'

  //   };

  const handleStartDateChange = event => {
    //console.log(event);
    const newStartDate = moment(event, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
      "YYYY-MM-DD"
    )
    console.log(newStartDate)
    //const newStartDate = dayjs(event, 'YYYY-MM-DD').format('YYYY-MM-DD');
    if (
      endDate &&
      new Date(endDate).getTime() < new Date(newStartDate).getTime()
    ) {
      // You can handle this validation error, for example, by not updating the state
      // console.error('End date cannot be earlier than start date');
      setStartDate("")
      return
    }
    setStartDate(newStartDate)
    // handleDateFilter(newStartDate, endDate);

    //fetchData();
  }

  const handleEndDateChange = event => {
    //const newEndDate = event.target.value;
    const newEndDate = moment(event, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
      "YYYY-MM-DD"
    )
    console.log(newEndDate)
    //  const newEndDate = dayjs(event, 'YYYY-MM-DD').format('YYYY-MM-DD');
    // Check if the new end date is not earlier than the start date
    if (
      startDate &&
      new Date(newEndDate).getTime() < new Date(startDate).getTime()
    ) {
      // You can handle this validation error, for example, by not updating the state
      // console.error('End date cannot be earlier than start date');
      setEndDate("")
      return
    }
    setEndDate(newEndDate)
    // handleDateFilter(startDate, newEndDate);

    //fetchData(startDate,newEndDate);
  }

  const filterApply = () => {
    // fetchData();

    toggleRightCanvas()
    setFilter(!isFilter)
  }

  const filterReset = () => {
    // setEndDate("")
    // setStartDate("")
    setDateValue([null, null])
    setselectedGroup("")
    setFilter(!isFilter)
  }

  useEffect(() => {
    fetchData()
  }, [dateValue, selectedGroup])

  function handleFilter(event) {
    const inputValue = event.target.value.trim().toLowerCase()
    const newData = data.filter(row => {
      return (
        (row.tp_start_date == null || row.tp_start_date == ""
          ? "-"
          : moment(row.tp_start_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
              "DD-MM-YYYY"
            )
        ).includes(inputValue) ||
        (row.tp_end_date == null || row.tp_end_date == ""
          ? "-"
          : moment(row.tp_end_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
              "DD-MM-YYYY"
            )
        ).includes(inputValue) ||
        (row.tp_ses_student_count == null || row.tp_ses_student_count == ""
          ? "-"
          : row.tp_ses_student_count
        )
          .toString()
          .includes(inputValue) ||
        (row.tp_tut_min_student == null || row.tp_tut_min_student == ""
          ? "-"
          : row.tp_tut_min_student
        )
          .toString()
          .includes(inputValue) ||
        (row.tp_pay_frequency == null || row.tp_pay_frequency == ""
          ? "-"
          : row.tp_pay_frequency
        )
          .toLowerCase()
          .includes(inputValue) ||
        (row.tp_amount == null || row.tp_amount == "" ? "-" : row.tp_amount)
          .toString()
          .includes(inputValue) ||
        (row.tp_status == null
          ? "-"
          : parseInt(row.tp_status) === 1
          ? "Paid"
          : "Not Paid"
        )
          .toLowerCase()
          .includes(inputValue)
      )
    })
    setRecords(newData)
  }

  return (
    <div className="page-content">
    <Loader
        show={loading}
        type="body"
      // stack="vertical"
      // message="Loading Data"
      />
      <Container fluid>
            <div className="custom-pg_header flex-wrap gap-2 mb-2">
              <div>
                <div className="d-flex align-items-center">
                  <div
                    className="short-ic"
                    onClick={() => window.history.back()}
                  >
                    <img className="bc-bg2 mr-30" src={chevleft} />
                  </div>

                  <div>
                    <h6 className="page-title inner-pg-title mb-0" style={{ width: '20vw'}}>
                      {" "}
                      Timesheet
                    </h6>
                    
                  </div>
                </div>
              </div>
            </div>
          <div className="row">
            <div className="filter-section pymts d-flex gap-3 mb-3 mt-0">
              <div>
                <label> Date</label>
                <br></br>

                <div className="filter-child">

                <DateRangePicker
                    format="dd-MM-yyyy"
                    value={dateValue}
                    onChange={handleDateChange}
                    cleanable={false}
                  />
                  {/*<DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    cleanable={false}
                    className="start_date w-100"
                    id="start_date"
                    oneTap
                    //size="xs"
                    shouldDisableDate={date =>
                      isAfter(
                        date,
                        endDate && endDate !=''? new Date(endDate) : new Date()
                      )
                    }
                    format="dd-MM-yyyy"
                  /> */}
                  {/* <input
                        className="form-control"
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        //min={new Date().toISOString().split("T")[0]}
                        max={endDate || new Date().toISOString().split("T")[0]}
                        format="DD-MM-YYYY"
                        handleStartDateChange
                        //defaultValue="2019-08-19"
                          id="start_date" /> */}
                </div>
              </div>

              {/* <div className="filter-child">
                <label>End Date</label>
                <div className="">
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    cleanable={false}
                    className="enddate"
                    id="enddate"
                    oneTap
                    //size="xs"
                    shouldDisableDate={date =>
                      isBefore(date, new Date(startDate)) ||
                      isAfter(date, new Date())
                    }
                    format="dd-MM-yyyy"
                  />

                 
                </div>
              </div> */}

              <div className="filter-child">
                <label>Status</label>
                <Select
                  className="cs-style"
                  value={optionGroup.find(
                    option => option.value === selectedGroup
                  )}
                  // value={selectedGroup}
                  onChange={selectedOption => {
                    // const event = {
                    //     target: {
                    //         name: "sest_day",
                    //         value: selectedOption["value"],
                    //     },
                    // };
                    handleSelectGroup(selectedOption["value"])
                  }}
                  // onChange={() => {
                  //   handleSelectGroup();
                  // }}
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                />
              </div>

              <div className="me-1">
                <Button className="mt-4" color="primary" onClick={filterReset}>
                  Clear Filter
                </Button>
              </div>
            </div>
          </div>
          <div className="table-responsive ">
          <TableComm
            loading={loading}
            columns={columns}
            defaultId={"tp_start_date"}
            data={records}
            //onRowClick={handleStudentViewClick}
            //hiddenColumns={['visibility', 'student_key']}
          />
        </div>
      </Container>
    </div>
  )
}

export default Timesheet
