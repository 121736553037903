import React, { useState, useEffect } from "react"
// import moment from 'moment';

import { Card, CardBody, Col, Row, Button, Table, Container } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { SECURITY_KEY, BASE_URL } from "../../global"
import axios from "axios"
//import moment from 'moment-timezone';
import moment from "moment"
// import 'moment-timezone';
const FormEditors = () => {
  const [data, setData] = useState([])
  const [dateTime, setDateTime] = useState({ date: "", time: "" })
  const navigate = useNavigate()
  const authUser = JSON.parse(localStorage.getItem("authUser")) || null

  useEffect(() => {
    if (authUser && authUser != "" && authUser != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .get(BASE_URL + "notification/notificationDetails")
        .then(responseNotification => {
          setData(responseNotification.data)
          setDateTime(responseNotification.data)
        })
        .catch(error => {
          console.log(error.response.data.error)
        })
    } else {
      // navigate(/login);
    }
  }, [])

  const markOverrideRedirect = item => {
    if (
      item &&
      item.question_id &&
      item.question_id != "" &&
      item.fk_sub_id &&
      item.fk_sub_id != "" &&
      item.pk_student_key &&
      item.pk_student_key != "" &&
      item.fk_lesson_activity_key &&
      item.fk_lesson_activity_key != ""
    ) {
      navigate(`/marking-override/${item.question_id}`, {
        state: {
          type: `${item.fk_sub_id}`,
          st_key: `${item.pk_student_key}`,
          activity_key: `${item.fk_lesson_activity_key}`,
        },
      })
    }
  }

  const FlagAssessmentOverrideRedirect = item => {
    if (
      item &&
      item.question_id &&
      item.question_id != "" &&
      item.fk_sub_id &&
      item.fk_sub_id != "" &&
      item.pk_student_key &&
      item.pk_student_key != "" &&
      item.fk_lesson_activity_key &&
      item.fk_lesson_activity_key != ""
    ) {
      navigate(`/flag-marking-override/${item.question_id}`, {
        state: {
          type: `${item.fk_sub_id}`,
          st_key: `${item.pk_student_key}`,
          activity_key: `${item.fk_lesson_activity_key}`,
          flag_reason: `${item.flag_reason}`,
          flag_text: `${item.flag_text}`,
        },
      })
    }
  }

  const FlagDiagnosticsOverrideRedirect = item => {
    if (
      item &&
      item.question_id &&
      item.question_id != "" &&
      item.fk_sub_id &&
      item.fk_sub_id != "" &&
      item.pk_student_key &&
      item.pk_student_key != "" &&
      item.fk_lesson_activity_key &&
      item.fk_lesson_activity_key != ""
    ) {
      navigate(`/flag-diagnostics-override/${item.question_id}`, {
        state: {
          type: `${item.fk_sub_id}`,
          st_key: `${item.pk_student_key}`,
          activity_key: `${item.fk_lesson_activity_key}`,
          flag_reason: `${item.flag_reason}`,
          flag_text: `${item.flag_text}`,
        },
      })
    }
  }

  const formatTime = timestamp => {
    var local_date = moment
      .utc(timestamp)
      .local()
      .format("YYYY-MM-DD hh:mm:ss a")

    return moment(local_date, "YYYY-MM-DD hh:mm:ss a").format("hh:mm a")
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box mb-4">
            <div className="custom-pg_header">
              <div>
                <div className="d-flex align-items-center gap-2">
                  <Button
                    color="light"
                    className="bck-btn"
                    onClick={() => window.history.back()}
                  >
                    <i className="ion ion-ios-arrow-back"></i>
                  </Button>

                  <h6 className="page-title mb-0">All Notifications</h6>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    {data.length === 0 ? (
                      <p>No notifications available.</p> // Message when no notifications exist
                    ) : (
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>Question Id</th>
                            <th>Report Type</th>
                            <th>Student Name</th>
                            <th>Date</th>
                            <th>Time</th>
                            {/* <th>Actions</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, index) => (
                            <tr key={index}>
                              {/* <th scope="row">{index + 1}</th> */}
                              <td>{item.question_id}</td>
                              <td>
                                {parseInt(item.report_type) === 1
                                  ? "Question marked incorrect report"
                                  : "Question flag report"}
                              </td>
                              <td>{item.studentname}</td>
                              <td>{item.created_date}</td>
                              {/* <td>{item.created_time}</td> */}
                              <td>{formatTime(item.created_on)}</td>
                              {/* <td>
                                                            <Link to="/yourTargetPage">
                                                                <i className="mdi mdi-chevron-right-circle-outline"></i>
                                                            </Link>
                                                        </td> */}
                              <td>
                                {parseInt(item.report_type) === 1 ? (
                                  <i
                                    class="mdi mdi-chevron-right-circle-outline"
                                    onClick={() => markOverrideRedirect(item)}
                                  ></i>
                                ) : parseInt(item.activity_type) === 2 ? (
                                  <i
                                    class="mdi mdi-chevron-right-circle-outline"
                                    onClick={() =>
                                      FlagDiagnosticsOverrideRedirect(item)
                                    }
                                  ></i>
                                ) : (
                                  <i
                                    class="mdi mdi-chevron-right-circle-outline"
                                    onClick={() =>
                                      FlagAssessmentOverrideRedirect(item)
                                    }
                                  ></i>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* <tbody>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                            </tbody> */}
                      </Table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormEditors
