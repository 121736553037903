import PropTypes, { array } from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"

import { Pie } from "react-chartjs-2"
import { DateRangePicker } from "rsuite"
import {
  Container,
  Input,
  Card,
  CardBody,
  Button,
  Label,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Col,
  CardText,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormFeedback,
  Form,
  Table,
  Progress,
} from "reactstrap"
import { Link } from "react-router-dom"
import Select from "react-select"
import classnames from "classnames"
import awicon from "../../assets/images/services-icon/coin.png"
import record from "../../assets/images/services-icon/record.png"
import play from "../../assets/images/services-icon/play.svg"
import calendar from "../../assets/images/services-icon/calendar.png"
import watch from "../../assets/images/services-icon/watch.png"
import timer from "../../assets/images/services-icon/timer.png"

import "chartist/dist/scss/chartist.scss"
import sort from "assets/images/sort.png"
// import images
import st1 from "../../assets/images/services-icon/st1.png"
import st2 from "../../assets/images/services-icon/st2.png"
import duration from "../../assets/images/services-icon/duration.png"
import evelaa from "../../assets/images/services-icon/evaluation.png"
import reel from "../../assets/images/services-icon/reel.png"
import vid from "../../assets/images/services-icon/video.png"
import chevleft from "assets/images/services-icon/chev-left.png"
import TableComm from "../../dataTableCommon"

//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import axios from "axios"
import { SECURITY_KEY, BASE_URL, STUDENT_BASE_URL } from "../../global"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import moment from "moment"

import { Line } from "react-chartjs-2"

import Loader from "react-spinner-loader"
import { spawn } from "redux-saga/effects"
//import { Key } from '@mui/icons-material';

const SessionDetails = props => {
  const { key } = useParams()
  const tog_center = () => {
    setmodal_center(!modal_center)
    // removeBodyCss();
  }
  const [NextSessionData, setnextsessiondeatils] = useState([])
  //setActivityNextLoading(true)
  const [SessionStudentPendingActivity, setSessionStudentPendingActivity] =
    useState([])
  const [ActivityPendingLoading, setActivityPendingLoading] = useState(false)

  const [TotalPendingActivity, setTotalPendingActivity] = useState(0)
  const [
    SessionStudentAutomationActivity,
    setSessionStudentAutomationActivity,
  ] = useState([])

  const [SessionStudentNextActivity, setSessionStudentNextActivity] = useState(
    []
  )
  const [SessionStudentSubject, setSessionStudentSubject] = useState([])
  const [
    SessionStudentSubjectNextActivity,
    setSessionStudentSubjectNextActivity,
  ] = useState([])
  const [SessiosNextActivity, setSessiosNextActivity] = useState([])
  const [ActivityNextLoading, setActivityNextLoading] = useState(false)
  const [ActivitySubjectNextLoading, setActivitysubjectNextLoading] =
    useState(false)
  const [rollOveractiveTab, setrollOverActiveTab] = useState("lessonPlan")
  const [modal_center3, setmodal_center3] = useState(false)
  const tog_center3 = () => {
    setmodal_center3(!modal_center3)
    validation1.resetForm()
  }

  const [modal_center2, setmodal_center2] = useState(false)
  const tog_center2 = () => {
    setmodal_center2(!modal_center2)
    // removeBodyCss();
  }

  const tog_session_end = () => {
    setModal_session_end(!modal_session_end)
    //fetchSessionLoopData();
    // removeBodyCss();
  }

  const today = new Date()
  const sixMonthsAgo = new Date()
  sixMonthsAgo.setMonth(today.getMonth() - 6)

  const [dateValue, setDateValue] = useState([sixMonthsAgo, today])

  const [selectedYearLevelTopic, setselectedYearLvel] = useState("")
  function handleSelectYearLevelTopic(selectedGroup) {
    //console.log(selectedGroup);
    setselectedYearLvel(selectedGroup)
    filterData(selectedGroup, selectedMastryScore)
  }

  const filterData = (
    yearLevel = selectedYearLevelTopic,
    masteryScore = selectedMastryScore
  ) => {
    let filteredData = Alldata

    if (masteryScore) {
      if (parseInt(masteryScore) === 1) {
        filteredData = filteredData.filter(item => item.masteryscore == 0)
      } else if (parseInt(masteryScore) === 2) {
        filteredData = filteredData.filter(
          item => item.masteryscore < 30 && item.masteryscore > 0
        )
      } else if (parseInt(masteryScore) === 3) {
        filteredData = filteredData.filter(
          item => item.masteryscore >= 30 && item.masteryscore <= 69
        )
      } else if (parseInt(masteryScore) === 4) {
        filteredData = filteredData.filter(item => item.masteryscore >= 70)
      }
    }

    if (yearLevel && yearLevel !== "all" && yearLevel != "") {
      filteredData = filteredData.filter(item =>
        item.activity_node.some(node => node.level_name === yearLevel)
      )
    }

    setMistryTopic(filteredData)
  }

  const [selectedMastryScore, setselectedMasterScore] = useState("")
  function handleSelectMastryScore(selectedGroup) {
    //console.log(selectedGroup);
    setselectedMasterScore(selectedGroup)
    filterData(selectedYearLevelTopic, selectedGroup)
  }

  const optionMastryScore = [
    { label: "Select", value: "" },
    { label: "No score", value: "1" },
    { label: "Low Mastery", value: "2" },
    { label: "Medium Mastery", value: "3" },
    { label: "High Mastery", value: "4" },
  ]

  const [modal_center1, setmodal_center1] = useState(false)
  const tog_center1 = () => {
    setmodal_center1(!modal_center1)
    //removeBodyCss()
  }

  const [isRightStuTopic, setIsRightStuTopic] = useState(false)
  const toggleRightCanvasStuTopic = () => {
    setIsRightStuTopic(!isRightStuTopic)
  }

  const [isRightTopicGraph, setIsRightTopicGraph] = useState(false)

  const toggleRightCanvasTopicGraph = () => {
    setIsRightTopicGraph(!isRightTopicGraph)
    const today = new Date()
    const sixMonthsAgo = new Date()
    sixMonthsAgo.setMonth(today.getMonth() - 6)
    setDateValue([sixMonthsAgo, today])
  }

  const [optionGroup, setoptionGroup] = useState([])
  const [levelOption, setLevelOptions] = useState([])
  const [isRightStu, setIsRightStu] = useState(false)
  const [studentKay, setStudentKey] = useState("")
  const [lessonNote, setLessonNote] = useState("")

  const toggleRightCanvasStu = studentKey => {
    setIsRightStu(!isRightStu)
    fetchStudentData(studentKey)
    fetchlessonNotesData(studentKey)
    fetchpastsessionData(studentKey)
    fetchMastryScoresessionData(studentKey)
    setStudentKey(studentKey)
    setLessonNote("")
  }

  const [activeTab1, setActiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setActiveTab1(tab)
    }
  }

  const [modalStuPastSession, setStuPastSession] = useState(false)
  const togStudentPastSession = () => {
    setStuPastSession(!modalStuPastSession)
  }

  const [startDate, setStartDate] = useState("")
  const handelDateReset = () => {
    setStartDate("")
  }

  const handleDateChange = date => {
    //setStartDate(date)
    setDateValue(date)
    // Here you can implement logic to fetch data for the selected month
    // and update the chart accordingly
  }

  const [isTopicId, setTopicId] = useState("")
  const [isNodeId, setNodeId] = useState("")
  const [MistryTopic, setMistryTopic] = useState([])
  const [Alldata, setAllData] = useState([])
  const [topicData, setToipc] = useState([])
  const [mastryScore, setMastryScore] = useState([])
  const [TopicGraphDeatils, setMistryTopicGraphDeatils] = useState([])
  const [TopicGraphDeatilsValueData, setTopicGraphDeatilsValueData] = useState(
    []
  )
  const [TopicGraphDeatilsLabelData, setTopicGraphDeatilsLabelData] = useState(
    []
  )

  const [PastSessionStuData, setPastSessionStuData] = useState([])
  const [LessonNoteDetail, setLessonNoteDetail] = useState([])
  //delete notes start
  const [modal_delete_note, setmodal_delete_note] = useState(false)

  const tog_delete_note = () => {
    setmodal_delete_note(!modal_delete_note)
    // removeBodyCss();
  }
  const [isNoteSubmitting, setIsNoteSubmitting] = useState(false)

  const [note_key, setNoteKey] = useState(null)
  function delete_notes(getNoteKey) {
    setNoteKey(getNoteKey)
    tog_delete_note()
  }
  //delete notes end
  const [isNotesSubmitting, setIsNotesSubmitting] = useState(false)

  function textareachange(event) {
    const value = event.target.value

    setLessonNote(value)
  }

  const [studentDetail, setstudentDetail] = useState([])

  const [SessionStart, setIsSessionStart] = useState(false)
  const [SessionEnd, setIsSessionEnd] = useState(false)

  const [isLoopRun, setLoopRun] = useState(1)

  const [modal_center, setmodal_center] = useState(false)
  const [modal_session_end, setModal_session_end] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)
  const [sessionData, setSessionData] = useState([])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [studentOption, setStudentOption] = useState([])

  const optionPendingActivityType = [
    { label: "Lesson Plan", value: "0" },
    { label: "Homework", value: "1" },
  ]

  const [loader, setLoader] = useState(false)

  //student attendence star
  const [studentattDetails, setStudentAttDetails] = useState([])

  const initialSetCount = 1 // Set the initial number of sets
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const initialDynamicFields = studentattDetails

  //HomeWork tab
  const [HomeSessionData, setHomeSessionData] = useState([])

  //Homework tab

  //Student tab
  const [StudentData, setStudentData] = useState([])
  //student tab

  //precious tab
  const [PresessionData, setPreSessionData] = useState([])
  const [PreHomeSessionData, setPreHomeSessionData] = useState([])

  //previous tab

  //delete activity start
  const [modal_delete_activity, setmodal_delete_activity] = useState(false)

  const tog_delete_activity = () => {
    setmodal_delete_activity(!modal_delete_activity)
    // removeBodyCss();
  }
  const [isDASubmitting, setIsDASubmitting] = useState(false)

  const [activity_key, setActivityKey] = useState([])

  //delete activity end

  //Move activity start
  const [UpcomingSessionData, setUpcomingSessionData] = useState([])
  const [modal_move_activity, setmodal_move_activity] = useState(false)
  const [moveTosession, setmoveTosession] = useState(false)
  const tog_move_activity = () => setmodal_move_activity(!modal_move_activity)
  // const tog_move_activity = () => {
  //   setmodal_move_activity(!modal_move_activity)
  //   // removeBodyCss();
  // }
  const [move_activity, setMoveActivity] = useState([])
  function onMoveActivityPopup(getActivity) {
    setMoveActivity(getActivity)
    setmoveTosession(true)
  }

  const [moveingSessionKey, setmoveingSessionKey] = useState([])
  const [isMASubmitting, setIsMASubmitting] = useState(false)
  const [modal_moveing_conform, setmodal_moveing_conform] = useState(false)
  const tog_moveing_popup = () => {
    setmodal_moveing_conform(!modal_moveing_conform)
    // removeBodyCss();
  }
  function onMoveingActivityPopup() {
    // setmoveingSessionKey(sessionKey)

    if (moveingSessionKey.length === 0) {
      toast.error("Please select at least one session!", {
        autoClose: 3000, // 3 seconds in this example
      })
    } else {
      tog_moveing_popup()
    }
  }

  const handleCheckboxChange = (key, isChecked) => {
    const updatedSessions = UpcomingSessionData.map(session => {
      if (session.pk_ses_key === key) {
        return { ...session, checked: isChecked }
      }
      return session
    })

    setUpcomingSessionData(updatedSessions)
    if (isChecked) {
      // Add key to the selectedKeys array if checked
      setmoveingSessionKey(prev => [...prev, key])
    } else {
      // Remove key from the selectedKeys array if unchecked
      setmoveingSessionKey(prev => prev.filter(k => k !== key))
    }
  }

  //Roll over to homework start
  const [modal_move_home_activity, setmodal_move_home_activity] =
    useState(false)

  const tog_move_home_activity = () => {
    setmodal_move_home_activity(!modal_move_home_activity)
    // removeBodyCss();
  }
  

  const [isMAHSubmitting, setIsMAHSubmitting] = useState(false)
  const [isMAHmSubmitting, setIsMAHmSubmitting] = useState(false)

  function onMoveHomeworkActivityPopup(getActivity) {
    setMoveActivity(getActivity)
    setmoveTosession(false)
    tog_move_home_activity()
  }
  // Roll over to homework end
  useEffect(() => {
    if (moveTosession && move_activity && move_activity != "") {
      fetchUpcommingSessionData()
    }
  }, [moveTosession, move_activity])

  //Move activity end

  const [customActiveTab, setcustomActiveTab] = useState("1")

  function toggleCustom(tab) {
    // console.log(tab);
    if (customActiveTab !== tab) {
      setActivityStudentKey("")
      setSelectedActivityKeys([])
      setselectedActivitySubject([])
      setSelectAllChecked(false)
      setcustomActiveTab(tab)
    }
  }

  const [isRight, setIsRight] = useState(false)
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }
  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }

  const authUser = JSON.parse(localStorage.getItem("authUser")) || null

  const navigate = useNavigate()

  const getUniqueSubjectNames = jsonData => {
    const uniqueSubjectNames = new Set()

    // Check if the necessary data is available
    if (
      jsonData &&
      jsonData.session &&
      jsonData.session.tbl_session_time &&
      jsonData.session.tbl_session_time.tbl_student_enrolment
    ) {
      // Iterate through student enrollments
      jsonData.session.tbl_session_time.tbl_student_enrolment.forEach(
        enrollment => {
          // Check if the necessary data is available and is an array
          if (
            enrollment &&
            enrollment.tbl_enrolment_subject &&
            Array.isArray(enrollment.tbl_enrolment_subject)
          ) {
            // Iterate through enrollment subjects
            enrollment.tbl_enrolment_subject.forEach(subject => {
              // Assuming subject_name is a property of the subject object and is not null/undefined
              if (subject && subject.subject_name != null) {
                uniqueSubjectNames.add(subject.subject_name)
              }
            })
          }
        }
      )
    }

    // Convert Set to array if needed
    return Array.from(uniqueSubjectNames)
  }

  const getUniqueStudentSubjectNames = jsonData => {
    const uniqueSubjectNames = new Set()

    //console.log(jsonData);

    // Check if the necessary data is available
    if (jsonData) {
      // Iterate through student enrollments
      // jsonData.forEach((enrollment) => {
      // Check if the necessary data is available and is an array
      if (jsonData && Array.isArray(jsonData)) {
        // Iterate through enrollment subjects
        jsonData.forEach(subject => {
          // console.log(subject);
          // Assuming subject_name is a property of the subject object and is not null/undefined
          if (subject && subject.subject_name != null) {
            uniqueSubjectNames.add(subject.subject_name)
          }
        })
      }
      //});
    }

    // Convert Set to array if needed
    return Array.from(uniqueSubjectNames)
  }

  const getUniqueStudentSubjectID = jsonData => {
    const uniqueSubjectID = new Set()

    //console.log(jsonData);

    // Check if the necessary data is available
    if (jsonData) {
      // Iterate through student enrollments
      // jsonData.forEach((enrollment) => {
      // Check if the necessary data is available and is an array
      if (jsonData && Array.isArray(jsonData)) {
        // Iterate through enrollment subjects
        jsonData.forEach(subject => {
          // console.log(subject);
          // Assuming subject_name is a property of the subject object and is not null/undefined
          if (subject && subject.fk_sub_id != null) {
            uniqueSubjectID.add(subject.fk_sub_id)
          }
        })
      }
      //});
    }

    // Convert Set to array if needed
    return Array.from(uniqueSubjectID)
  }

  const getUniqueTopicsNames = jsonData => {
    const uniqueTopicsNames = new Set()

    //console.log(jsonData);

    // Check if the necessary data is available
    if (jsonData) {
      // Iterate through student enrollments
      // jsonData.forEach((enrollment) => {
      // Check if the necessary data is available and is an array
      if (jsonData && Array.isArray(jsonData)) {
        // Iterate through enrollment subjects
        jsonData.forEach(subject => {
          subject.forEach(data => {
            if (data && data.topic_name != null) {
              uniqueTopicsNames.add(data.topic_name)
            }
          })
        })
      }
      //});
    }

    // Convert Set to array if needed
    return Array.from(uniqueTopicsNames)
  }

  const location = useLocation()
  useEffect(() => {
    fetchData()
    setIsRightStu(false)
    setStuPastSession(false)
    fetchMasterData()
    setActivityStudentKey("")
    setSelectedActivityKeys([])
    setselectedActivitySubject([])
    setSelectAllChecked(false)
  }, [location, customActiveTab])
  let timeoutID = ""
  useEffect(() => {
    if (isLoopRun !== 0) {
      timeoutID = setTimeout(() => {
        fetchSessionLoopData()
      }, 120000)

      return () => {
        clearTimeout(timeoutID)
      }
    } else {
    }
  }, [isLoopRun])
  // useEffect(() => {
  //   if(isLoopRun === true){

  //   const timeoutID = setTimeout(() => {
  //     fetchSessionLoopData();
  //   }, 1000);

  //   return () => {
  //     // 👇️ clear timeout when the component unmounts
  //     clearTimeout(timeoutID);
  //   };
  // }else{

  // }
  // }, [setLoopRun,isLoopRun ]);

  const CountTotalActivityStudent = enrolments => {
    const students = [] // Initialize an array to store student data

    enrolments.forEach(enrolment => {
      let total_activity_stu = 0
      let completed_activity_stu = 0

      let student_data = {} // Use object instead of array for student data

      if (
        enrolment.tbl_student &&
        enrolment.tbl_student.pk_student_key &&
        enrolment.tbl_student.tbl_session_attendance &&
        enrolment.tbl_student.tbl_session_attendance.attendance_status !== 6 &&
        enrolment.tbl_student.tbl_session_attendance.attendance_status !== 5 &&
        enrolment.tbl_student.tbl_session_attendance.attendance_status !== 4 &&
        enrolment.tbl_student.tbl_session_attendance.attendance_status !== 3
      ) {
        student_data.student_key = enrolment.tbl_student.pk_student_key
        student_data.student_name =
          enrolment.tbl_student.st_first_name +
          " " +
          enrolment.tbl_student.st_surname

        student_data.status = ""

        if (
          enrolment.tbl_student &&
          enrolment.tbl_student.Lesson &&
          enrolment.tbl_student.Lesson.LessonActivities.length > 0
        ) {
          enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
            completed_activity_stu +=
              activity.status != null && activity.status === "1" ? 1 : 0
            total_activity_stu += 1
          })
        }

        var Count_activity = 0
        if (total_activity_stu !== completed_activity_stu) {
          Count_activity = total_activity_stu - completed_activity_stu
          student_data.pendingactivity = Count_activity
          student_data.homework = false
        } else {
          student_data.pendingactivity = 0
          student_data.homework = false
        }

        students.push(student_data) // Push student data to the array
      }
    })

    setStudentAttDetails(students)

    // console.log(students); // Print the array of student data
  }

  // Example usage:
  // CountTotalActivityStudent(enrolments); // Pass your array of enrolments here

  const fetchSessionLoopData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (key) {
        //  if(parseInt(customActiveTab) === 1){

        // setLoader(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/sessionDetails", {
            pk_ses_key: key,
          })
          .then(responseSession => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties

            if (
              responseSession.data.session != null &&
              responseSession.data.session != ""
            ) {
              //  setLoader(false);
              if (
                responseSession.data.session.pk_ses_key &&
                key === responseSession.data.session.pk_ses_key
              ) {
                setSessionData(responseSession.data)
                setLoopRun(isLoopRun + 1)
                //console.log('enter')
                //console.log(location.pathname);
                // if (location.pathname.startsWith('/session/')) {
                // const timerId = setTimeout(() => {
                //   fetchSessionLoopData();
                // }, 6000);

                //   // Cleanup function to clear the timeout when component unmounts or pathname changes
                //   clearTimeout(timerId);
                // //}

                if (
                  responseSession.data &&
                  responseSession.data.session &&
                  responseSession.data.session.tbl_session_time &&
                  responseSession.data.session.tbl_session_time
                    .tbl_student_enrolment &&
                  responseSession.data.session.tbl_session_time
                    .tbl_student_enrolment.length > 0
                ) {
                  //console.log(responseSession.data.session.tbl_session_time.tbl_student_enrolment)
                  // const formattedOptions = responseSession.data.session.tbl_session_time.tbl_student_enrolment.map(enroll => ({
                  //   value: enroll.tbl_student.pk_student_key,
                  //   label: enroll.tbl_student.st_first_name != null && enroll.tbl_student.st_first_name !== '' ? enroll.tbl_student.st_first_name + ' ' + enroll.tbl_student.st_surname : null
                  // }));

                  CountTotalActivityStudent(
                    responseSession.data.session.tbl_session_time
                      .tbl_student_enrolment
                  )
                } else {
                }

                if (
                  responseSession.data &&
                  responseSession.data.session &&
                  responseSession.data.session.tbl_session_time &&
                  responseSession.data.session.tbl_session_time
                    .tbl_student_enrolment &&
                  responseSession.data.session.tbl_session_time
                    .tbl_student_enrolment.length > 0
                ) {
                  //console.log(responseSession.data.session.tbl_session_time.tbl_student_enrolment)
                  const formattedOptions =
                    responseSession.data.session.tbl_session_time.tbl_student_enrolment.map(
                      enroll => ({
                        value: enroll.tbl_student.pk_student_key,
                        label:
                          enroll.tbl_student.st_first_name != null &&
                          enroll.tbl_student.st_first_name !== ""
                            ? enroll.tbl_student.st_first_name +
                              " " +
                              enroll.tbl_student.st_surname
                            : null,
                      })
                    )

                  setStudentOption(formattedOptions)
                } else {
                  setStudentOption([])
                  //setLoader(false);
                }
              }
              // Get unique subject names from the provided JSON data
              //const uniqueSubjectNames = getUniqueSubjectNames(responseSession.data);
            } else {
              //console.error("Home card details not get from database.");
              setSessionData([])
              //  setLoader(false);

              setStudentOption([])
            }
          })
          .catch(error => {
            setLoopRun(0)
            // setLoader(false);
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })

        // }

        // else{
        //   setLoader(false);
        //  // navigate("/session");
        // }
      } else {
        setLoader(false)
        navigate("/session")
      }
    } else {
      setLoader(false)
      navigate("/login")
    }
  }

  const fetchData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      if (key) {
        if (parseInt(customActiveTab) === 1) {
          setLoader(true)
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          axios
            .post(BASE_URL + "session/sessionDetails", {
              pk_ses_key: key,
            })
            .then(responseSession => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties

              if (
                responseSession.data.session != null &&
                responseSession.data.session != ""
              ) {
                setLoader(false)
                setSessionData(responseSession.data)

                // setTimeout(() => {
                //   fetchSessionLoopData()
                // }, 6000);

                if (
                  responseSession.data &&
                  responseSession.data.session &&
                  responseSession.data.session.tbl_session_time &&
                  responseSession.data.session.tbl_session_time
                    .tbl_student_enrolment &&
                  responseSession.data.session.tbl_session_time
                    .tbl_student_enrolment.length > 0
                ) {
                  //console.log(responseSession.data.session.tbl_session_time.tbl_student_enrolment)
                  // const formattedOptions = responseSession.data.session.tbl_session_time.tbl_student_enrolment.map(enroll => ({
                  //   value: enroll.tbl_student.pk_student_key,
                  //   label: enroll.tbl_student.st_first_name != null && enroll.tbl_student.st_first_name !== '' ? enroll.tbl_student.st_first_name + ' ' + enroll.tbl_student.st_surname : null
                  // }));

                  CountTotalActivityStudent(
                    responseSession.data.session.tbl_session_time
                      .tbl_student_enrolment
                  )
                } else {
                }

                if (
                  responseSession.data &&
                  responseSession.data.session &&
                  responseSession.data.session.tbl_session_time &&
                  responseSession.data.session.tbl_session_time
                    .tbl_student_enrolment &&
                  responseSession.data.session.tbl_session_time
                    .tbl_student_enrolment.length > 0
                ) {
                  //console.log(responseSession.data.session.tbl_session_time.tbl_student_enrolment)
                  const formattedOptions =
                    responseSession.data.session.tbl_session_time.tbl_student_enrolment.map(
                      enroll => ({
                        value: enroll.tbl_student.pk_student_key,
                        label:
                          enroll.tbl_student.st_first_name != null &&
                          enroll.tbl_student.st_first_name !== ""
                            ? enroll.tbl_student.st_first_name +
                              " " +
                              enroll.tbl_student.st_surname
                            : null,
                      })
                    )

                  setStudentOption(formattedOptions)
                } else {
                  setStudentOption([])
                  setLoader(false)
                }
                // Get unique subject names from the provided JSON data
                //const uniqueSubjectNames = getUniqueSubjectNames(responseSession.data);
              } else {
                //console.error("Home card details not get from database.");
                setSessionData([])
                setLoader(false)

                setStudentOption([])
              }
            })
            .catch(error => {
              setLoader(false)
              //console.log(error)
              if (
                error.response.status === 403 &&
                error.response.data.error === "jwt expired"
              ) {
                localStorage.clear()
                navigate("/login")
              }
            })
        } else if (parseInt(customActiveTab) === 2) {
          //Home work tab

          setLoader(true)
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          axios
            .post(BASE_URL + "session/sessionHomeWorkDetails", {
              pk_ses_key: key,
            })
            .then(responseSession => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties

              // console.log(
              //   responseSession.data.session.tbl_session_time.sest_name
              // )

              if (
                responseSession.data.session != null &&
                responseSession.data.session != ""
              ) {
                setLoader(false)
                setHomeSessionData(responseSession.data)

                // if (
                //   responseSession.data &&
                //   responseSession.data.session &&
                //   responseSession.data.session.tbl_session_time &&
                //   responseSession.data.session.tbl_session_time.tbl_student_enrolment &&
                //   responseSession.data.session.tbl_session_time.tbl_student_enrolment.length > 0
                // ) {

                //   //console.log(responseSession.data.session.tbl_session_time.tbl_student_enrolment)
                //   const formattedOptions = responseSession.data.session.tbl_session_time.tbl_student_enrolment.map(enroll => ({
                //     value: enroll.tbl_student.pk_student_key,
                //     label: enroll.tbl_student.st_first_name != null && enroll.tbl_student.st_first_name !== '' ? enroll.tbl_student.st_first_name + ' ' + enroll.tbl_student.st_surname : null
                //   }));

                //   setStudentOption(formattedOptions);

                // } else {
                //   setStudentOption([]);
                //   setLoader(false);
                // }
                // Get unique subject names from the provided JSON data
                //const uniqueSubjectNames = getUniqueSubjectNames(responseSession.data);
              } else {
                //console.error("Home card details not get from database.");
                setSessionData([])
                setLoader(false)

                setStudentOption([])
              }
            })
            .catch(error => {
              setLoader(false)
              //console.log(error)
              if (
                error.response.status === 403 &&
                error.response.data.error === "jwt expired"
              ) {
                localStorage.clear()
                navigate("/login")
              }
            })
        } else if (parseInt(customActiveTab) === 3) {
          setLoader(true)
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          axios
            .post(BASE_URL + "session/sessionDetails", {
              pk_ses_key: key,
              tab_type: 3,
            })
            .then(responseSession => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties

              // console.log(
              //   responseSession.data.session.tbl_session_time.sest_name
              // )

              if (
                responseSession.data.session != null &&
                responseSession.data.session != ""
              ) {
                setLoader(false)
                setPreSessionData(responseSession.data)

                // if (
                //   responseSession.data &&
                //   responseSession.data.session &&
                //   responseSession.data.session.tbl_session_time &&
                //   responseSession.data.session.tbl_session_time.tbl_student_enrolment &&
                //   responseSession.data.session.tbl_session_time.tbl_student_enrolment.length > 0
                // ) {

                //   // //console.log(responseSession.data.session.tbl_session_time.tbl_student_enrolment)
                //   // const formattedOptions = responseSession.data.session.tbl_session_time.tbl_student_enrolment.map(enroll => ({
                //   //   value: enroll.tbl_student.pk_student_key,
                //   //   label: enroll.tbl_student.st_first_name != null && enroll.tbl_student.st_first_name !== '' ? enroll.tbl_student.st_first_name + ' ' + enroll.tbl_student.st_surname : null
                //   // }));

                //   // setStudentOption(formattedOptions);

                // } else {
                //   // setStudentOption([]);
                //   // setLoader(false);
                // }
                // Get unique subject names from the provided JSON data
                //const uniqueSubjectNames = getUniqueSubjectNames(responseSession.data);
              } else {
                //console.error("Home card details not get from database.");
                setPreSessionData([])
                setLoader(false)

                //setStudentOption([]);
              }
            })
            .catch(error => {
              setLoader(false)
              //console.log(error)
              if (
                error.response.status === 403 &&
                error.response.data.error === "jwt expired"
              ) {
                localStorage.clear()
                navigate("/login")
              }
            })

          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          axios
            .post(BASE_URL + "session/sessionHomeWorkDetails", {
              pk_ses_key: key,
              tab_type: 3,
            })
            .then(responseSession => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties

              // console.log(
              //   responseSession.data.session.tbl_session_time.sest_name
              // )

              if (
                responseSession.data.session != null &&
                responseSession.data.session != ""
              ) {
                setLoader(false)
                setPreHomeSessionData(responseSession.data)

                // if (
                //   responseSession.data &&
                //   responseSession.data.session &&
                //   responseSession.data.session.tbl_session_time &&
                //   responseSession.data.session.tbl_session_time.tbl_student_enrolment &&
                //   responseSession.data.session.tbl_session_time.tbl_student_enrolment.length > 0
                // ) {

                //   // //console.log(responseSession.data.session.tbl_session_time.tbl_student_enrolment)
                //   // const formattedOptions = responseSession.data.session.tbl_session_time.tbl_student_enrolment.map(enroll => ({
                //   //   value: enroll.tbl_student.pk_student_key,
                //   //   label: enroll.tbl_student.st_first_name != null && enroll.tbl_student.st_first_name !== '' ? enroll.tbl_student.st_first_name + ' ' + enroll.tbl_student.st_surname : null
                //   // }));

                //   // setStudentOption(formattedOptions);

                // } else {
                //   // setStudentOption([]);
                //   // setLoader(false);
                // }
                // Get unique subject names from the provided JSON data
                //const uniqueSubjectNames = getUniqueSubjectNames(responseSession.data);
              } else {
                //console.error("Home card details not get from database.");
                setPreHomeSessionData([])
                setLoader(false)

                //setStudentOption([]);
              }
            })
            .catch(error => {
              setLoader(false)
              //console.log(error)
              if (
                error.response.status === 403 &&
                error.response.data.error === "jwt expired"
              ) {
                localStorage.clear()
                navigate("/login")
              }
            })
        } else if (parseInt(customActiveTab) === 4) {
          setLoader(true)
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          axios
            .post(BASE_URL + "session/sessionStudentDetails", {
              pk_ses_key: key,
            })
            .then(responseStudent => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties

              if (responseStudent.data.length > 0) {
                setLoader(false)
                setStudentData(responseStudent.data)
              } else {
                setLoader(false)
                setStudentData([])
              }
            })
            .catch(error => {
              setLoader(false)
              //console.log(error)
              if (
                error.response.status === 403 &&
                error.response.data.error === "jwt expired"
              ) {
                localStorage.clear()
                navigate("/login")
              }
            })
        } else {
          setLoader(false)
          // navigate("/session");
        }
      } else {
        setLoader(false)
        navigate("/session")
      }
    } else {
      setLoader(false)
      navigate("/login")
    }
  }

  const fetchStudentData = async studentKey => {
    // console.log(studentKey);
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseStudent = await axios.post(
          BASE_URL + "student/getstudentdetails",
          {
            pk_student_key: studentKey,
          }
        ) //console.log(studentKey);
        // console.log(responseStudent.data.data.data)
        if (responseStudent.data.data.data != "") {
          setstudentDetail(responseStudent.data.data.data)
        } else {
          setstudentDetail([])
        }
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }
  const fetchlessonNotesData = async studentKey => {
    // console.log(studentKey);
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseStudentNotes = await axios.post(
          BASE_URL + "session/sessionLessonDetails",
          {
            pk_student_key: studentKey,
            pk_ses_key: key,
          }
        ) //console.log(studentKey);

        if (responseStudentNotes.data.length > 0) {
          setLessonNoteDetail(responseStudentNotes.data)
        } else {
          setLessonNoteDetail([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  const fetchpastsessionData = async studentKey => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responsePastSessionStudent = await axios.post(
          BASE_URL + "student/getpastsessionDetails",
          {
            pk_student_key: studentKey,
          }
        ) //console.log(studentKey);

        //console.log(responsePastSessionStudent.data.data.data);

        if (responsePastSessionStudent.data.data.data) {
          setPastSessionStuData(responsePastSessionStudent.data.data.data)
        } else {
          setPastSessionStuData([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  const fetchTopicGraphData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseMastryScroreStudentGraph = await axios.post(
          BASE_URL + "student/TopicGraphDetails",
          {
            pk_student_key: studentKay,
            filter_node_id: isNodeId,
            filter_topic_id: isTopicId,
            filter_date: startDate,
            startDate: moment(dateValue[0]).format("YYYY-MM-DD"),
            endDate: moment(dateValue[1]).format("YYYY-MM-DD"),
          }
        ) //console.log(studentKey);

        //console.log(responseMastryScroreStudentGraph.data);

        if (responseMastryScroreStudentGraph.data.TopicDetails != "") {
          setMistryTopicGraphDeatils(
            responseMastryScroreStudentGraph.data.TopicDetails
          )
          setTopicGraphDeatilsLabelData(
            responseMastryScroreStudentGraph.data.TopicDetails.LabelData
          )
          setTopicGraphDeatilsValueData(
            responseMastryScroreStudentGraph.data.TopicDetails.ValueData
          )

          const formattedDate = moment(
            responseMastryScroreStudentGraph.data[0]?.ses_date
          ).format("yyyy-MM")
        } else {
          setMistryTopicGraphDeatils([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  // console.log(TopicGraphDeatilsValueData);
  useEffect(() => {
    if (isRightTopicGraph) {
      fetchTopicGraphData()
    } else {
    }
  }, [isRightTopicGraph, startDate, dateValue])

  const fetchMastryScoreTopicListData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseMastryScroreStudent = await axios.post(
          BASE_URL + "student/mastryScroeTopic",
          {
            pk_student_key: studentKay,
            // filter_year_level: selectedYearLevelTopic,
            // filter_mastery_range: selectedMastryScore,
          }
        ) //console.log(studentKey);

        //console.log(responsePastSessionStudent.data.data.data);

        if (responseMastryScroreStudent.data.length > 0) {
          const data = responseMastryScroreStudent.data.filter(
            entry => entry.activity_node.length > 0
          )

          const levelNames = [
            ...new Set(
              data
                .flatMap(entry => entry.activity_node)
                .map(node => node.level_name)
            ),
          ]
          //console.log(data)
          setMistryTopic(data)
          setAllData(data)
          setLevelOptions(levelNames)
        } else {
          setMistryTopic([])
          setAllData([])
          setLevelOptions([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isRightStuTopic) {
      fetchMastryScoreTopicListData()
      setLevelOptions([])
      setselectedMasterScore("")
    } else {
    }
  }, [isRightStuTopic])

  const data_area_chart = {
    labels: TopicGraphDeatilsLabelData || [],
    datasets: [
      {
        label: "Mastery score over time",
        data: TopicGraphDeatilsValueData || [],
        fill: true,
        backgroundColor: "rgba(192,192,192,0.2)", // Gray color with opacity
        borderColor: "rgba(192,192,192,1)", // Gray color
      },
    ],
  }

  const fetchMastryScoresessionData = async studentKey => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseMastrySessionStudent = await axios.post(
          BASE_URL + "student/mastryScroe",
          {
            pk_student_key: studentKey,
          }
        ) //console.log(studentKey);

        //console.log(responsePastSessionStudent.data.data.data);

        if (responseMastrySessionStudent.data != "") {
          if (responseMastrySessionStudent.data.studentDetails != "") {
            setMastryScore(responseMastrySessionStudent.data.studentDetails)
          } else {
            setMastryScore([])
          }

          if (responseMastrySessionStudent.data.studentprogressBar != "") {
            setToipc(responseMastrySessionStudent.data.studentprogressBar)
          } else {
            setToipc([])
          }
        } else {
          setMastryScore([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  const fetchUpcommingSessionData = async () => {
    // console.log(studentKey);
    if (authUser && authUser != "" && authUser != null) {
      setmoveTosession(false)
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseUpcomingList = await axios.post(
          BASE_URL + "session/getAllUpcommingSessionList",
          {
            pk_student_key: move_activity.fk_student_key,
            activity_type: move_activity.activity_type,
            fk_activity_node_id: move_activity.fk_activity_node_id,
            topic_id:
              move_activity.LessonActivitiesQuestions.length > 0
                ? move_activity.LessonActivitiesQuestions[0].topic_id
                : null,
            fk_question_id:
              move_activity.LessonActivitiesQuestions.length > 0
                ? move_activity.LessonActivitiesQuestions[0].fk_question_id
                : null,
            fk_subject_id: move_activity.fk_sub_id,
          }
        ) //console.log(studentKey);
        //console.log(responseUpcomingList)
        if (responseUpcomingList.data.length > 0) {
          const updatedData = responseUpcomingList.data.map(item => ({
            ...item,
            checked: false, // Add the checked property to each item
          }))
          setUpcomingSessionData(updatedData)
          setmodal_move_activity(true)
        } else {
          toast.error("Not found upcoming sessions!", {
            autoClose: 3000, // 3 seconds in this example
          })
          setUpcomingSessionData([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching upcoming session deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  function generateRandomColors(count) {
    const colors = []
    for (let i = 0; i < count; i++) {
      // Generate a random color in hexadecimal format
      const color = "#" + Math.floor(Math.random() * 16777215).toString(16)
      colors.push(color)
    }
    return colors
  }

  function labelName(data_label) {
    const label = []

    if (data_label.mistryScore && data_label.mistryScore.length > 0) {
      data_label.mistryScore.forEach(activity => {
        var labe = ""
        if (
          activity.activity_type === 0 &&
          (activity.fk_sub_id === 1 || activity.fk_sub_id === 2)
        ) {
          labe =
            activity.activity_node[0].node_name != "" &&
            activity.activity_node[0].level_name != ""
              ? activity.activity_node[0].level_name +
                " > " +
                activity.activity_node[0].node_name
              : activity.activity_node[0].node_name != ""
              ? activity.activity_node[0].node_name
              : null
        } else if (
          activity.activity_type === 1 
        ) {
          labe =
            activity.activity_node[0].name != ""
              ? activity.activity_node[0].name
              : null
        } else if (
          activity.activity_type === 2 &&
          (activity.fk_sub_id === 1 || activity.fk_sub_id === 2)
        ) {
          labe =
            activity.activity_node[0].topic_name != ""
              ? activity.activity_node[0].topic_name
              : null
        } else if (
          activity.activity_type === 3 
        ) {
          labe =
            activity.activity_node[0].name != ""
              ? activity.activity_node[0].name
              : null
        } else if (
          activity.activity_type === 4 
        ) {
          labe =
            activity.activity_node[0].name != ""
              ? activity.activity_node[0].name
              : null
        } else if (
          activity.activity_type === 5 
        ) {
          labe =
            activity.activity_node[0].name != ""
              ? activity.activity_node[0].name
              : null
        } else if (activity.activity_type === 6 && activity.fk_sub_id === 1) {
          labe =
            activity.activity_node[0].video_name != ""
              ? activity.activity_node[0].video_name
              : null
        } else if (activity.activity_type === 6 && activity.fk_sub_id === 2) {
          labe =
            activity.activity_node[0].video_name != ""
              ? activity.activity_node[0].video_name
              : null
        } else if (
          activity.activity_type === 7 &&
          (activity.fk_sub_id === 1 || activity.fk_sub_id === 2)
        ) {
          labe =
            activity.activity_node[0].file_name != ""
              ? activity.activity_node[0].file_name
              : null
        } else {
          labe = ""
        }

        label.push(labe)
      })
    }
    return label
  }

  function labelValue(data_label) {
    const labelValue = []

    if (data_label.mistryScore && data_label.mistryScore.length > 0) {
      data_label.mistryScore.forEach(activity => {
        var val =
          activity.masteryscore != "" && activity.masteryscore != ""
            ? activity.masteryscore
            : null
        labelValue.push(val)
      })
    }
    return labelValue
  }

  const options_pie_mastry = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
      },
    },

    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  }

  const pie_mastry_data = {
    labels: labelName(mastryScore),
    datasets: [
      {
        data: labelValue(mastryScore),
        // backgroundColor: generateRandomColors(50),
        backgroundColor: [
          "#FF5733",
          "#FFD700",
          "#FF6347",
          "#FF8C00",
          "#FF4500",
          "#FF1493",
          "#FF00FF",
          "#FF00FF",
          "#FF0000",
          "#FF69B4",
          "#FF7F50",
          "#FF8C00",
          "#FF4500",
          "#FF6347",
          "#FFD700",
          "#FF5733",
          "#FF69B4",
          "#FF1493",
          "#FF00FF",
          "#FF00FF",
          "#FF0000",
          "#FF7F50",
          "#FF8C00",
          "#FF4500",
          "#FF6347",
        ],

        hoverBorderColor: "#fff",
      },
    ],
  }
  const fetchNextSessionData = async (pk_student_key, activity_method) => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responsenextsessiondetails = await axios.post(
          BASE_URL + "session/getNextSessionDetails",
          {
            pk_student_key: pk_student_key.value,
            pk_ses_key: key,
            is_homework: activity_method,
          }
        )

        if (responsenextsessiondetails.data != "") {
          setnextsessiondeatils(responsenextsessiondetails.data[0])
        } else {
          setnextsessiondeatils([])
        }
      } catch (error) {
        console.error("Error in fetching session deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  const fetchSessionStudentPendingActivityData = async (
    pk_student_key,
    activity_method
  ) => {
    if (authUser && authUser != "" && authUser != null) {
      setSessionStudentPendingActivity([])
      setActivityPendingLoading(true)
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responsesessionpendingactivitydetails = await axios.post(
          BASE_URL + "session/SessionStudentPendingActivity",
          {
            pk_student_key: pk_student_key.value,
            pk_ses_key_cur: key,
            is_homework: activity_method,
          }
        )

        if (responsesessionpendingactivitydetails.data.length > 0) {
          setSessionStudentPendingActivity(
            responsesessionpendingactivitydetails.data
          )
          setActivityPendingLoading(false)
        } else {
          setSessionStudentPendingActivity([])
          setActivityPendingLoading(false)
        }
      } catch (error) {
        console.error("Error in fetching session deatails:", error)
        setActivityPendingLoading(false)
      }
    } else {
      navigate("/login")
      setActivityPendingLoading(false)
    }
  }

  const fetchSessionStudentNextActivityData = async (
    pk_student_key,
    is_homework,
    session_key,
    subject_id
  ) => {
    if (authUser && authUser != "" && authUser != null) {
      setActivityNextLoading(true)

      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responsesessionnextactivitydetails = await axios.post(
          BASE_URL + "activity/getAssessmentActivitiesNext",
          {
            activity_level_id: 0,
            attended: "",
            search: "",
            student_key: pk_student_key.value,
            session_key: session_key,
            subject_id: subject_id,
            is_homework: is_homework,
          }
        )

        if (
          responsesessionnextactivitydetails.data.activities &&
          responsesessionnextactivitydetails.data.activities.length > 0
        ) {
          setSessionStudentNextActivity(
            responsesessionnextactivitydetails.data.activities
          )
          //setSessionStudentNextActivity(prevData => [...prevData, ...responsesessionnextactivitydetails.data.activities]);
          setActivityNextLoading(false)
        } else {
          setSessionStudentNextActivity([])
          setSessionStudentNextActivity(prevData => [...prevData, ...[]])
          setActivityNextLoading(false)
        }
      } catch (error) {
        console.error("Error in fetching session deatails:", error)
        setActivityNextLoading(false)
      }
    } else {
      navigate("/login")
      setActivityNextLoading(false)
    }
  }

  const fetchSessionStudentSubjectNextActivityData = async (
    pk_student_key,
    is_homework,
    session_key,
    subject_id
  ) => {
    if (authUser && authUser != "" && authUser != null) {
      setActivitysubjectNextLoading(true)
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responsesessionnextactivitydetails = await axios.post(
          BASE_URL + "activity/getAssessmentActivitiesNext",
          {
            activity_level_id: 0,
            attended: "",
            search: "",
            student_key: pk_student_key.value,
            session_key: session_key,
            subject_id: subject_id,
            is_homework: is_homework,
          }
        )

        if (
          responsesessionnextactivitydetails.data.activities &&
          responsesessionnextactivitydetails.data.activities.length > 0
        ) {
          //setSessionStudentNextActivity(responsesessionnextactivitydetails.data.activities)
          setSessionStudentSubjectNextActivity(
            responsesessionnextactivitydetails.data.activities
          )
          //setSessionStudentNextActivity(prevData => [...prevData, ...responsesessionnextactivitydetails.data.activities]);
          setActivitysubjectNextLoading(false)
        } else {
          setSessionStudentSubjectNextActivity([])
          //setSessionStudentNextActivity(prevData => [...prevData, ...[]])
          setActivitysubjectNextLoading(false)
        }
      } catch (error) {
        console.error("Error in fetching session deatails:", error)
        setActivitysubjectNextLoading(false)
      }
    } else {
      navigate("/login")
      setActivitysubjectNextLoading(false)
    }
  }

  useEffect(() => {
    let totalPending = 0

    SessionStudentPendingActivity.forEach(enroll => {
      const isNextSessionAvailable =
        NextSessionData &&
        NextSessionData.next_session_date &&
        NextSessionData.next_session_date !== ""

      if (isNextSessionAvailable) {
        const activities = enroll.Lesson?.LessonActivities || []
        const incompleteActivities = activities.filter(
          activity =>
            activity.status === 0 ||
            ((activity.mastryScore === null ||
              parseInt(activity.mastryScore) < 75) &&
              activity.status === 1 &&
              [0, 2, 6, 7].includes(activity.activity_type))
        )

        totalPending += incompleteActivities.length
      }
    })

    setTotalPendingActivity(totalPending)
  }, [SessionStudentPendingActivity, NextSessionData])
  //console.log(TotalPendingActivity)

  //lest session activity based
  const fetchSessionStudentFetureActivityData = async (
    pk_student_key,
    is_homework
  ) => {
    if (authUser && authUser != "" && authUser != null) {
      setActivityNextLoading(true)
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responsesessionnextactivitydetails = await axios.post(
          BASE_URL + "activity/getAssessmentAutomationActivitiesData",
          {
            activity_level_id: 0,
            attended: "",
            search: "",
            student_key: pk_student_key.value,
            past_session_key: key,
            session_key: key,
            is_homework: is_homework,
            remaining_activity:
              20 -
              (NextSessionData.activity_count + parseInt(TotalPendingActivity)),
          }
        )

        if (
          responsesessionnextactivitydetails.data.activities &&
          responsesessionnextactivitydetails.data.activities.length > 0
        ) {
          setSessionStudentAutomationActivity(
            responsesessionnextactivitydetails.data.activities
          )
          setActivityNextLoading(false)
        } else {
          setSessionStudentAutomationActivity([])
          setActivityNextLoading(false)
          toast.error("Not found any activity!", {
            autoClose: 3000, // 3 seconds in this example
          })
        }
      } catch (error) {
        console.error("Error in fetching session deatails:", error)
        setActivityNextLoading(false)
        toast.error("Not found any activity!", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      navigate("/login")
      setActivityNextLoading(false)
      toast.error("Not found any activity!", {
        autoClose: 3000, // 3 seconds in this example
      })
    }
  }
  //

  const fetchMasterData = async () => {
    try {
      if (authUser && authUser != "" && authUser != null) {
        // setLoading(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseLevel = await axios.post(BASE_URL + "student/yearLevel")
        //console.log(response)
        if (responseLevel.data.length > 0) {
          const formattedyearOptions = responseLevel.data.map(option => ({
            value: option.activity_level_id,
            label: option.level_name,
          }))
          formattedyearOptions.unshift({
            value: "",
            label: "Select",
          })
          setoptionGroup(formattedyearOptions)
        } else {
          setoptionGroup([])
        }
      } else {
        navigate("/login")
      }
    } catch (error) {
      if (
        error.response.status === 403 &&
        error.response.data.error === "jwt expired"
      ) {
        localStorage.clear()
        navigate("/login")
      }
    }
  }

  function noteaddfunction(event) {
    const value = event.target.value
    //console.log(value);

    if (value.length > 0) {
      if (authUser && authUser != "" && authUser != null) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/lessonNotesAdd", {
            pk_ses_key: key,
            pk_student_key: studentKay,
            lessonNote: lessonNote,
          })
          .then(responseNotes => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseNotes.data) === 1) {
              setLessonNote("")
              fetchlessonNotesData(studentKay)
              fetchData()
            } else {
            }
          })
          .catch(error => {
            // setIsDASubmitting(false);
            //console.log(error)

            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        navigate("/login")
      }
    }
  }

  const onDelectNote = () => {
    // //function onDelectActivity() {

    //console.log();

    if (authUser && authUser != "" && authUser != null) {
      if (key) {
        setIsNoteSubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/deleteNote", {
            pk_ses_key: key,
            pk_notes_key: note_key,
          })
          .then(responseNoteDelete => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseNoteDelete.data) === 1) {
              tog_delete_note()
              setIsNoteSubmitting(false)
              fetchlessonNotesData(studentKay)
              fetchData()
            } else {
              setIsNoteSubmitting(false)
              // toast.error(
              //   "Failure! Unable to delete activity. Try again later",
              //   {
              //     autoClose: 3000, // 3 seconds in this example
              //   }
              // )
            }
          })
          .catch(error => {
            setIsNoteSubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsNoteSubmitting(false)
        navigate("/session")
      }
    } else {
      setIsNoteSubmitting(false)
      navigate("/login")
    }
  }

  const columns = useMemo(
    () => [
      {
        name: "Student Name",
        Header: "Student Name",
        accessor: row =>
          row.st_first_name && row.st_first_name != ""
            ? row.st_first_name + " " + row.st_surname
            : "-",
        // disableFilters: true,
        // disableSortBy: true,
      },
      {
        name: "Level",
        Header: "Level",
        // accessor: 'st_first_name',
        accessor: row =>
          row.level_name && row.level_name != "" ? row.level_name : "-",
      },
      {
        name: "School Name",
        Header: "School Name",
        accessor: row =>
          row.fk_sc_id != null && row.fk_sc_id != "" ? row.fk_sc_id : "-",
      },
      {
        name: "Attendance",
        Header: "Attendance",
        accessor: "attendance_status",
        Cell: ({ row }) => {
          const rowValue = row.values
          // Conditionally render content based on the value of tp_status

          if (parseInt(rowValue.attendance_status) === 0) {
            return (
              <button className="btn tb-primary text-white text-uppercase">
                Pending
              </button>
            )
          } else if (parseInt(rowValue.attendance_status) === 2) {
            return (
              <button className="btn tb-success text-uppercase">
                Attended
              </button>
            )
          } else if (parseInt(rowValue.attendance_status) === 1) {
            return (
              <button className="btn tb-warning text-white text-uppercase">
                {" "}
                Ongoing
              </button>
            )
          } else if (parseInt(rowValue.attendance_status) === 3) {
            return (
              <button className="btn tb-danger text-white text-uppercase">
                {" "}
                Missed
              </button>
            )
          } else if (parseInt(rowValue.attendance_status) === 4) {
            return (
              <button className="btn tb-danger text-white text-uppercase">
                {" "}
                Offline
              </button>
            )
          } else if (parseInt(rowValue.attendance_status) === 5) {
            return (
              <button className="btn tb-danger text-white text-uppercase">
                {" "}
                Deffer Request
              </button>
            )
          } else if (parseInt(rowValue.attendance_status) === 6) {
            return (
              <button className="btn tb-danger text-white text-uppercase">
                {" "}
                Cancelled
              </button>
            )
          } else {
            return (
              <button className="btn tb-primary text-white text-uppercase">
                Pending
              </button>
            )
          }
        },
        //accessor: row => row.attendance_status != null && row.attendance_status != '' ? parseInt(row.attendance_status) === 0 ? 'Pending' : parseInt(row.attendance_status) === 1 ? 'Ongoing' : parseInt(row.attendance_status) === 2 ? 'Attended' : parseInt(row.attendance_status) === 6 ? 'Canceled' : 'Pending': '-',
      },
      {
        name: "Previous Notes",
        Header: "Previous Notes",
        accessor: row =>
          row.previous_notes != null && row.previous_notes != ""
            ? row.previous_notes
            : "-",
      },
      {
        name: "'Today's Notes'",
        Header: "Today's Notes",
        accessor: row =>
          row.today_notes != null && row.today_notes != ""
            ? row.today_notes
            : "-",
      },
      {
        Header: "",
        disableSortBy: true,
        accessor: "pk_student_key",
        disableFilters: true,

        Cell: ({ row }) => (
          <>
            <button
              className="btn"
              onClick={() => toggleRightCanvasStu(row.original.pk_student_key)}
            >
              <i
                class="mdi mdi-chevron-right-circle-outline"
                style={{ color: "#979797", fontSize: "20px" }}
              ></i>
            </button>
          </>
        ),
      },
      {
        accessor: "student_key",
        disableSortBy: true,
        disableFilters: true,
        isVisible: false,

        selector: row => row.values.pk_student_key,
        show: false,
      },
      //  {
      //       Header: "Status",
      //       disableSortBy: true,
      //       accessor: 'pk_student_key',
      //       disableFilters: true,

      //       Cell: ({ row }) => (<><button className='btn status-btn'> ON GOING</button></>),

      // },
      // {
      //       Header: "#",
      //       disableSortBy: true,
      //       accessor: 'pk_student_keys',
      //       disableFilters: true,

      //       Cell: ({ row }) => (<><button className='btn'><i class="mdi mdi-chevron-right-circle-outline" style={{ color: '#979797', fontSize: '20px'}}></i></button></>),

      // },
      // {
      //   accessor: 'student_key', disableSortBy: true, disableFilters: true,           isVisible: false,

      //   selector: (row) => row.values.pk_student_key, show: false,
      // },
    ],
    []
  )

  const columns1 = useMemo(
    () => [
      {
        name: "Date",
        Header: "Date",
        accessor: row =>
          row.ses_date != null || row.ses_date != ""
            ? moment(row.ses_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
                "DD-MM-YYYY"
              )
            : "-",
        // disableFilters: true,
        // disableSortBy: true,
      },
      {
        name: "Time",
        Header: "Time",
        accessor: row =>
          row.ses_start_time != null || row.ses_start_time != ""
            ? moment(row.ses_start_time, "HH:mm:ss").format("hh:mm A") +
              " " +
              moment(row.ses_end_time, "HH:mm:ss").format("hh:mm A")
            : "-",
      },
      {
        name: "Sestion Name",
        Header: "Sestion Name",
        accessor: row =>
          row.sest_name != null || row.sest_name != "" ? row.sest_name : "-",
      },
      {
        name: "Subject",
        Header: "Subject",
        accessor: row =>
          row.student_subject == null || row.student_subject == ""
            ? "-"
            : row.student_subject,
      },
      {
        name: "Type",
        Header: "Type",
        accessor: row =>
          row.sest_is_holidays == null || row.sest_is_holidays == ""
            ? "-"
            : row.sest_is_holidays == "1"
            ? "Recurring"
            : row.sest_is_holidays == "2"
            ? "One Off Session"
            : row.sest_is_holidays == "3"
            ? "Make Up Session"
            : "-",
      },
      {
        Header: "Status",
        disableSortBy: true,
        accessor: "ses_status",
        disableFilters: true,
        Cell: ({ row }) => {
          // console.log(row)
          const rowValue = row.original
          //  console.log('reas'+row.original.ses_status);
          //  console.log('reasdd'+row.original.ses_is_completed);
          // Conditionally render content based on the value of tp_status
          if (parseInt(rowValue.ses_status) === 0) {
            return (
              <button className="btn bg-primary text-white">Upcoming</button>
            )
          } else if (
            parseInt(rowValue.ses_status) === 1 &&
            parseInt(rowValue.ses_is_completed) === 1
          ) {
            return <button className="btn bg-success">Completed</button>
          } else if (
            parseInt(rowValue.ses_status) === 1 &&
            parseInt(rowValue.ses_is_completed) === 0
          ) {
            return (
              <button className="btn bg-warning text-white"> On Going</button>
            )
          } else {
            return (
              <button className="btn bg-primary text-white">Upcoming</button>
            )
          }
        },
      },
      {
        Header: "#",
        disableSortBy: true,
        accessor: "pk_ses_key",
        disableFilters: true,
        Cell: ({ row }) => {
          const rowValue = row.values
          // Conditionally render content based on the value of tp_status
          if (rowValue.pk_ses_key && rowValue.pk_ses_key != null) {
            return (
              <Link to={"/session/" + rowValue.pk_ses_key}>
                <i
                  class="mdi mdi-chevron-right-circle-outline"
                  style={{ color: "#979797", fontSize: "20px" }}
                ></i>
              </Link>
            )
          } else {
            return ""
          }
        },
      },
      // {
      //   accessor: row => row.st_is_completed != null && row.st_is_completed != '' ? row.st_is_completed : '-',
      //   accessor: 'st_is_completed',
      //   disableSortBy: true,
      //   disableFilters: true,
      //   isVisible: false,
      //    show: false,
      // },
      // {
      //   accessor: 'visibility', disableSortBy: true, disableFilters: true,     isVisible: false,

      //   selector: (row) => row.visibility,

      // }
    ],
    []
  )

  const columns_session = [
    {
      name: "Date",
      Header: "Date",
      // accessor: row =>
      // row.ses_date != null || row.ses_date != ""
      //   ? moment(row.ses_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
      //     "DD-MM-YYYY"
      //   )
      //   : "-",
      accessor: row => (row.ses_date ? new Date(row.ses_date) : null), // Use raw date for sorting
      Cell: ({ value }) => {
        if (!value) return "-"

        const day = String(value.getDate()).padStart(2, "0")
        const month = String(value.getMonth() + 1).padStart(2, "0")
        const year = value.getFullYear()
        return `${day}-${month}-${year}`
      },
      // disableFilters: true,
      // disableSortBy: true,
    },
    {
      name: "Session Name",
      Header: "Session Name",
      // accessor: 'st_first_name',
      accessor: row =>
        row.sest_name && row.sest_name != "" ? row.sest_name : "-",
    },
    {
      name: "Subject",
      Header: "Subject",
      accessor: row =>
        row.sub_name != null && row.sub_name != "" ? row.sub_name : "-",
    },
    {
      name: "Action",
      Header: "Action",
      accessor: "pk_ses_key",
      Cell: ({ row }) => {
        const { pk_ses_key } = row.values

        return (
          <input
            type="checkbox"
            className="form-check-input"
            checked={row.original.checked}
            onChange={e => handleCheckboxChange(pk_ses_key, e.target.checked)}
          />
        )
      },
      //accessor: row => row.attendance_status != null && row.attendance_status != '' ? parseInt(row.attendance_status) === 0 ? 'Pending' : parseInt(row.attendance_status) === 1 ? 'Ongoing' : parseInt(row.attendance_status) === 2 ? 'Attended' : parseInt(row.attendance_status) === 6 ? 'Canceled' : 'Pending': '-',
    },

    //  {
    //       Header: "Status",
    //       disableSortBy: true,
    //       accessor: 'pk_student_key',
    //       disableFilters: true,

    //       Cell: ({ row }) => (<><button className='btn status-btn'> ON GOING</button></>),

    // },
    // {
    //       Header: "#",
    //       disableSortBy: true,
    //       accessor: 'pk_student_keys',
    //       disableFilters: true,

    //       Cell: ({ row }) => (<><button className='btn'><i class="mdi mdi-chevron-right-circle-outline" style={{ color: '#979797', fontSize: '20px'}}></i></button></>),

    // },
    // {
    //   accessor: 'student_key', disableSortBy: true, disableFilters: true,           isVisible: false,

    //   selector: (row) => row.values.pk_student_key, show: false,
    // },
  ]

  const columns_multi_session = [
    {
      name: "Date",
      Header: "Date",
      accessor: row => (row.ses_date ? new Date(row.ses_date) : null), // Use raw date for sorting
      Cell: ({ value }) => {
        if (!value) return "-"

        const day = String(value.getDate()).padStart(2, "0")
        const month = String(value.getMonth() + 1).padStart(2, "0")
        const year = value.getFullYear()
        return `${day}-${month}-${year}`
      },
      // disableFilters: true,
      // disableSortBy: true,
    },
    {
      name: "Session Name",
      Header: "Session Name",
      // accessor: 'st_first_name',
      accessor: row =>
        row.sest_name && row.sest_name != "" ? row.sest_name : "-",
    },
    {
      name: "Subject",
      Header: "Subject",
      accessor: row =>
        row.sub_name != null && row.sub_name != "" ? row.sub_name : "-",
    },
    {
      name: "Action",
      Header: "Action",
      accessor: "pk_ses_key",
      Cell: ({ row }) => {
        const { pk_ses_key } = row.values

        return (
          <input
            type="checkbox"
            className="form-check-input"
            checked={row.original.checked}
            onChange={e =>
              handleCheckboxMulChange(pk_ses_key, e.target.checked)
            }
          />
        )
      },
      //accessor: row => row.attendance_status != null && row.attendance_status != '' ? parseInt(row.attendance_status) === 0 ? 'Pending' : parseInt(row.attendance_status) === 1 ? 'Ongoing' : parseInt(row.attendance_status) === 2 ? 'Attended' : parseInt(row.attendance_status) === 6 ? 'Canceled' : 'Pending': '-',
    },

    //  {
    //       Header: "Status",
    //       disableSortBy: true,
    //       accessor: 'pk_student_key',
    //       disableFilters: true,

    //       Cell: ({ row }) => (<><button className='btn status-btn'> ON GOING</button></>),

    // },
    // {
    //       Header: "#",
    //       disableSortBy: true,
    //       accessor: 'pk_student_keys',
    //       disableFilters: true,

    //       Cell: ({ row }) => (<><button className='btn'><i class="mdi mdi-chevron-right-circle-outline" style={{ color: '#979797', fontSize: '20px'}}></i></button></>),

    // },
    // {
    //   accessor: 'student_key', disableSortBy: true, disableFilters: true,           isVisible: false,

    //   selector: (row) => row.values.pk_student_key, show: false,
    // },
  ]

  const validationSchema1 = Yup.object().shape({
    students: Yup.array().of(
      Yup.object().shape({
        status: Yup.string().required("Status is required"),
        homework: Yup.boolean(),
      })
    ),
  })

  //console.log(initialDynamicFields);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      students: initialDynamicFields,
    },
    validationSchema: validationSchema1,
    onSubmit: values => {
      //console.log(values)
      setIsSessionEnd(true)
      if (authUser && authUser != "" && authUser != null) {
        if (key) {
          setIsSessionEnd(true)
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          axios
            .post(BASE_URL + "session/sessionEnd", {
              pk_ses_key: key,
              ...values,
            })
            .then(responseSessionEnd => {
              // console.log(responseSessionEnd.data)
              // Assuming the API response is an array of objects with 'id' and 'value' properties
              if (parseInt(responseSessionEnd.data) === 1) {
                tog_session_end()
                setIsSessionEnd(false)
                fetchData()
              } else {
                setIsSessionEnd(false)
                toast.error("Failure! Unable to session end. Try again later", {
                  autoClose: 3000, // 3 seconds in this example
                })
              }
            })
            .catch(error => {
              //console.log(error)
              if (
                error.response.status === 403 &&
                error.response.data.error === "jwt expired"
              ) {
                localStorage.clear()
                navigate("/login")
              }
            })
        } else {
          setIsSessionEnd(false)
          navigate("/session")
        }
      } else {
        setIsSessionEnd(false)
        navigate("/login")
      }

      //setSubmitting(false);
    },
  })

  //console.log(sessionData.session.tbl_session_time.tbl_student_enrolment.tbl_student);

  // useEffect(() => {

  //   fetchData();
  //   //console.log(customActiveTab);

  // }, [customActiveTab]);

  //Add Coin Popup start
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      pk_student_key: "",
      coins_earned: "",
      remarks: "",
    },
    validationSchema: Yup.object({
      pk_student_key: Yup.mixed().required("Student list is required"),
      coins_earned: Yup.number()
        .integer()
        .min(1, "Minimum value 1 required")
        .required("Coins is required."),
      remarks: Yup.string()
        .notRequired()
        .matches(
          /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
          "Avoid start & end with a space or comma"
        ),
    }),
    onSubmit: async (values, actions) => {
      //   //disable submit button
      setIsSubmitting(true)

      try {
        const response = await axios.post(BASE_URL + "session/awardCoins", {
          ...values,
        })
        if (parseInt(response.data) === 1) {
          tog_center2()
          actions.resetForm()
          // localStorage.setItem("authUser", JSON.stringify(response.data[0]['token']));
          // props.router.navigate("/dashboard");
          //console.log('success');
          toast.success(" Coins assigned to students", {
            autoClose: 3000, // 3 seconds in this example
          })

          setIsSubmitting(false)
        } else {
          toast.error("Coins not assigned to student, Try again later", {
            autoClose: 3000, // 3 seconds in this example
          })
          setIsSubmitting(false)
        }
      } catch (error) {
        if (
          error.response.status === 403 &&
          error.response.data.error === "jwt expired"
        ) {
          localStorage.clear()
          navigate("/login")
        }
        console.error("Error fetching data:", error)

        setIsSubmitting(false) // Re-enable the submit button
      } finally {
        setIsSubmitting(false) // Re-enable the submit button
      }

      //dispatch(loginUser(values, props.router.navigate));
    },
  })
  //add coin popup end
  const validation1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      pk_student_key: "",
      activities: [],
      next_session: "", // Initialize as empty
      activity_method: "0",
    },
    validationSchema: Yup.object({
      pk_student_key: Yup.mixed().required("Student list is required"),
      activity_method: Yup.mixed().required("Activity method is required"),
      activities: Yup.array()
        .min(1, "At least one activity must be selected.") // Ensure at least one activity is selected
        .required("Activities are required."),
    }),
    onSubmit: async (values, actions) => {
      setIsSubmitting(true) // Disable submit button

      // const selectedStudentSession =
      //   sessionData.session.tbl_session_time.tbl_student_enrolment.filter(
      //     enroll => enroll.fk_stu_key === values.pk_student_key.value
      //   )

      // const nextSession =
      //   selectedStudentSession.length > 0
      //     ? selectedStudentSession[0].tbl_student.next_session_key
      //     : ""
      const nextSession = NextSessionData.next_session

      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`

        const response = await axios.post(
          BASE_URL + "session/MoveActivityTonextSession",
          {
            ...values,
            pk_student_key: values.pk_student_key.value,
            pk_ses_key_new: nextSession,
          }
        )

        if (parseInt(response.data) === 1) {
          tog_center3()
          actions.resetForm()
          toast.success("Moved to the next session successfully", {
            autoClose: 3000,
            onClose: () => {
              //window.history.back()
              // After 3 seconds, navigate back one step in the browser's history
              OnSubmitNavActivityAssign(
                nextSession,
                values.pk_student_key,
                values.activity_method
              )
            },
          })

          //fetchData()
        } else {
          toast.error(
            "Activity not moved to the next session, Try again later",
            {
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        if (
          error.response.status === 403 &&
          error.response.data.error === "jwt expired"
        ) {
          localStorage.clear()
          navigate("/login")
        }
        console.error("Error fetching data:", error)
      } finally {
        setIsSubmitting(false) // Re-enable the submit button
      }
    },
  })
  const OnSubmitActivityData = async (
    next_session_key,
    next_student_key,
    activity_method
  ) => {
    try {
      //setLoader(true)

      if (authUser && authUser != "" && authUser != null) {
        if (
          next_session_key &&
          next_student_key &&
          next_student_key != null &&
          next_session_key != null &&
          SessiosNextActivity &&
          SessiosNextActivity.length > 0
        ) {
          // setLoader(true);
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          setIsSubmitting(true)
          const response = await axios
            .post(BASE_URL + "activity/createActivities", {
              activities:
                SessiosNextActivity.flatMap(item =>
                  JSON.parse(item.activity)
                ) || [],
              is_homework: activity_method && activity_method == 1 ? 1 : 0,
              lesson_key: "",
              session_key: next_session_key || "",
              student_key: next_student_key.value || "",
            })
            .then(responseActivity => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties
              const activitiesData = responseActivity.data

              if (activitiesData == 1) {
                // Reset the form
                //formRef.current.reset();

                toast.success(" Activities added successfully", {
                  autoClose: 3000, // 3 seconds in this example
                  onClose: () => {
                    //window.history.back()
                    // After 3 seconds, navigate back one step in the browser's history
                    window.location.reload()
                  },
                })

                setIsSubmitting(false) // Re-enable the submit button
                //localStorage.removeItem("selectedData")
                //setLoader(false)
              } else {
                // toast.success("Failure! Unable to add activities. Try again later", {
                //   autoClose: 3000, // 3 seconds in this example
                //   onClose: () => {
                //     //window.history.back()
                //     // After 3 seconds, navigate back one step in the browser's history
                //     // window.location.reload();
                //   },
                // })

                toast.success(" Activities added successfully", {
                  autoClose: 3000, // 3 seconds in this example
                  onClose: () => {
                    //window.history.back()
                    // After 3 seconds, navigate back one step in the browser's history
                    window.location.reload()
                  },
                })
                setIsSubmitting(false) // Re-enable the submit button
                // localStorage.removeItem("selectedData")
                // setLoader(false)
              }
            })
            .catch(error => {
              console.error("Error fetching options from API: ", error)
              //setLoader(false)
            })
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
      toast.error("Failure! Unable to create activity. Try again later", {
        autoClose: 3000, // 3 seconds in this example
      })

      setIsSubmitting(false) // Re-enable the submit button
    } finally {
      setIsSubmitting(false) // Re-enable the submit button
    }
  }

  // const OnSubmitNavActivityAssign = async (next_session_key, next_student_key, activity_method) => {
  //   if (
  //     next_session_key &&
  //     next_student_key &&
  //     next_student_key != null &&
  //     next_session_key != null && SessiosNextActivity && SessiosNextActivity.length > 0
  //   ) {

  //     const updatedSessiosNextActivity = await SessiosNextActivity.map(({ activity_level_data, activity_level_id_data, activity_topic_data, activity_subject, ...rest }) => rest);

  //     // Convert the concatenated data into JSON format
  //     const jsonValue = await JSON.stringify(updatedSessiosNextActivity)
  //     // Update local storage with the new concatenated data
  //     await localStorage.setItem("selectedData", jsonValue)

  //     await localStorage.removeItem("typeAct")
  //     await localStorage.setItem("typeAct", activity_method && activity_method == 1 ? 2 : 1)

  //     await localStorage.setItem("session_key", next_session_key)
  //     await localStorage.setItem("student_key", next_student_key.value)
  //     //localStorage.setItem("subject_ID", subject_ID)

  //     navigate("/session-newactivity")

  //   }else{
  //     //skip
  //   }
  // }

  const OnSubmitNavActivityAssign = async (
    next_session_key,
    next_student_key,
    activity_method
  ) => {
    if (
      next_session_key &&
      next_student_key &&
      SessiosNextActivity?.length > 0
    ) {
      // Remove specific fields from SessiosNextActivity
      const updatedSessiosNextActivity = SessiosNextActivity.map(
        ({
          activity_level_data,
          activity_level_id_data,
          activity_topic_data,
          activity_subject,
          ...rest
        }) => rest
      )

      if (updatedSessiosNextActivity.length > 0) {
        // Convert the updated data to JSON and store in localStorage
        localStorage.setItem(
          "selectedData",
          JSON.stringify(updatedSessiosNextActivity)
        )
      } else {
        localStorage.removeItem("selectedData")
      }

      // Update typeAct in localStorage
      localStorage.setItem("typeAct", activity_method === 1 ? 2 : 1)

      // Update session and student keys
      localStorage.setItem("session_key", next_session_key)
      localStorage.setItem("student_key", next_student_key.value)

      // Navigate to the new activity session
      navigate("/session-newactivity")
    } else {
      // Skip if validation fails
      console.warn("Invalid inputs or empty activity list.")
    }
  }

  const calculateSessionDuration = (sesStartTime, sesEndTime) => {
    if (sesStartTime && sesEndTime) {
      const currentDate = "2024-02-04"
      const startTime = moment(
        `${currentDate} ${sesStartTime}`,
        "YYYY-MM-DD HH:mm:ss"
      )
      const endTime = moment(
        `${currentDate} ${sesEndTime}`,
        "YYYY-MM-DD HH:mm:ss"
      )

      const duration = moment.duration(endTime.diff(startTime)).asMinutes()
      return `${duration} mins`
    } else {
      return "-"
    }
  }

  const authUserCountry = JSON.parse(localStorage.getItem("country")) || "1"
  const [startButton, setStartButton] = useState(false)

  const [endButton, setendButton] = useState(false)

  useEffect(() => {
    /*kathir 29/03/24 start */

    if (
      sessionData &&
      sessionData.session &&
      sessionData.session.ses_date &&
      parseInt(sessionData.session.ses_status) === 0 &&
      parseInt(sessionData.session.ses_is_completed) === 0
    ) {
      if (
        parseInt(authUserCountry) === 183 &&
        moment(sessionData.session.ses_date, moment.ISO_8601).format(
          "YYYY-MM-DD"
        ) === moment().tz("Europe/London").format("YYYY-MM-DD") &&
        moment(sessionData.session.ses_start_time, "HH:mm:ss").format(
          "HH:mm:ss"
        ) <=
          moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format(
            "HH:mm:ss"
          )
        //&& moment(sessionData.session.ses_end_time, "HH:mm:ss").format("HH:mm:ss") >= moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format("HH:mm:ss")
      ) {
        setStartButton(true)
      } else if (
        parseInt(authUserCountry) === 1 &&
        moment(sessionData.session.ses_date, moment.ISO_8601).format(
          "YYYY-MM-DD"
        ) === moment().tz("Asia/Singapore").format("YYYY-MM-DD") &&
        moment(sessionData.session.ses_start_time, "HH:mm:ss").format(
          "HH:mm:ss"
        ) <=
          moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format(
            "HH:mm:ss"
          )
        //&& moment(sessionData.session.ses_end_time, "HH:mm:ss").format("HH:mm:ss") >= moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format("HH:mm:ss")
      ) {
        setStartButton(true)
      } else if (
        parseInt(authUserCountry) === 184 &&
        moment(sessionData.session.ses_date, moment.ISO_8601).format(
          "YYYY-MM-DD"
        ) === moment().tz("Pacific/Auckland").format("YYYY-MM-DD") &&
        moment(sessionData.session.ses_start_time, "HH:mm:ss").format(
          "HH:mm:ss"
        ) <=
          moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format(
            "HH:mm:ss"
          )
        //&& moment(sessionData.session.ses_end_time, "HH:mm:ss").format("HH:mm:ss") >= moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format("HH:mm:ss")
      ) {
        setStartButton(true)
      } else if (
        parseInt(authUserCountry) === 185 &&
        moment(sessionData.session.ses_date, moment.ISO_8601).format(
          "YYYY-MM-DD"
        ) === moment().tz("Australia/Sydney").format("YYYY-MM-DD") &&
        moment(sessionData.session.ses_start_time, "HH:mm:ss").format(
          "HH:mm:ss"
        ) <=
          moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format(
            "HH:mm:ss"
          )
        //moment(sessionData.session.ses_end_time, "HH:mm:ss").format("HH:mm:ss") >= moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format("HH:mm:ss")
      ) {
        setStartButton(true)
      }
    } else {
      setStartButton(false)
    }

    if (
      sessionData &&
      sessionData.session &&
      sessionData.session.ses_date &&
      parseInt(sessionData.session.ses_status) === 1 &&
      parseInt(sessionData.session.ses_is_completed) === 0
    ) {
      if (
        parseInt(authUserCountry) === 183 &&
        moment(sessionData.session.ses_date, moment.ISO_8601).format(
          "YYYY-MM-DD"
        ) === moment().tz("Europe/London").format("YYYY-MM-DD") &&
        moment(sessionData.session.ses_end_time, "HH:mm:ss")
          .add(15, "minutes")
          .format("HH:mm:ss") <=
          moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format(
            "HH:mm:ss"
          )
      ) {
        setendButton(true)
      } else if (
        parseInt(authUserCountry) === 1 &&
        moment(sessionData.session.ses_date, moment.ISO_8601).format(
          "YYYY-MM-DD"
        ) === moment().tz("Asia/Singapore").format("YYYY-MM-DD") &&
        moment(sessionData.session.ses_end_time, "HH:mm:ss")
          .add(15, "minutes")
          .format("HH:mm:ss") <=
          moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format(
            "HH:mm:ss"
          )
      ) {
        setendButton(true)
      } else if (
        parseInt(authUserCountry) === 184 &&
        moment(sessionData.session.ses_date, moment.ISO_8601).format(
          "YYYY-MM-DD"
        ) === moment().tz("Pacific/Auckland").format("YYYY-MM-DD") &&
        moment(sessionData.session.ses_end_time, "HH:mm:ss")
          .add(15, "minutes")
          .format("HH:mm:ss") <=
          moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format(
            "HH:mm:ss"
          )
      ) {
        setendButton(true)
      } else if (
        parseInt(authUserCountry) === 185 &&
        moment(sessionData.session.ses_date, moment.ISO_8601).format(
          "YYYY-MM-DD"
        ) === moment().tz("Australia/Sydney").format("YYYY-MM-DD") &&
        moment(sessionData.session.ses_end_time, "HH:mm:ss")
          .add(15, "minutes")
          .format("HH:mm:ss") <=
          moment(sessionData.session.todayCurrectTime, "HH:mm:ss").format(
            "HH:mm:ss"
          )
      ) {
        setendButton(true)
      }
    } else {
      setendButton(false)
    }

    /* kathir 29/03/24 end*/
  }, [sessionData])
  function onSessionStart() {
    if (authUser && authUser != "" && authUser != null) {
      if (key) {
        setIsSessionStart(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/sessionStart", {
            pk_ses_key: key,
            pk_ses_id: sessionData.session.pk_ses_id,
          })
          .then(responseSessionStart => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseSessionStart.data) === 1) {
              tog_center()
              setIsSessionStart(false)
              fetchData()
            } else {
              setIsSessionStart(false)
              toast.error("Failure! Unable to session start. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsSessionStart(false)
        navigate("/session")
      }
    } else {
      setIsSessionStart(false)
      navigate("/login")
    }
  }

  function onSessionEnd() {
    if (authUser && authUser != "" && authUser != null) {
      if (key) {
        setIsSessionEnd(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/sessionEnd", {
            pk_ses_key: key,
          })
          .then(responseSessionEnd => {
            //console.log(responseSessionEnd.data)
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseSessionEnd.data) === 1) {
              tog_session_end()
              setIsSessionEnd(false)
              fetchData()
            } else {
              setIsSessionEnd(false)
              toast.error("Failure! Unable to session end. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsSessionEnd(false)
        navigate("/session")
      }
    } else {
      setIsSessionEnd(false)
      navigate("/login")
    }
  }

  function onDelectActivityPopup(getActivityKey) {
    setActivityKey(getActivityKey)
    tog_delete_activity()
  }

  const onDelectActivity = () => {
    // //function onDelectActivity() {

    //console.log();

    if (authUser && authUser != "" && authUser != null) {
      if (key) {
        setIsDASubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/deleteActivity", {
            pk_ses_key: key,
            pk_lesson_activity_key: activity_key,
          })
          .then(responseActivityDelete => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityDelete.data) === 1) {
              toast.success("Activity deleted successfully", {
                autoClose: 3000, // 3 seconds in this example
              })
              tog_delete_activity()
              setIsDASubmitting(false)
              fetchData()
            } else {
              setIsDASubmitting(false)
              toast.error(
                "Failure! Unable to delete activity. Try again later",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )
            }
          })
          .catch(error => {
            setIsDASubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsDASubmitting(false)
        navigate("/session")
      }
    } else {
      setIsDASubmitting(false)
      navigate("/login")
    }
  }
  const onMoveActivityUpcomingSession = () => {
    // //function onDelectActivity() {

    // console.log(move_activity)

    if (authUser && authUser != "" && authUser != null) {
      if (key) {
        setIsMASubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/submitMoveActivityToSession", {
            pk_ses_key: moveingSessionKey,
            pk_lesson_activity_key: move_activity.pk_lesson_activity_key,
            pk_student_key: move_activity.fk_student_key,
            fk_lesson_key: move_activity.fk_lesson_key,
          })
          .then(responseActivityMove => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityMove.data) === 1) {
              toast.success(
                "Activity rolled over to next session(s) successfully",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )
              tog_moveing_popup()
              tog_move_activity()
              setIsMASubmitting(false)
              fetchData()
            } else {
              setIsMASubmitting(false)
              toast.error("Failure! Unable to move activity. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            setIsMASubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsMASubmitting(false)
        navigate("/session")
      }
    } else {
      setIsMASubmitting(false)
      navigate("/login")
    }
  }

  const onMoveActivityHomework = () => {
    // //function onDelectActivity() {

    //console.log();

    if (authUser && authUser != "" && authUser != null) {
      if (key) {
        setIsMAHSubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(BASE_URL + "session/submitMoveActivityToHomework", {
            pk_ses_key: key,
            pk_lesson_activity_key: move_activity.pk_lesson_activity_key,
            pk_student_key: move_activity.fk_student_key,
          })
          .then(responseActivityMoveHome => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityMoveHome.data) === 1) {
              toast.success(
                "Activity Rolled over to same session homework successfully",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )
              tog_move_home_activity()
              setIsMAHSubmitting(false)
              fetchData()
            } else {
              setIsMAHSubmitting(false)
              toast.error("Failure! Unable to move activity. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            setIsMAHSubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsMAHSubmitting(false)
        navigate("/session")
      }
    } else {
      setIsMAHSubmitting(false)
      navigate("/login")
    }
  }

  const onMoveAllActivityHomework = () => {
    // //function onDelectActivity() {

    //console.log();

    if (authUser && authUser != "" && authUser != null) {
      if (selectedActivityKeys) {
        setIsMAHmSubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`

        axios
          .post(BASE_URL + "session/submitMovemultipleActivityToHomework", {
            pk_ses_key: key,
            selectedactivitykeys: selectedActivityKeys,
            pk_student_key: activityStudentKey,
          })
          .then(responseActivityMoveHome => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityMoveHome.data) === 1) {
              toast.success(
                "Activity Rolled over to same session homework successfully",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )

              tog_move_home_all_activity()
              setIsMAHmSubmitting(false)
              tog_move_multiple_activity()
              setSelectedActivityKeys([])
              setselectedActivitySubject([])
              setSelectAllChecked(false)
              fetchData()
              window.location.reload()
            } else {
              setIsMAHmSubmitting(false)
              toast.error("Failure! Unable to move activity. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            setIsMAHmSubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsMAHmSubmitting(false)
        navigate("/session")
      }
    } else {
      setIsMAHmSubmitting(false)
      navigate("/login")
    }
  }
  function onPreviewActivity(activityData) {
    // console.log(activityData)
    if (
      activityData.pk_lesson_activity_key &&
      activityData.fk_sub_id &&
      activityData.fk_student_key
    ) {
      if (parseInt(activityData.activity_type) === 0) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-assessment-details/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 1) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-video/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 2) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-diagnostic-details/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 3) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-link/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 4) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-task/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 5) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-worksheet/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 6) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-video/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      } else if (parseInt(activityData.activity_type) === 7) {
        window.location.href =
          STUDENT_BASE_URL +
          "preview-worksheet/" +
          activityData.pk_lesson_activity_key +
          "?type=" +
          activityData.fk_sub_id +
          "&homework=" +
          activityData.marked_for_homework +
          "&st_key=" +
          activityData.fk_student_key
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

    // setDropdownOpen(prevState => ({
    //   ...prevState,
    //   [key]: !prevState[key] || false
    // }));
  }

  function onManualMarkingActivity(activityDetails) {
    if (activityDetails && parseInt(activityDetails.activity_type) === 0) {
      if (
        activityDetails.pk_lesson_activity_key &&
        activityDetails.fk_sub_id &&
        activityDetails.fk_student_key
      ) {
        navigate(
          `/manual-assessment/${activityDetails.pk_lesson_activity_key}`,
          {
            state: {
              type: `${activityDetails.fk_sub_id}`,
              st_key: `${activityDetails.fk_student_key}`,
            },
          }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else if (
      activityDetails &&
      parseInt(activityDetails.activity_type) === 5
    ) {
      if (
        activityDetails.pk_lesson_activity_key &&
        activityDetails.fk_sub_id &&
        activityDetails.fk_student_key
      ) {
        navigate(
          `/manual-worksheet/${activityDetails.pk_lesson_activity_key}`,
          {
            state: {
              type: `${activityDetails.fk_sub_id}`,
              st_key: `${activityDetails.fk_student_key}`,
            },
          }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else if (
      activityDetails &&
      parseInt(activityDetails.activity_type) === 7
    ) {
      if (
        activityDetails.pk_lesson_activity_key &&
        activityDetails.fk_sub_id &&
        activityDetails.fk_student_key
      ) {
        navigate(
          `/manual-lcms-worksheet/${activityDetails.pk_lesson_activity_key}`,
          {
            state: {
              type: `${activityDetails.fk_sub_id}`,
              st_key: `${activityDetails.fk_student_key}`,
            },
          }
        )
      } else {
        toast.error("Sorry, Activity Key Missing", {
          autoClose: 3000, // 3 seconds in this example
        })
      }
    } else {
      toast.error("Sorry, Activity Key Missing", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

    // if(activityData.pk_lesson_activity_key && activityData.fk_sub_id && activityData.fk_student_key){
    //   if(parseInt(activityData.activity_type) === 0){
    //     window.location.href = '/preview-assessment-details/'+activityData.pk_lesson_activity_key+'?type='+activityData.fk_sub_id+'&homework='+activityData.marked_for_homework+'&st_key='+activityData.fk_student_key;
    //   }else if(parseInt(activityData.activity_type) === 1){
    //     window.location.href = '/preview-video/'+activityData.pk_lesson_activity_key+'?type='+activityData.fk_sub_id+'&homework='+activityData.marked_for_homework+'&st_key='+activityData.fk_student_key;
    //   }else if(parseInt(activityData.activity_type) === 2){
    //     window.location.href = '/preview-diagnostic-details/'+activityData.pk_lesson_activity_key+'?type='+activityData.fk_sub_id+'&homework='+activityData.marked_for_homework+'&st_key='+activityData.fk_student_key;
    //   }else if(parseInt(activityData.activity_type) === 3){
    //     window.location.href = '/preview-link/'+activityData.pk_lesson_activity_key+'?type='+activityData.fk_sub_id+'&homework='+activityData.marked_for_homework+'&st_key='+activityData.fk_student_key;
    //   }else if(parseInt(activityData.activity_type) === 4){
    //     window.location.href = '/preview-task/'+activityData.pk_lesson_activity_key+'?type='+activityData.fk_sub_id+'&homework='+activityData.marked_for_homework+'&st_key='+activityData.fk_student_key;
    //   }else if(parseInt(activityData.activity_type) === 5){
    //     window.location.href = '/preview-worksheet/'+activityData.pk_lesson_activity_key+'?type='+activityData.fk_sub_id+'&homework='+activityData.marked_for_homework+'&st_key='+activityData.fk_student_key;
    //   }else if(parseInt(activityData.activity_type) === 6){
    //     window.location.href = '/preview-video/'+activityData.pk_lesson_activity_key+'?type='+activityData.fk_sub_id+'&homework='+activityData.marked_for_homework+'&st_key='+activityData.fk_student_key;
    //   }else if(parseInt(activityData.activity_type) === 7){
    //     window.location.href = '/preview-worksheet/'+activityData.pk_lesson_activity_key+'?type='+activityData.fk_sub_id+'&homework='+activityData.marked_for_homework+'&st_key='+activityData.fk_student_key;
    //   }
    // }else{
    //   toast.error("Sorry, Activity Key Missing", {
    //     autoClose: 3000, // 3 seconds in this example
    //   })
    // }
  }

  //Home Tab
  const CountTotalCoins = enrolment => {
    let total_coins_stu = 0

    let Completed_total_stu = 0

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        Completed_total_stu +=
          activity.coins_earned != null && activity.coins_earned != ""
            ? parseInt(activity.coins_earned)
            : 0
        //console.log("tim"+activity.LessonActivitiesQuestions.length);

        if (
          activity.LessonActivitiesQuestions.length > 0 &&
          parseInt(activity.activity_type) === 0
        ) {
          activity.LessonActivitiesQuestions.forEach(lsquestion => {
            if (
              parseInt(lsquestion.fk_sub_id) === 2 &&
              lsquestion.question.length > 0
            ) {
              lsquestion.question.forEach(lsquestionParts => {
                total_coins_stu += lsquestionParts.question_parts.length * 2
              })
            } else if (
              parseInt(lsquestion.fk_sub_id) === 1 &&
              lsquestion.english_question.length > 0
            ) {
              lsquestion.english_question.forEach(lsquestionParts => {
                if (
                  parseInt(lsquestionParts.english_question_category_id) ===
                    10 ||
                  parseInt(lsquestionParts.english_question_category_id) === 13
                ) {
                  let total_part = lsquestionParts.english_question_parts
                  let array_part_data = total_part.filter(
                    part =>
                      part.english_question_answers.length > 0 &&
                      part.english_question_answers[0].english_answer_text &&
                      part.english_question_answers[0].english_answer_text != ""
                  )
                  total_coins_stu += array_part_data.length * 2 || 0
                } else {
                  total_coins_stu +=
                    lsquestionParts.english_question_parts.length * 2
                }

                // total_coins_stu += lsquestionParts.english_question_parts.length * 2;
              })
            }
          })
        } else if (
          activity.LessonActivitiesQuestions.length > 0 &&
          parseInt(activity.activity_type) === 2
        ) {
          activity.LessonActivitiesQuestions.forEach(lsquestion => {
            if (
              parseInt(lsquestion.fk_sub_id) === 2 &&
              lsquestion.question.length > 0
            ) {
              //lsquestion.question.forEach(lsquestionParts => {
              total_coins_stu += lsquestion.question.length * 2
              //});
            } else if (
              parseInt(lsquestion.fk_sub_id) === 1 &&
              lsquestion.english_question.length > 0
            ) {
              // lsquestion.english_question.forEach(lsquestionParts => {
              total_coins_stu += lsquestion.english_question.length * 2
              //});
            }
          })
        } else {
          activity.LessonActivitiesQuestions.forEach(lsquestion => {
            if (lsquestion.question.length > 0) {
              //lsquestion.question.forEach(lsquestionParts => {
              total_coins_stu += lsquestion.question.length * 2
              //});
            }
          })
        }
      })
    }

    return Completed_total_stu + "/" + total_coins_stu
  }

  const CountHours = enrolment => {
    let total_time = 0

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      //total_time = enrolment.tbl_student.Lesson.LessonActivities.length > 0 ? enrolment.tbl_student.Lesson.LessonActivities.length * 5 : 0;
      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        //console.log("tim"+activity.LessonActivitiesQuestions.length);
        total_time += parseInt(activity.total_activity_time) || 0
      })
      let hours = Math.floor(total_time / 60)

      // Calculate remaining minutes
      let minutes = total_time % 60

      // console.log(hours + " hours and " + minutes + " minutes");
      return hours + " h " + minutes + " m"
    } else {
      return null
    }
  }

  const CountTotalActivity = enrolment => {
    let total_activity_stu = 0
    let completed_activity_stu = 0

    var icon = <i className="mdi mdi-shuffle-variant"></i>

    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.Lesson &&
      enrolment.tbl_student.Lesson.LessonActivities.length > 0
    ) {
      if (parseInt(enrolment.tbl_student.Lesson.is_lesson_sequential) === 1) {
        icon = <i className=" mdi mdi-sort-ascending"></i>
      } else {
        icon = <i className="mdi mdi-shuffle-variant"></i>
      }

      enrolment.tbl_student.Lesson.LessonActivities.forEach(activity => {
        completed_activity_stu +=
          activity.status != null && activity.status == "1" ? 1 : 0
        total_activity_stu += 1
      })
    }
    //console.log(enrolment)
    if (
      enrolment.tbl_student &&
      enrolment.tbl_student.tbl_session_attendance &&
      enrolment.tbl_student.tbl_session_attendance.attendance_status &&
      enrolment.tbl_student.tbl_session_attendance.attendance_status === 3
    ) {
      return <p className="mt-2 mb-2 text-danger">Missed</p>
    } else if (
      enrolment.tbl_student &&
      enrolment.tbl_student.tbl_session_attendance &&
      enrolment.tbl_student.tbl_session_attendance.attendance_status &&
      enrolment.tbl_student.tbl_session_attendance.attendance_status === 4
    ) {
      return <p className="mt-2 mb-2 text-danger">Offline</p>
    } else if (
      enrolment.tbl_student &&
      enrolment.tbl_student.tbl_session_attendance &&
      enrolment.tbl_student.tbl_session_attendance.attendance_status &&
      enrolment.tbl_student.tbl_session_attendance.attendance_status === 5
    ) {
      return <p className="mt-2 mb-2 text-danger">Defer Request</p>
    } else if (
      enrolment.tbl_student &&
      enrolment.tbl_student.tbl_session_attendance &&
      enrolment.tbl_student.tbl_session_attendance.attendance_status &&
      enrolment.tbl_student.tbl_session_attendance.attendance_status === 6
    ) {
      return <p className="mt-2 mb-2 text-danger">Cancelled</p>
    }

    if (total_activity_stu === 0) {
      return <p className="mt-2 mb-2 text-danger">No activities assigned</p>
    }

    let percentage = (completed_activity_stu / total_activity_stu) * 100

    return (
      <p className="mt-2 mb-2">
        {completed_activity_stu}/{total_activity_stu} activities completed (
        {percentage.toFixed(2)}%)<span className="float-end ">{icon}</span>
      </p>
    )
  }

  const [dropdownOpen, setDropdownOpen] = useState({})

  const toggleDropdown = key => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [key]: !prevState[key] || false,
    }))
  }
  const handleLinkClick = (student_key, session_key, subject_ID) => {
    // Store data in localStorage
    localStorage.setItem("session_key", session_key)
    localStorage.setItem("student_key", student_key)
    localStorage.setItem("subject_ID", subject_ID)
  }

  //Rollover multiple moving option start

  // State to store selected activity keys

  const [activityStudentKey, setActivityStudentKey] = useState("")
  const [selectedActivityKeys, setSelectedActivityKeys] = useState([])
  const [selectedActivitySubject, setselectedActivitySubject] = useState([])
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  // console.log(activityStudentKey);
  // console.log(selectedActivityKeys);
  // console.log(selectedActivitySubject);
  // console.log(selectAllChecked);
  // Toggle all checkboxes when the master checkbox changes
  // const handleSelectAllChange = (LessonActivities) => {
  //   const newSelectAllState = !selectAllChecked;
  //   setSelectAllChecked(newSelectAllState);

  //   const allActivityKeys = LessonActivities.map(activity => activity.pk_lesson_activity_key);

  //   setSelectedActivityKeys(newSelectAllState ? allActivityKeys : []);

  //   setselectedActivitySubject(newSelectAllState
  //     ? LessonActivities.map(activity => ({
  //         pk_lesson_activity_key: activity.pk_lesson_activity_key,
  //         fk_sub_id: activity.fk_sub_id,
  //       }))
  //     : []
  //   );

  //   LessonActivities.map(activity => newSelectAllState ? setActivityStudentKey(activity.fk_student_key) : '');

  // };
  const handleSelectAllChange = LessonActivities => {
    const newSelectAllState = !selectAllChecked
    setSelectAllChecked(newSelectAllState)

    // Get all activity keys and student keys
    const allActivityKeys = LessonActivities.map(
      activity => activity.pk_lesson_activity_key
    )
    const allActivitySubjects = LessonActivities.map(activity => ({
      pk_lesson_activity_key: activity.pk_lesson_activity_key,
      fk_sub_id: activity.fk_sub_id,
    }))

    // Set selected activities and subjects based on the select-all state
    setSelectedActivityKeys(newSelectAllState ? allActivityKeys : [])
    setselectedActivitySubject(newSelectAllState ? allActivitySubjects : [])

    // Set the student key only once if selecting all, otherwise clear it
    if (newSelectAllState && LessonActivities.length > 0) {
      setActivityStudentKey(LessonActivities[0].fk_student_key)
    } else {
      setActivityStudentKey("")
    }
  }

  // Function to handle checkbox change
  const handleCheckboxActivityChange = (
    activityKey,
    activity,
    totalActivities
  ) => {
    //  setSelectedActivityKeys((prevSelectedKeys) => {
    //    if (prevSelectedKeys.includes(activityKey)) {
    //      // If activity key is already selected, remove it from the array
    //      return prevSelectedKeys.filter((key) => key !== activityKey);
    //    } else {
    //      // If not selected, add it to the array
    //      return [...prevSelectedKeys, activityKey];
    //    }

    //  });

    setSelectedActivityKeys(prevSelectedKeys => {
      const updatedKeys = prevSelectedKeys.includes(activityKey)
        ? prevSelectedKeys.filter(key => key !== activityKey)
        : [...prevSelectedKeys, activityKey]

      // Update selectAllChecked based on the length of selected keys
      setSelectAllChecked(updatedKeys.length === totalActivities)

      return updatedKeys
    })

    setselectedActivitySubject(prevSelectedActivities => {
      const activityObject = {
        pk_lesson_activity_key: activityKey,
        fk_sub_id: activity.fk_sub_id,
      }

      if (
        prevSelectedActivities.some(
          activity => activity.pk_lesson_activity_key === activityKey
        )
      ) {
        // If the activity is already selected, remove it
        const updatedActivities = prevSelectedActivities.filter(
          activity => activity.pk_lesson_activity_key !== activityKey
        )
        // console.log(`Removed activity:`, activityObject);
        return updatedActivities
      } else {
        // If not selected, add the activity object
        // console.log(`Added activity:`, activityObject);
        return [...prevSelectedActivities, activityObject]
      }
    })
    // console.log(selectedActivityKeys.length);
    //  if(selectedActivityKeys.length === 0){
    //   setActivityStudentKey('');
    //  }else{
    setActivityStudentKey(activity.fk_student_key)
    //}
  }
  useEffect(() => {
    if (selectedActivityKeys && selectedActivityKeys.length === 0) {
      setActivityStudentKey("")
    }
  }, [selectedActivityKeys])

  //console.log(activityStudentKey);

  //console.log(selectedActivitySubject);

  const [MultipleUpcomingSessionData, setMultipleUpcomingSessionData] =
    useState([])

  const [show_modal_move_multiple_activity, setmodal_move_multiple_activity] =
    useState(false)

  const tog_move_multiple_activity = () => {
    setmodal_move_multiple_activity(!show_modal_move_multiple_activity)
    // removeBodyCss();
  }

  const [moveingMultipleSessionKey, setmoveingMultipleSessionKey] = useState([])
  const [isMulMoningSubmitting, setIsMulMoningSubmitting] = useState(false)
  const [modal_multiple_moveing_conform, setmodal_multiple_moveing_conform] =
    useState(false)
  const tog_multiple_moveing_popup = () => {
    setmodal_multiple_moveing_conform(!modal_multiple_moveing_conform)
    // removeBodyCss();
  }

  const [modal_move_home_all_activity, setmodal_move_home_all_activity] =
    useState(false)

  const tog_move_home_all_activity = () => {
    setmodal_move_home_all_activity(!modal_move_home_all_activity)
    // removeBodyCss();
  }

  function onMoveingMultipleActivityPopup() {
    // setmoveingSessionKey(sessionKey)

    if (moveingMultipleSessionKey.length === 0) {
      toast.error("Please select at least one session!", {
        autoClose: 3000, // 3 seconds in this example
      })
    } else {
      tog_multiple_moveing_popup()
    }
  }
  function onMoveingMultipleActivityHomePopup() {
    tog_move_home_all_activity()
  }

  const handleCheckboxMulChange = (key, isChecked) => {
    const updatedSessions = MultipleUpcomingSessionData.map(session => {
      if (session.pk_ses_key === key) {
        return { ...session, checked: isChecked }
      }
      return session
    })
    console.log(MultipleUpcomingSessionData)

    setMultipleUpcomingSessionData(updatedSessions)
    if (isChecked) {
      // Add key to the selectedKeys array if checked
      setmoveingMultipleSessionKey(prev => [...prev, key])
    } else {
      // Remove key from the selectedKeys array if unchecked
      setmoveingMultipleSessionKey(prev => prev.filter(k => k !== key))
    }
  }

  const handlerollOverTabChange = tab => {
    setrollOverActiveTab(tab)

    const updatedSessions = MultipleUpcomingSessionData.map(session => ({
      ...session,
      checked: false,
    }))

    setMultipleUpcomingSessionData(updatedSessions)
    setmoveingMultipleSessionKey([])
  }
  const fetchMultipleUpcommingSessionData = async (
    fk_student_key,
    subjcet_id
  ) => {
    // console.log(studentKey);
    if (authUser && authUser != "" && authUser != null) {
      //setmoveTosession(false);
      // setActivityStudentKey(fk_student_key);
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const uniqueSubjects = selectedActivitySubject.reduce((acc, item) => {
          if (!acc.some(obj => obj.subject_id === item.fk_sub_id)) {
            acc.push({ subject_id: item.fk_sub_id })
          }
          return acc
        }, [])
        //console.log(uniqueSubjects);
        const responseSessionUpcomingList = await axios.post(
          BASE_URL + "session/getAllUpcommingActivitySessionList",
          {
            pk_student_key: activityStudentKey,
            fk_subject_id: uniqueSubjects,
          }
        ) //console.log(studentKey);
        //console.log(responseUpcomingList)
        if (responseSessionUpcomingList.data.length > 0) {
          const updatedData = responseSessionUpcomingList.data.map(item => ({
            ...item,
            checked: false, // Add the checked property to each item
          }))
          setMultipleUpcomingSessionData(updatedData)
          //setUpcomingSessionData(responseUpcomingList.data);
          setmodal_move_multiple_activity(true)
        } else {
          toast.error("Not found upcoming sessions!", {
            autoClose: 3000, // 3 seconds in this example
          })
          setMultipleUpcomingSessionData([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching upcoming session deatails:", error)
      }
    } else {
      navigate("/login")
      //setLoading(false)
    }
  }

  const onMoveMultipleActivityUpcomingSession = () => {
    // //function onDelectActivity() {

    //console.log(selectedActivityKeys);

    if (authUser && authUser != "" && authUser != null) {
      if (moveingMultipleSessionKey) {
        setIsMulMoningSubmitting(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        axios
          .post(
            BASE_URL + "session/submitMoveMultipleActivityToSessionMultiple",
            {
              pk_ses_key_array: moveingMultipleSessionKey,
              pk_lesson_activity_key_array: selectedActivityKeys,
              pk_student_key: activityStudentKey,
            }
          )
          .then(responseActivityMove => {
            //console.log(responseSessionStart.data);
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            if (parseInt(responseActivityMove.data) === 1) {
              toast.success(
                "Activity rolled over to next session(s) successfully",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )
              tog_multiple_moveing_popup()
              tog_move_multiple_activity()
              setIsMulMoningSubmitting(false)
              setSelectedActivityKeys([])
              setselectedActivitySubject([])
              setSelectAllChecked(false)
              window.location.reload()
            } else {
              setIsMulMoningSubmitting(false)
              toast.error("Failure! Unable to move activity. Try again later", {
                autoClose: 3000, // 3 seconds in this example
              })
            }
          })
          .catch(error => {
            setIsMulMoningSubmitting(false)
            //console.log(error)
            if (
              error.response.status === 403 &&
              error.response.data.error === "jwt expired"
            ) {
              localStorage.clear()
              navigate("/login")
            }
          })
      } else {
        setIsMulMoningSubmitting(false)
        navigate("/session")
      }
    } else {
      setIsMulMoningSubmitting(false)
      navigate("/login")
    }
  }

  //Rollover multiple moving option end

  const LassonPlanContent = ({
    sessionData,
    customActiveTab,
    getUniqueStudentSubjectNames,
    getUniqueStudentSubjectID,
    CountTotalCoins,
    CountHours,
    CountTotalActivity,
    clearTimeout,
    setLoopRun,
    handleLinkClick,
    toggleDropdown,
    dropdownOpen,
    onPreviewActivity,
    onDelectActivityPopup,
    onMoveActivityPopup,
    getUniqueTopicsNames,
    selectedActivityKeys,
    handleCheckboxActivityChange,
    activityStudentKey,
    fetchMultipleUpcommingSessionData,
    handleSelectAllChange,
    selectAllChecked,
  }) => {
    // Destructure sessionData from props
    //const { sessionData } = props

    //  console.log(sessionData); // Check if sessionData is correctly received

    if (
      sessionData &&
      sessionData.session &&
      sessionData.session.tbl_session_time &&
      sessionData.session.tbl_session_time.tbl_student_enrolment &&
      sessionData.session.tbl_session_time.tbl_student_enrolment.length > 0
    ) {
      // Perform operations with tbl_student_enrolment
    } else {
      return <p className="cs-txt-danger">No student assigned</p>
    }

    return sessionData &&
      sessionData.session &&
      sessionData.session.tbl_session_time &&
      sessionData.session.tbl_session_time.tbl_student_enrolment
      ? sessionData.session.tbl_session_time.tbl_student_enrolment.map(
          (enrolment, index) => {
            // console.log(CountTotalCoins(enrolment))

            return (
              <div className="kb-child" key={index}>
                <div className="bg-supermild st-pd">
                  <div className="profile-details d-flex align-items-center pb-1">
                    <div className="stat-img">
                      <img
                        width={30}
                        className="rounded-circle me-10"
                        src={
                          enrolment.tbl_student && enrolment.tbl_student.avatar
                            ? enrolment.tbl_student.avatar
                            : st2
                        }
                        alt=""
                      />
                    </div>
                    <div>
                      <h5 className="text-dark fw-700">
                        {enrolment &&
                        enrolment.reassign &&
                        enrolment.reassign == "1"
                          ? "*"
                          : null}
                        {enrolment.tbl_student &&
                        enrolment.tbl_student.st_first_name
                          ? enrolment.tbl_student.st_first_name +
                            " " +
                            enrolment.tbl_student.st_surname
                          : null}
                      </h5>
                    </div>
                  </div>

                  <p className="font-size-15 fw-400 text-dark">
                    {sessionData && sessionData != ""
                      ? getUniqueStudentSubjectNames(
                          enrolment.tbl_enrolment_subject
                        ).join(" & ") || "-"
                      : "-"}
                  </p>
                  <div className="d-flex gap-3 align-items-center mt-50">
                    <div className="d-flex align-items-center">
                      <img className="me-10" width={24} src={awicon} alt="" />
                      <p className="mb-0 fw-700 text-dark font-size-12">
                        {CountTotalCoins(enrolment) || "-"}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <img className="me-10" width={24} src={duration} alt="" />
                      <p className="mb-0 fw-700 text-dark font-size-12">
                        {CountHours(enrolment)}
                      </p>
                    </div>
                  </div>
                </div>

                {CountTotalActivity(enrolment)}

                {/* {parseInt(customActiveTab) === 1 ? (
                  (sessionData.session &&
                    sessionData.session.ses_is_completed == "1") ||
                  (enrolment.tbl_student &&
                    enrolment.tbl_student.tbl_session_attendance &&
                    enrolment.tbl_student.tbl_session_attendance
                      .attendance_status !== 0 &&
                    enrolment.tbl_student.tbl_session_attendance
                      .attendance_status !== 1) ? (
                    sessionData.session.next_session &&
                    sessionData.session.next_session != null &&
                    sessionData.session.next_session != "" ? (
                      enrolment.tbl_student.tbl_session_attendance
                        .attendance_status === 3 ? (
                        <>
                          <Link
                            to={"/session/" + sessionData.session.next_session}
                            className="btn btn-secondary w-100 btn-md"
                            onClick={() => {
                              clearTimeout(timeoutID), setLoopRun(isLoopRun + 1)
                            }}
                          >
                            Plan Next Session
                          </Link>
                        </>
                      ) : enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 4 ? (
                        <>
                          <Link
                            to={"/session/" + sessionData.session.next_session}
                            className="btn btn-secondary w-100 btn-md"
                            onClick={() => {
                              clearTimeout(timeoutID), setLoopRun(isLoopRun + 1)
                            }}
                          >
                            Plan Next Session
                          </Link>
                        </>
                      ) : enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 5 ? (
                        <>
                          <Link
                            to={"/session/" + sessionData.session.next_session}
                            className="btn btn-secondary w-100 btn-md"
                            onClick={() => {
                              clearTimeout(timeoutID), setLoopRun(isLoopRun + 1)
                            }}
                          >
                            Plan Next Session
                          </Link>
                        </>
                      ) : enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 6 ? (
                        <>
                          <Link
                            to={"/session/" + sessionData.session.next_session}
                            className="btn btn-secondary w-100 btn-md"
                            onClick={() => {
                              clearTimeout(timeoutID), setLoopRun(isLoopRun + 1)
                            }}
                          >
                            Plan Next Session
                          </Link>
                        </>
                      ) : (
                        <Link
                          to={"/session/" + sessionData.session.next_session}
                          className="btn btn-secondary w-100 btn-md"
                        >
                          Plan Next Session
                        </Link>
                      )
                    ) : null
                  ) : enrolment.tbl_student &&
                    enrolment.tbl_student.Lesson &&
                    enrolment.tbl_student.Lesson &&
                    enrolment.tbl_student.Lesson.pk_lesson_key &&
                    enrolment.tbl_student.Lesson.pk_lesson_key != "" ? (
                    <Link
                      to={
                        "/edit-lesson-plan/" +
                        enrolment.tbl_student.Lesson.pk_lesson_key
                      }
                      className="btn btn-secondary w-100 btn-md"
                      onClick={() =>
                        handleLinkClick(
                          enrolment.tbl_student.pk_student_key,
                          sessionData.session.pk_ses_key
                        )
                      }
                    >
                      Edit Lesson Plan
                    </Link>
                  ) : (
                    <Link
                      to="/edit-lesson-plan"
                      className="btn btn-secondary w-100 btn-md"
                      onClick={() =>
                        handleLinkClick(
                          enrolment.tbl_student.pk_student_key,
                          sessionData.session.pk_ses_key
                        )
                      }
                    >
                      Create Lesson Plan
                    </Link>
                  )
                ) : parseInt(customActiveTab) === 2 ? (
                  (sessionData.session &&
                    sessionData.session.ses_is_completed == "1") ||
                  (enrolment.tbl_student &&
                    enrolment.tbl_student.tbl_session_attendance &&
                    enrolment.tbl_student.tbl_session_attendance
                      .attendance_status !== 0 &&
                    enrolment.tbl_student.tbl_session_attendance
                      .attendance_status !== 1) ? (
                    //   <Link to="/session-homework" className='btn btn-secondary w-100 btn-md'>
                    //   Edit homework plan
                    //  </Link>

                    sessionData.session.next_session &&
                    sessionData.session.next_session != null &&
                    sessionData.session.next_session != "" ? (
                      enrolment.tbl_student.tbl_session_attendance
                        .attendance_status === 3 ? (
                        <>
                          <Link
                            to={"/session/" + sessionData.session.next_session}
                            className="btn btn-secondary w-100 btn-md"
                          >
                            Plan Next Session
                          </Link>
                        </>
                      ) : enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 4 ? (
                        <>
                          <Link
                            to={"/session/" + sessionData.session.next_session}
                            className="btn btn-secondary w-100 btn-md"
                          >
                            Plan Next Session
                          </Link>
                        </>
                      ) : enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 5 ? (
                        <>
                          <Link
                            to={"/session/" + sessionData.session.next_session}
                            className="btn btn-secondary w-100 btn-md"
                          >
                            Plan Next Session
                          </Link>
                        </>
                      ) : enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 6 ? (
                        <>
                          <Link
                            to={"/session/" + sessionData.session.next_session}
                            className="btn btn-secondary w-100 btn-md"
                          >
                            Plan Next Session
                          </Link>
                        </>
                      ) : (
                        <Link
                          to={"/session/" + sessionData.session.next_session}
                          className="btn btn-secondary w-100 btn-md"
                        >
                          Plan Next Session
                        </Link>
                      )
                    ) : null
                  ) : enrolment.tbl_student &&
                    enrolment.tbl_student.Lesson &&
                    enrolment.tbl_student.Lesson &&
                    enrolment.tbl_student.Lesson.LessonActivities.length > 0 ? (
                    <Link
                      to={
                        "/edit-lesson-plan/" +
                        enrolment.tbl_student.Lesson.pk_lesson_key
                      }
                      className="btn btn-secondary w-100 btn-md"
                      onClick={() =>
                        handleLinkClick(
                          enrolment.tbl_student.pk_student_key,
                          sessionData.session.pk_ses_key
                        )
                      }
                    >
                      Edit homework plan
                    </Link>
                  ) : (
                    <Link
                      to="/edit-lesson-plan"
                      className="btn btn-secondary w-100 btn-md"
                      onClick={() =>
                        handleLinkClick(
                          enrolment.tbl_student.pk_student_key,
                          sessionData.session.pk_ses_key
                        )
                      }
                    >
                      Create homework plan
                    </Link>
                  )
                ) : null} */}

                {parseInt(customActiveTab) === 1 ? (
                  // Condition for tab 1
                  (sessionData.session &&
                    sessionData.session.ses_is_completed === 1) ||
                  (enrolment.tbl_student &&
                    enrolment.tbl_student.tbl_session_attendance &&
                    enrolment.tbl_student.tbl_session_attendance
                      .attendance_status &&
                    enrolment.tbl_student.tbl_session_attendance
                      .attendance_status !== null &&
                    enrolment.tbl_student.tbl_session_attendance
                      .attendance_status !== 0 &&
                    enrolment.tbl_student.tbl_session_attendance
                      .attendance_status !== 1) ? (
                    // Inside if condition
                    enrolment.tbl_student &&
                    enrolment.tbl_student.next_session_key !== null &&
                    enrolment.tbl_student.next_session_key !== "" ? (
                      enrolment.tbl_student.tbl_session_attendance &&
                      enrolment.tbl_student.tbl_session_attendance != null &&
                      enrolment.tbl_student.tbl_session_attendance
                        .attendance_status &&
                      enrolment.tbl_student.tbl_session_attendance
                        .attendance_status != null &&
                      enrolment.tbl_student.tbl_session_attendance
                        .attendance_status != "" &&
                      (enrolment.tbl_student.tbl_session_attendance
                        .attendance_status === 3 ||
                        enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 4 ||
                        enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 5 ||
                        enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 6) ? (
                        // If attendance status is 3
                        <Link
                          to={
                            "/session/" + enrolment.tbl_student.next_session_key
                          }
                          className="btn btn-secondary w-100 btn-md"
                          onClick={() => {
                            clearTimeout(timeoutID)
                            setLoopRun(isLoopRun + 1)
                          }}
                        >
                          Plan Next Session
                        </Link>
                      ) : (
                        // For other attendance statuses
                        <Link
                          to={
                            "/session/" + enrolment.tbl_student.next_session_key
                          }
                          className="btn btn-secondary w-100 btn-md"
                          onClick={() => {
                            clearTimeout(timeoutID)
                            setLoopRun(isLoopRun + 1)
                          }}
                        >
                          Plan Next Session
                        </Link>
                      )
                    ) : (
                      //null
                      // If next session data is missing
                      <Link className="btn btn-secondary w-100 btn-md">
                        No Next Session
                      </Link>
                    )
                  ) : // If outer if condition fails
                  enrolment.tbl_student &&
                    enrolment.tbl_student.Lesson &&
                    enrolment.tbl_student.Lesson.pk_lesson_key &&
                    enrolment.tbl_student.Lesson.pk_lesson_key !== "" &&
                    enrolment.tbl_student.Lesson.LessonActivities &&
                    enrolment.tbl_student.Lesson.LessonActivities.length > 0 ? (
                    // If lesson plan exists
                    <Link
                      to={
                        "/edit-lesson-plan/" +
                        enrolment.tbl_student.Lesson.pk_lesson_key
                      }
                      className="btn btn-secondary w-100 btn-md"
                      onClick={() =>
                        handleLinkClick(
                          enrolment.tbl_student.pk_student_key,
                          sessionData.session.pk_ses_key,
                          getUniqueStudentSubjectID(
                            enrolment.tbl_enrolment_subject
                          ).join(",") || ""
                        )
                      }
                    >
                      Edit Lesson Plan
                    </Link>
                  ) : (
                    // If no lesson plan exists
                    <Link
                      to="/edit-lesson-plan"
                      className="btn btn-secondary w-100 btn-md"
                      onClick={() =>
                        handleLinkClick(
                          enrolment.tbl_student.pk_student_key,
                          sessionData.session.pk_ses_key,
                          getUniqueStudentSubjectID(
                            enrolment.tbl_enrolment_subject
                          ).join(",") || ""
                        )
                      }
                    >
                      Create Lesson Plan
                    </Link>
                  )
                ) : // For other tabs
                parseInt(customActiveTab) === 2 ? (
                  // Condition for tab 2
                  (sessionData.session &&
                    sessionData.session.ses_is_completed === 1) ||
                  (enrolment.tbl_student &&
                    enrolment.tbl_student.tbl_session_attendance &&
                    enrolment.tbl_student.tbl_session_attendance != null &&
                    enrolment.tbl_student.tbl_session_attendance
                      .attendance_status !== 0 &&
                    enrolment.tbl_student.tbl_session_attendance
                      .attendance_status !== 1) ? (
                    // Inside if condition
                    enrolment.tbl_student &&
                    enrolment.tbl_student.next_session_key !== null &&
                    enrolment.tbl_student.next_session_key !== "" ? (
                      enrolment.tbl_student.tbl_session_attendance &&
                      enrolment.tbl_student.tbl_session_attendance != null &&
                      enrolment.tbl_student.tbl_session_attendance
                        .attendance_status &&
                      enrolment.tbl_student.tbl_session_attendance
                        .attendance_status != null &&
                      (enrolment.tbl_student.tbl_session_attendance
                        .attendance_status === 3 ||
                        enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 4 ||
                        enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 5 ||
                        enrolment.tbl_student.tbl_session_attendance
                          .attendance_status === 6) ? (
                        // If attendance status is 3
                        <Link
                          to={
                            "/session/" + enrolment.tbl_student.next_session_key
                          }
                          className="btn btn-secondary w-100 btn-md"
                        >
                          Plan Next Session
                        </Link>
                      ) : (
                        // For other attendance statuses
                        <Link
                          to={
                            "/session/" + enrolment.tbl_student.next_session_key
                          }
                          className="btn btn-secondary w-100 btn-md"
                        >
                          Plan Next Session
                        </Link>
                      )
                    ) : (
                      // If next session data is missing
                      <Link className="btn btn-secondary w-100 btn-md">
                        No Next Session
                      </Link>
                    )
                  ) : // If outer if condition fails
                  // For other tabs
                  enrolment.tbl_student &&
                    enrolment.tbl_student.Lesson &&
                    enrolment.tbl_student.Lesson.pk_lesson_key &&
                    enrolment.tbl_student.Lesson.pk_lesson_key !== "" &&
                    enrolment.tbl_student.Lesson.LessonActivities &&
                    enrolment.tbl_student.Lesson.LessonActivities.length > 0 ? (
                    // enrolment.tbl_student && enrolment.tbl_student.Lesson  && enrolment.tbl_student.Lesson.LessonActivitie && enrolment.tbl_student.Lesson.LessonActivities.length > 0 ? (
                    // If lesson activities exist
                    <Link
                      to={
                        "/edit-lesson-plan/" +
                        enrolment.tbl_student.Lesson.pk_lesson_key
                      }
                      className="btn btn-secondary w-100 btn-md"
                      onClick={() =>
                        handleLinkClick(
                          enrolment.tbl_student.pk_student_key,
                          sessionData.session.pk_ses_key,
                          getUniqueStudentSubjectID(
                            enrolment.tbl_enrolment_subject
                          ).join(",") || ""
                        )
                      }
                    >
                      Edit homework plan
                    </Link>
                  ) : (
                    // If no lesson activities exist
                    <Link
                      to="/edit-lesson-plan"
                      className="btn btn-secondary w-100 btn-md"
                      onClick={() =>
                        handleLinkClick(
                          enrolment.tbl_student.pk_student_key,
                          sessionData.session.pk_ses_key,
                          getUniqueStudentSubjectID(
                            enrolment.tbl_enrolment_subject
                          ).join(",") || ""
                        )
                      }
                    >
                      Create homework plan
                    </Link>
                  )
                ) : null}
                <div className="d-flex justify-content-end gap-3 me-3">
                  {enrolment.tbl_student &&
                  enrolment.tbl_student.pk_student_key &&
                  enrolment.tbl_student.pk_student_key === activityStudentKey &&
                  sessionData &&
                  sessionData.session &&
                  sessionData.session.ses_is_completed === 1 &&
                  selectedActivityKeys &&
                  selectedActivityKeys.length > 0 ? (
                    <button
                      className="btn bg-danger text-white me-0 font-size-12"
                      onClick={() => {
                        fetchMultipleUpcommingSessionData(
                          enrolment.fk_stu_key,
                          enrolment.tbl_enrolment_subject
                        )
                      }}
                    >
                      Roll over
                    </button>
                  ) : null}
                  {sessionData &&
                  sessionData.session &&
                  sessionData.session.ses_is_completed === 1 &&
                  enrolment.tbl_student &&
                  enrolment.tbl_student.Lesson &&
                  enrolment.tbl_student.Lesson.LessonActivities.length > 0 &&
                  (enrolment.tbl_student.tbl_session_attendance == null ||
                    (enrolment.tbl_student.tbl_session_attendance &&
                      enrolment.tbl_student.tbl_session_attendance
                        .attendance_status != null &&
                      parseInt(
                        enrolment.tbl_student.tbl_session_attendance
                          .attendance_status
                      ) != 6)) &&
                  ((enrolment.tbl_student &&
                    enrolment.tbl_student.pk_student_key &&
                    enrolment.tbl_student.pk_student_key ===
                      activityStudentKey) ||
                    activityStudentKey === "") ? (
                    <input
                      type="checkbox"
                      checked={selectAllChecked}
                      onChange={() =>
                        handleSelectAllChange(
                          enrolment.tbl_student.Lesson.LessonActivities
                        )
                      }
                    />
                  ) : null}
                </div>
                <div className="events border-top">
                  {enrolment.tbl_student &&
                  enrolment.tbl_student.Lesson &&
                  enrolment.tbl_student.Lesson.LessonActivities.length > 0 &&
                  (enrolment.tbl_student.tbl_session_attendance == null ||
                    (enrolment.tbl_student.tbl_session_attendance &&
                      enrolment.tbl_student.tbl_session_attendance
                        .attendance_status != null &&
                      parseInt(
                        enrolment.tbl_student.tbl_session_attendance
                          .attendance_status
                      ) != 6))
                    ? enrolment.tbl_student.Lesson.LessonActivities.map(
                        (activity, index1) => {
                          // console.log(activity)
                          if (
                            activity.activity_type === 0 &&
                            activity.fk_sub_id === 2
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0;

                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            //let manual_mark = 0;

                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        question.question_parts &&
                                          question.question_parts.length > 0 &&
                                          question.question_parts.forEach(
                                            (questionpat, index4) => {
                                              total_question += 1

                                              total_coins += 2
                                              if (
                                                questionpat.StudentAnsAttempts &&
                                                questionpat.StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                questionpat.StudentAnsAttempts.forEach(
                                                  stuAnswer => {
                                                    total_time += parseInt(
                                                      stuAnswer.submitted_on
                                                    )
                                                  }
                                                )
                                                count_answer += 1 // Increment count_answer by 1 for each occurrence
                                              }
                                            }
                                          )
                                      }
                                    )
                                  }
                                }
                              )
                            }

                            return (
                              <div className="set1 bg-supermild">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <span
                                      className={`${
                                        activity.status == "0"
                                          ? "cs-txt-danger"
                                          : activity.status == "1"
                                          ? "text-success"
                                          : ""
                                      } font-size-10 me-2`}
                                    >
                                      {activity.status == "0"
                                        ? "Incomplete"
                                        : activity.status == "1"
                                        ? "Completed"
                                        : null}
                                    </span>
                                    <span className="font-size-10">
                                      {count_answer != null &&
                                      total_question != null
                                        ? count_answer +
                                          "/" +
                                          total_question +
                                          " attempted"
                                        : null}
                                    </span>
                                  </div>
                                  <div>
                                    {sessionData &&
                                    sessionData.session &&
                                    parseInt(
                                      sessionData.session.ses_is_completed
                                    ) === 1 &&
                                    (activityStudentKey === "" ||
                                      (activity.fk_student_key &&
                                        activity.fk_student_key ===
                                          activityStudentKey)) ? (
                                      <input
                                        type="checkbox"
                                        checked={selectedActivityKeys.includes(
                                          activity.pk_lesson_activity_key
                                        )}
                                        onChange={() =>
                                          handleCheckboxActivityChange(
                                            activity.pk_lesson_activity_key,
                                            activity,
                                            enrolment.tbl_student.Lesson
                                              .LessonActivities.length
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].node_name != ""
                                    ? activity.activity_node[0].node_name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Assessment
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveActivityPopup(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to next session
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 &&
                                      parseInt(activity.marked_for_homework) ===
                                        0 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveHomeworkActivityPopup(
                                                activity
                                              )
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to same session homework
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                      ? activity.LessonActivitiesQuestions.map(
                                          (activityQuestion, index2) => {
                                            let color = ""
                                            let attempt = ""
                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  if (
                                                    question.question_parts
                                                      .length > 0
                                                  ) {
                                                    question.question_parts.forEach(
                                                      (
                                                        partQuestion,
                                                        index4
                                                      ) => {
                                                        if (
                                                          partQuestion
                                                            .StudentAnsAttempts
                                                            .length > 0
                                                        ) {
                                                          partQuestion.StudentAnsAttempts.forEach(
                                                            (
                                                              answerattquestion,
                                                              index5
                                                            ) => {
                                                              attempt += 1
                                                              color +=
                                                                answerattquestion.points_obtained
                                                            }
                                                          )
                                                        }
                                                      }
                                                    )
                                                  }
                                                }
                                              )
                                            }

                                            //console.log(color); // This logs the concatenated string, not each value separately

                                            // Parse color to an integer
                                            const colorInt = parseInt(color)
                                            const attemptInt = parseInt(attempt)
                                            return (
                                              <li
                                                key={index2}
                                                className={`${
                                                  attemptInt >= 2 &&
                                                  colorInt === 1
                                                    ? "attempt2 text-warning bg-success-subtle"
                                                    : attemptInt >= 1 &&
                                                      colorInt === 2
                                                    ? "attempt1 custom-success bg-success-subtle"
                                                    : attemptInt >= 2 &&
                                                      colorInt === 0
                                                    ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                    : ""
                                                }`}
                                              >
                                                {attemptInt >= 2 &&
                                                colorInt === 1 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : attemptInt >= 1 &&
                                                  colorInt === 2 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : attemptInt >= 2 &&
                                                  colorInt === 0 ? (
                                                  <i className="mdi mdi-window-close"></i>
                                                ) : (
                                                  index2 + 1
                                                )}
                                              </li>
                                            )
                                          }
                                        )
                                      : null}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 0 &&
                            activity.fk_sub_id === 1
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            let manual_mark = 0
                            let manual_mark_review = 0
                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  manual_mark_review += parseInt(
                                    activityQuestion.marked_for_manual_marking
                                  )
                                  if (
                                    activityQuestion.english_question &&
                                    activityQuestion.english_question.length > 0
                                  ) {
                                    activityQuestion.english_question.forEach(
                                      (question, index3) => {
                                        let filteredManualMark =
                                          question.english_question_parts.filter(
                                            manualMark =>
                                              parseInt(
                                                manualMark.english_question_part_automark
                                              ) === 0
                                          )
                                        if (filteredManualMark.length > 0) {
                                          manual_mark += 1
                                        }

                                        question.english_question_parts.forEach(
                                          (questionpart, index4) => {
                                            if (
                                              parseInt(
                                                question.english_question_category_id
                                              ) === 10 ||
                                              parseInt(
                                                question.english_question_category_id
                                              ) === 13
                                            ) {
                                              questionpart
                                                .english_question_answers
                                                .length > 0 &&
                                              questionpart
                                                .english_question_answers[0]
                                                .english_answer_text &&
                                              questionpart
                                                .english_question_answers[0]
                                                .english_answer_text != ""
                                                ? ((total_question += 1),
                                                  (total_coins += 2))
                                                : null
                                            } else {
                                              total_question += 1
                                              total_coins += 2
                                            }
                                            if (
                                              questionpart.StudentAnsAttempts &&
                                              questionpart.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              questionpart.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <span
                                      className={`${
                                        activity.status == "0"
                                          ? "cs-txt-danger"
                                          : activity.status == "1"
                                          ? "text-success"
                                          : ""
                                      } font-size-10 me-2`}
                                    >
                                      {activity.status == "0"
                                        ? "Incomplete"
                                        : activity.status == "1"
                                        ? "Completed"
                                        : null}
                                    </span>
                                    <span className="font-size-10">
                                      {count_answer != null &&
                                      total_question != null
                                        ? count_answer +
                                          "/" +
                                          total_question +
                                          " attempted"
                                        : null}
                                    </span>
                                  </div>
                                  <div>
                                    {sessionData &&
                                    sessionData.session &&
                                    parseInt(
                                      sessionData.session.ses_is_completed
                                    ) === 1 &&
                                    (activityStudentKey === "" ||
                                      (activity.fk_student_key &&
                                        activity.fk_student_key ===
                                          activityStudentKey)) ? (
                                      <input
                                        type="checkbox"
                                        checked={selectedActivityKeys.includes(
                                          activity.pk_lesson_activity_key
                                        )}
                                        onChange={() =>
                                          handleCheckboxActivityChange(
                                            activity.pk_lesson_activity_key,
                                            activity,
                                            enrolment.tbl_student.Lesson
                                              .LessonActivities.length
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].node_name != ""
                                    ? activity.activity_node[0].node_name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Assessment
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {manual_mark &&
                                      manual_mark > 0 &&
                                      manual_mark_review === 0 &&
                                      activity.status == "1" ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onManualMarkingActivity(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Mark
                                          </DropdownItem>
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveActivityPopup(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to next session
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 &&
                                      parseInt(activity.marked_for_homework) ===
                                        0 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveHomeworkActivityPopup(
                                                activity
                                              )
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to same session homework
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                      ? activity.LessonActivitiesQuestions.map(
                                          (activityQuestion, index2) => {
                                            let color = ""
                                            let attempt = ""
                                            let manual_mark = 0
                                            if (
                                              activityQuestion.english_question &&
                                              activityQuestion.english_question
                                                .length > 0
                                            ) {
                                              activityQuestion.english_question.forEach(
                                                (question, index3) => {
                                                  let filteredManualMark =
                                                    question.english_question_parts.filter(
                                                      manualMark =>
                                                        parseInt(
                                                          manualMark.english_question_part_automark
                                                        ) === 0
                                                    )
                                                  if (
                                                    filteredManualMark.length >
                                                    0
                                                  ) {
                                                    manual_mark += 1
                                                  }
                                                  if (
                                                    question
                                                      .english_question_parts
                                                      .length > 0
                                                  ) {
                                                    question.english_question_parts.forEach(
                                                      (
                                                        questionpart,
                                                        index4
                                                      ) => {
                                                        if (
                                                          questionpart
                                                            .StudentAnsAttempts
                                                            .length > 0
                                                        ) {
                                                          questionpart.StudentAnsAttempts.forEach(
                                                            (
                                                              answerattquestion,
                                                              index5
                                                            ) => {
                                                              attempt += 1
                                                              color +=
                                                                answerattquestion.points_obtained
                                                            }
                                                          )
                                                        }
                                                      }
                                                    )
                                                  }
                                                }
                                              )
                                            }

                                            // Parse color to an integer
                                            const colorInt = parseInt(color)
                                            const attemptInt = parseInt(attempt)
                                            return (
                                              <li
                                                key={index2}
                                                className={`${
                                                  manual_mark > 0
                                                    ? ""
                                                    : attemptInt >= 2 &&
                                                      colorInt === 1
                                                    ? "attempt2 text-warning bg-success-subtle"
                                                    : attemptInt >= 1 &&
                                                      colorInt >= 2
                                                    ? "attempt1 custom-success bg-success-subtle"
                                                    : attemptInt >= 2 &&
                                                      colorInt === 0
                                                    ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                    : ""
                                                }`}
                                              >
                                                {manual_mark > 0 ? (
                                                  "M"
                                                ) : attemptInt >= 2 &&
                                                  colorInt === 1 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : attemptInt >= 1 &&
                                                  colorInt >= 2 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : attemptInt >= 2 &&
                                                  colorInt === 0 ? (
                                                  <i className="mdi mdi-window-close"></i>
                                                ) : (
                                                  index2 + 1
                                                )}
                                              </li>
                                            )
                                          }
                                        )
                                      : null}
                                  </ul>
                                </div>
                                {manual_mark &&
                                manual_mark > 0 &&
                                manual_mark_review === 0 ? (
                                  <div class="manual_mark_div">
                                    <span className="d-flex align-items-center gap-1">
                                      <i className="font-size-18 mdi mdi-alert-outline"></i>
                                      <p className="font-size-12 mb-0">
                                        Manual marking required
                                      </p>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            )
                          } else if (
                            activity.activity_type === 1 
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0

                            //let manual_mark = 0;

                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <span
                                      className={`${
                                        activity.status == "0"
                                          ? "cs-txt-danger"
                                          : activity.status == "1"
                                          ? "text-success"
                                          : ""
                                      } font-size-10 me-2`}
                                    >
                                      {activity.status == "0"
                                        ? "Incomplete"
                                        : activity.status == "1"
                                        ? "Completed"
                                        : null}
                                    </span>
                                    <span className="font-size-10">
                                      {count_answer != null &&
                                      total_question != null
                                        ? count_answer +
                                          "/" +
                                          total_question +
                                          " attempted"
                                        : null}
                                    </span>
                                  </div>
                                  <div>
                                    {sessionData &&
                                    sessionData.session &&
                                    parseInt(
                                      sessionData.session.ses_is_completed
                                    ) === 1 &&
                                    (activityStudentKey === "" ||
                                      (activity.fk_student_key &&
                                        activity.fk_student_key ===
                                          activityStudentKey)) ? (
                                      <input
                                        type="checkbox"
                                        checked={selectedActivityKeys.includes(
                                          activity.pk_lesson_activity_key
                                        )}
                                        onChange={() =>
                                          handleCheckboxActivityChange(
                                            activity.pk_lesson_activity_key,
                                            activity,
                                            enrolment.tbl_student.Lesson
                                              .LessonActivities.length
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].name != ""
                                    ? activity.activity_node[0].name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Video
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveActivityPopup(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to next session
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 &&
                                      parseInt(activity.marked_for_homework) ===
                                        0 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveHomeworkActivityPopup(
                                                activity
                                              )
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to same session homework
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 2 &&
                            activity.fk_sub_id === 1
                          ) {
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            let manual_mark = 0
                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.english_question &&
                                    activityQuestion.english_question.length > 0
                                  ) {
                                    let filteredManualMark = []
                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    //   if(filteredManualMark.length > 0){
                                    //     manual_mark += 1;
                                    //   }
                                    activityQuestion.english_question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            // console.log(activity.activity_node[0].);
                            return (
                              <div className="set1 bg-supermild">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <span
                                      className={`${
                                        activity.status == "0"
                                          ? "cs-txt-danger"
                                          : activity.status == "1"
                                          ? "text-success"
                                          : ""
                                      } font-size-10 me-2`}
                                    >
                                      {activity.status == "0"
                                        ? "Incomplete"
                                        : activity.status == "1"
                                        ? "Completed"
                                        : null}
                                    </span>
                                    <span className="font-size-10">
                                      {count_answer != null &&
                                      total_question != null
                                        ? count_answer +
                                          "/" +
                                          total_question +
                                          " attempted"
                                        : null}
                                    </span>
                                  </div>
                                  <div>
                                    {sessionData &&
                                    sessionData.session &&
                                    parseInt(
                                      sessionData.session.ses_is_completed
                                    ) === 1 &&
                                    (activityStudentKey === "" ||
                                      (activity.fk_student_key &&
                                        activity.fk_student_key ===
                                          activityStudentKey)) ? (
                                      <input
                                        type="checkbox"
                                        checked={selectedActivityKeys.includes(
                                          activity.pk_lesson_activity_key
                                        )}
                                        onChange={() =>
                                          handleCheckboxActivityChange(
                                            activity.pk_lesson_activity_key,
                                            activity,
                                            enrolment.tbl_student.Lesson
                                              .LessonActivities.length
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.topics && activity.topics.length > 0
                                    ? getUniqueTopicsNames(activity.topics)
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Diagnostics
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveActivityPopup(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to next session
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 &&
                                      parseInt(activity.marked_for_homework) ===
                                        0 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveHomeworkActivityPopup(
                                                activity
                                              )
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to same session homework
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                      ? activity.LessonActivitiesQuestions.map(
                                          (activityQuestion, index2) => {
                                            let color = ""

                                            if (
                                              activityQuestion.english_question &&
                                              activityQuestion.english_question
                                                .length > 0
                                            ) {
                                              activityQuestion.english_question.forEach(
                                                (question, index3) => {
                                                  if (
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      (
                                                        answerattquestion,
                                                        index4
                                                      ) => {
                                                        color +=
                                                          answerattquestion.points_obtained
                                                      }
                                                    )
                                                  }
                                                }
                                              )
                                            }

                                            // console.log(color); // This logs the concatenated string, not each value separately

                                            // Parse color to an integer
                                            const colorInt = parseInt(color)

                                            return (
                                              <li
                                                key={index2}
                                                className={`${
                                                  colorInt === 1
                                                    ? "attempt2 text-warning bg-success-subtle"
                                                    : colorInt === 2
                                                    ? "attempt1 custom-success bg-success-subtle"
                                                    : colorInt === 0
                                                    ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                    : ""
                                                }`}
                                              >
                                                {colorInt === 1 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : colorInt === 2 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : colorInt === 0 ? (
                                                  <i className="mdi mdi-window-close"></i>
                                                ) : (
                                                  index2 + 1
                                                )}
                                              </li>
                                            )
                                          }
                                        )
                                      : null}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 2 &&
                            activity.fk_sub_id === 2
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);

                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0

                            let manual_mark = 0
                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []
                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    //   if(filteredManualMark.length > 0){
                                    //     manual_mark += 1;
                                    //   }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <span
                                      className={`${
                                        activity.status == "0"
                                          ? "cs-txt-danger"
                                          : activity.status == "1"
                                          ? "text-success"
                                          : ""
                                      } font-size-10 me-2`}
                                    >
                                      {activity.status == "0"
                                        ? "Incomplete"
                                        : activity.status == "1"
                                        ? "Completed"
                                        : null}
                                    </span>
                                    <span className="font-size-10">
                                      {count_answer != null &&
                                      total_question != null
                                        ? count_answer +
                                          "/" +
                                          total_question +
                                          " attempted"
                                        : null}
                                    </span>
                                  </div>
                                  <div>
                                    {sessionData &&
                                    sessionData.session &&
                                    parseInt(
                                      sessionData.session.ses_is_completed
                                    ) === 1 &&
                                    (activityStudentKey === "" ||
                                      (activity.fk_student_key &&
                                        activity.fk_student_key ===
                                          activityStudentKey)) ? (
                                      <input
                                        type="checkbox"
                                        checked={selectedActivityKeys.includes(
                                          activity.pk_lesson_activity_key
                                        )}
                                        onChange={() =>
                                          handleCheckboxActivityChange(
                                            activity.pk_lesson_activity_key,
                                            activity,
                                            enrolment.tbl_student.Lesson
                                              .LessonActivities.length
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.topics && activity.topics.length > 0
                                    ? getUniqueTopicsNames(activity.topics)
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Diagnostics
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveActivityPopup(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to next session
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 &&
                                      parseInt(activity.marked_for_homework) ===
                                        0 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveHomeworkActivityPopup(
                                                activity
                                              )
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to same session homework
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                      ? activity.LessonActivitiesQuestions.map(
                                          (activityQuestion, index2) => {
                                            let color = ""

                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  if (
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      (
                                                        answerattquestion,
                                                        index4
                                                      ) => {
                                                        color +=
                                                          answerattquestion.points_obtained
                                                      }
                                                    )
                                                  }
                                                }
                                              )
                                            }

                                            // console.log(color); // This logs the concatenated string, not each value separately

                                            // Parse color to an integer
                                            const colorInt = parseInt(color)

                                            return (
                                              <li
                                                key={index2}
                                                className={`${
                                                  colorInt === 1
                                                    ? "attempt2 text-warning bg-success-subtle"
                                                    : colorInt === 2
                                                    ? "attempt1 custom-success bg-success-subtle"
                                                    : colorInt === 0
                                                    ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                    : ""
                                                }`}
                                              >
                                                {colorInt === 1 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : colorInt === 2 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : colorInt === 0 ? (
                                                  <i className="mdi mdi-window-close"></i>
                                                ) : (
                                                  index2 + 1
                                                )}
                                              </li>
                                            )
                                          }
                                        )
                                      : null}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 3 
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0

                            //let manual_mark = 0;

                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <span
                                      className={`${
                                        activity.status == "0"
                                          ? "cs-txt-danger"
                                          : activity.status == "1"
                                          ? "text-success"
                                          : ""
                                      } font-size-10 me-2`}
                                    >
                                      {activity.status == "0"
                                        ? "Incomplete"
                                        : activity.status == "1"
                                        ? "Completed"
                                        : null}
                                    </span>
                                    <span className="font-size-10">
                                      {count_answer != null &&
                                      total_question != null
                                        ? count_answer +
                                          "/" +
                                          total_question +
                                          " attempted"
                                        : null}
                                    </span>
                                  </div>
                                  <div>
                                    {sessionData &&
                                    sessionData.session &&
                                    parseInt(
                                      sessionData.session.ses_is_completed
                                    ) === 1 &&
                                    (activityStudentKey === "" ||
                                      (activity.fk_student_key &&
                                        activity.fk_student_key ===
                                          activityStudentKey)) ? (
                                      <input
                                        type="checkbox"
                                        checked={selectedActivityKeys.includes(
                                          activity.pk_lesson_activity_key
                                        )}
                                        onChange={() =>
                                          handleCheckboxActivityChange(
                                            activity.pk_lesson_activity_key,
                                            activity,
                                            enrolment.tbl_student.Lesson
                                              .LessonActivities.length
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].name != ""
                                    ? activity.activity_node[0].name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Link
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveActivityPopup(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to next session
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 &&
                                      parseInt(activity.marked_for_homework) ===
                                        0 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveHomeworkActivityPopup(
                                                activity
                                              )
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to same session homework
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 4 
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            //let manual_mark = 0;

                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }

                            return (
                              <div className="set1 bg-supermild">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <span
                                      className={`${
                                        activity.status == "0"
                                          ? "cs-txt-danger"
                                          : activity.status == "1"
                                          ? "text-success"
                                          : ""
                                      } font-size-10 me-2`}
                                    >
                                      {activity.status == "0"
                                        ? "Incomplete"
                                        : activity.status == "1"
                                        ? "Completed"
                                        : null}
                                    </span>
                                    <span className="font-size-10">
                                      {count_answer != null &&
                                      total_question != null
                                        ? count_answer +
                                          "/" +
                                          total_question +
                                          " attempted"
                                        : null}
                                    </span>
                                  </div>
                                  <div>
                                    {sessionData &&
                                    sessionData.session &&
                                    parseInt(
                                      sessionData.session.ses_is_completed
                                    ) === 1 &&
                                    (activityStudentKey === "" ||
                                      (activity.fk_student_key &&
                                        activity.fk_student_key ===
                                          activityStudentKey)) ? (
                                      <input
                                        type="checkbox"
                                        checked={selectedActivityKeys.includes(
                                          activity.pk_lesson_activity_key
                                        )}
                                        onChange={() =>
                                          handleCheckboxActivityChange(
                                            activity.pk_lesson_activity_key,
                                            activity,
                                            enrolment.tbl_student.Lesson
                                              .LessonActivities.length
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].name != ""
                                    ? activity.activity_node[0].name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Task
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveActivityPopup(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to next session
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 &&
                                      parseInt(activity.marked_for_homework) ===
                                        0 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveHomeworkActivityPopup(
                                                activity
                                              )
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to same session homework
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 5
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);

                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            //let manual_mark = 0;
                            let manual_mark_review = 0
                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  total_question += 1
                                  manual_mark_review += parseInt(
                                    activityQuestion.marked_for_manual_marking
                                  )
                                  if (
                                    activityQuestion.StudentAnsAttempts &&
                                    activityQuestion.StudentAnsAttempts.length >
                                      0
                                  ) {
                                    activityQuestion.StudentAnsAttempts.forEach(
                                      stuAnswer => {
                                        total_time += parseInt(
                                          stuAnswer.submitted_on
                                        )
                                      }
                                    )
                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                  }
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_coins += 2
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            // if(total_question >= count_answer && total_question != 0){
                            //   percentage = (count_answer/total_question) * 100;
                            // }

                            return (
                              <div className="set1 bg-supermild">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <span
                                      className={`${
                                        activity.status == "0"
                                          ? "cs-txt-danger"
                                          : activity.status == "1"
                                          ? "text-success"
                                          : ""
                                      } font-size-10 me-2`}
                                    >
                                      {activity.status == "0"
                                        ? "Incomplete"
                                        : activity.status == "1"
                                        ? "Completed"
                                        : null}
                                    </span>
                                    <span className="font-size-10">
                                      {count_answer != null &&
                                      total_question != null
                                        ? count_answer +
                                          "/" +
                                          total_question +
                                          " attempted"
                                        : null}
                                    </span>
                                  </div>
                                  <div>
                                    {sessionData &&
                                    sessionData.session &&
                                    parseInt(
                                      sessionData.session.ses_is_completed
                                    ) === 1 &&
                                    (activityStudentKey === "" ||
                                      (activity.fk_student_key &&
                                        activity.fk_student_key ===
                                          activityStudentKey)) ? (
                                      <input
                                        type="checkbox"
                                        checked={selectedActivityKeys.includes(
                                          activity.pk_lesson_activity_key
                                        )}
                                        onChange={() =>
                                          handleCheckboxActivityChange(
                                            activity.pk_lesson_activity_key,
                                            activity,
                                            enrolment.tbl_student.Lesson
                                              .LessonActivities.length
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].name != ""
                                    ? activity.activity_node[0].name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    WorkSheet
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {manual_mark_review === 0 &&
                                      activity.status == "1" ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onManualMarkingActivity(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Mark
                                          </DropdownItem>
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveActivityPopup(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to next session
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 &&
                                      parseInt(activity.marked_for_homework) ===
                                        0 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveHomeworkActivityPopup(
                                                activity
                                              )
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to same session homework
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                                {manual_mark_review === 0 ? (
                                  <div class="manual_mark_div">
                                    <span className="d-flex align-items-center gap-1">
                                      <i className="font-size-18 mdi mdi-alert-outline"></i>
                                      <p className="font-size-12 mb-0">
                                        Manual marking required
                                      </p>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            )
                          } else if (
                            activity.activity_type === 6 &&
                            (activity.fk_sub_id === 1 ||
                              activity.fk_sub_id === 2)
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            //let manual_mark = 0;

                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }

                            return (
                              <div className="set1 bg-supermild">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <span
                                      className={`${
                                        activity.status == "0"
                                          ? "cs-txt-danger"
                                          : activity.status == "1"
                                          ? "text-success"
                                          : ""
                                      } font-size-10 me-2`}
                                    >
                                      {activity.status == "0"
                                        ? "Incomplete"
                                        : activity.status == "1"
                                        ? "Completed"
                                        : null}
                                    </span>
                                    <span className="font-size-10">
                                      {count_answer != null &&
                                      total_question != null
                                        ? count_answer +
                                          "/" +
                                          total_question +
                                          " attempted"
                                        : null}
                                    </span>
                                  </div>
                                  <div>
                                    {sessionData &&
                                    sessionData.session &&
                                    parseInt(
                                      sessionData.session.ses_is_completed
                                    ) === 1 &&
                                    (activityStudentKey === "" ||
                                      (activity.fk_student_key &&
                                        activity.fk_student_key ===
                                          activityStudentKey)) ? (
                                      <input
                                        type="checkbox"
                                        checked={selectedActivityKeys.includes(
                                          activity.pk_lesson_activity_key
                                        )}
                                        onChange={() =>
                                          handleCheckboxActivityChange(
                                            activity.pk_lesson_activity_key,
                                            activity,
                                            enrolment.tbl_student.Lesson
                                              .LessonActivities.length
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].video_name != ""
                                    ? activity.activity_node[0].video_name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Video
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveActivityPopup(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to next session
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 &&
                                      parseInt(activity.marked_for_homework) ===
                                        0 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveHomeworkActivityPopup(
                                                activity
                                              )
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to same session homework
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 7 &&
                            (activity.fk_sub_id === 1 ||
                              activity.fk_sub_id === 2)
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            //let manual_mark = 0;
                            let manual_mark_review = 0
                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  total_question += 1
                                  manual_mark_review += parseInt(
                                    activityQuestion.marked_for_manual_marking
                                  )
                                  if (
                                    activityQuestion.StudentAnsAttempts &&
                                    activityQuestion.StudentAnsAttempts.length >
                                      0
                                  ) {
                                    activityQuestion.StudentAnsAttempts.forEach(
                                      stuAnswer => {
                                        total_time += parseInt(
                                          stuAnswer.submitted_on
                                        )
                                      }
                                    )
                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                  }

                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_coins += 2
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <span
                                      className={`${
                                        activity.status == "0"
                                          ? "cs-txt-danger"
                                          : activity.status == "1"
                                          ? "text-success"
                                          : ""
                                      } font-size-10 me-2`}
                                    >
                                      {activity.status == "0"
                                        ? "Incomplete"
                                        : activity.status == "1"
                                        ? "Completed"
                                        : null}
                                    </span>
                                    <span className="font-size-10">
                                      {count_answer != null &&
                                      total_question != null
                                        ? count_answer +
                                          "/" +
                                          total_question +
                                          " attempted"
                                        : null}
                                    </span>
                                  </div>
                                  <div>
                                    {sessionData &&
                                    sessionData.session &&
                                    parseInt(
                                      sessionData.session.ses_is_completed
                                    ) === 1 &&
                                    (activityStudentKey === "" ||
                                      (activity.fk_student_key &&
                                        activity.fk_student_key ===
                                          activityStudentKey)) ? (
                                      <input
                                        type="checkbox"
                                        checked={selectedActivityKeys.includes(
                                          activity.pk_lesson_activity_key
                                        )}
                                        onChange={() =>
                                          handleCheckboxActivityChange(
                                            activity.pk_lesson_activity_key,
                                            activity,
                                            enrolment.tbl_student.Lesson
                                              .LessonActivities.length
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].node_name != ""
                                    ? activity.activity_node[0].node_name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    WorkSheet
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {manual_mark_review === 0 &&
                                      activity.status == "1" ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onManualMarkingActivity(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Mark
                                          </DropdownItem>
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveActivityPopup(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to next session
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      {sessionData &&
                                      sessionData.session &&
                                      sessionData.session.ses_is_completed ===
                                        1 &&
                                      parseInt(activity.marked_for_homework) ===
                                        0 ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onMoveHomeworkActivityPopup(
                                                activity
                                              )
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-arrow-right-bold-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Roll over to same session homework
                                          </DropdownItem>{" "}
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                                {manual_mark_review === 0 ? (
                                  <div class="manual_mark_div">
                                    <span className="d-flex align-items-center gap-1">
                                      <i className="font-size-18 mdi mdi-alert-outline"></i>
                                      <p className="font-size-12 mb-0">
                                        Manual marking required
                                      </p>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            )
                          } else {
                            return null
                          }
                        }
                      )
                    : null}
                </div>
              </div>
            )
          }
        )
      : null
  }

  // Memoize LessonPlanContent to prevent unnecessary re-renders
  const MemoizedLessonPlanContent = useMemo(() => LassonPlanContent, [])

  // const PreviousHomeWorkSessionContent = ({ sessionData, indexKey }) => {
  const PreviousHomeWorkSessionContent = ({
    sessionData,
    indexKey,
    customActiveTab,
    getUniqueStudentSubjectNames,
    CountTotalCoins,
    CountHours,
    CountTotalActivity,
    clearTimeout,
    setLoopRun,
    handleLinkClick,
    toggleDropdown,
    dropdownOpen,
    onPreviewActivity,
    onDelectActivityPopup,
    getUniqueTopicsNames,
  }) => {
    // Destructure sessionData from props
    //const { sessionData, indexKey } = Lesson

    //const { indexKey } = indexKey;

    //const { HomeworkSessionData } = Homework;

    // if (
    //   sessionData &&
    //   sessionData.session &&
    //   sessionData.session.tbl_session_time &&
    //   sessionData.session.tbl_session_time.tbl_student_enrolment &&
    //   sessionData.session.tbl_session_time.tbl_student_enrolment.length > 0
    // ) {
    //   // Perform operations with tbl_student_enrolment
    // } else {
    //   return <p className='cs-txt-danger'>No student assigned previous session</p>;
    // }

    //console.log(sessionData); // Check if sessionData is correctly received

    //lesson Plan

    return sessionData &&
      sessionData.session &&
      sessionData.session.tbl_session_time &&
      sessionData.session.tbl_session_time.tbl_student_enrolment
      ? sessionData.session.tbl_session_time.tbl_student_enrolment.map(
          (enrolment, index) => {
            if (index == indexKey) {
            } else {
              return null
            }

            // console.log(indexKey)

            return (
              <div className="lesson-plan-wrapper bg-white p-2 pt-0">
                <div className="section-divider">
                  <div className="border-bottom pb-2 mb-2">
                    <h6 className="mb-0 text-dark">Homework plan</h6>
                    <div>
                      {enrolment.tbl_student.Lesson &&
                      enrolment.tbl_student.Lesson.homework_description
                        ? enrolment.tbl_student.Lesson.homework_description
                        : null}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between list-unstyled mb-0">
                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                      {" "}
                      <img className="me-10" width={24} src={awicon} alt="" />
                      {CountTotalCoins(enrolment) || "-"}
                    </div>
                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                      <span>
                        {" "}
                        <img width={24} src={duration} alt="" />
                      </span>
                      {CountHours(enrolment)}
                    </div>
                  </div>
                  {CountTotalActivity(enrolment)}
                </div>

                <div
                  className="events rm-heignt border-bottom1 pb-1 mb-1 mt-0"
                  style={{ minHeight: "100px" }}
                >
                  {enrolment.tbl_student &&
                  enrolment.tbl_student.Lesson &&
                  enrolment.tbl_student.Lesson.LessonActivities.length > 0 &&
                  (enrolment.tbl_student.tbl_session_attendance == null ||
                    (enrolment.tbl_student.tbl_session_attendance &&
                      enrolment.tbl_student.tbl_session_attendance
                        .attendance_status != null &&
                      parseInt(
                        enrolment.tbl_student.tbl_session_attendance
                          .attendance_status
                      ) != 6))
                    ? enrolment.tbl_student.Lesson.LessonActivities.map(
                        (activity, index1) => {
                          // console.log(activity)
                          if (
                            activity.activity_type === 0 &&
                            activity.fk_sub_id === 2
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0

                            // console.log(activity.activity_node[0].);

                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            //let manual_mark = 0;

                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        question.question_parts &&
                                          question.question_parts.length > 0 &&
                                          question.question_parts.forEach(
                                            (questionpat, index4) => {
                                              total_question += 1

                                              total_coins += 2
                                              if (
                                                questionpat.StudentAnsAttempts &&
                                                questionpat.StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                questionpat.StudentAnsAttempts.forEach(
                                                  stuAnswer => {
                                                    total_time += parseInt(
                                                      stuAnswer.submitted_on
                                                    )
                                                  }
                                                )
                                                count_answer += 1 // Increment count_answer by 1 for each occurrence
                                              }
                                            }
                                          )
                                      }
                                    )
                                  }
                                }
                              )
                            }

                            return (
                              <div className="set1 bg-supermild">
                                <span
                                  className={`${
                                    activity.status == "0"
                                      ? "cs-txt-danger"
                                      : activity.status == "1"
                                      ? "text-success"
                                      : ""
                                  } font-size-10 me-2`}
                                >
                                  {activity.status == "0"
                                    ? "Incomplete"
                                    : activity.status == "1"
                                    ? "Completed"
                                    : null}
                                </span>
                                <span className="font-size-10">
                                  {count_answer != null &&
                                  total_question != null
                                    ? count_answer +
                                      "/" +
                                      total_question +
                                      " attempted"
                                    : null}
                                </span>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].node_name != ""
                                    ? activity.activity_node[0].node_name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Assessment
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                      ? activity.LessonActivitiesQuestions.map(
                                          (activityQuestion, index2) => {
                                            let color = ""
                                            let attempt = ""
                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  if (
                                                    question.question_parts
                                                      .length > 0
                                                  ) {
                                                    question.question_parts.forEach(
                                                      (
                                                        partQuestion,
                                                        index4
                                                      ) => {
                                                        if (
                                                          partQuestion
                                                            .StudentAnsAttempts
                                                            .length > 0
                                                        ) {
                                                          partQuestion.StudentAnsAttempts.forEach(
                                                            (
                                                              answerattquestion,
                                                              index5
                                                            ) => {
                                                              attempt += 1
                                                              color +=
                                                                answerattquestion.points_obtained
                                                            }
                                                          )
                                                        }
                                                      }
                                                    )
                                                  }
                                                }
                                              )
                                            }

                                            //console.log(color); // This logs the concatenated string, not each value separately

                                            // Parse color to an integer
                                            const colorInt = parseInt(color)
                                            const attemptInt = parseInt(attempt)
                                            return (
                                              <li
                                                key={index2}
                                                className={`${
                                                  attemptInt >= 2 &&
                                                  colorInt === 1
                                                    ? "attempt2 text-warning bg-success-subtle"
                                                    : attemptInt >= 1 &&
                                                      colorInt === 2
                                                    ? "attempt1 custom-success bg-success-subtle"
                                                    : attemptInt >= 2 &&
                                                      colorInt === 0
                                                    ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                    : ""
                                                }`}
                                              >
                                                {attemptInt >= 2 &&
                                                colorInt === 1 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : attemptInt >= 1 &&
                                                  colorInt === 2 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : attemptInt >= 2 &&
                                                  colorInt === 0 ? (
                                                  <i className="mdi mdi-window-close"></i>
                                                ) : (
                                                  index2 + 1
                                                )}
                                              </li>
                                            )
                                          }
                                        )
                                      : null}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 0 &&
                            activity.fk_sub_id === 1
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            let manual_mark = 0
                            let manual_mark_review = 0
                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  manual_mark_review += parseInt(
                                    activityQuestion.marked_for_manual_marking
                                  )
                                  if (
                                    activityQuestion.english_question &&
                                    activityQuestion.english_question.length > 0
                                  ) {
                                    activityQuestion.english_question.forEach(
                                      (question, index3) => {
                                        let filteredManualMark =
                                          question.english_question_parts.filter(
                                            manualMark =>
                                              parseInt(
                                                manualMark.english_question_part_automark
                                              ) === 0
                                          )
                                        if (filteredManualMark.length > 0) {
                                          manual_mark += 1
                                        }

                                        question.english_question_parts.forEach(
                                          (questionpart, index4) => {
                                            if (
                                              parseInt(
                                                question.english_question_category_id
                                              ) === 10 ||
                                              parseInt(
                                                question.english_question_category_id
                                              ) === 13
                                            ) {
                                              questionpart
                                                .english_question_answers
                                                .length > 0 &&
                                              questionpart
                                                .english_question_answers[0]
                                                .english_answer_text &&
                                              questionpart
                                                .english_question_answers[0]
                                                .english_answer_text != ""
                                                ? ((total_question += 1),
                                                  (total_coins += 2))
                                                : null
                                            } else {
                                              total_question += 1
                                              total_coins += 2
                                            }
                                            // total_question += 1;
                                            // total_coins +=2;
                                            if (
                                              questionpart.StudentAnsAttempts &&
                                              questionpart.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              questionpart.StudentAnsAttempts.forEach(
                                                stuAnswer => {
                                                  total_time += parseInt(
                                                    stuAnswer.submitted_on
                                                  )
                                                }
                                              )
                                              count_answer += 1 // Increment count_answer by 1 for each occurrence
                                            }
                                          }
                                        )
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <span
                                  className={`${
                                    activity.status == "0"
                                      ? "cs-txt-danger"
                                      : activity.status == "1"
                                      ? "text-success"
                                      : ""
                                  } font-size-10 me-2`}
                                >
                                  {activity.status == "0"
                                    ? "Incomplete"
                                    : activity.status == "1"
                                    ? "Completed"
                                    : null}
                                </span>
                                <span className="font-size-10">
                                  {count_answer != null &&
                                  total_question != null
                                    ? count_answer +
                                      "/" +
                                      total_question +
                                      " attempted"
                                    : null}
                                </span>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].node_name != ""
                                    ? activity.activity_node[0].node_name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Assessment
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {manual_mark &&
                                      manual_mark > 0 &&
                                      manual_mark_review === 0 &&
                                      activity.status == "1" ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onManualMarkingActivity(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Mark
                                          </DropdownItem>
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                      ? activity.LessonActivitiesQuestions.map(
                                          (activityQuestion, index2) => {
                                            let color = ""
                                            let attempt = ""
                                            let manual_mark = 0
                                            if (
                                              activityQuestion.english_question &&
                                              activityQuestion.english_question
                                                .length > 0
                                            ) {
                                              activityQuestion.english_question.forEach(
                                                (question, index3) => {
                                                  let filteredManualMark =
                                                    question.english_question_parts.filter(
                                                      manualMark =>
                                                        parseInt(
                                                          manualMark.english_question_part_automark
                                                        ) === 0
                                                    )
                                                  if (
                                                    filteredManualMark.length >
                                                    0
                                                  ) {
                                                    manual_mark += 1
                                                  }
                                                  if (
                                                    question
                                                      .english_question_parts
                                                      .length > 0
                                                  ) {
                                                    question.english_question_parts.forEach(
                                                      (
                                                        questionpart,
                                                        index4
                                                      ) => {
                                                        if (
                                                          questionpart
                                                            .StudentAnsAttempts
                                                            .length > 0
                                                        ) {
                                                          questionpart.StudentAnsAttempts.forEach(
                                                            (
                                                              answerattquestion,
                                                              index5
                                                            ) => {
                                                              attempt += 1
                                                              color +=
                                                                answerattquestion.points_obtained
                                                            }
                                                          )
                                                        }
                                                      }
                                                    )
                                                  }
                                                }
                                              )
                                            }

                                            // Parse color to an integer
                                            const colorInt = parseInt(color)
                                            const attemptInt = parseInt(attempt)
                                            return (
                                              <li
                                                key={index2}
                                                className={`${
                                                  manual_mark > 0
                                                    ? ""
                                                    : attemptInt >= 2 &&
                                                      colorInt === 1
                                                    ? "attempt2 text-warning bg-success-subtle"
                                                    : attemptInt >= 1 &&
                                                      colorInt >= 2
                                                    ? "attempt1 custom-success bg-success-subtle"
                                                    : attemptInt >= 2 &&
                                                      colorInt === 0
                                                    ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                    : ""
                                                }`}
                                              >
                                                {manual_mark > 0 ? (
                                                  "M"
                                                ) : attemptInt >= 2 &&
                                                  colorInt === 1 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : attemptInt >= 1 &&
                                                  colorInt >= 2 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : attemptInt >= 2 &&
                                                  colorInt === 0 ? (
                                                  <i className="mdi mdi-window-close"></i>
                                                ) : (
                                                  index2 + 1
                                                )}
                                              </li>
                                            )
                                          }
                                        )
                                      : null}
                                  </ul>
                                </div>
                                {manual_mark &&
                                manual_mark > 0 &&
                                manual_mark_review === 0 ? (
                                  <div class="manual_mark_div">
                                    <span className="d-flex align-items-center gap-1">
                                      <i className="font-size-18 mdi mdi-alert-outline"></i>
                                      <p className="font-size-12 mb-0">
                                        Manual marking required
                                      </p>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            )
                          } else if (
                            activity.activity_type === 1 
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0

                            //let manual_mark = 0;

                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <span
                                  className={`${
                                    activity.status == "0"
                                      ? "cs-txt-danger"
                                      : activity.status == "1"
                                      ? "text-success"
                                      : ""
                                  } font-size-10 me-2`}
                                >
                                  {activity.status == "0"
                                    ? "Incomplete"
                                    : activity.status == "1"
                                    ? "Completed"
                                    : null}
                                </span>
                                <span className="font-size-10">
                                  {count_answer != null &&
                                  total_question != null
                                    ? count_answer +
                                      "/" +
                                      total_question +
                                      " attempted"
                                    : null}
                                </span>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].name != ""
                                    ? activity.activity_node[0].name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Video
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 2 &&
                            activity.fk_sub_id === 1
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            let manual_mark = 0
                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.english_question &&
                                    activityQuestion.english_question.length > 0
                                  ) {
                                    let filteredManualMark = []
                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    //   if(filteredManualMark.length > 0){
                                    //     manual_mark += 1;
                                    //   }
                                    activityQuestion.english_question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <span
                                  className={`${
                                    activity.status == "0"
                                      ? "cs-txt-danger"
                                      : activity.status == "1"
                                      ? "text-success"
                                      : ""
                                  } font-size-10 me-2`}
                                >
                                  {activity.status == "0"
                                    ? "Incomplete"
                                    : activity.status == "1"
                                    ? "Completed"
                                    : null}
                                </span>
                                <span className="font-size-10">
                                  {count_answer != null &&
                                  total_question != null
                                    ? count_answer +
                                      "/" +
                                      total_question +
                                      " attempted"
                                    : null}
                                </span>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.topics && activity.topics.length > 0
                                    ? getUniqueTopicsNames(activity.topics)
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Diagnostics
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                      ? activity.LessonActivitiesQuestions.map(
                                          (activityQuestion, index2) => {
                                            let color = ""

                                            if (
                                              activityQuestion.english_question &&
                                              activityQuestion.english_question
                                                .length > 0
                                            ) {
                                              activityQuestion.english_question.forEach(
                                                (question, index3) => {
                                                  if (
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      (
                                                        answerattquestion,
                                                        index4
                                                      ) => {
                                                        color +=
                                                          answerattquestion.points_obtained
                                                      }
                                                    )
                                                  }
                                                }
                                              )
                                            }

                                            // console.log(color); // This logs the concatenated string, not each value separately

                                            // Parse color to an integer
                                            const colorInt = parseInt(color)

                                            return (
                                              <li
                                                key={index2}
                                                className={`${
                                                  colorInt === 1
                                                    ? "attempt2 text-warning bg-success-subtle"
                                                    : colorInt === 2
                                                    ? "attempt1 custom-success bg-success-subtle"
                                                    : colorInt === 0
                                                    ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                    : ""
                                                }`}
                                              >
                                                {colorInt === 1 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : colorInt === 2 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : colorInt === 0 ? (
                                                  <i className="mdi mdi-window-close"></i>
                                                ) : (
                                                  index2 + 1
                                                )}
                                              </li>
                                            )
                                          }
                                        )
                                      : null}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 2 &&
                            activity.fk_sub_id === 2
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0

                            let manual_mark = 0
                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []
                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    //   if(filteredManualMark.length > 0){
                                    //     manual_mark += 1;
                                    //   }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <span
                                  className={`${
                                    activity.status == "0"
                                      ? "cs-txt-danger"
                                      : activity.status == "1"
                                      ? "text-success"
                                      : ""
                                  } font-size-10 me-2`}
                                >
                                  {activity.status == "0"
                                    ? "Incomplete"
                                    : activity.status == "1"
                                    ? "Completed"
                                    : null}
                                </span>
                                <span className="font-size-10">
                                  {count_answer != null &&
                                  total_question != null
                                    ? count_answer +
                                      "/" +
                                      total_question +
                                      " attempted"
                                    : null}
                                </span>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.topics && activity.topics.length > 0
                                    ? getUniqueTopicsNames(activity.topics)
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Diagnostics
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions.length >
                                      0
                                      ? activity.LessonActivitiesQuestions.map(
                                          (activityQuestion, index2) => {
                                            let color = ""

                                            if (
                                              activityQuestion.question &&
                                              activityQuestion.question.length >
                                                0
                                            ) {
                                              activityQuestion.question.forEach(
                                                (question, index3) => {
                                                  if (
                                                    question.StudentAnsAttempts
                                                      .length > 0
                                                  ) {
                                                    question.StudentAnsAttempts.forEach(
                                                      (
                                                        answerattquestion,
                                                        index4
                                                      ) => {
                                                        color +=
                                                          answerattquestion.points_obtained
                                                      }
                                                    )
                                                  }
                                                }
                                              )
                                            }

                                            // console.log(color); // This logs the concatenated string, not each value separately

                                            // Parse color to an integer
                                            const colorInt = parseInt(color)

                                            return (
                                              <li
                                                key={index2}
                                                className={`${
                                                  colorInt === 1
                                                    ? "attempt2 text-warning bg-success-subtle"
                                                    : colorInt === 2
                                                    ? "attempt1 custom-success bg-success-subtle"
                                                    : colorInt === 0
                                                    ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                    : ""
                                                }`}
                                              >
                                                {colorInt === 1 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : colorInt === 2 ? (
                                                  <i className="fas fa-check"></i>
                                                ) : colorInt === 0 ? (
                                                  <i className="mdi mdi-window-close"></i>
                                                ) : (
                                                  index2 + 1
                                                )}
                                              </li>
                                            )
                                          }
                                        )
                                      : null}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 3 
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0

                            //let manual_mark = 0;

                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <span
                                  className={`${
                                    activity.status == "0"
                                      ? "cs-txt-danger"
                                      : activity.status == "1"
                                      ? "text-success"
                                      : ""
                                  } font-size-10 me-2`}
                                >
                                  {activity.status == "0"
                                    ? "Incomplete"
                                    : activity.status == "1"
                                    ? "Completed"
                                    : null}
                                </span>
                                <span className="font-size-10">
                                  {count_answer != null &&
                                  total_question != null
                                    ? count_answer +
                                      "/" +
                                      total_question +
                                      " attempted"
                                    : null}
                                </span>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].name != ""
                                    ? activity.activity_node[0].name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Link
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 4 
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            //let manual_mark = 0;

                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <span
                                  className={`${
                                    activity.status == "0"
                                      ? "cs-txt-danger"
                                      : activity.status == "1"
                                      ? "text-success"
                                      : ""
                                  } font-size-10 me-2`}
                                >
                                  {activity.status == "0"
                                    ? "Incomplete"
                                    : activity.status == "1"
                                    ? "Completed"
                                    : null}
                                </span>
                                <span className="font-size-10">
                                  {count_answer != null &&
                                  total_question != null
                                    ? count_answer +
                                      "/" +
                                      total_question +
                                      " attempted"
                                    : null}
                                </span>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].name != ""
                                    ? activity.activity_node[0].name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Task
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 5 
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            //let manual_mark = 0;
                            let manual_mark_review = 0
                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  total_question += 1
                                  manual_mark_review += parseInt(
                                    activityQuestion.marked_for_manual_marking
                                  )
                                  if (
                                    activityQuestion.StudentAnsAttempts &&
                                    activityQuestion.StudentAnsAttempts.length >
                                      0
                                  ) {
                                    activityQuestion.StudentAnsAttempts.forEach(
                                      stuAnswer => {
                                        total_time += parseInt(
                                          stuAnswer.submitted_on
                                        )
                                      }
                                    )
                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                  }
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_coins += 2
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <span
                                  className={`${
                                    activity.status == "0"
                                      ? "cs-txt-danger"
                                      : activity.status == "1"
                                      ? "text-success"
                                      : ""
                                  } font-size-10 me-2`}
                                >
                                  {activity.status == "0"
                                    ? "Incomplete"
                                    : activity.status == "1"
                                    ? "Completed"
                                    : null}
                                </span>
                                <span className="font-size-10">
                                  {count_answer != null &&
                                  total_question != null
                                    ? count_answer +
                                      "/" +
                                      total_question +
                                      " attempted"
                                    : null}
                                </span>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].name != ""
                                    ? activity.activity_node[0].name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    WorkSheet
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {manual_mark_review === 0 &&
                                      activity.status == "1" ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onManualMarkingActivity(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Mark
                                          </DropdownItem>
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                                {manual_mark_review === 0 ? (
                                  <div class="manual_mark_div">
                                    <span className="d-flex align-items-center gap-1">
                                      <i className="font-size-18 mdi mdi-alert-outline"></i>
                                      <p className="font-size-12 mb-0">
                                        Manual marking required
                                      </p>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            )
                          } else if (
                            activity.activity_type === 6 &&
                            (activity.fk_sub_id === 1 ||
                              activity.fk_sub_id === 2)
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            //let manual_mark = 0;

                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_question += 1
                                        total_coins += 2
                                        if (
                                          question.StudentAnsAttempts &&
                                          question.StudentAnsAttempts.length > 0
                                        ) {
                                          question.StudentAnsAttempts.forEach(
                                            stuAnswer => {
                                              total_time += parseInt(
                                                stuAnswer.submitted_on
                                              )
                                            }
                                          )
                                          count_answer += 1 // Increment count_answer by 1 for each occurrence
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <span
                                  className={`${
                                    activity.status == "0"
                                      ? "cs-txt-danger"
                                      : activity.status == "1"
                                      ? "text-success"
                                      : ""
                                  } font-size-10 me-2`}
                                >
                                  {activity.status == "0"
                                    ? "Incomplete"
                                    : activity.status == "1"
                                    ? "Completed"
                                    : null}
                                </span>
                                <span className="font-size-10">
                                  {count_answer != null &&
                                  total_question != null
                                    ? count_answer +
                                      "/" +
                                      total_question +
                                      " attempted"
                                    : null}
                                </span>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].video_name != ""
                                    ? activity.activity_node[0].video_name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    Video
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 7 &&
                            (activity.fk_sub_id === 1 ||
                              activity.fk_sub_id === 2)
                          ) {
                            // var total_coins =
                            //   activity.LessonActivitiesQuestions.length > 0
                            //     ? activity.LessonActivitiesQuestions.length * 2
                            //     : 0
                            // console.log(activity.activity_node[0].);
                            let total_coins = 0
                            let total_time = 0

                            let total_question = 0
                            let count_answer = 0
                            //let percentage = 0;
                            //let manual_mark = 0;
                            let manual_mark_review = 0
                            if (
                              activity.LessonActivitiesQuestions &&
                              activity.LessonActivitiesQuestions.length > 0
                            ) {
                              //total_question += activity.LessonActivitiesQuestions.length;

                              activity.LessonActivitiesQuestions.forEach(
                                (activityQuestion, index2) => {
                                  total_question += 1
                                  manual_mark_review += parseInt(
                                    activityQuestion.marked_for_manual_marking
                                  )
                                  if (
                                    activityQuestion.StudentAnsAttempts &&
                                    activityQuestion.StudentAnsAttempts.length >
                                      0
                                  ) {
                                    activityQuestion.StudentAnsAttempts.forEach(
                                      stuAnswer => {
                                        total_time += parseInt(
                                          stuAnswer.submitted_on
                                        )
                                      }
                                    )
                                    count_answer += 1 // Increment count_answer by 1 for each occurrence
                                  }

                                  if (
                                    activityQuestion.question &&
                                    activityQuestion.question.length > 0
                                  ) {
                                    let filteredManualMark = []

                                    // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                    // if(filteredManualMark.length > 0){
                                    //   manual_mark += 1;
                                    // }
                                    activityQuestion.question.forEach(
                                      (question, index3) => {
                                        total_coins += 2
                                      }
                                    )
                                  }
                                }
                              )
                            }
                            return (
                              <div className="set1 bg-supermild">
                                <span
                                  className={`${
                                    activity.status == "0"
                                      ? "cs-txt-danger"
                                      : activity.status == "1"
                                      ? "text-success"
                                      : ""
                                  } font-size-10 me-2`}
                                >
                                  {activity.status == "0"
                                    ? "Incomplete"
                                    : activity.status == "1"
                                    ? "Completed"
                                    : null}
                                </span>
                                <span className="font-size-10">
                                  {count_answer != null &&
                                  total_question != null
                                    ? count_answer +
                                      "/" +
                                      total_question +
                                      " attempted"
                                    : null}
                                </span>
                                <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                  {activity.activity_node[0] &&
                                  activity.activity_node[0].node_name != ""
                                    ? activity.activity_node[0].node_name
                                    : null}
                                </h6>

                                <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span>
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={reel}
                                        alt=""
                                      />
                                    </span>
                                    WorkSheet
                                  </div>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[
                                        activity.pk_lesson_activity_key
                                      ]
                                    }
                                    toggle={() =>
                                      toggleDropdown(
                                        activity.pk_lesson_activity_key
                                      )
                                    }
                                  >
                                    <DropdownToggle className="p-0 bg-transparent mb-0">
                                      <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                    </DropdownToggle>
                                    <DropdownMenu className="custom-position">
                                      {manual_mark_review === 0 &&
                                      activity.status == "1" ? (
                                        <>
                                          <DropdownItem
                                            onClick={() =>
                                              onManualMarkingActivity(activity)
                                            }
                                          >
                                            <span>
                                              <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                            </span>{" "}
                                            Mark
                                          </DropdownItem>
                                        </>
                                      ) : null}
                                      <DropdownItem
                                        onClick={() =>
                                          onPreviewActivity(activity)
                                        }
                                      >
                                        <span>
                                          <i className="mdi mdi-eye-outline font-size-15 me-1"></i>
                                        </span>{" "}
                                        Preview
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          onDelectActivityPopup(
                                            activity.pk_lesson_activity_key
                                          )
                                        }
                                      >
                                        <span>
                                          <i className="far fa-trash-alt font-size-15 me-1"></i>
                                        </span>{" "}
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    <span className="me-10">
                                      {" "}
                                      <img width={24} src={duration} alt="" />
                                    </span>
                                    {activity.total_activity_time
                                      ? activity.total_activity_time
                                      : 0}{" "}
                                    mins
                                  </div>

                                  <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                    {" "}
                                    <img
                                      className="me-10"
                                      width={24}
                                      src={awicon}
                                      alt=""
                                    />
                                    {activity.coins_earned
                                      ? activity.coins_earned
                                      : 0}
                                    /{total_coins}{" "}
                                  </div>
                                </div>

                                <div className="question-count mt-2">
                                  <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                    {/* {activity.LessonActivitiesQuestions &&
                                  activity.LessonActivitiesQuestions.length >
                                  0
                                  ? activity.LessonActivitiesQuestions.map(
                                    (activityQuestion, index2) => {
                                      let color = ""

                                      if (
                                        activityQuestion.question &&
                                        activityQuestion.question.length >
                                        0
                                      ) {
                                        activityQuestion.question.forEach(
                                          (question, index3) => {
                                            if (
                                              question.StudentAnsAttempts
                                                .length > 0
                                            ) {
                                              question.StudentAnsAttempts.forEach(
                                                (
                                                  answerattquestion,
                                                  index4
                                                ) => {
                                                  color +=
                                                    answerattquestion.points_obtained
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }

                                      // console.log(color); // This logs the concatenated string, not each value separately

                                      // Parse color to an integer
                                      const colorInt = parseInt(color)

                                      return null
                                      // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                      //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                      // </li>
                                    }
                                  )
                                  : null} */}
                                  </ul>
                                </div>
                                {manual_mark_review === 0 ? (
                                  <div class="manual_mark_div">
                                    <span className="d-flex align-items-center gap-1">
                                      <i className="font-size-18 mdi mdi-alert-outline"></i>
                                      <p className="font-size-12 mb-0">
                                        Manual marking required
                                      </p>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            )
                          } else {
                            return null
                          }
                        }
                      )
                    : null}
                </div>
              </div>
            )
          }
        )
      : null

    //home
  }

  // Memoize LessonPlanContent to prevent unnecessary re-renders
  const MemoizedPreviousHomeWorkSessionContent = useMemo(
    () => PreviousHomeWorkSessionContent,
    []
  )

  const PreviousSessionContent = ({
    sessionData,
    PreHomeSessionData,
    customActiveTab,
    getUniqueStudentSubjectNames,
    CountTotalCoins,
    CountHours,
    CountTotalActivity,
    clearTimeout,
    setLoopRun,
    handleLinkClick,
    toggleDropdown,
    dropdownOpen,
    onPreviewActivity,
    onDelectActivityPopup,
    getUniqueTopicsNames,
  }) => {
    // Destructure sessionData from props
    //const { sessionData } = Lesson

    //const { HomeworkSessionData } = Homework;
    if (
      sessionData &&
      sessionData.session &&
      sessionData.session.tbl_session_time &&
      sessionData.session.tbl_session_time.tbl_student_enrolment &&
      sessionData.session.tbl_session_time.tbl_student_enrolment.length > 0
    ) {
      // Perform operations with tbl_student_enrolment
    } else {
      return (
        <p className="cs-txt-danger">No student assigned previous session</p>
      )
    }

    // console.log(sessionData) // Check if sessionData is correctly received

    //lesson Plan

    return sessionData &&
      sessionData.session &&
      sessionData.session.tbl_session_time &&
      sessionData.session.tbl_session_time.tbl_student_enrolment
      ? sessionData.session.tbl_session_time.tbl_student_enrolment.map(
          (enrolment, index) => {
            // console.log(CountTotalCoins(enrolment))

            return (
              <div
                className="kb-child p-0"
                style={{ height: "calc(99vh - 120px)", overflow: "auto" }}
                key={index}
              >
                <div className="ss-details border-bottom1 pb-3 bg-white p-1 mb-3">
                  <div className="bg-supermild st-pd">
                    <div className="profile-details d-flex align-items-center gap-2">
                      <div className="stat-img">
                        <img
                          className="rounded-circle avatar-xs"
                          src={st2}
                          alt=""
                        />
                      </div>
                      <div>
                        <h5 className="text-dark mb-1">
                          {enrolment &&
                          enrolment.reassign &&
                          enrolment.reassign == "1"
                            ? "*"
                            : null}
                          {enrolment.tbl_student &&
                          enrolment.tbl_student.st_first_name
                            ? enrolment.tbl_student.st_first_name +
                              " " +
                              enrolment.tbl_student.st_surname
                            : null}
                        </h5>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                      <div>
                        <p>{sessionData.session.tbl_session_time.sest_name}</p>
                        <small>
                          {sessionData.session.tbl_session_time.sest_type}
                        </small>
                      </div>
                      <div>
                        <p>
                          {sessionData &&
                          sessionData.session &&
                          sessionData.session.ses_date
                            ? moment(
                                sessionData.session.ses_date,
                                moment.ISO_8601
                              ).format("DD MMM YYYY (ddd)")
                            : "-"}
                        </p>
                        <small>
                          {sessionData &&
                          sessionData.session &&
                          sessionData.session.ses_start_time
                            ? moment(
                                sessionData.session.ses_start_time,
                                "HH:mm:ss"
                              ).format("hh:mm A")
                            : "-"}{" "}
                          -{" "}
                          {sessionData &&
                          sessionData.session &&
                          sessionData.session.ses_end_time
                            ? moment(
                                sessionData.session.ses_end_time,
                                "HH:mm:ss"
                              ).format("hh:mm A")
                            : "-"}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lesson-plan-wrapper bg-white p-2 pt-0">
                  <div className="section-divider">
                    <div className="border-bottom pb-2 mb-2">
                      <h6 className="mb-0 text-dark">Lesson Plan</h6>
                      <div>
                        {enrolment.tbl_student.Lesson &&
                        enrolment.tbl_student.Lesson.description
                          ? enrolment.tbl_student.Lesson.description
                          : null}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between list-unstyled mb-0">
                      <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                        {" "}
                        <img className="me-10" width={24} src={awicon} alt="" />
                        {CountTotalCoins(enrolment) || "-"}
                      </div>
                      <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                        <span>
                          {" "}
                          <img width={24} src={duration} alt="" />
                        </span>
                        {CountHours(enrolment)}
                      </div>
                    </div>
                    {CountTotalActivity(enrolment)}
                  </div>

                  <div
                    className="events rm-heignt border-bottom1 pb-1 mb-1 mt-0"
                    style={{ minHeight: "100px" }}
                  >
                    {enrolment.tbl_student &&
                    enrolment.tbl_student.Lesson &&
                    enrolment.tbl_student.Lesson.LessonActivities.length > 0 &&
                    (enrolment.tbl_student.tbl_session_attendance == null ||
                      (enrolment.tbl_student.tbl_session_attendance &&
                        enrolment.tbl_student.tbl_session_attendance
                          .attendance_status != null &&
                        parseInt(
                          enrolment.tbl_student.tbl_session_attendance
                            .attendance_status
                        ) != 6))
                      ? enrolment.tbl_student.Lesson.LessonActivities.map(
                          (activity, index1) => {
                            // console.log(activity)
                            if (
                              activity.activity_type === 0 &&
                              activity.fk_sub_id === 2
                            ) {
                              // var total_coins =
                              //   activity.LessonActivitiesQuestions.length > 0
                              //     ? activity.LessonActivitiesQuestions.length *
                              //     2
                              //     : 0

                              // console.log(activity.activity_node[0].);

                              let total_coins = 0
                              let total_time = 0

                              let total_question = 0
                              let count_answer = 0
                              //let percentage = 0;
                              //let manual_mark = 0;

                              if (
                                activity.LessonActivitiesQuestions &&
                                activity.LessonActivitiesQuestions.length > 0
                              ) {
                                //total_question += activity.LessonActivitiesQuestions.length;

                                activity.LessonActivitiesQuestions.forEach(
                                  (activityQuestion, index2) => {
                                    if (
                                      activityQuestion.question &&
                                      activityQuestion.question.length > 0
                                    ) {
                                      let filteredManualMark = []

                                      // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                      // if(filteredManualMark.length > 0){
                                      //   manual_mark += 1;
                                      // }
                                      activityQuestion.question.forEach(
                                        (question, index3) => {
                                          question.question_parts &&
                                            question.question_parts.length >
                                              0 &&
                                            question.question_parts.forEach(
                                              (questionpat, index4) => {
                                                total_question += 1

                                                total_coins += 2
                                                if (
                                                  questionpat.StudentAnsAttempts &&
                                                  questionpat.StudentAnsAttempts
                                                    .length > 0
                                                ) {
                                                  questionpat.StudentAnsAttempts.forEach(
                                                    stuAnswer => {
                                                      total_time += parseInt(
                                                        stuAnswer.submitted_on
                                                      )
                                                    }
                                                  )
                                                  count_answer += 1 // Increment count_answer by 1 for each occurrence
                                                }
                                              }
                                            )
                                        }
                                      )
                                    }
                                  }
                                )
                              }
                              return (
                                <div className="set1 bg-supermild">
                                  <span
                                    className={`${
                                      activity.status == "0"
                                        ? "cs-txt-danger"
                                        : activity.status == "1"
                                        ? "text-success"
                                        : ""
                                    } font-size-10 me-2`}
                                  >
                                    {activity.status == "0"
                                      ? "Incomplete"
                                      : activity.status == "1"
                                      ? "Completed"
                                      : null}
                                  </span>
                                  <span className="font-size-10">
                                    {count_answer != null &&
                                    total_question != null
                                      ? count_answer +
                                        "/" +
                                        total_question +
                                        " attempted"
                                      : null}
                                  </span>
                                  <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                    {activity.activity_node[0] &&
                                    activity.activity_node[0].node_name != ""
                                      ? activity.activity_node[0].node_name
                                      : null}
                                  </h6>

                                  <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={reel} alt="" />
                                      </span>
                                      Assessment
                                    </div>
                                    <Dropdown
                                      isOpen={
                                        dropdownOpen[
                                          activity.pk_lesson_activity_key
                                        ]
                                      }
                                      toggle={() =>
                                        toggleDropdown(
                                          activity.pk_lesson_activity_key
                                        )
                                      }
                                    >
                                      <DropdownToggle className="p-0 bg-transparent mb-0">
                                        <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                      </DropdownToggle>
                                      <DropdownMenu className="custom-position">
                                        <DropdownItem
                                          onClick={() =>
                                            onPreviewActivity(activity)
                                          }
                                        >
                                          Preview
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            onDelectActivityPopup(
                                              activity.pk_lesson_activity_key
                                            )
                                          }
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={duration} alt="" />
                                      </span>
                                      {activity.total_activity_time
                                        ? activity.total_activity_time
                                        : 0}{" "}
                                      mins
                                    </div>

                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={awicon}
                                        alt=""
                                      />
                                      {activity.coins_earned
                                        ? activity.coins_earned
                                        : 0}
                                      /{total_coins}{" "}
                                    </div>
                                  </div>

                                  <div className="question-count mt-2">
                                    <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                      {activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                        ? activity.LessonActivitiesQuestions.map(
                                            (activityQuestion, index2) => {
                                              let color = ""
                                              let attempt = ""
                                              if (
                                                activityQuestion.question &&
                                                activityQuestion.question
                                                  .length > 0
                                              ) {
                                                activityQuestion.question.forEach(
                                                  (question, index3) => {
                                                    if (
                                                      question.question_parts
                                                        .length > 0
                                                    ) {
                                                      question.question_parts.forEach(
                                                        (
                                                          partQuestion,
                                                          index4
                                                        ) => {
                                                          if (
                                                            partQuestion
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            partQuestion.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index5
                                                              ) => {
                                                                attempt += 1
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }
                                                  }
                                                )
                                              }

                                              //console.log(color); // This logs the concatenated string, not each value separately

                                              // Parse color to an integer
                                              const colorInt = parseInt(color)
                                              const attemptInt =
                                                parseInt(attempt)
                                              return (
                                                <li
                                                  key={index2}
                                                  className={`${
                                                    attemptInt >= 2 &&
                                                    colorInt === 1
                                                      ? "attempt2 text-warning bg-success-subtle"
                                                      : attemptInt >= 1 &&
                                                        colorInt === 2
                                                      ? "attempt1 custom-success bg-success-subtle"
                                                      : attemptInt >= 2 &&
                                                        colorInt === 0
                                                      ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                      : ""
                                                  }`}
                                                >
                                                  {attemptInt >= 2 &&
                                                  colorInt === 1 ? (
                                                    <i className="fas fa-check"></i>
                                                  ) : attemptInt >= 1 &&
                                                    colorInt === 2 ? (
                                                    <i className="fas fa-check"></i>
                                                  ) : attemptInt >= 2 &&
                                                    colorInt === 0 ? (
                                                    <i className="mdi mdi-window-close"></i>
                                                  ) : (
                                                    index2 + 1
                                                  )}
                                                </li>
                                              )
                                            }
                                          )
                                        : null}
                                    </ul>
                                  </div>
                                </div>
                              )
                            } else if (
                              activity.activity_type === 0 &&
                              activity.fk_sub_id === 1
                            ) {
                              // var total_coins =
                              //   activity.LessonActivitiesQuestions.length > 0
                              //     ? activity.LessonActivitiesQuestions.length *
                              //     2
                              //     : 0
                              // console.log(activity.activity_node[0].);
                              let total_coins = 0
                              let total_time = 0

                              let total_question = 0
                              let count_answer = 0
                              //let percentage = 0;
                              let manual_mark = 0
                              let manual_mark_review = 0
                              if (
                                activity.LessonActivitiesQuestions &&
                                activity.LessonActivitiesQuestions.length > 0
                              ) {
                                //total_question += activity.LessonActivitiesQuestions.length;

                                activity.LessonActivitiesQuestions.forEach(
                                  (activityQuestion, index2) => {
                                    manual_mark_review += parseInt(
                                      activityQuestion.marked_for_manual_marking
                                    )
                                    if (
                                      activityQuestion.english_question &&
                                      activityQuestion.english_question.length >
                                        0
                                    ) {
                                      activityQuestion.english_question.forEach(
                                        (question, index3) => {
                                          let filteredManualMark =
                                            question.english_question_parts.filter(
                                              manualMark =>
                                                parseInt(
                                                  manualMark.english_question_part_automark
                                                ) === 0
                                            )
                                          if (filteredManualMark.length > 0) {
                                            manual_mark += 1
                                          }

                                          question.english_question_parts.forEach(
                                            (questionpart, index4) => {
                                              if (
                                                parseInt(
                                                  question.english_question_category_id
                                                ) === 10 ||
                                                parseInt(
                                                  question.english_question_category_id
                                                ) === 13
                                              ) {
                                                questionpart
                                                  .english_question_answers
                                                  .length > 0 &&
                                                questionpart
                                                  .english_question_answers[0]
                                                  .english_answer_text &&
                                                questionpart
                                                  .english_question_answers[0]
                                                  .english_answer_text != ""
                                                  ? ((total_question += 1),
                                                    (total_coins += 2))
                                                  : null
                                              } else {
                                                total_question += 1
                                                total_coins += 2
                                              }
                                              // total_question += 1;
                                              // total_coins +=2;
                                              if (
                                                questionpart.StudentAnsAttempts &&
                                                questionpart.StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                questionpart.StudentAnsAttempts.forEach(
                                                  stuAnswer => {
                                                    total_time += parseInt(
                                                      stuAnswer.submitted_on
                                                    )
                                                  }
                                                )
                                                count_answer += 1 // Increment count_answer by 1 for each occurrence
                                              }
                                            }
                                          )
                                        }
                                      )
                                    }
                                  }
                                )
                              }
                              return (
                                <div className="set1 bg-supermild">
                                  <span
                                    className={`${
                                      activity.status == "0"
                                        ? "cs-txt-danger"
                                        : activity.status == "1"
                                        ? "text-success"
                                        : ""
                                    } font-size-10 me-2`}
                                  >
                                    {activity.status == "0"
                                      ? "Incomplete"
                                      : activity.status == "1"
                                      ? "Completed"
                                      : null}
                                  </span>
                                  <span className="font-size-10">
                                    {count_answer != null &&
                                    total_question != null
                                      ? count_answer +
                                        "/" +
                                        total_question +
                                        " attempted"
                                      : null}
                                  </span>
                                  <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                    {activity.activity_node[0] &&
                                    activity.activity_node[0].node_name != ""
                                      ? activity.activity_node[0].node_name
                                      : null}
                                  </h6>

                                  <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={reel} alt="" />
                                      </span>
                                      Assessment
                                    </div>
                                    <Dropdown
                                      isOpen={
                                        dropdownOpen[
                                          activity.pk_lesson_activity_key
                                        ]
                                      }
                                      toggle={() =>
                                        toggleDropdown(
                                          activity.pk_lesson_activity_key
                                        )
                                      }
                                    >
                                      <DropdownToggle className="p-0 bg-transparent mb-0">
                                        <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                      </DropdownToggle>
                                      <DropdownMenu className="custom-position">
                                        {manual_mark &&
                                        manual_mark > 0 &&
                                        manual_mark_review === 0 &&
                                        activity.status == "1" ? (
                                          <>
                                            <DropdownItem
                                              onClick={() =>
                                                onManualMarkingActivity(
                                                  activity
                                                )
                                              }
                                            >
                                              <span>
                                                <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                              </span>{" "}
                                              Mark
                                            </DropdownItem>
                                          </>
                                        ) : null}
                                        <DropdownItem
                                          onClick={() =>
                                            onPreviewActivity(activity)
                                          }
                                        >
                                          Preview
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            onDelectActivityPopup(
                                              activity.pk_lesson_activity_key
                                            )
                                          }
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={duration} alt="" />
                                      </span>
                                      {activity.total_activity_time
                                        ? activity.total_activity_time
                                        : 0}{" "}
                                      mins
                                    </div>

                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={awicon}
                                        alt=""
                                      />
                                      {activity.coins_earned
                                        ? activity.coins_earned
                                        : 0}
                                      /{total_coins}{" "}
                                    </div>
                                  </div>

                                  <div className="question-count mt-2">
                                    <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                      {activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                        ? activity.LessonActivitiesQuestions.map(
                                            (activityQuestion, index2) => {
                                              let color = ""
                                              let attempt = ""
                                              let manual_mark = 0
                                              if (
                                                activityQuestion.english_question &&
                                                activityQuestion
                                                  .english_question.length > 0
                                              ) {
                                                activityQuestion.english_question.forEach(
                                                  (question, index3) => {
                                                    let filteredManualMark =
                                                      question.english_question_parts.filter(
                                                        manualMark =>
                                                          parseInt(
                                                            manualMark.english_question_part_automark
                                                          ) === 0
                                                      )
                                                    if (
                                                      filteredManualMark.length >
                                                      0
                                                    ) {
                                                      manual_mark += 1
                                                    }
                                                    if (
                                                      question
                                                        .english_question_parts
                                                        .length > 0
                                                    ) {
                                                      question.english_question_parts.forEach(
                                                        (
                                                          questionpart,
                                                          index4
                                                        ) => {
                                                          if (
                                                            questionpart
                                                              .StudentAnsAttempts
                                                              .length > 0
                                                          ) {
                                                            questionpart.StudentAnsAttempts.forEach(
                                                              (
                                                                answerattquestion,
                                                                index5
                                                              ) => {
                                                                attempt += 1
                                                                color +=
                                                                  answerattquestion.points_obtained
                                                              }
                                                            )
                                                          }
                                                        }
                                                      )
                                                    }
                                                  }
                                                )
                                              }

                                              // Parse color to an integer
                                              const colorInt = parseInt(color)
                                              const attemptInt =
                                                parseInt(attempt)
                                              return (
                                                <li
                                                  key={index2}
                                                  className={`${
                                                    manual_mark > 0
                                                      ? ""
                                                      : attemptInt >= 2 &&
                                                        colorInt === 1
                                                      ? "attempt2 text-warning bg-success-subtle"
                                                      : attemptInt >= 1 &&
                                                        colorInt >= 2
                                                      ? "attempt1 custom-success bg-success-subtle"
                                                      : attemptInt >= 2 &&
                                                        colorInt === 0
                                                      ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                      : ""
                                                  }`}
                                                >
                                                  {manual_mark > 0 ? (
                                                    "M"
                                                  ) : attemptInt >= 2 &&
                                                    colorInt === 1 ? (
                                                    <i className="fas fa-check"></i>
                                                  ) : attemptInt >= 1 &&
                                                    colorInt >= 2 ? (
                                                    <i className="fas fa-check"></i>
                                                  ) : attemptInt >= 2 &&
                                                    colorInt === 0 ? (
                                                    <i className="mdi mdi-window-close"></i>
                                                  ) : (
                                                    index2 + 1
                                                  )}
                                                </li>
                                              )
                                            }
                                          )
                                        : null}
                                    </ul>
                                  </div>
                                  {manual_mark &&
                                  manual_mark > 0 &&
                                  manual_mark_review === 0 ? (
                                    <div class="manual_mark_div">
                                      <span className="d-flex align-items-center gap-1">
                                        <i className="font-size-18 mdi mdi-alert-outline"></i>
                                        <p className="font-size-12 mb-0">
                                          Manual marking required
                                        </p>
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              )
                            } else if (
                              activity.activity_type === 1 
                            ) {
                              // var total_coins =
                              //   activity.LessonActivitiesQuestions.length > 0
                              //     ? activity.LessonActivitiesQuestions.length *
                              //     2
                              //     : 0
                              // console.log(activity.activity_node[0].);
                              let total_coins = 0
                              let total_time = 0

                              let total_question = 0
                              let count_answer = 0

                              //let manual_mark = 0;

                              if (
                                activity.LessonActivitiesQuestions &&
                                activity.LessonActivitiesQuestions.length > 0
                              ) {
                                //total_question += activity.LessonActivitiesQuestions.length;

                                activity.LessonActivitiesQuestions.forEach(
                                  (activityQuestion, index2) => {
                                    if (
                                      activityQuestion.question &&
                                      activityQuestion.question.length > 0
                                    ) {
                                      let filteredManualMark = []

                                      // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                      // if(filteredManualMark.length > 0){
                                      //   manual_mark += 1;
                                      // }
                                      activityQuestion.question.forEach(
                                        (question, index3) => {
                                          total_question += 1
                                          total_coins += 2
                                          if (
                                            question.StudentAnsAttempts &&
                                            question.StudentAnsAttempts.length >
                                              0
                                          ) {
                                            question.StudentAnsAttempts.forEach(
                                              stuAnswer => {
                                                total_time += parseInt(
                                                  stuAnswer.submitted_on
                                                )
                                              }
                                            )
                                            count_answer += 1 // Increment count_answer by 1 for each occurrence
                                          }
                                        }
                                      )
                                    }
                                  }
                                )
                              }
                              return (
                                <div className="set1 bg-supermild">
                                  <span
                                    className={`${
                                      activity.status == "0"
                                        ? "cs-txt-danger"
                                        : activity.status == "1"
                                        ? "text-success"
                                        : ""
                                    } font-size-10 me-2`}
                                  >
                                    {activity.status == "0"
                                      ? "Incomplete"
                                      : activity.status == "1"
                                      ? "Completed"
                                      : null}
                                  </span>
                                  <span className="font-size-10">
                                    {count_answer != null &&
                                    total_question != null
                                      ? count_answer +
                                        "/" +
                                        total_question +
                                        " attempted"
                                      : null}
                                  </span>
                                  <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                    {activity.activity_node[0] &&
                                    activity.activity_node[0].name != ""
                                      ? activity.activity_node[0].name
                                      : null}
                                  </h6>

                                  <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={reel} alt="" />
                                      </span>
                                      Video
                                    </div>
                                    <Dropdown
                                      isOpen={
                                        dropdownOpen[
                                          activity.pk_lesson_activity_key
                                        ]
                                      }
                                      toggle={() =>
                                        toggleDropdown(
                                          activity.pk_lesson_activity_key
                                        )
                                      }
                                    >
                                      <DropdownToggle className="p-0 bg-transparent mb-0">
                                        <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                      </DropdownToggle>
                                      <DropdownMenu className="custom-position">
                                        <DropdownItem
                                          onClick={() =>
                                            onPreviewActivity(activity)
                                          }
                                        >
                                          Preview
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            onDelectActivityPopup(
                                              activity.pk_lesson_activity_key
                                            )
                                          }
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={duration} alt="" />
                                      </span>
                                      {activity.total_activity_time
                                        ? activity.total_activity_time
                                        : 0}{" "}
                                      mins
                                    </div>

                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={awicon}
                                        alt=""
                                      />
                                      {activity.coins_earned
                                        ? activity.coins_earned
                                        : 0}
                                      /{total_coins}{" "}
                                    </div>
                                  </div>

                                  <div className="question-count mt-2">
                                    <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                      {/* {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions
                                      .length > 0
                                    ? activity.LessonActivitiesQuestions.map(
                                      (activityQuestion, index2) => {
                                        let color = ""

                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question
                                            .length > 0
                                        ) {
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              if (
                                                question
                                                  .StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  (
                                                    answerattquestion,
                                                    index4
                                                  ) => {
                                                    color +=
                                                      answerattquestion.points_obtained
                                                  }
                                                )
                                              }
                                            }
                                          )
                                        }

                                        // console.log(color); // This logs the concatenated string, not each value separately

                                        // Parse color to an integer
                                        const colorInt = parseInt(color)

                                        return null
                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                        // </li>
                                      }
                                    )
                                    : null} */}
                                    </ul>
                                  </div>
                                </div>
                              )
                            } else if (
                              activity.activity_type === 2 &&
                              activity.fk_sub_id === 1
                            ) {
                              // var total_coins =
                              //   activity.LessonActivitiesQuestions.length > 0
                              //     ? activity.LessonActivitiesQuestions.length *
                              //     2
                              //     : 0
                              // console.log(activity.activity_node[0].);
                              let total_coins = 0
                              let total_time = 0

                              let total_question = 0
                              let count_answer = 0
                              let manual_mark = 0
                              if (
                                activity.LessonActivitiesQuestions &&
                                activity.LessonActivitiesQuestions.length > 0
                              ) {
                                //total_question += activity.LessonActivitiesQuestions.length;

                                activity.LessonActivitiesQuestions.forEach(
                                  (activityQuestion, index2) => {
                                    if (
                                      activityQuestion.english_question &&
                                      activityQuestion.english_question.length >
                                        0
                                    ) {
                                      let filteredManualMark = []
                                      // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                      //   if(filteredManualMark.length > 0){
                                      //     manual_mark += 1;
                                      //   }
                                      activityQuestion.english_question.forEach(
                                        (question, index3) => {
                                          total_question += 1
                                          total_coins += 2
                                          if (
                                            question.StudentAnsAttempts &&
                                            question.StudentAnsAttempts.length >
                                              0
                                          ) {
                                            question.StudentAnsAttempts.forEach(
                                              stuAnswer => {
                                                total_time += parseInt(
                                                  stuAnswer.submitted_on
                                                )
                                              }
                                            )
                                            count_answer += 1 // Increment count_answer by 1 for each occurrence
                                          }
                                        }
                                      )
                                    }
                                  }
                                )
                              }
                              return (
                                <div className="set1 bg-supermild">
                                  <span
                                    className={`${
                                      activity.status == "0"
                                        ? "cs-txt-danger"
                                        : activity.status == "1"
                                        ? "text-success"
                                        : ""
                                    } font-size-10 me-2`}
                                  >
                                    {activity.status == "0"
                                      ? "Incomplete"
                                      : activity.status == "1"
                                      ? "Completed"
                                      : null}
                                  </span>
                                  <span className="font-size-10">
                                    {count_answer != null &&
                                    total_question != null
                                      ? count_answer +
                                        "/" +
                                        total_question +
                                        " attempted"
                                      : null}
                                  </span>
                                  <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                    {activity.topics &&
                                    activity.topics.length > 0
                                      ? getUniqueTopicsNames(activity.topics)
                                      : null}
                                  </h6>

                                  <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={reel} alt="" />
                                      </span>
                                      Diagnostics
                                    </div>
                                    <Dropdown
                                      isOpen={
                                        dropdownOpen[
                                          activity.pk_lesson_activity_key
                                        ]
                                      }
                                      toggle={() =>
                                        toggleDropdown(
                                          activity.pk_lesson_activity_key
                                        )
                                      }
                                    >
                                      <DropdownToggle className="p-0 bg-transparent mb-0">
                                        <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                      </DropdownToggle>
                                      <DropdownMenu className="custom-position">
                                        <DropdownItem
                                          onClick={() =>
                                            onPreviewActivity(activity)
                                          }
                                        >
                                          Preview
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            onDelectActivityPopup(
                                              activity.pk_lesson_activity_key
                                            )
                                          }
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={duration} alt="" />
                                      </span>
                                      {activity.total_activity_time
                                        ? activity.total_activity_time
                                        : 0}{" "}
                                      mins
                                    </div>

                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={awicon}
                                        alt=""
                                      />
                                      {activity.coins_earned
                                        ? activity.coins_earned
                                        : 0}
                                      /{total_coins}{" "}
                                    </div>
                                  </div>

                                  <div className="question-count mt-2">
                                    <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                      {activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                        ? activity.LessonActivitiesQuestions.map(
                                            (activityQuestion, index2) => {
                                              let color = ""

                                              if (
                                                activityQuestion.english_question &&
                                                activityQuestion
                                                  .english_question.length > 0
                                              ) {
                                                activityQuestion.english_question.forEach(
                                                  (question, index3) => {
                                                    if (
                                                      question
                                                        .StudentAnsAttempts
                                                        .length > 0
                                                    ) {
                                                      question.StudentAnsAttempts.forEach(
                                                        (
                                                          answerattquestion,
                                                          index4
                                                        ) => {
                                                          color +=
                                                            answerattquestion.points_obtained
                                                        }
                                                      )
                                                    }
                                                  }
                                                )
                                              }

                                              // console.log(color); // This logs the concatenated string, not each value separately

                                              // Parse color to an integer
                                              const colorInt = parseInt(color)

                                              return (
                                                <li
                                                  key={index2}
                                                  className={`${
                                                    colorInt === 1
                                                      ? "attempt2 text-warning bg-success-subtle"
                                                      : colorInt === 2
                                                      ? "attempt1 custom-success bg-success-subtle"
                                                      : colorInt === 0
                                                      ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                      : ""
                                                  }`}
                                                >
                                                  {colorInt === 1 ? (
                                                    <i className="fas fa-check"></i>
                                                  ) : colorInt === 2 ? (
                                                    <i className="fas fa-check"></i>
                                                  ) : colorInt === 0 ? (
                                                    <i className="mdi mdi-window-close"></i>
                                                  ) : (
                                                    index2 + 1
                                                  )}
                                                </li>
                                              )
                                            }
                                          )
                                        : null}
                                    </ul>
                                  </div>
                                </div>
                              )
                            } else if (
                              activity.activity_type === 2 &&
                              activity.fk_sub_id === 2
                            ) {
                              // var total_coins =
                              //   activity.LessonActivitiesQuestions.length > 0
                              //     ? activity.LessonActivitiesQuestions.length *
                              //     2
                              //     : 0
                              // console.log(activity.activity_node[0].);
                              let total_coins = 0
                              let total_time = 0

                              let total_question = 0
                              let count_answer = 0

                              let manual_mark = 0
                              if (
                                activity.LessonActivitiesQuestions &&
                                activity.LessonActivitiesQuestions.length > 0
                              ) {
                                //total_question += activity.LessonActivitiesQuestions.length;

                                activity.LessonActivitiesQuestions.forEach(
                                  (activityQuestion, index2) => {
                                    if (
                                      activityQuestion.question &&
                                      activityQuestion.question.length > 0
                                    ) {
                                      let filteredManualMark = []
                                      // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                      //   if(filteredManualMark.length > 0){
                                      //     manual_mark += 1;
                                      //   }
                                      activityQuestion.question.forEach(
                                        (question, index3) => {
                                          total_question += 1
                                          total_coins += 2
                                          if (
                                            question.StudentAnsAttempts &&
                                            question.StudentAnsAttempts.length >
                                              0
                                          ) {
                                            question.StudentAnsAttempts.forEach(
                                              stuAnswer => {
                                                total_time += parseInt(
                                                  stuAnswer.submitted_on
                                                )
                                              }
                                            )
                                            count_answer += 1 // Increment count_answer by 1 for each occurrence
                                          }
                                        }
                                      )
                                    }
                                  }
                                )
                              }
                              return (
                                <div className="set1 bg-supermild">
                                  <span
                                    className={`${
                                      activity.status == "0"
                                        ? "cs-txt-danger"
                                        : activity.status == "1"
                                        ? "text-success"
                                        : ""
                                    } font-size-10 me-2`}
                                  >
                                    {activity.status == "0"
                                      ? "Incomplete"
                                      : activity.status == "1"
                                      ? "Completed"
                                      : null}
                                  </span>
                                  <span className="font-size-10">
                                    {count_answer != null &&
                                    total_question != null
                                      ? count_answer +
                                        "/" +
                                        total_question +
                                        " attempted"
                                      : null}
                                  </span>
                                  <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                    {activity.topics &&
                                    activity.topics.length > 0
                                      ? getUniqueTopicsNames(activity.topics)
                                      : null}
                                  </h6>

                                  <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={reel} alt="" />
                                      </span>
                                      Diagnostics
                                    </div>
                                    <Dropdown
                                      isOpen={
                                        dropdownOpen[
                                          activity.pk_lesson_activity_key
                                        ]
                                      }
                                      toggle={() =>
                                        toggleDropdown(
                                          activity.pk_lesson_activity_key
                                        )
                                      }
                                    >
                                      <DropdownToggle className="p-0 bg-transparent mb-0">
                                        <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                      </DropdownToggle>
                                      <DropdownMenu className="custom-position">
                                        <DropdownItem
                                          onClick={() =>
                                            onPreviewActivity(activity)
                                          }
                                        >
                                          Preview
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            onDelectActivityPopup(
                                              activity.pk_lesson_activity_key
                                            )
                                          }
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={duration} alt="" />
                                      </span>
                                      {activity.total_activity_time
                                        ? activity.total_activity_time
                                        : 0}{" "}
                                      mins
                                    </div>

                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={awicon}
                                        alt=""
                                      />
                                      {activity.coins_earned
                                        ? activity.coins_earned
                                        : 0}
                                      /{total_coins}{" "}
                                    </div>
                                  </div>

                                  <div className="question-count mt-2">
                                    <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                      {activity.LessonActivitiesQuestions &&
                                      activity.LessonActivitiesQuestions
                                        .length > 0
                                        ? activity.LessonActivitiesQuestions.map(
                                            (activityQuestion, index2) => {
                                              let color = ""

                                              if (
                                                activityQuestion.question &&
                                                activityQuestion.question
                                                  .length > 0
                                              ) {
                                                activityQuestion.question.forEach(
                                                  (question, index3) => {
                                                    if (
                                                      question
                                                        .StudentAnsAttempts
                                                        .length > 0
                                                    ) {
                                                      question.StudentAnsAttempts.forEach(
                                                        (
                                                          answerattquestion,
                                                          index4
                                                        ) => {
                                                          color +=
                                                            answerattquestion.points_obtained
                                                        }
                                                      )
                                                    }
                                                  }
                                                )
                                              }

                                              // console.log(color); // This logs the concatenated string, not each value separately

                                              // Parse color to an integer
                                              const colorInt = parseInt(color)

                                              return (
                                                <li
                                                  key={index2}
                                                  className={`${
                                                    colorInt === 1
                                                      ? "attempt2 text-warning bg-success-subtle"
                                                      : colorInt === 2
                                                      ? "attempt1 custom-success bg-success-subtle"
                                                      : colorInt === 0
                                                      ? "attempt3 cs-txt-danger bg-danger-subtle"
                                                      : ""
                                                  }`}
                                                >
                                                  {colorInt === 1 ? (
                                                    <i className="fas fa-check"></i>
                                                  ) : colorInt === 2 ? (
                                                    <i className="fas fa-check"></i>
                                                  ) : colorInt === 0 ? (
                                                    <i className="mdi mdi-window-close"></i>
                                                  ) : (
                                                    index2 + 1
                                                  )}
                                                </li>
                                              )
                                            }
                                          )
                                        : null}
                                    </ul>
                                  </div>
                                </div>
                              )
                            } else if (
                              activity.activity_type === 3 
                            ) {
                              // var total_coins =
                              //   activity.LessonActivitiesQuestions.length > 0
                              //     ? activity.LessonActivitiesQuestions.length *
                              //     2
                              //     : 0
                              // console.log(activity.activity_node[0].);
                              let total_coins = 0
                              let total_time = 0

                              let total_question = 0
                              let count_answer = 0

                              //let manual_mark = 0;

                              if (
                                activity.LessonActivitiesQuestions &&
                                activity.LessonActivitiesQuestions.length > 0
                              ) {
                                //total_question += activity.LessonActivitiesQuestions.length;

                                activity.LessonActivitiesQuestions.forEach(
                                  (activityQuestion, index2) => {
                                    if (
                                      activityQuestion.question &&
                                      activityQuestion.question.length > 0
                                    ) {
                                      let filteredManualMark = []

                                      // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                      // if(filteredManualMark.length > 0){
                                      //   manual_mark += 1;
                                      // }
                                      activityQuestion.question.forEach(
                                        (question, index3) => {
                                          total_question += 1
                                          total_coins += 2
                                          if (
                                            question.StudentAnsAttempts &&
                                            question.StudentAnsAttempts.length >
                                              0
                                          ) {
                                            question.StudentAnsAttempts.forEach(
                                              stuAnswer => {
                                                total_time += parseInt(
                                                  stuAnswer.submitted_on
                                                )
                                              }
                                            )
                                            count_answer += 1 // Increment count_answer by 1 for each occurrence
                                          }
                                        }
                                      )
                                    }
                                  }
                                )
                              }
                              return (
                                <div className="set1 bg-supermild">
                                  <span
                                    className={`${
                                      activity.status == "0"
                                        ? "cs-txt-danger"
                                        : activity.status == "1"
                                        ? "text-success"
                                        : ""
                                    } font-size-10 me-2`}
                                  >
                                    {activity.status == "0"
                                      ? "Incomplete"
                                      : activity.status == "1"
                                      ? "Completed"
                                      : null}
                                  </span>
                                  <span className="font-size-10">
                                    {count_answer != null &&
                                    total_question != null
                                      ? count_answer +
                                        "/" +
                                        total_question +
                                        " attempted"
                                      : null}
                                  </span>
                                  <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                    {activity.activity_node[0] &&
                                    activity.activity_node[0].name != ""
                                      ? activity.activity_node[0].name
                                      : null}
                                  </h6>

                                  <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={reel} alt="" />
                                      </span>
                                      Link
                                    </div>
                                    <Dropdown
                                      isOpen={
                                        dropdownOpen[
                                          activity.pk_lesson_activity_key
                                        ]
                                      }
                                      toggle={() =>
                                        toggleDropdown(
                                          activity.pk_lesson_activity_key
                                        )
                                      }
                                    >
                                      <DropdownToggle className="p-0 bg-transparent mb-0">
                                        <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                      </DropdownToggle>
                                      <DropdownMenu className="custom-position">
                                        <DropdownItem
                                          onClick={() =>
                                            onPreviewActivity(activity)
                                          }
                                        >
                                          Preview
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            onDelectActivityPopup(
                                              activity.pk_lesson_activity_key
                                            )
                                          }
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={duration} alt="" />
                                      </span>
                                      {activity.total_activity_time
                                        ? activity.total_activity_time
                                        : 0}{" "}
                                      mins
                                    </div>

                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={awicon}
                                        alt=""
                                      />
                                      {activity.coins_earned
                                        ? activity.coins_earned
                                        : 0}
                                      /{total_coins}{" "}
                                    </div>
                                  </div>

                                  <div className="question-count mt-2">
                                    <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                      {/* {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions
                                      .length > 0
                                    ? activity.LessonActivitiesQuestions.map(
                                      (activityQuestion, index2) => {
                                        let color = ""

                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question
                                            .length > 0
                                        ) {
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              if (
                                                question
                                                  .StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  (
                                                    answerattquestion,
                                                    index4
                                                  ) => {
                                                    color +=
                                                      answerattquestion.points_obtained
                                                  }
                                                )
                                              }
                                            }
                                          )
                                        }

                                        // console.log(color); // This logs the concatenated string, not each value separately

                                        // Parse color to an integer
                                        const colorInt = parseInt(color)

                                        return null
                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                        // </li>
                                      }
                                    )
                                    : null} */}
                                    </ul>
                                  </div>
                                </div>
                              )
                            } else if (
                              activity.activity_type === 4 
                            ) {
                              // var total_coins =
                              //   activity.LessonActivitiesQuestions.length > 0
                              //     ? activity.LessonActivitiesQuestions.length *
                              //     2
                              //     : 0
                              // console.log(activity.activity_node[0].);
                              let total_coins = 0
                              let total_time = 0

                              let total_question = 0
                              let count_answer = 0
                              //let percentage = 0;
                              //let manual_mark = 0;

                              if (
                                activity.LessonActivitiesQuestions &&
                                activity.LessonActivitiesQuestions.length > 0
                              ) {
                                //total_question += activity.LessonActivitiesQuestions.length;

                                activity.LessonActivitiesQuestions.forEach(
                                  (activityQuestion, index2) => {
                                    if (
                                      activityQuestion.question &&
                                      activityQuestion.question.length > 0
                                    ) {
                                      let filteredManualMark = []

                                      // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                      // if(filteredManualMark.length > 0){
                                      //   manual_mark += 1;
                                      // }
                                      activityQuestion.question.forEach(
                                        (question, index3) => {
                                          total_question += 1
                                          total_coins += 2
                                          if (
                                            question.StudentAnsAttempts &&
                                            question.StudentAnsAttempts.length >
                                              0
                                          ) {
                                            question.StudentAnsAttempts.forEach(
                                              stuAnswer => {
                                                total_time += parseInt(
                                                  stuAnswer.submitted_on
                                                )
                                              }
                                            )
                                            count_answer += 1 // Increment count_answer by 1 for each occurrence
                                          }
                                        }
                                      )
                                    }
                                  }
                                )
                              }

                              return (
                                <div className="set1 bg-supermild">
                                  <span
                                    className={`${
                                      activity.status == "0"
                                        ? "cs-txt-danger"
                                        : activity.status == "1"
                                        ? "text-success"
                                        : ""
                                    } font-size-10 me-2`}
                                  >
                                    {activity.status == "0"
                                      ? "Incomplete"
                                      : activity.status == "1"
                                      ? "Completed"
                                      : null}
                                  </span>
                                  <span className="font-size-10">
                                    {count_answer != null &&
                                    total_question != null
                                      ? count_answer +
                                        "/" +
                                        total_question +
                                        " attempted"
                                      : null}
                                  </span>
                                  <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                    {activity.activity_node[0] &&
                                    activity.activity_node[0].name != ""
                                      ? activity.activity_node[0].name
                                      : null}
                                  </h6>

                                  <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={reel} alt="" />
                                      </span>
                                      Task
                                    </div>
                                    <Dropdown
                                      isOpen={
                                        dropdownOpen[
                                          activity.pk_lesson_activity_key
                                        ]
                                      }
                                      toggle={() =>
                                        toggleDropdown(
                                          activity.pk_lesson_activity_key
                                        )
                                      }
                                    >
                                      <DropdownToggle className="p-0 bg-transparent mb-0">
                                        <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                      </DropdownToggle>
                                      <DropdownMenu className="custom-position">
                                        <DropdownItem
                                          onClick={() =>
                                            onPreviewActivity(activity)
                                          }
                                        >
                                          Preview
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            onDelectActivityPopup(
                                              activity.pk_lesson_activity_key
                                            )
                                          }
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={duration} alt="" />
                                      </span>
                                      {activity.total_activity_time
                                        ? activity.total_activity_time
                                        : 0}{" "}
                                      mins
                                    </div>

                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={awicon}
                                        alt=""
                                      />
                                      {activity.coins_earned
                                        ? activity.coins_earned
                                        : 0}
                                      /{total_coins}{" "}
                                    </div>
                                  </div>

                                  <div className="question-count mt-2">
                                    <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                      {/* {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions
                                      .length > 0
                                    ? activity.LessonActivitiesQuestions.map(
                                      (activityQuestion, index2) => {
                                        let color = ""

                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question
                                            .length > 0
                                        ) {
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              if (
                                                question
                                                  .StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  (
                                                    answerattquestion,
                                                    index4
                                                  ) => {
                                                    color +=
                                                      answerattquestion.points_obtained
                                                  }
                                                )
                                              }
                                            }
                                          )
                                        }

                                        // console.log(color); // This logs the concatenated string, not each value separately

                                        // Parse color to an integer
                                        const colorInt = parseInt(color)

                                        return null
                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                        // </li>
                                      }
                                    )
                                    : null} */}
                                    </ul>
                                  </div>
                                </div>
                              )
                            } else if (
                              activity.activity_type === 5 
                            ) {
                              // var total_coins =
                              //   activity.LessonActivitiesQuestions.length > 0
                              //     ? activity.LessonActivitiesQuestions.length *
                              //     2
                              //     : 0
                              // console.log(activity.activity_node[0].);
                              let total_coins = 0
                              let total_time = 0

                              let total_question = 0
                              let count_answer = 0
                              //let percentage = 0;
                              //let manual_mark = 0;
                              let manual_mark_review = 0
                              if (
                                activity.LessonActivitiesQuestions &&
                                activity.LessonActivitiesQuestions.length > 0
                              ) {
                                //total_question += activity.LessonActivitiesQuestions.length;

                                activity.LessonActivitiesQuestions.forEach(
                                  (activityQuestion, index2) => {
                                    total_question += 1
                                    manual_mark_review += parseInt(
                                      activityQuestion.marked_for_manual_marking
                                    )
                                    if (
                                      activityQuestion.StudentAnsAttempts &&
                                      activityQuestion.StudentAnsAttempts
                                        .length > 0
                                    ) {
                                      activityQuestion.StudentAnsAttempts.forEach(
                                        stuAnswer => {
                                          total_time += parseInt(
                                            stuAnswer.submitted_on
                                          )
                                        }
                                      )
                                      count_answer += 1 // Increment count_answer by 1 for each occurrence
                                    }
                                    if (
                                      activityQuestion.question &&
                                      activityQuestion.question.length > 0
                                    ) {
                                      let filteredManualMark = []

                                      // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                      // if(filteredManualMark.length > 0){
                                      //   manual_mark += 1;
                                      // }
                                      activityQuestion.question.forEach(
                                        (question, index3) => {
                                          total_coins += 2
                                        }
                                      )
                                    }
                                  }
                                )
                              }
                              return (
                                <div className="set1 bg-supermild">
                                  <span
                                    className={`${
                                      activity.status == "0"
                                        ? "cs-txt-danger"
                                        : activity.status == "1"
                                        ? "text-success"
                                        : ""
                                    } font-size-10 me-2`}
                                  >
                                    {activity.status == "0"
                                      ? "Incomplete"
                                      : activity.status == "1"
                                      ? "Completed"
                                      : null}
                                  </span>
                                  <span className="font-size-10">
                                    {count_answer != null &&
                                    total_question != null
                                      ? count_answer +
                                        "/" +
                                        total_question +
                                        " attempted"
                                      : null}
                                  </span>
                                  <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                    {activity.activity_node[0] &&
                                    activity.activity_node[0].name != ""
                                      ? activity.activity_node[0].name
                                      : null}
                                  </h6>

                                  <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={reel} alt="" />
                                      </span>
                                      WorkSheet
                                    </div>
                                    <Dropdown
                                      isOpen={
                                        dropdownOpen[
                                          activity.pk_lesson_activity_key
                                        ]
                                      }
                                      toggle={() =>
                                        toggleDropdown(
                                          activity.pk_lesson_activity_key
                                        )
                                      }
                                    >
                                      <DropdownToggle className="p-0 bg-transparent mb-0">
                                        <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                      </DropdownToggle>
                                      <DropdownMenu className="custom-position">
                                        {manual_mark_review === 0 &&
                                        activity.status == "1" ? (
                                          <>
                                            <DropdownItem
                                              onClick={() =>
                                                onManualMarkingActivity(
                                                  activity
                                                )
                                              }
                                            >
                                              <span>
                                                <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                              </span>{" "}
                                              Mark
                                            </DropdownItem>
                                          </>
                                        ) : null}
                                        <DropdownItem
                                          onClick={() =>
                                            onPreviewActivity(activity)
                                          }
                                        >
                                          Preview
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            onDelectActivityPopup(
                                              activity.pk_lesson_activity_key
                                            )
                                          }
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={duration} alt="" />
                                      </span>
                                      {activity.total_activity_time
                                        ? activity.total_activity_time
                                        : 0}{" "}
                                      mins
                                    </div>

                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={awicon}
                                        alt=""
                                      />
                                      {activity.coins_earned
                                        ? activity.coins_earned
                                        : 0}
                                      /{total_coins}{" "}
                                    </div>
                                  </div>

                                  <div className="question-count mt-2">
                                    <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                      {/* {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions
                                      .length > 0
                                    ? activity.LessonActivitiesQuestions.map(
                                      (activityQuestion, index2) => {
                                        let color = ""

                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question
                                            .length > 0
                                        ) {
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              if (
                                                question
                                                  .StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  (
                                                    answerattquestion,
                                                    index4
                                                  ) => {
                                                    color +=
                                                      answerattquestion.points_obtained
                                                  }
                                                )
                                              }
                                            }
                                          )
                                        }

                                        // console.log(color); // This logs the concatenated string, not each value separately

                                        // Parse color to an integer
                                        const colorInt = parseInt(color)

                                        return null
                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                        // </li>
                                      }
                                    )
                                    : null} */}
                                    </ul>
                                  </div>
                                  {manual_mark_review === 0 ? (
                                    <div class="manual_mark_div">
                                      <span className="d-flex align-items-center gap-1">
                                        <i className="font-size-18 mdi mdi-alert-outline"></i>
                                        <p className="font-size-12 mb-0">
                                          Manual marking required
                                        </p>
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              )
                            } else if (
                              activity.activity_type === 6 &&
                              (activity.fk_sub_id === 1 ||
                                activity.fk_sub_id === 2)
                            ) {
                              // var total_coins =
                              //   activity.LessonActivitiesQuestions.length > 0
                              //     ? activity.LessonActivitiesQuestions.length *
                              //     2
                              //     : 0
                              // console.log(activity.activity_node[0].);
                              let total_coins = 0
                              let total_time = 0

                              let total_question = 0
                              let count_answer = 0
                              //let percentage = 0;
                              //let manual_mark = 0;

                              if (
                                activity.LessonActivitiesQuestions &&
                                activity.LessonActivitiesQuestions.length > 0
                              ) {
                                //total_question += activity.LessonActivitiesQuestions.length;

                                activity.LessonActivitiesQuestions.forEach(
                                  (activityQuestion, index2) => {
                                    if (
                                      activityQuestion.question &&
                                      activityQuestion.question.length > 0
                                    ) {
                                      let filteredManualMark = []

                                      // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                      // if(filteredManualMark.length > 0){
                                      //   manual_mark += 1;
                                      // }
                                      activityQuestion.question.forEach(
                                        (question, index3) => {
                                          total_question += 1
                                          total_coins += 2
                                          if (
                                            question.StudentAnsAttempts &&
                                            question.StudentAnsAttempts.length >
                                              0
                                          ) {
                                            question.StudentAnsAttempts.forEach(
                                              stuAnswer => {
                                                total_time += parseInt(
                                                  stuAnswer.submitted_on
                                                )
                                              }
                                            )
                                            count_answer += 1 // Increment count_answer by 1 for each occurrence
                                          }
                                        }
                                      )
                                    }
                                  }
                                )
                              }
                              return (
                                <div className="set1 bg-supermild">
                                  <span
                                    className={`${
                                      activity.status == "0"
                                        ? "cs-txt-danger"
                                        : activity.status == "1"
                                        ? "text-success"
                                        : ""
                                    } font-size-10 me-2`}
                                  >
                                    {activity.status == "0"
                                      ? "Incomplete"
                                      : activity.status == "1"
                                      ? "Completed"
                                      : null}
                                  </span>
                                  <span className="font-size-10">
                                    {count_answer != null &&
                                    total_question != null
                                      ? count_answer +
                                        "/" +
                                        total_question +
                                        " attempted"
                                      : null}
                                  </span>
                                  <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                    {activity.activity_node[0] &&
                                    activity.activity_node[0].video_name != ""
                                      ? activity.activity_node[0].video_name
                                      : null}
                                  </h6>

                                  <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={reel} alt="" />
                                      </span>
                                      Video
                                    </div>
                                    <Dropdown
                                      isOpen={
                                        dropdownOpen[
                                          activity.pk_lesson_activity_key
                                        ]
                                      }
                                      toggle={() =>
                                        toggleDropdown(
                                          activity.pk_lesson_activity_key
                                        )
                                      }
                                    >
                                      <DropdownToggle className="p-0 bg-transparent mb-0">
                                        <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                      </DropdownToggle>
                                      <DropdownMenu className="custom-position">
                                        <DropdownItem
                                          onClick={() =>
                                            onPreviewActivity(activity)
                                          }
                                        >
                                          Preview
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            onDelectActivityPopup(
                                              activity.pk_lesson_activity_key
                                            )
                                          }
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={duration} alt="" />
                                      </span>
                                      {activity.total_activity_time
                                        ? activity.total_activity_time
                                        : 0}{" "}
                                      mins
                                    </div>

                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={awicon}
                                        alt=""
                                      />
                                      {activity.coins_earned
                                        ? activity.coins_earned
                                        : 0}
                                      /{total_coins}{" "}
                                    </div>
                                  </div>

                                  <div className="question-count mt-2">
                                    <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                      {/* {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions
                                      .length > 0
                                    ? activity.LessonActivitiesQuestions.map(
                                      (activityQuestion, index2) => {
                                        let color = ""

                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question
                                            .length > 0
                                        ) {
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              if (
                                                question
                                                  .StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  (
                                                    answerattquestion,
                                                    index4
                                                  ) => {
                                                    color +=
                                                      answerattquestion.points_obtained
                                                  }
                                                )
                                              }
                                            }
                                          )
                                        }

                                        // console.log(color); // This logs the concatenated string, not each value separately

                                        // Parse color to an integer
                                        const colorInt = parseInt(color)

                                        return null
                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                        // </li>
                                      }
                                    )
                                    : null} */}
                                    </ul>
                                  </div>
                                </div>
                              )
                            } else if (
                              activity.activity_type === 7 &&
                              (activity.fk_sub_id === 1 ||
                                activity.fk_sub_id === 2)
                            ) {
                              // var total_coins =
                              //   activity.LessonActivitiesQuestions.length > 0
                              //     ? activity.LessonActivitiesQuestions.length *
                              //     2
                              //     : 0
                              // console.log(activity.activity_node[0].);
                              let total_coins = 0
                              let total_time = 0

                              let total_question = 0
                              let count_answer = 0
                              //let percentage = 0;
                              //let manual_mark = 0;
                              let manual_mark_review = 0
                              if (
                                activity.LessonActivitiesQuestions &&
                                activity.LessonActivitiesQuestions.length > 0
                              ) {
                                //total_question += activity.LessonActivitiesQuestions.length;

                                activity.LessonActivitiesQuestions.forEach(
                                  (activityQuestion, index2) => {
                                    total_question += 1
                                    manual_mark_review += parseInt(
                                      activityQuestion.marked_for_manual_marking
                                    )
                                    if (
                                      activityQuestion.StudentAnsAttempts &&
                                      activityQuestion.StudentAnsAttempts
                                        .length > 0
                                    ) {
                                      activityQuestion.StudentAnsAttempts.forEach(
                                        stuAnswer => {
                                          total_time += parseInt(
                                            stuAnswer.submitted_on
                                          )
                                        }
                                      )
                                      count_answer += 1 // Increment count_answer by 1 for each occurrence
                                    }

                                    if (
                                      activityQuestion.question &&
                                      activityQuestion.question.length > 0
                                    ) {
                                      let filteredManualMark = []

                                      // let filteredManualMark = activityQuestion.english_question.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0)))
                                      // if(filteredManualMark.length > 0){
                                      //   manual_mark += 1;
                                      // }
                                      activityQuestion.question.forEach(
                                        (question, index3) => {
                                          total_coins += 2
                                        }
                                      )
                                    }
                                  }
                                )
                              }
                              return (
                                <div className="set1 bg-supermild">
                                  <span
                                    className={`${
                                      activity.status == "0"
                                        ? "cs-txt-danger"
                                        : activity.status == "1"
                                        ? "text-success"
                                        : ""
                                    } font-size-10 me-2`}
                                  >
                                    {activity.status == "0"
                                      ? "Incomplete"
                                      : activity.status == "1"
                                      ? "Completed"
                                      : null}
                                  </span>
                                  <span className="font-size-10">
                                    {count_answer != null &&
                                    total_question != null
                                      ? count_answer +
                                        "/" +
                                        total_question +
                                        " attempted"
                                      : null}
                                  </span>
                                  <h6 className="text-dark border-bottom pb-1 mb-0 mt-2 font-size-12 lh-sm fw-800">
                                    {activity.activity_node[0] &&
                                    activity.activity_node[0].node_name != ""
                                      ? activity.activity_node[0].node_name
                                      : null}
                                  </h6>

                                  <div className="d-flex justify-content-between mb-2 align-items-center mt-2 flex-wrap">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={reel} alt="" />
                                      </span>
                                      WorkSheet
                                    </div>
                                    <Dropdown
                                      isOpen={
                                        dropdownOpen[
                                          activity.pk_lesson_activity_key
                                        ]
                                      }
                                      toggle={() =>
                                        toggleDropdown(
                                          activity.pk_lesson_activity_key
                                        )
                                      }
                                    >
                                      <DropdownToggle className="p-0 bg-transparent mb-0">
                                        <i className="mdi mdi-dots-horizontal text-dark"></i>{" "}
                                      </DropdownToggle>
                                      <DropdownMenu className="custom-position">
                                        {manual_mark_review === 0 &&
                                        activity.status == "1" ? (
                                          <>
                                            <DropdownItem
                                              onClick={() =>
                                                onManualMarkingActivity(
                                                  activity
                                                )
                                              }
                                            >
                                              <span>
                                                <i className="mdi mdi-checkbox-marked-circle-outline font-size-15 me-1"></i>
                                              </span>{" "}
                                              Mark
                                            </DropdownItem>
                                          </>
                                        ) : null}
                                        <DropdownItem
                                          onClick={() =>
                                            onPreviewActivity(activity)
                                          }
                                        >
                                          Preview
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            onDelectActivityPopup(
                                              activity.pk_lesson_activity_key
                                            )
                                          }
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      <span>
                                        {" "}
                                        <img width={24} src={duration} alt="" />
                                      </span>
                                      {activity.total_activity_time
                                        ? activity.total_activity_time
                                        : 0}{" "}
                                      mins
                                    </div>

                                    <div className="d-flex align-items-center font-size-12 text-dark fw-400">
                                      {" "}
                                      <img
                                        className="me-10"
                                        width={24}
                                        src={awicon}
                                        alt=""
                                      />
                                      {activity.coins_earned
                                        ? activity.coins_earned
                                        : 0}
                                      /{total_coins}{" "}
                                    </div>
                                  </div>

                                  <div className="question-count mt-2">
                                    <ul className="list-unstyled mb-0 d-flex align-items-center mt-1">
                                      {/* {activity.LessonActivitiesQuestions &&
                                    activity.LessonActivitiesQuestions
                                      .length > 0
                                    ? activity.LessonActivitiesQuestions.map(
                                      (activityQuestion, index2) => {
                                        let color = ""

                                        if (
                                          activityQuestion.question &&
                                          activityQuestion.question
                                            .length > 0
                                        ) {
                                          activityQuestion.question.forEach(
                                            (question, index3) => {
                                              if (
                                                question
                                                  .StudentAnsAttempts
                                                  .length > 0
                                              ) {
                                                question.StudentAnsAttempts.forEach(
                                                  (
                                                    answerattquestion,
                                                    index4
                                                  ) => {
                                                    color +=
                                                      answerattquestion.points_obtained
                                                  }
                                                )
                                              }
                                            }
                                          )
                                        }

                                        // console.log(color); // This logs the concatenated string, not each value separately

                                        // Parse color to an integer
                                        const colorInt = parseInt(color)

                                        return null
                                        // <li key={index2} className={`${colorInt === 1 ? 'attempt2 text-warning bg-success-subtle' : colorInt === 2 ? 'attempt1 custom-success bg-success-subtle' : colorInt === 0 ? 'attempt3 cs-txt-danger bg-danger-subtle' : ''}`}>
                                        //     {colorInt === 1 ? <i className='fas fa-check'></i> : colorInt === 2 ? <i className='fas fa-check'></i> : colorInt === 0 ? <i className='mdi mdi-window-close'></i> : index2 + 1}
                                        // </li>
                                      }
                                    )
                                    : null} */}
                                    </ul>
                                  </div>
                                  {manual_mark_review === 0 ? (
                                    <div class="manual_mark_div">
                                      <span className="d-flex align-items-center gap-1">
                                        <i className="font-size-18 mdi mdi-alert-outline"></i>
                                        <p className="font-size-12 mb-0">
                                          Manual marking required
                                        </p>
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              )
                            } else {
                              return null
                            }
                          }
                        )
                      : null}
                  </div>
                </div>
                {/* {console.log(index)} */}
                {/* <PreviousHomeWorkSessionContent
                sessionData={PreHomeSessionData}
                indexKey={index}
              /> */}
                <MemoizedPreviousHomeWorkSessionContent
                  sessionData={PreHomeSessionData}
                  indexKey={index}
                  customActiveTab={customActiveTab}
                  getUniqueStudentSubjectNames={getUniqueStudentSubjectNames}
                  CountTotalCoins={CountTotalCoins}
                  CountHours={CountHours}
                  CountTotalActivity={CountTotalActivity}
                  clearTimeout={clearTimeout}
                  setLoopRun={setLoopRun}
                  handleLinkClick={handleLinkClick}
                  toggleDropdown={toggleDropdown}
                  dropdownOpen={dropdownOpen}
                  onPreviewActivity={onPreviewActivity}
                  onDelectActivityPopup={onDelectActivityPopup}
                  getUniqueTopicsNames={getUniqueTopicsNames}
                />
              </div>
            )
          }
        )
      : null

    //home
  }

  // Memoize LessonPlanContent to prevent unnecessary re-renders
  const MemoizedPreviousSessionContent = useMemo(
    () => PreviousSessionContent,
    []
  )

  function getRandomEven(min, max) {
    const random = Math.floor(Math.random() * ((max - min) / 2 + 1))
    return min + random * 2
  }
  useEffect(() => {
    setSessiosNextActivity([])

    // Only proceed if there is data
    if (
      (SessionStudentNextActivity &&
        Array.isArray(SessionStudentNextActivity) &&
        SessionStudentNextActivity.length > 0) ||
      (SessionStudentSubjectNextActivity &&
        Array.isArray(SessionStudentSubjectNextActivity) &&
        SessionStudentSubjectNextActivity.length > 0)
    ) {
      // Declare a new result array to collect valid activities
      let processingArray = []
      // Check for any numbers other than 1 or 2
      let hasOtherNumbers = SessionStudentSubject.some(
        id => id !== 1 && id !== 2
      )

      if (hasOtherNumbers) {
        processingArray = [
          ...SessionStudentNextActivity,
          ...SessionStudentSubjectNextActivity,
        ]
      } else {
        // If only 1 or 2 are present
        if (SessionStudentSubject.length === 1) {
          processingArray = SessionStudentNextActivity
        } else {
          processingArray = [
            ...SessionStudentNextActivity,
            ...SessionStudentSubjectNextActivity,
          ]
        }
      }

      const result = []
      processingArray.forEach((yearLevel, index1) => {
        yearLevel.LevelTopics.forEach((topic, index) => {
          if (
            topic.topic_completed_existing > 0 &&
            topic.topic_Incompleted_existing === 0
          ) {
            if (topic.TopicActivities.length > 0) {
              if (
                NextSessionData.activity_count +
                  parseInt(TotalPendingActivity) +
                  result.length <=
                20
              ) {
                const options = [5, 10, 15, 20, 25, 30]
                const randomValue =
                  options[Math.floor(Math.random() * options.length)]
                result.push({
                  id: topic.TopicActivities[0].activity_node_id,
                  name: topic.TopicActivities[0].node_name,
                  subject_id: topic.subject_id,
                  topicID: topic.activity_topic_id,
                  coin: getRandomEven(4, 20),
                  duration: randomValue + " mins",
                  is_homework: validation1.values.activity_method,
                  type: "Assessment",
                  activity_node_id: topic.TopicActivities[0].activity_node_id,
                  activity_type: 0,
                  activity_level_data: yearLevel.level_name,
                  activity_level_id_data: yearLevel.activity_level_id,
                  activity_topic_data: topic.topic_name,
                  activity_subject: yearLevel.subject_name,
                })
              }
            } else {
              // Check subsequent topics for activities
              for (let i = index + 1; i < yearLevel.LevelTopics.length; i++) {
                const nextTopic = yearLevel.LevelTopics[i]
                if (nextTopic.TopicActivities.length > 0) {
                  // Skip activity assignment if conditions met
                  if (
                    nextTopic.topic_completed_existing > 0 ||
                    nextTopic.topic_Incompleted_existing > 0
                  ) {
                    // Skip this iteration as the activity is automatically assigned
                  } else {
                    if (
                      NextSessionData.activity_count +
                        parseInt(TotalPendingActivity) +
                        result.length <=
                      20
                    ) {
                      const options = [5, 10, 15, 20, 25, 30]
                      const randomValue =
                        options[Math.floor(Math.random() * options.length)]
                      result.push({
                        // level_name: yearLevel.level_name,
                        // topic_name: nextTopic.topic_name,
                        // activity: nextTopic.TopicActivities[0],
                        id: nextTopic.TopicActivities[0].activity_node_id,
                        name: nextTopic.TopicActivities[0].node_name,
                        subject_id: nextTopic.subject_id,
                        topicID: nextTopic.activity_topic_id,
                        coin: getRandomEven(4, 20),
                        duration: randomValue + " mins",
                        is_homework: validation1.values.activity_method,
                        type: "Assessment",
                        activity_node_id:
                          nextTopic.TopicActivities[0].activity_node_id,
                        activity_type: 0,
                        activity_level_data: yearLevel.level_name,
                        activity_level_id_data: yearLevel.activity_level_id,
                        activity_topic_data: nextTopic.topic_name,
                        activity_subject: yearLevel.subject_name,
                      })
                    }
                  }
                  break // Stop once a valid next topic is found
                }
              }
            }
          }
        })
      })

      // Update state with the new result array
      setSessiosNextActivity(prevResults => [...prevResults, ...result])
    }
  }, [SessionStudentNextActivity, SessionStudentSubjectNextActivity]) // Trigger effect when SessionStudentNextActivity changes

  // Grouping activities by `activity_level_id_data`
  const groupedByLevel = SessiosNextActivity.reduce((acc, activity) => {
    const { activity_level_id_data } = activity

    // Debugging line to check if activity_level_id_data is available

    if (activity_level_id_data !== undefined) {
      if (!acc[activity_level_id_data]) {
        acc[activity_level_id_data] = []
      }
      acc[activity_level_id_data].push(activity)
    }
    return acc
  }, {})

  useEffect(() => {
    if (
      validation1.values.pk_student_key &&
      validation1.values.pk_student_key.value &&
      validation1.values.activity_method &&
      NextSessionData?.next_session &&
      NextSessionData?.next_session_date &&
      ActivityPendingLoading === false &&
      sessionData
    ) {
      sessionData.session.tbl_session_time.tbl_student_enrolment
        .filter(
          enroll =>
            enroll.fk_stu_key === validation1.values.pk_student_key.value
        )
        .map(enroll => {
          let totalPending = 0

          SessionStudentPendingActivity.forEach(enroll => {
            const isNextSessionAvailable =
              NextSessionData &&
              NextSessionData.next_session_date &&
              NextSessionData.next_session_date !== ""

            if (isNextSessionAvailable) {
              const activities = enroll.Lesson?.LessonActivities || []
              const incompleteActivities = activities.filter(
                activity =>
                  activity.status === 0 ||
                  ((activity.mastryScore === null ||
                    parseInt(activity.mastryScore) < 75) &&
                    activity.status === 1 &&
                    [0, 2, 6, 7].includes(activity.activity_type))
              )

              totalPending += incompleteActivities.length
            }
          })

          // console.log(enroll)
          let subject_id =
            getUniqueStudentSubjectID(enroll.tbl_enrolment_subject).join(",") ||
            "1"
          //let subject_id = '1,2,4';

          let subject = "1"
          let NextSubject = ""

          // Calculate subject and next subject
          //const subject_id = getUniqueStudentSubjectID(validation1.values.tbl_enrolment_subject || []).join(",") || "1";
          const ids = subject_id.split(",").map(Number).filter(Boolean)
          const hasOtherNumbers = ids.some(id => id !== 1 && id !== 2)
          setSessionStudentSubject(ids)
          if (hasOtherNumbers) {
            subject = "1"
            NextSubject = "2"
          } else {
            subject = ids.length === 1 ? subject_id : "1"
            NextSubject = ids.length === 2 ? "2" : ""
          }

          if (
            NextSessionData.activity_count + parseInt(totalPending) < 20 &&
            SessionStudentNextActivity.length === 0
          ) {
            // Automatically trigger the required actions
            if (NextSubject && NextSubject !== "") {
              fetchSessionStudentNextActivityData(
                validation1.values.pk_student_key,
                validation1.values.activity_method,
                NextSessionData.next_session,
                subject
              )
              fetchSessionStudentSubjectNextActivityData(
                validation1.values.pk_student_key,
                validation1.values.activity_method,
                NextSessionData.next_session,
                NextSubject
              )
            } else {
              fetchSessionStudentNextActivityData(
                validation1.values.pk_student_key,
                validation1.values.activity_method,
                NextSessionData.next_session,
                subject
              )
            }
          } else {
            //skip triggered
          }
        })
    }
  }, [
    // validation1.values.pk_student_key,
    // validation1.values.activity_method,
    //NextSessionData?.next_session,
    ActivityPendingLoading,
    SessionStudentPendingActivity,
  ])

  return (
    <React.Fragment>
      <Loader
        show={loader}
        type="body"
        // stack="vertical"
        // message="Loading Data"
      />
      <ToastContainer autoClose={3000} />
      {/* popup for note delete start*/}
      <Modal isOpen={modal_delete_note} toggle={tog_delete_note} centered>
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h5 className="mb-4">Are you sure you want to delete?</h5>
            <Button
              color="success"
              className="mr-3"
              disabled={isNoteSubmitting}
              onClick={onDelectNote}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button color="danger" onClick={tog_delete_note}>
              {" "}
              No{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity delete  end*/}
      {/* popup for activity delete start*/}
      <Modal
        isOpen={modal_delete_activity}
        toggle={tog_delete_activity}
        centered
      >
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h5 className="mb-4">Are you sure you want to delete?</h5>
            <Button
              color="success"
              className="mr-3"
              disabled={isDASubmitting}
              onClick={onDelectActivity}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button color="danger" onClick={tog_delete_activity}>
              {" "}
              No{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity delete  end*/}
      {/* popup for activity Move start*/}
      <Modal
        isOpen={modal_move_activity}
        toggle={tog_move_activity}
        size="lg"
        centered
      >
        <ModalHeader
          className="mt-0 w-100"
          close={
            moveingSessionKey.length > 0 ? (
              <button
                className="btn bg-danger text-white text-uppercase position-absolute top-3 end-0 me-3"
                onClick={() => {
                  onMoveingActivityPopup()
                }}
              >
                Submit
              </button>
            ) : (
              ""
            )
          }
        >
          <label>Choose session</label>
        </ModalHeader>
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div>
            <TableComm
              // loading={loading}
              columns={columns_session}
              defaultId={"ses_date"}
              data={UpcomingSessionData}

              //onRowClick={handleStudentViewClick}
              //hiddenColumns={['ses_is_completed']}
            />
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity move  end*/}
      {/* popup for activity Roll over to homework start*/}
      <Modal isOpen={modal_moveing_conform} toggle={tog_moveing_popup} centered>
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h5 className="mb-4">
              {" "}
              Are you sure you want to Roll over to next session(s)?
            </h5>
            <Button
              color="success"
              className="mr-3"
              disabled={isMASubmitting}
              onClick={() => onMoveActivityUpcomingSession()}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button color="danger" onClick={tog_moveing_popup}>
              {" "}
              No{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity Roll over to homework  end*/}
      {/* popup for activity Roll over to homework start*/}

      <Modal
        isOpen={modal_move_home_activity}
        toggle={tog_move_home_activity}
        centered
      >
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h5 className="mb-4">
              Are you sure you want to Roll over to same session homework?
            </h5>
            <Button
              color="success"
              className="mr-3"
              disabled={isMAHSubmitting}
              onClick={() => onMoveActivityHomework()}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button color="danger" onClick={tog_move_home_activity}>
              {" "}
              No{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity Roll over to homework  end*/}
      {/* popup for student past session start*/}
      <Modal
        isOpen={modalStuPastSession}
        toggle={togStudentPastSession}
        size="xl"
        centered
      >
        <ModalHeader className="mt-0" toggle={togStudentPastSession}>
          <label>View past sessions</label>
        </ModalHeader>
        <ModalBody>
          <TableComm
            // loading={loading}
            columns={columns1}
            defaultId={"sest_name"}
            data={PastSessionStuData}
            //onRowClick={handleStudentViewClick}
            //hiddenColumns={['ses_is_completed']}
          />
        </ModalBody>
      </Modal>
      {/* popup for student past session end*/}
      {/* popup for multiple activity Move start*/}
      {/* <Modal
        isOpen={show_modal_move_multiple_activity}
        toggle={tog_move_multiple_activity}
        size="lg"
        centered
      >
       <ModalHeader className="mt-0 w-100" close={ moveingMultipleSessionKey.length > 0 ? (<button className="btn bg-danger text-white text-uppercase position-absolute top-3 end-0 me-3" onClick={()=> {onMoveingMultipleActivityPopup();}}>Submit</button>) : ('')}>
          <label>Choose session</label>
        </ModalHeader>
       
        <ModalBody className="text-center">
          <div>
          <TableComm
            // loading={loading}
            columns={columns_multi_session}
            defaultId={"ses_date"}
            data={MultipleUpcomingSessionData}
          //onRowClick={handleStudentViewClick}
          //hiddenColumns={['ses_is_completed']}
          />
          </div>
         
        </ModalBody>
      </Modal> */}

      <Modal
        isOpen={show_modal_move_multiple_activity}
        toggle={tog_move_multiple_activity}
        size="lg"
        centered
      >
        <ModalHeader
          className="mt-0 w-100"
          close={
            moveingMultipleSessionKey.length > 0 ? (
              <button
                className="btn bg-danger text-white text-uppercase position-absolute top-3 end-0 me-3"
                onClick={() => {
                  onMoveingMultipleActivityPopup()
                }}
              >
                Submit
              </button>
            ) : (
              ""
            )
          }
        >
          {/* Tab Navigation */}

          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${
                  rollOveractiveTab === "lessonPlan" ? "active" : ""
                }`}
                id="lessonPlan-tab"
                data-bs-toggle="tab"
                role="tab"
                // onClick={() => {
                //   setrollOverActiveTab("lessonPlan")
                //   setmoveingMultipleSessionKey([])
                // }}
                onClick={() => handlerollOverTabChange("lessonPlan")}
              >
                Roll over to lesson plan
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${
                  rollOveractiveTab === "homework" ? "active" : ""
                }`}
                id="homework-tab"
                data-bs-toggle="tab"
                role="tab"
                // onClick={() => {
                //   setrollOverActiveTab("homework")
                //   setmoveingMultipleSessionKey([])
                // }}
                onClick={() => handlerollOverTabChange("homework")}
              >
                Roll over to home work
              </a>
            </li>
          </ul>
        </ModalHeader>

        <ModalBody className="text-center">
          <div>
            {/* Tab Content */}
            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  rollOveractiveTab === "lessonPlan" ? "show active" : ""
                }`}
                // id="lessonPlan"
                role="tabpanel"
              >
                <label>Choose session</label>
                {/* Content for the Lesson Plan Tab */}
                <TableComm
                  columns={columns_multi_session}
                  defaultId={"ses_date"}
                  data={MultipleUpcomingSessionData}
                />
              </div>
              <div
                className={`tab-pane fade ${
                  rollOveractiveTab === "homework" ? "show active" : ""
                }`}
                // id="homework"
                role="tabpanel"
              >
                <p>
                  Click submit to roll over all activities to the same session
                  home work
                </p>
                <button
                  className="btn bg-danger text-white text-uppercase position-absolute top-3 end-0 me-3"
                  onClick={() => {
                    onMoveingMultipleActivityHomePopup()
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for multiple activity move  end*/}

      <Modal
        isOpen={modal_move_home_all_activity}
        toggle={tog_move_home_all_activity}
        centered
      >
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h5 className="mb-4">
              Are you sure you want to Roll over activities to same session
              homework?
            </h5>
            <Button
              color="success"
              className="mr-3"
              disabled={isMAHmSubmitting}
              onClick={() => onMoveAllActivityHomework()}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button color="danger" onClick={tog_move_home_all_activity}>
              {" "}
              No{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* popup for activity Roll over to lession or homewor start*/}
      <Modal
        isOpen={modal_multiple_moveing_conform}
        toggle={tog_multiple_moveing_popup}
        centered
      >
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h5 className="mb-4">
              Are you sure you want to Roll over to next session(s)?
            </h5>
            <Button
              color="success"
              className="mr-3"
              disabled={isMulMoningSubmitting}
              onClick={() => onMoveMultipleActivityUpcomingSession()}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button color="danger" onClick={tog_multiple_moveing_popup}>
              {" "}
              No{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for activity Roll over to homework  end*/}
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box mb-2">
            <div className="custom-pg_header">
              <div>
                <div className="d-flex align-items-center gap-2">
                  <Button
                    color="light"
                    className="bck-btn"
                    onClick={() => window.history.back()}
                  >
                    <i className="ion ion-ios-arrow-back"></i>
                  </Button>

                  <h6 className="page-title mb-0">
                    {sessionData &&
                    sessionData.session &&
                    sessionData.session.tbl_session_time &&
                    sessionData.session.tbl_session_time.sest_name
                      ? sessionData.session.tbl_session_time.sest_name
                      : "-"}{" "}
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <div className="text-dark font-size-14">
                  {" "}
                  {sessionData &&
                  sessionData.session &&
                  sessionData.session.tbl_session_time &&
                  sessionData.session.tbl_session_time.sest_type
                    ? sessionData.session.tbl_session_time.sest_type
                    : "-"}
                </div>
                <div
                  className="me-2 d-flex align-items-center aw-button"
                  color=""
                  // onClick={toggleRightCanvas}
                  onClick={tog_center2}
                >
                  <span>
                    <img className="cs-mg" width={24} src={awicon} alt="" />
                  </span>
                  Award Extra Coins
                </div>
                {sessionData &&
                sessionData.session &&
                sessionData.session.ses_is_completed === 1 ? (
                  <div
                    className="me-2 d-flex align-items-center aw-button"
                    color=""
                    onClick={tog_center3}
                  >
                    Move incomplete activity
                  </div>
                ) : null}
                <Modal
                  isOpen={modal_center2}
                  toggle={tog_center2}
                  centered
                  className="mastery-modal"
                >
                  <ModalHeader className="mt-0 text-dark" toggle={tog_center2}>
                    Award coin to
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      className=""
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      <div className="mb-3">
                        <Label className="font-size-12 mb-1 pb-0">
                          Student List
                        </Label>
                        <Select
                          isMulti={true}
                          options={studentOption}
                          classNamePrefix="select2-selection"
                          id="pk_student_key"
                          onBlur={validation.handleBlur}
                          name="pk_student_key"
                          value={validation.values.pk_student_key || ""}
                          onChange={selectedOption => {
                            const event = {
                              target: {
                                name: "pk_student_key",
                                value: selectedOption,
                              },
                            }
                            validation.handleChange(event)
                          }}
                          className={
                            validation.touched.coins_earned &&
                            validation.errors.coins_earned
                              ? "is-invalid cs-style"
                              : "cs-style"
                          }
                          // invalid={
                          //   validation.touched.pk_student_key && validation.errors.pk_student_key ? true : false
                          // }
                        />
                        {validation.touched.pk_student_key &&
                        validation.errors.pk_student_key ? (
                          <FormFeedback type="invalid">
                            {validation.errors.pk_student_key}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className=" col-form-label pt-0 font-size-12 mb-1 pb-0"
                        >
                          No. Of Coins
                        </label>
                        <div className="">
                          <input
                            type="number"
                            placeholder="Enter number of coins to award"
                            id="coins_earned"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.coins_earned}
                            className={
                              validation.touched.coins_earned &&
                              validation.errors.coins_earned
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            // invalid={
                            //   validation.touched.coins_earned && validation.errors.coins_earned ? true : false
                            // }
                          />
                          {validation.touched.coins_earned &&
                          validation.errors.coins_earned ? (
                            <FormFeedback type="invalid">
                              {validation.errors.coins_earned}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className=" col-form-label pt-0 font-size-12 mb-1 pb-0"
                        >
                          Reason
                        </label>
                        <Input
                          type="textarea"
                          maxLength="225"
                          rows="3"
                          placeholder="Type notes here"
                          id="remarks"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.remarks || ""}
                          className={
                            validation.touched.remarks &&
                            validation.errors.remarks
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          // invalid={
                          //   validation.touched.remarks && validation.errors.remarks ? true : false
                          // }
                        />
                        {validation.touched.remarks &&
                        validation.errors.remarks ? (
                          <FormFeedback type="invalid">
                            {validation.errors.remarks}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-end">
                        <div className="btn-group">
                          {/* <button
                            type="button"
                            class="btn btn-outline-dark me-2"
                            onClick={toggleRightCanvas}
                          >
                            Close
                          </button> */}
                          <button
                            type="submit"
                            class="btn btn-danger"
                            disabled={isSubmitting}
                          >
                            Award
                          </button>
                        </div>
                      </div>
                    </Form>
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={modal_center3}
                  toggle={tog_center3}
                  centered
                  className="mastery-modal"
                >
                  <ModalHeader className="mt-0 text-dark" toggle={tog_center3}>
                    Incomplete Activities
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      className=""
                      onSubmit={e => {
                        e.preventDefault()
                        validation1.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      <div className="mb-3">
                        <Label className="font-size-12 mb-1 pb-0">
                          Student List
                        </Label>
                        <Select
                          // isMulti={true}
                          options={studentOption}
                          classNamePrefix="select2-selection"
                          id="pk_student_key"
                          onBlur={validation1.handleBlur}
                          name="pk_student_key"
                          value={validation1.values.pk_student_key || ""}
                          onChange={selectedOption => {
                            const event = {
                              target: {
                                name: "pk_student_key",
                                value: selectedOption,
                              },
                            }
                            validation1.handleChange(event)
                            validation1.setFieldValue("activities", [])
                            fetchNextSessionData(
                              selectedOption,
                              validation1.values.activity_method
                            )
                            setnextsessiondeatils("")
                            fetchSessionStudentPendingActivityData(
                              selectedOption,
                              validation1.values.activity_method
                            )
                            setSessionStudentPendingActivity([])
                            setSessionStudentNextActivity([])
                            setSessiosNextActivity([])
                          }}
                          className={
                            validation1.touched.pk_student_key &&
                            validation1.errors.pk_student_key
                              ? "is-invalid cs-style"
                              : "cs-style"
                          }
                        />
                        {validation1.touched.pk_student_key &&
                        validation1.errors.pk_student_key ? (
                          <FormFeedback type="invalid">
                            {validation1.errors.pk_student_key}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="font-size-12 mb-1 pb-0">
                          Activity Method
                        </Label>
                        <Select
                          // isMulti={true}
                          options={optionPendingActivityType}
                          classNamePrefix="select2-selection"
                          id="activity_method"
                          onBlur={validation1.handleBlur}
                          name="activity_method"
                          value={optionPendingActivityType.find(
                            option =>
                              option.value == validation1.values.activity_method
                          )}
                          onChange={selectedOption => {
                            // console.log(selectedOption)
                            const event = {
                              target: {
                                name: "activity_method",
                                value: selectedOption["value"],
                              },
                            }
                            validation1.handleChange(event)
                            validation1.setFieldValue("activities", [])
                            fetchNextSessionData(
                              validation1.values.pk_student_key,
                              selectedOption["value"]
                            )
                            setnextsessiondeatils("")
                            fetchSessionStudentPendingActivityData(
                              validation1.values.pk_student_key,
                              selectedOption["value"]
                            )
                            setSessionStudentPendingActivity([])
                            setSessionStudentNextActivity([])
                            setSessiosNextActivity([])
                          }}
                          className={
                            validation1.touched.activity_method &&
                            validation1.errors.activity_method
                              ? "is-invalid cs-style"
                              : "cs-style"
                          }
                        />
                        {validation1.touched.activity_method &&
                        validation1.errors.activity_method ? (
                          <FormFeedback type="invalid">
                            {validation1.errors.activity_method}
                          </FormFeedback>
                        ) : null}
                      </div>
                      {validation1.values.pk_student_key &&
                        (NextSessionData &&
                        NextSessionData.next_session_date ? (
                          <div className="mb-3">
                            <Label className="font-size-12 mb-1 pb-0">
                              Next Session: {NextSessionData.sest_name} (
                              {moment(
                                NextSessionData.next_session_date,
                                "YYYY-MM-DDTHH:mm:ss.SSSZ"
                              ).format("DD MMM YYYY")}
                              ) (Total Activity :{" "}
                              {NextSessionData && NextSessionData.activity_count
                                ? NextSessionData.activity_count
                                : 0}
                              )
                            </Label>
                          </div>
                        ) : (
                          <div className="alert alert-info">
                            No session available for this student.
                          </div>
                        ))}

                      <div className="mb-3">
                        {SessionStudentPendingActivity &&
                        SessionStudentPendingActivity.length > 0 ? (
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label pt-0 font-size-12 mb-1 pb-0"
                          >
                            Incomplete activities move to next session
                          </label>
                        ) : null}
                        <div className="activities-container">
                          <Loader
                            show={ActivityPendingLoading}
                            type="inline"
                            spinnerSize="20px"
                            spinnerStyle={{
                              secondary: "#2D866D",
                            }}
                          />
                          {!validation1.values.pk_student_key ? (
                            <div className="alert alert-warning">
                              Please select a student to list the activities.
                            </div>
                          ) : SessionStudentPendingActivity &&
                            SessionStudentPendingActivity.length > 0 ? (
                            SessionStudentPendingActivity.map(enroll => {
                              const isNextSessionAvailable =
                                NextSessionData &&
                                NextSessionData.next_session_date &&
                                NextSessionData.next_session_date !== ""

                              if (!isNextSessionAvailable) {
                                return (
                                  <div
                                    key={`no-session-${enroll.pk_student_key}`}
                                    className="alert alert-info"
                                  >
                                    Next session not available for this student.
                                  </div>
                                )
                              }

                              const activities =
                                enroll.Lesson?.LessonActivities || []
                              const incompleteActivities = activities.filter(
                                activity =>
                                  activity.status === 0 ||
                                  ((activity.mastryScore === null ||
                                    parseInt(activity.mastryScore) < 75) &&
                                    activity.status === 1 &&
                                    [0, 2, 6, 7].includes(
                                      activity.activity_type
                                    ))
                              )

                              if (incompleteActivities.length === 0) {
                                return null
                                // <div key={`no-activities-${enroll.pk_student_key}`} className="alert alert-info">
                                //   No pending activities for this student.
                                // </div>
                              }
                              //console.log(validation1.values.activities);
                              return (
                                <div
                                  key={`activities-${enroll.pk_ses_key}`}
                                  className="mb-3"
                                >
                                  <Label className="font-size-12 mb-1 pb-0">
                                    <strong>
                                      {" "}
                                      Date:{" "}
                                      {moment(
                                        enroll.ses_date,
                                        "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                      ).format("DD MMM YYYY")}{" "}
                                    </strong>
                                  </Label>
                                  {incompleteActivities.map(activity => (
                                    <div
                                      key={activity.pk_lesson_activity_key}
                                      className="form-check"
                                    >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`activity-${activity.pk_lesson_activity_key}`}
                                        name="activities"
                                        value={activity.pk_lesson_activity_key}
                                        checked={validation1.values.activities.includes(
                                          activity.pk_lesson_activity_key
                                        )}
                                        onChange={event => {
                                          const value = event.target.value
                                          let next_activity_count =
                                            NextSessionData &&
                                            NextSessionData.activity_count !=
                                              null &&
                                            NextSessionData.activity_count <= 20
                                              ? 20 -
                                                parseInt(
                                                  NextSessionData.activity_count
                                                )
                                              : 0
                                          if (
                                            validation1.values.activities.includes(
                                              value
                                            )
                                          ) {
                                            const updatedActivities =
                                              validation1.values.activities.includes(
                                                value
                                              )
                                                ? validation1.values.activities.filter(
                                                    item => item !== value
                                                  )
                                                : [
                                                    ...validation1.values
                                                      .activities,
                                                    value,
                                                  ]
                                            validation1.setFieldValue(
                                              "activities",
                                              updatedActivities
                                            )
                                          } else if (
                                            validation1.values.activities
                                              .length < next_activity_count &&
                                            !validation1.values.activities.includes(
                                              value
                                            )
                                          ) {
                                            const updatedActivities =
                                              validation1.values.activities.includes(
                                                value
                                              )
                                                ? validation1.values.activities.filter(
                                                    item => item !== value
                                                  )
                                                : [
                                                    ...validation1.values
                                                      .activities,
                                                    value,
                                                  ]
                                            validation1.setFieldValue(
                                              "activities",
                                              updatedActivities
                                            )
                                          } else {
                                            toast.error(
                                              "Sorry, a maximum of 20 activities are allowed next session",
                                              {
                                                autoClose: 3000, // 3 seconds
                                              }
                                            )
                                          }
                                        }}
                                      />
                                      <label>
                                        {
                                          // Customize the displayed name based on the activity type and subject
                                          activity.activity_type === 0 &&
                                          [1, 2].includes(activity.fk_sub_id)
                                            ? activity.activity_node[0]
                                                ?.node_name || ""
                                            : activity.activity_type === 1 
                                            ? activity.activity_node[0]?.name ||
                                              ""
                                            : activity.activity_type === 2 &&
                                              [1, 2].includes(
                                                activity.fk_sub_id
                                              )
                                            ? getUniqueTopicsNames(
                                                activity?.topics
                                              )
                                            : activity.activity_type === 3 
                                            ? activity.activity_node[0]?.name ||
                                              ""
                                            : activity.activity_type === 4 
                                            ? activity.activity_node[0]?.name ||
                                              ""
                                            : activity.activity_type === 5 
                                            ? activity.activity_node[0]?.name ||
                                              ""
                                            : activity.activity_type === 6 &&
                                              [1, 2].includes(
                                                activity.fk_sub_id
                                              )
                                            ? activity.activity_node[0]
                                                ?.video_name || ""
                                            : activity.activity_type === 7 &&
                                              [1, 2].includes(
                                                activity.fk_sub_id
                                              )
                                            ? activity.activity_node[0]
                                                ?.node_name || ""
                                            : "Activity"
                                        }
                                      </label>
                                      <label>
                                        {" - "}Status :
                                        <span className="text-danger">
                                          {activity.status === 0
                                            ? "Incomplete"
                                            : activity.status === 1 &&
                                              parseInt(activity.mastryScore) <
                                                75
                                            ? "Score below 75%"
                                            : "Incomplete"}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              )
                            })
                          ) : null}
                          {validation1.touched.activities &&
                            validation1.errors.activities && (
                              <p className="text-danger">
                                {validation1.errors.activities}
                              </p>
                            )}

                          {ActivityPendingLoading === false ? (
                            <div>
                              {NextSessionData?.next_session &&
                              validation1.values.pk_student_key &&
                              sessionData &&
                              validation1.values.activity_method
                                ? sessionData.session.tbl_session_time.tbl_student_enrolment
                                    .filter(
                                      enroll =>
                                        enroll.fk_stu_key ===
                                        validation1.values.pk_student_key.value
                                    )
                                    .map(enroll => {
                                      // console.log(enroll)
                                      let subject_id =
                                        getUniqueStudentSubjectID(
                                          enroll.tbl_enrolment_subject
                                        ).join(",") || "1"
                                      //let subject_id = '1,2,4';

                                      //console.log(subject_id);

                                      let ids = subject_id
                                        .split(",")
                                        .map(Number)
                                        .filter(Boolean)

                                      // Check for any numbers other than 1 or 2
                                      let hasOtherNumbers = ids.some(
                                        id => id !== 1 && id !== 2
                                      )
                                      let subject = "1"
                                      let NextSubject = ""
                                      if (hasOtherNumbers) {
                                        // If there are numbers other than 1 or 2
                                        //console.log("Other numbers found");
                                        subject = "1"
                                        NextSubject = "2"
                                      } else {
                                        // If only 1 or 2 are present
                                        if (ids.length === 1) {
                                          subject = subject_id
                                        } else {
                                          subject = "1"
                                          NextSubject = "2"
                                          // console.log("1,2 found");
                                        }
                                      }
                                      return SessionStudentNextActivity.length ===
                                        0 ? (
                                        <div>
                                          {SessionStudentPendingActivity &&
                                          SessionStudentPendingActivity.length >
                                            0 ? null : (
                                            <div
                                              key={`no-activities`}
                                              className="alert alert-info mb-3 align-items-center"
                                            >
                                              No pending activities for this
                                              student.
                                            </div>
                                          )}
                                          <Loader
                                            show={
                                              ActivityNextLoading ||
                                              ActivitySubjectNextLoading
                                            }
                                            type="inline"
                                            spinnerSize="20px"
                                            spinnerStyle={{
                                              secondary: "#2D866D",
                                            }}
                                          />
                                          {!ActivityNextLoading &&
                                          !ActivitySubjectNextLoading ? null : (
                                            <div className="d-flex justify-content-center">
                                              <p>
                                                <strong>
                                                  <small>
                                                    Please Wait Automation Check
                                                    Next Activity...
                                                  </small>
                                                </strong>
                                              </p>
                                            </div>
                                          )}
                                          {/* <div className="d-flex justify-content-center">
                                   {(NextSessionData.activity_count + parseInt(TotalPendingActivity)) < 20 ? 
                                   <button  
                                   type="button"
                                   className="btn btn-danger"
                                   onClick={() => {
                                     if (NextSubject && NextSubject !== '') {
                                       fetchSessionStudentNextActivityData(
                                         validation1.values.pk_student_key,
                                         validation1.values.activity_method,
                                         NextSessionData.next_session,
                                         subject
                                       );
                                       fetchSessionStudentSubjectNextActivityData(
                                         validation1.values.pk_student_key,
                                         validation1.values.activity_method,
                                         NextSessionData.next_session,
                                         NextSubject
                                       );
                                     } else {
                                       fetchSessionStudentNextActivityData(
                                         validation1.values.pk_student_key,
                                         validation1.values.activity_method,
                                         NextSessionData.next_session,
                                         subject
                                       );
                                     }
                                   }}
                                 >
                                   Next Auto Activity
                                 </button>
                                  : null}
                                  </div> */}
                                        </div>
                                      ) : SessionStudentNextActivity &&
                                        SessionStudentNextActivity.length > 0 &&
                                        !ActivityNextLoading &&
                                        !ActivitySubjectNextLoading ? (
                                        // Check if there are no activities in SessiosNextActivity
                                        SessiosNextActivity.length === 0 ? (
                                          <p>
                                            <strong>
                                              {" "}
                                              Automation not found any
                                              activities{" "}
                                              <small>
                                                Because no activity has been
                                                assigned, or topic-based wise
                                                any activity has been not
                                                completed in past session, or
                                                next session maximum 20
                                                activitys reached
                                              </small>
                                            </strong>
                                          </p> // Display message if SessiosNextActivity is empty
                                        ) : (
                                          <>
                                            <p className="mb-3">
                                              <strong>
                                                Automation found below
                                                activities:{" "}
                                                <small>
                                                  Note: The activities for each
                                                  past session is based on the
                                                  initial topic. Ensure all
                                                  pendings activities are
                                                  completed before proceeding to
                                                  the get next activities and
                                                  also check next session
                                                  available space for assign
                                                  activities.{" "}
                                                </small>
                                              </strong>
                                            </p>
                                            {Object.keys(groupedByLevel).map(
                                              levelId => {
                                                const activitiesForLevel =
                                                  groupedByLevel[levelId]

                                                // Find the correct `activity_level_data` using the current `levelId`
                                                const levelData =
                                                  activitiesForLevel[0]
                                                    ?.activity_level_data
                                                const subjectData =
                                                  activitiesForLevel[0]
                                                    ?.activity_subject
                                                return (
                                                  <div
                                                    key={`activitiesData-${levelId}`}
                                                    className="mb-3"
                                                  >
                                                    <Label className="font-size-12 mb-1 pb-0">
                                                      <strong>
                                                        {levelData} (
                                                        {subjectData})
                                                      </strong>
                                                    </Label>
                                                    <div className="form-check">
                                                      {activitiesForLevel.length >
                                                      0 ? (
                                                        activitiesForLevel.map(
                                                          (activity, index) => (
                                                            <Label
                                                              className="font-size-12 mb-1 pb-0"
                                                              key={`activity-${activity.id}`}
                                                            >
                                                              <strong>
                                                                {
                                                                  activity.activity_topic_data
                                                                }
                                                              </strong>
                                                              : {activity.name}
                                                            </Label>
                                                          )
                                                        )
                                                      ) : (
                                                        <p>
                                                          Automation did not
                                                          find any activities.
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            )}
                                          </>
                                        )
                                      ) : null
                                    })
                                : null}
                            </div>
                          ) : // <div key={`no-activities`} className="alert alert-info">
                          //             No pending activities for this student.
                          //           </div>
                          null}
                          {/* {(NextSessionData?.next_session && validation1.values.pk_student_key && validation1.values.activity_method && (NextSessionData.activity_count + parseInt(TotalPendingActivity))) < 20 ? 
                             <div>
                                  {SessionStudentAutomationActivity && SessionStudentAutomationActivity.length <= 0 ? <div className="d-flex justify-content-center mt-3">
                                  <Loader
                                      show={ActivityNextLoading}
                                      type="inline"
                                      spinnerSize = "20px"
                                      spinnerStyle = {{
                                        secondary: '#2D866D'
                                    }}
                                    />
                                    <button  
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => {
                                        fetchSessionStudentFetureActivityData(
                                          validation1.values.pk_student_key,
                                          validation1.values.activity_method
                                        );
                                      }}
                                    >
                                      Next Auto Activity
                                    </button>
                                  </div> : null}
                                  {SessionStudentAutomationActivity && SessionStudentAutomationActivity.length > 0 ?
                                  (SessionStudentAutomationActivity.map(pastActivity => {
                                    //const levelName = yearLevel.level_name; // Get the level name
                                    const levelTopics = pastActivity.LevelTopics.filter(topic => topic.activity_topic_id == pastActivity.activity_topic_id) || []; // Access LevelTopics
                                    
                                  // Function to find the next activity
                                    function findNextActivity(activities, activity_node_id) {
                                      const currentIndex = activities.findIndex(activity => activity.activity_node_id === activity_node_id);

                                      // If activity_node_id is found, get the next activity, or wrap to the first
                                      if (currentIndex !== -1) {
                                          const nextIndex = (currentIndex + 1) % activities.length;
                                          return activities[nextIndex];
                                      } else {
                                          // If activity_node_id is not found, return null or handle as needed
                                          return null;
                                      }
                                    }

                                   
                                    // Iterate over each topic to log the activity count
                                    // Define array to collect JSX for each topic's next activities
                                      const topicElements = levelTopics.map(topic => {
                                        const activities = topic.TopicActivities || [];
                                        const nextActivity = findNextActivity(activities, pastActivity.activity_node_id);

                                        // Return JSX for the next activity if it exists
                                        return nextActivity ? (
                                            <div key={nextActivity.activity_node_id} className="form-check">
                                                <label>{nextActivity.node_name}</label>
                                            </div>
                                        ) : null;
                                    });
                                    return <div key={pastActivity.activity_node_id}>
                                      <div className="mb-3"><strong> Automation Found Activities</strong></div>{topicElements}</div>;
                                    // return(NextActivities.map(activityData => {
                                    //   <div key={activityData.activity_node_id} className="form-check">
                                    //      <label>
                                    //      {activityData.node_name}
                                    //      </label>
                                    //   </div>
                                    // }))
                                  }

                                  
                                 
                                ))
                                : null}
                                </div> : null} */}
                        </div>
                      </div>
                      <div className="text-end">
                        <div className="btn-group">
                          {!ActivityNextLoading &&
                          !ActivitySubjectNextLoading &&
                          validation1.values.activities.length > 0 &&
                          SessionStudentPendingActivity &&
                          SessionStudentPendingActivity.length > 0 ? (
                            <button
                              type="submit"
                              className="btn btn-danger"
                              disabled={isSubmitting}
                            >
                              Move
                            </button>
                          ) : null}

                          {!ActivityNextLoading &&
                          !ActivitySubjectNextLoading &&
                          TotalPendingActivity === 0 &&
                          SessiosNextActivity.length > 0 &&
                          NextSessionData.next_session ? (
                            <button
                              type="button"
                              className="btn btn-danger"
                              disabled={isSubmitting}
                              //onClick={()=>{OnSubmitActivityData(NextSessionData.next_session, validation1.values.pk_student_key, validation1.values.activity_method)}}
                              onClick={() => {
                                OnSubmitNavActivityAssign(
                                  NextSessionData.next_session,
                                  validation1.values.pk_student_key,
                                  validation1.values.activity_method
                                )
                              }}
                            >
                              Move
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </Form>
                  </ModalBody>
                </Modal>
                {startButton ? (
                  <div
                    className="ms-0 d-flex align-items-center aw-button"
                    color=""
                    onClick={tog_center}
                  >
                    {" "}
                    <span>
                      <img className="cs-mg" width={24} src={play} alt="" />
                    </span>{" "}
                    Start Session{" "}
                  </div>
                ) : null}

                {endButton ? (
                  <div
                    className="ms-0 d-flex align-items-center aw-button"
                    color=""
                    onClick={tog_session_end}
                  >
                    {" "}
                    <span>
                      <img className="cs-mg" width={24} src={record} alt="" />
                    </span>{" "}
                    End Session{" "}
                  </div>
                ) : null}
                {/* {sessionData && sessionData.session ? (
                  parseInt(sessionData.session.ses_status) === 0 &&
                    parseInt(sessionData.session.ses_is_completed) === 0 ? (
                    <div
                      className="ms-0 d-flex align-items-center aw-button"
                      color=""
                      onClick={tog_center}
                    >
                      {" "}
                      <span>
                        <img className="cs-mg" width={24} src={play} alt="" />
                      </span> Start Session{" "}
                    </div>
                  ) : parseInt(sessionData.session.ses_status) === 1 &&
                    parseInt(sessionData.session.ses_is_completed) === 0 ? (
                    <div
                      className="ms-0 d-flex align-items-center aw-button"
                      color=""
                      onClick={tog_session_end}
                    >
                      {" "}
                      <span>
                        <img className="cs-mg" width={24} src={record} alt="" />
                      </span> End Session{" "}
                    </div>
                  ) : null
                ) : null} */}

                <Modal isOpen={modal_center} toggle={tog_center} centered>
                  {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
                  <ModalBody className="text-center">
                    <div className="my-4">
                      <h5 className="mb-4">
                        Do you want to start the session?
                      </h5>
                      <Button
                        color="success"
                        disabled={SessionStart}
                        onClick={onSessionStart}
                      >
                        {" "}
                        Start Session{" "}
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={modal_session_end}
                  toggle={tog_session_end}
                  centered
                >
                  <ModalBody className="text-center">
                    <div className="my-4">
                      <h5 className="mb-4">Do you want to end the session?</h5>
                      <Button
                        color="success"
                        disabled={SessionEnd}
                        onClick={onSessionEnd}
                      >
                        {" "}
                        End Session{" "}
                      </Button>
                    </div>
                  </ModalBody>
                  {/* <Form
                    className=""
                    onSubmit={e => {
                      e.preventDefault()
                      formik.handleSubmit()
                      return false
                    }}
                    action="#"
                  >
                    
                    <ModalBody className="text-center">
                      <Table className="table mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>First Name</th>
                            <th>Status</th>
                            <th>Move to homework</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.students.map((student, index) => (
                            <tr key={index}>
                              <td>{student.student_name}</td>
                              <td>
                                <select
                                  className="form-control"
                                  name={`students.${index}.status`}
                                  value={student.status}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                >
                                  <option value="">Select</option>
                                  <option value="2">Attended</option>
                                  <option value="3">Not Attended</option>
                                </select>
                                {formik.touched.students &&
                                  formik.errors.students &&
                                  formik.errors.students[index] &&
                                  formik.errors.students[index].status && (
                                    <div className="error">
                                      {formik.errors.students[index].status}
                                    </div>
                                  )}
                              </td>
                              <td>
                                <div className="form-check mt-4">
                                  {student.pendingactivity !== 0 ? (
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`students.${index}.homework`}
                                      name={`students.${index}.homework`}
                                      checked={student.homework}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  ) : null}
                                  <label
                                    className="form-check-label mb-0 fw-medium"
                                    htmlFor={`students.${index}.homework`}
                                  >
                                    Move to Homework
                                  </label>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="my-4">
                       
                        <Button
                          color="success"
                          type="submit"
                          disabled={formik.isSubmitting}
                        >
                          End Session
                        </Button>
                      </div>
                      
                    </ModalBody>

                    
                  </Form> */}
                </Modal>

                <Offcanvas
                  isOpen={isRightStu}
                  direction="end"
                  toggle={toggleRightCanvasStu}
                >
                  <OffcanvasHeader
                    toggle={toggleRightCanvasStu}
                    className="border-bottom off-can-header align-items-start"
                  >
                    <div className="stu-detail-wrap ">
                      <div className="d-flex">
                        <div className="me-10 mt-5">
                          <img width={40} src={st1} alt="" />
                        </div>
                        <div className="">
                          <label className="student_label m-0">
                            {studentDetail &&
                            studentDetail.st_first_name &&
                            studentDetail.st_first_name != ""
                              ? studentDetail.st_first_name.concat(
                                  " " +
                                    (studentDetail.st_surname
                                      ? studentDetail.st_surname
                                      : "")
                                )
                              : null}
                          </label>
                          <div className="">
                            <label className="student_label2 font-size-18 m-0 d-flex align-items-center">
                              {studentDetail &&
                              studentDetail.activity_level &&
                              studentDetail.activity_level.level_name &&
                              studentDetail.activity_level.level_name !== ""
                                ? studentDetail.activity_level.level_name
                                : null}{" "}
                              <i className="fa fa-circle ms-1"> </i>{" "}
                              {studentDetail &&
                              studentDetail.tbl_school &&
                              studentDetail.tbl_school.sc_name &&
                              studentDetail.tbl_school.sc_name !== ""
                                ? studentDetail.tbl_school.sc_name
                                : null}
                            </label>
                          </div>
                          <div className="d-flex align-items-center">
                            <p className="student_label_id me-1 font-size-12">
                              Student ID{" "}
                              {studentDetail &&
                              studentDetail.pk_student_id &&
                              studentDetail.pk_student_id != ""
                                ? studentDetail.pk_student_id
                                : null}
                            </p>
                            <i className="fa fa-circle text-dark me-1"></i>
                            <p className="student_label_id font-size-12">
                              Joined{" "}
                              {studentDetail &&
                              studentDetail.enroll_Date &&
                              studentDetail.enroll_Date != ""
                                ? moment(
                                    studentDetail.enroll_Date,
                                    "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                  ).format("DD MMM YYYY")
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OffcanvasHeader>
                  <OffcanvasBody className="cstm-off-body">
                    <div className="white-contrast">
                      <div>
                        <label className="student_label_id">Next Session</label>
                      </div>
                      {studentDetail &&
                      studentDetail.next_session &&
                      studentDetail.next_session.ses_date != "" ? (
                        <>
                          <div className="d-flex align-items-start">
                            <div className="stud_date">
                              <label className="font-size-16 fw-700">
                                {studentDetail &&
                                studentDetail.next_session &&
                                studentDetail.next_session.ses_date != ""
                                  ? moment(
                                      studentDetail.next_session.ses_date,
                                      "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                    ).format("DD")
                                  : null}
                              </label>

                              <label className="student_label_id font-size-16">
                                {studentDetail &&
                                studentDetail.next_session &&
                                studentDetail.next_session.ses_date != ""
                                  ? moment(
                                      studentDetail.next_session.ses_date,
                                      "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                    ).format("MMM")
                                  : null}
                              </label>
                            </div>

                            <div class="d-flex flex-column">
                              <label className="student_label_id m-0 font-size-15">
                                {studentDetail &&
                                studentDetail.next_session &&
                                studentDetail.next_session.ses_start_time &&
                                studentDetail.next_session.ses_end_time
                                  ? (() => {
                                      const startMoment = moment(
                                        studentDetail.next_session
                                          .ses_start_time,
                                        "HH:mm"
                                      )
                                      const endMoment = moment(
                                        studentDetail.next_session.ses_end_time,
                                        "HH:mm"
                                      )
                                      const durationMinutes = endMoment.diff(
                                        startMoment,
                                        "minutes"
                                      )
                                      const formattedDuration = `(${durationMinutes} min)`
                                      return (
                                        startMoment.format("hh:mm A") +
                                        " - " +
                                        endMoment.format("hh:mm A") +
                                        " " +
                                        formattedDuration
                                      )
                                    })()
                                  : null}
                              </label>

                              <label className="m-0 fw-800 font-size-15">
                                {studentDetail &&
                                studentDetail.next_session &&
                                studentDetail.next_session.tbl_session_time &&
                                studentDetail.next_session.tbl_session_time
                                  .sest_name != ""
                                  ? studentDetail.next_session.tbl_session_time
                                      .sest_name
                                  : null}
                              </label>

                              <label className="student_label_id m-0">
                                {studentDetail &&
                                studentDetail.next_session &&
                                studentDetail.next_session.tbl_session_time &&
                                studentDetail.next_session.tbl_session_time
                                  .sest_is_holidays != ""
                                  ? studentDetail.next_session.tbl_session_time
                                      .sest_is_holidays == null ||
                                    studentDetail.next_session.tbl_session_time
                                      .sest_is_holidays == ""
                                    ? "-"
                                    : studentDetail.next_session
                                        .tbl_session_time.sest_is_holidays ==
                                      "1"
                                    ? "Recurring"
                                    : studentDetail.next_session
                                        .tbl_session_time.sest_is_holidays ==
                                      "2"
                                    ? "One Off Session"
                                    : studentDetail.next_session
                                        .tbl_session_time.sest_is_holidays ==
                                      "3"
                                    ? "Make Up Session"
                                    : "-"
                                  : null}{" "}
                                {studentDetail &&
                                studentDetail.next_session &&
                                studentDetail.next_session.tbl_session_time &&
                                studentDetail.next_session.tbl_session_time
                                  .tbl_student_enrolment &&
                                studentDetail.next_session.tbl_session_time
                                  .tbl_student_enrolment
                                  .tbl_subject_enrolment &&
                                studentDetail.next_session.tbl_session_time
                                  .tbl_student_enrolment.tbl_subject_enrolment
                                  .sub_name &&
                                studentDetail.next_session.tbl_session_time
                                  .tbl_student_enrolment.tbl_subject_enrolment
                                  .sub_name != ""
                                  ? studentDetail.next_session.tbl_session_time
                                      .tbl_student_enrolment
                                      .tbl_subject_enrolment.sub_name
                                  : null}{" "}
                                {studentDetail &&
                                studentDetail.next_session &&
                                studentDetail.next_session.total_student &&
                                studentDetail.next_session.total_student != ""
                                  ? `${studentDetail.next_session.total_student}-students`
                                  : null}
                              </label>
                            </div>
                          </div>
                          <label
                            className={`${
                              studentDetail &&
                              studentDetail.next_session &&
                              studentDetail.next_session.activity_count &&
                              studentDetail.next_session.activity_count !== ""
                                ? ""
                                : "cs-txt-danger font-size-12 mt-5 ms-0 mb-0"
                            }`}
                          >
                            {studentDetail &&
                            studentDetail.next_session &&
                            studentDetail.next_session.activity_count &&
                            studentDetail.next_session.activity_count != ""
                              ? `${studentDetail.next_session.activity_count} activities assigned`
                              : "No activities assigned"}
                          </label>
                        </>
                      ) : (
                        <p className="ml-3">No next session</p>
                      )}
                    </div>
                    <div className="lesson-wrapper">
                      <div className="bg-white d-flex lesson-div justify-content-between">
                        <h4
                          className="font-size-18 text-dark"
                          style={{ lineHeight: "24px" }}
                        >
                          View past lessons
                        </h4>
                        <button
                          type="button"
                          className="btn p-0"
                          onClick={togStudentPastSession}
                        >
                          <i className="fas fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>

                    {/* lesson tab */}

                    <div>
                      <Nav pills className="navtab-bg nav-justified lesson-nav">
                        <NavItem className="lesson-navitem">
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames("lesson-navlink", {
                              active: activeTab1 === "5",
                            })}
                            onClick={() => {
                              toggle1("5")
                            }}
                          >
                            Notes
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames("lesson-navlink", {
                              active: activeTab1 === "6",
                            })}
                            onClick={() => {
                              toggle1("6")
                            }}
                          >
                            Report
                          </NavLink>
                        </NavItem>
                        <NavItem className="tab-btn">
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames("lesson-navlink", {
                              active: activeTab1 === "7",
                            })}
                            onClick={() => {
                              toggle1("7")
                            }}
                          >
                            Contact
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab1 === "8",
              })}
              onClick={() => {
                toggle1("8");
              }}
            >
              Settings
            </NavLink>
          </NavItem> */}
                      </Nav>

                      <TabContent activeTab={activeTab1} className="text-muted">
                        <TabPane tabId="5" className="pd-10">
                          <div className="note-wrap">
                            <div>
                              <div>
                                <p>Lesson Notes</p>
                                <small>Add lesson notes for this session</small>
                              </div>
                              <div className="mt-2">
                                <Input
                                  type="textarea"
                                  id="textarea"
                                  onChange={e => {
                                    textareachange(e)
                                  }}
                                  maxLength="225"
                                  rows="3"
                                  onBlur={e => {
                                    noteaddfunction(e)
                                  }}
                                  value={lessonNote}
                                />
                              </div>
                            </div>
                            <div className="mt-2">
                              {LessonNoteDetail &&
                              LessonNoteDetail.length > 0 ? (
                                <>
                                  {LessonNoteDetail.map((notes, index) =>
                                    notes.notes_details != null ? (
                                      <div
                                        className="mt-2 mb-2 w-100 justify-content-between d-flex bg-white"
                                        key={index}
                                      >
                                        <div>
                                          <p>
                                            {" "}
                                            {moment(
                                              notes.created_on,
                                              moment.ISO_8601
                                            ).format("DD MMM YYYY (ddd)")}
                                          </p>
                                          <small>
                                            {moment(
                                              notes.ses_start_time,
                                              "HH:mm:ss"
                                            ).format("hh:mm A")}{" "}
                                            -{" "}
                                            {moment(
                                              notes.ses_end_time,
                                              "HH:mm:ss"
                                            ).format("hh:mm A")}
                                          </small>
                                          <br />
                                          <small>{notes.sest_name}</small>
                                        </div>
                                        <div>
                                          <div
                                            onClick={() =>
                                              delete_notes(notes.pk_notes_key)
                                            }
                                            style={{ cursor: "pointer" }}
                                            className="me-0"
                                          >
                                            x
                                          </div>
                                          <p className="me-2">
                                            {" "}
                                            {notes.notes_details}
                                          </p>
                                        </div>
                                      </div>
                                    ) : null
                                  )}
                                </>
                              ) : (
                                <span>No Lesson Notes</span>
                              )}
                            </div>
                            <label className="mt-5 font-size-18 fw-700 mb-0">
                              Other Notes
                            </label>
                            <div className="mt-5 w-100">
                              <p className="bg-white pd-10 font-size-10">
                                {studentDetail &&
                                studentDetail.la_notes &&
                                studentDetail.la_notes &&
                                studentDetail.la_notes !== ""
                                  ? studentDetail.la_notes
                                  : "No Data"}
                              </p>
                            </div>
                            <label className="mt-5 font-size-18 fw-700 mb-0">
                              Goals
                            </label>
                            <div className="mt-5 ">
                              <p className="bg-white bg-white pd-10 w-100 font-size-10">
                                {studentDetail &&
                                studentDetail.la_goals &&
                                studentDetail.la_goals &&
                                studentDetail.la_goals !== ""
                                  ? studentDetail.la_goals
                                  : "No Data"}
                              </p>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="6" className="bg-white">
                          <div className="report-wrap pd-10">
                            <div>
                              <label className="student_label2 font-size-18 fw-700">
                                {mastryScore &&
                                mastryScore.level_name &&
                                mastryScore.level_name != ""
                                  ? mastryScore.level_name
                                  : ""}{" "}
                                <i className="fa fa-circle"></i>{" "}
                                {mastryScore &&
                                mastryScore.subject_name &&
                                mastryScore.subject_name != ""
                                  ? mastryScore.subject_name
                                  : ""}
                                Curriculum
                              </label>
                            </div>
                            <div className=""></div>
                            <div class="studentdetails-report">
                              <div class="studentdetails-report-values">
                                <label>Classes Attended</label>
                                <span>
                                  {mastryScore &&
                                  mastryScore.attended_session &&
                                  mastryScore.attended_session != ""
                                    ? mastryScore.attended_session
                                    : 0}
                                </span>
                              </div>
                              <div class="studentdetails-report-values">
                                <label>Activities Completed</label>
                                <span>
                                  {mastryScore &&
                                  mastryScore.attended_session &&
                                  mastryScore.attended_session != ""
                                    ? mastryScore.attended_session
                                    : 0}
                                </span>
                              </div>
                            </div>

                            <div className="mt-2">
                              <label className="student_label2 font-size-18 fw-700">
                                Topic mastery{" "}
                                <span onClick={tog_center1}>
                                  <i className="font-size-14 far fa-question-circle"></i>
                                </span>
                                <Modal
                                  className="mastery-modal"
                                  isOpen={modal_center1}
                                  toggle={tog_center1}
                                  centered
                                >
                                  <ModalHeader
                                    className="mt-0 text-dark"
                                    toggle={tog_center1}
                                  >
                                    Topic mastery
                                  </ModalHeader>
                                  <ModalBody className="mastery-modal_body">
                                    <p style={{ marginBottom: "1rem" }}>
                                      A mastery score is an integer from 0 - 100
                                      that indicates a student's competence in a
                                      particular topic at a particular year
                                      level. A mastery score is always given
                                      with a (Year Level) and a (Topic). This
                                      year level and topic is obtained from the
                                      Mastery Tree in LCMS.
                                    </p>
                                    <div
                                      className="good-wrapper"
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      <p className="state-good fw-700">
                                        High mastery
                                      </p>
                                      <p className="state-good ">
                                        Mastery score of more than 70
                                      </p>
                                      <p>
                                        Your child is proficient at this topic
                                        and can move on to new topics.
                                      </p>
                                    </div>
                                    <div
                                      className="warning-wrapper"
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      <p className="state-warning fw-700">
                                        Mid mastery
                                      </p>
                                      <p className="state-warning ">
                                        Mastery score of 30 - 69
                                      </p>
                                      <p>
                                        Your child is getting the hang of these
                                        topics and should practice more to get
                                        better at it!
                                      </p>
                                    </div>
                                    <div
                                      className="critical-wrapper"
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      <p className="state-critical fw-700">
                                        Low mastery
                                      </p>
                                      <p
                                        className="state-critical"
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        Mastery score of less than 30
                                      </p>
                                      <p>
                                        Your child might be new to the topic and
                                        would need time to grasp the fundamental
                                        concepts.!
                                      </p>
                                    </div>
                                    <div
                                      className="disabled-wrapper"
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      <p className="state-secondary fw-700">
                                        No score
                                      </p>
                                      <p>
                                        Your child will need to complete more
                                        questions in that topic to generate a
                                        mastery score.
                                      </p>
                                    </div>
                                    <div
                                      className="child-wrapper"
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      <p className="state-secondary fw-700 text-dark">
                                        How is the mastery score calculated?{" "}
                                      </p>
                                      <p>
                                        Formula: (No of Q correct on 1st try) /
                                        (Total no of Q in Year Level + Topic) *
                                        100%
                                      </p>
                                    </div>
                                    <p style={{ marginBottom: "1rem" }}>
                                      Rounding: To nearest whole integer
                                    </p>
                                  </ModalBody>
                                  <ModalFooter></ModalFooter>
                                </Modal>
                              </label>
                            </div>

                            <p className="d-flex flex-column text-center font-size-15 mt-5">
                              No of Topics
                              <span className="font-size-15 fw-700 text-dark">
                                {topicData &&
                                topicData.total_level &&
                                topicData.total_level != ""
                                  ? topicData.total_level
                                  : 0}
                              </span>
                            </p>

                            <div className="mt-5">
                              <Progress multi>
                                <Progress
                                  bar
                                  className="high_mastery"
                                  value={25}
                                />

                                <Progress
                                  bar
                                  className="medium_mastery"
                                  value={25}
                                />

                                <Progress
                                  bar
                                  className="low_mastery"
                                  value={25}
                                />
                                <Progress bar className="noscore" value={25} />
                              </Progress>

                              <div className="row">
                                <div className="col-3 text-center">
                                  <label
                                    className="topic_mas_label mt-1 font-size-15"
                                    style={{ color: "#63d3a6" }}
                                  >
                                    High mastery
                                  </label>
                                  <p className="topic_mas_para">
                                    <b className="font-size-15">
                                      {topicData &&
                                      topicData.high_level &&
                                      topicData.high_level != ""
                                        ? topicData.high_level
                                        : 0}
                                    </b>{" "}
                                    topic
                                  </p>
                                </div>
                                <div className="col-3 text-center">
                                  <label
                                    className="topic_mas_label mt-1 font-size-15"
                                    style={{ color: "#f6911e" }}
                                  >
                                    Medium mastery
                                  </label>
                                  <p className="topic_mas_para">
                                    <b className="font-size-15">
                                      {topicData &&
                                      topicData.mid_level &&
                                      topicData.mid_level != ""
                                        ? topicData.mid_level
                                        : 0}
                                    </b>{" "}
                                    topic
                                  </p>
                                </div>
                                <div className="col-3 text-center">
                                  <label
                                    className="topic_mas_label mt-1 font-size-15"
                                    style={{ color: "#fb0000" }}
                                  >
                                    Low mastery
                                  </label>
                                  <p className="topic_mas_para">
                                    <b className="font-size-15">
                                      {topicData &&
                                      topicData.low_level &&
                                      topicData.low_level != ""
                                        ? topicData.low_level
                                        : 0}
                                    </b>{" "}
                                    topic
                                  </p>
                                </div>
                                <div className="col-3 text-center">
                                  <label
                                    className="topic_mas_label mt-1 font-size-15"
                                    style={{ color: "#b3b3b3" }}
                                  >
                                    No score
                                  </label>
                                  <p className="topic_mas_para">
                                    <b className="font-size-15">
                                      {topicData &&
                                      topicData.no_score_level &&
                                      topicData.no_score_level != ""
                                        ? topicData.no_score_level
                                        : 0}
                                    </b>{" "}
                                    topic
                                  </p>
                                </div>
                              </div>

                              <button
                                className="btn report-btn"
                                onClick={toggleRightCanvasStuTopic}
                              >
                                All topics{" "}
                                <i className="fas fa-arrow-right"></i>{" "}
                              </button>
                              {mastryScore &&
                              mastryScore.mistryScore &&
                              mastryScore.mistryScore.length > 0 ? (
                                <>
                                  {" "}
                                  <h5 className="mt-1 font-size-18 text-dark">
                                    Topics practiced in the past month
                                  </h5>
                                  <div className="justify-content-right h-30 w-98">
                                    <Pie
                                      width={98}
                                      height={30}
                                      data={pie_mastry_data}
                                      options={options_pie_mastry}
                                      style={{
                                        height: "270px",
                                        width: "560px",
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <h5 className="font-size-18 text-dark">
                                      Improve their mastery score
                                    </h5>
                                    <p className="mb-1 font-size-15 text-dark">
                                      Practice these topics more
                                    </p>
                                    <div className="justify-content-between d-flex">
                                      <p className="text-uppercase font-size-12 fw-700 luminous-wht mb-2">
                                        TOPIC
                                      </p>
                                      <p className="text-uppercase font-size-12 fw-700 luminous-wht mt-0 mb-2">
                                        MASTERY SCORE
                                      </p>
                                    </div>
                                    {mastryScore.mistryScore.map(activity => {
                                      if (
                                        activity.activity_type === 0 &&
                                        (activity.fk_sub_id === 1 ||
                                          activity.fk_sub_id === 2)
                                      ) {
                                        return (
                                          <div className="justify-content-between d-flex">
                                            <div className="font-size-15 text-dark w-70 mb-1">
                                              {activity.activity_node[0]
                                                .node_name != ""
                                                ? activity.activity_node[0]
                                                    .node_name
                                                : null}
                                            </div>
                                            <div
                                              className={`font-size-15 ${
                                                activity.masteryscore >= 70
                                                  ? "text-success"
                                                  : activity.masteryscore >=
                                                      30 &&
                                                    activity.masteryscore <= 69
                                                  ? "text-warning"
                                                  : activity.masteryscore <=
                                                      30 &&
                                                    activity.masteryscore >= 1
                                                  ? "text-danger"
                                                  : ""
                                              }`}
                                            >
                                              {activity.masteryscore != "" &&
                                              activity.masteryscore != ""
                                                ? activity.masteryscore
                                                : "No Score"}
                                              <span>
                                                <i
                                                  className="mdi mdi-chevron-right"
                                                  onClick={() => {
                                                    toggleRightCanvasTopicGraph()
                                                    setTopicId(
                                                      activity.ms_topic_id
                                                    )
                                                    setNodeId(
                                                      activity.fk_activity_node_id
                                                    )
                                                  }}
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      } else if (
                                        activity.activity_type === 1 
                                      ) {
                                        return (
                                          <div className="justify-content-between d-flex">
                                            <div>
                                              {activity.activity_node[0].name !=
                                              ""
                                                ? activity.activity_node[0].name
                                                : null}
                                            </div>
                                            <div
                                              className={`ms-3 ${
                                                activity.masteryscore >= 70
                                                  ? "text-success"
                                                  : activity.masteryscore >=
                                                      30 &&
                                                    activity.masteryscore <= 69
                                                  ? "text-warning"
                                                  : activity.masteryscore <=
                                                      30 &&
                                                    activity.masteryscore >= 1
                                                  ? "text-danger"
                                                  : ""
                                              }`}
                                            >
                                              {activity.masteryscore != "" &&
                                              activity.masteryscore != ""
                                                ? activity.masteryscore
                                                : "No Score"}
                                              <span>
                                                <i
                                                  className="mdi mdi-chevron-right"
                                                  onClick={() => {
                                                    toggleRightCanvasTopicGraph()
                                                    setTopicId(
                                                      activity.ms_topic_id
                                                    )
                                                    setNodeId(
                                                      activity.fk_activity_node_id
                                                    )
                                                  }}
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      } else if (
                                        activity.activity_type === 2 &&
                                        (activity.fk_sub_id === 1 ||
                                          activity.fk_sub_id === 2)
                                      ) {
                                        return (
                                          <div className="justify-content-between d-flex">
                                            <div>
                                              {activity.activity_node[0]
                                                .topic_name != ""
                                                ? activity.activity_node[0]
                                                    .topic_name
                                                : null}
                                            </div>
                                            <div
                                              className={`ms-3 ${
                                                activity.masteryscore >= 70
                                                  ? "text-success"
                                                  : activity.masteryscore >=
                                                      30 &&
                                                    activity.masteryscore <= 69
                                                  ? "text-warning"
                                                  : activity.masteryscore <=
                                                      30 &&
                                                    activity.masteryscore >= 1
                                                  ? "text-danger"
                                                  : ""
                                              }`}
                                            >
                                              {activity.masteryscore != "" &&
                                              activity.masteryscore != ""
                                                ? activity.masteryscore
                                                : "No Score"}
                                              <span>
                                                <i
                                                  className="mdi mdi-chevron-right"
                                                  onClick={() => {
                                                    toggleRightCanvasTopicGraph()
                                                    setTopicId(
                                                      activity.ms_topic_id
                                                    )
                                                    setNodeId(
                                                      activity.fk_activity_node_id
                                                    )
                                                  }}
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      } else if (
                                        activity.activity_type === 3 
                                      ) {
                                        return (
                                          <div className="justify-content-between d-flex">
                                            <div>
                                              {activity.activity_node[0].name !=
                                              ""
                                                ? activity.activity_node[0].name
                                                : null}
                                            </div>
                                            <div
                                              className={`ms-3 ${
                                                activity.masteryscore >= 70
                                                  ? "text-success"
                                                  : activity.masteryscore >=
                                                      30 &&
                                                    activity.masteryscore <= 69
                                                  ? "text-warning"
                                                  : activity.masteryscore <=
                                                      30 &&
                                                    activity.masteryscore >= 1
                                                  ? "text-danger"
                                                  : ""
                                              }`}
                                            >
                                              {activity.masteryscore != "" &&
                                              activity.masteryscore != ""
                                                ? activity.masteryscore
                                                : "No Score"}
                                              <span>
                                                <i
                                                  className="mdi mdi-chevron-right"
                                                  onClick={() => {
                                                    toggleRightCanvasTopicGraph()
                                                    setTopicId(
                                                      activity.ms_topic_id
                                                    )
                                                    setNodeId(
                                                      activity.fk_activity_node_id
                                                    )
                                                  }}
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      } else if (
                                        activity.activity_type === 4 
                                      ) {
                                        return (
                                          <div className="justify-content-between d-flex">
                                            <div>
                                              {activity.activity_node[0].name !=
                                              ""
                                                ? activity.activity_node[0].name
                                                : null}
                                            </div>
                                            <div
                                              className={`ms-3 ${
                                                activity.masteryscore >= 70
                                                  ? "text-success"
                                                  : activity.masteryscore >=
                                                      30 &&
                                                    activity.masteryscore <= 69
                                                  ? "text-warning"
                                                  : activity.masteryscore <=
                                                      30 &&
                                                    activity.masteryscore >= 1
                                                  ? "text-danger"
                                                  : ""
                                              }`}
                                            >
                                              {activity.masteryscore != "" &&
                                              activity.masteryscore != ""
                                                ? activity.masteryscore
                                                : "No Score"}
                                              <span>
                                                <i
                                                  className="mdi mdi-chevron-right"
                                                  onClick={() => {
                                                    toggleRightCanvasTopicGraph()
                                                    setTopicId(
                                                      activity.ms_topic_id
                                                    )
                                                    setNodeId(
                                                      activity.fk_activity_node_id
                                                    )
                                                  }}
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      } else if (
                                        activity.activity_type === 5 
                                      ) {
                                        return (
                                          <div className="justify-content-between d-flex">
                                            <div>
                                              {activity.activity_node[0].name !=
                                              ""
                                                ? activity.activity_node[0].name
                                                : null}
                                            </div>
                                            <div
                                              className={`ms-3 ${
                                                activity.masteryscore >= 70
                                                  ? "text-success"
                                                  : activity.masteryscore >=
                                                      30 &&
                                                    activity.masteryscore <= 69
                                                  ? "text-warning"
                                                  : activity.masteryscore <=
                                                      30 &&
                                                    activity.masteryscore >= 1
                                                  ? "text-danger"
                                                  : ""
                                              }`}
                                            >
                                              {activity.masteryscore != "" &&
                                              activity.masteryscore != ""
                                                ? activity.masteryscore
                                                : "No Score"}
                                              <span>
                                                <i
                                                  className="mdi mdi-chevron-right"
                                                  onClick={() => {
                                                    toggleRightCanvasTopicGraph()
                                                    setTopicId(
                                                      activity.ms_topic_id
                                                    )
                                                    setNodeId(
                                                      activity.fk_activity_node_id
                                                    )
                                                  }}
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      } else if (
                                        activity.activity_type === 6 &&
                                        activity.fk_sub_id === 1
                                      ) {
                                        return (
                                          <div className="justify-content-between d-flex">
                                            <div>
                                              {activity.activity_node[0]
                                                .video_name != ""
                                                ? activity.activity_node[0]
                                                    .video_name
                                                : null}
                                            </div>
                                            <div
                                              className={`ms-3 ${
                                                activity.masteryscore >= 70
                                                  ? "text-success"
                                                  : activity.masteryscore >=
                                                      30 &&
                                                    activity.masteryscore <= 69
                                                  ? "text-warning"
                                                  : activity.masteryscore <=
                                                      30 &&
                                                    activity.masteryscore >= 1
                                                  ? "text-danger"
                                                  : ""
                                              }`}
                                            >
                                              {activity.masteryscore != "" &&
                                              activity.masteryscore != ""
                                                ? activity.masteryscore
                                                : "No Score"}
                                              <span>
                                                <i
                                                  className="mdi mdi-chevron-right"
                                                  onClick={() => {
                                                    toggleRightCanvasTopicGraph()
                                                    setTopicId(
                                                      activity.ms_topic_id
                                                    )
                                                    setNodeId(
                                                      activity.fk_activity_node_id
                                                    )
                                                  }}
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      } else if (
                                        activity.activity_type === 6 &&
                                        activity.fk_sub_id === 2
                                      ) {
                                        return (
                                          <div className="justify-content-between d-flex">
                                            <div>
                                              {activity.activity_node[0]
                                                .video_name != ""
                                                ? activity.activity_node[0]
                                                    .video_name
                                                : null}
                                            </div>
                                            <div
                                              className={`ms-3 ${
                                                activity.masteryscore >= 70
                                                  ? "text-success"
                                                  : activity.masteryscore >=
                                                      30 &&
                                                    activity.masteryscore <= 69
                                                  ? "text-warning"
                                                  : activity.masteryscore <=
                                                      30 &&
                                                    activity.masteryscore >= 1
                                                  ? "text-danger"
                                                  : ""
                                              }`}
                                            >
                                              {activity.masteryscore != "" &&
                                              activity.masteryscore != ""
                                                ? activity.masteryscore
                                                : "No Score"}
                                              <span>
                                                <i
                                                  className="mdi mdi-chevron-right"
                                                  onClick={() => {
                                                    toggleRightCanvasTopicGraph()
                                                    setTopicId(
                                                      activity.ms_topic_id
                                                    )
                                                    setNodeId(
                                                      activity.fk_activity_node_id
                                                    )
                                                  }}
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      } else if (
                                        activity.activity_type === 7 &&
                                        (activity.fk_sub_id === 1 ||
                                          activity.fk_sub_id === 2)
                                      ) {
                                        return (
                                          <div className="justify-content-between d-flex">
                                            <div>
                                              {activity.activity_node[0]
                                                .file_name != ""
                                                ? activity.activity_node[0]
                                                    .file_name
                                                : null}
                                            </div>
                                            <div
                                              className={`ms-3 ${
                                                activity.masteryscore >= 70
                                                  ? "text-success"
                                                  : activity.masteryscore >=
                                                      30 &&
                                                    activity.masteryscore <= 69
                                                  ? "text-warning"
                                                  : activity.masteryscore <=
                                                      30 &&
                                                    activity.masteryscore >= 1
                                                  ? "text-danger"
                                                  : ""
                                              }`}
                                            >
                                              {activity.masteryscore != "" &&
                                              activity.masteryscore != ""
                                                ? activity.masteryscore
                                                : "No Score"}
                                              <span>
                                                <i
                                                  className="mdi mdi-chevron-right"
                                                  onClick={() => {
                                                    toggleRightCanvasTopicGraph()
                                                    setTopicId(
                                                      activity.ms_topic_id
                                                    )
                                                    setNodeId(
                                                      activity.fk_activity_node_id
                                                    )
                                                  }}
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      } else {
                                        return null // or any other JSX you want to render for other activity types
                                      }
                                    })}
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="7">
                          <div class="contact-wrap">
                            <div class="contact-stu-det mb-10">
                              <label className="mb-10 font-size-18 text-dark">
                                Student Details
                              </label>
                              <div class="contact-stu-value">
                                <label className="font-size-15 fw-400">
                                  Gender Identity
                                </label>
                                <span className="font-size-15 fw-700">
                                  {studentDetail &&
                                  studentDetail.st_gender &&
                                  studentDetail.st_gender &&
                                  studentDetail.st_gender !== ""
                                    ? studentDetail.st_gender
                                    : null}
                                </span>
                              </div>
                              <div class="contact-stu-value">
                                <label className="font-size-15 fw-400">
                                  Date of birth
                                </label>
                                <span className="font-size-15 fw-700">
                                  {studentDetail &&
                                  studentDetail.st_dob &&
                                  studentDetail.st_dob !== ""
                                    ? moment(
                                        studentDetail.st_dob,
                                        moment.ISO_8601
                                      ).format("DD MMM YYYY")
                                    : null}
                                </span>
                              </div>
                              <div class="contact-stu-value">
                                <label className="font-size-15 fw-400">
                                  School
                                </label>
                                <span className="font-size-15 fw-700">
                                  {studentDetail &&
                                  studentDetail.fk_sc_id &&
                                  studentDetail.fk_sc_id &&
                                  studentDetail.fk_sc_id !== ""
                                    ? studentDetail.fk_sc_id
                                    : null}
                                </span>
                              </div>
                              <div class="contact-stu-value">
                                <label className="font-size-15 fw-400">
                                  Email
                                </label>
                                <span className="font-size-15 fw-700">
                                  {studentDetail &&
                                  studentDetail.st_email &&
                                  studentDetail.st_email &&
                                  studentDetail.st_email !== ""
                                    ? studentDetail.st_email
                                    : null}
                                </span>
                              </div>
                              <div class="contact-stu-value">
                                <label className="font-size-15 fw-400">
                                  Mobile
                                </label>
                                <span className="font-size-15 fw-700">
                                  {studentDetail &&
                                  studentDetail.st_phone &&
                                  studentDetail.st_phone !== ""
                                    ? studentDetail.st_phone
                                    : null}
                                </span>
                              </div>
                              <div class="contact-stu-value">
                                <label className="font-size-15 fw-400">
                                  Country
                                </label>
                                <span className="font-size-15 fw-700">
                                  {studentDetail &&
                                  studentDetail.country_name &&
                                  studentDetail.country_name &&
                                  studentDetail.country_name !== ""
                                    ? studentDetail.country_name
                                    : null}
                                </span>
                              </div>
                            </div>
                            <div class="contact-stu-det">
                              <label className=" mb-10 font-size-18 text-dark">
                                Contact Details
                              </label>
                              <div class="contact-stu-value">
                                <label className="font-size-15 fw-400">
                                  First name
                                </label>
                                <span className="font-size-15 fw-700">
                                  {studentDetail &&
                                  studentDetail.tbl_parstu_relation &&
                                  studentDetail.tbl_parstu_relation
                                    .tbl_parent &&
                                  studentDetail.tbl_parstu_relation.tbl_parent
                                    .par_firstname &&
                                  studentDetail.tbl_parstu_relation.tbl_parent
                                    .par_firstname !== ""
                                    ? studentDetail.tbl_parstu_relation
                                        .tbl_parent.par_firstname
                                    : null}
                                </span>
                              </div>
                              <div class="contact-stu-value">
                                <label className="font-size-15 fw-400">
                                  Last name
                                </label>
                                <span className="font-size-15 fw-700">
                                  {studentDetail &&
                                  studentDetail.tbl_parstu_relation &&
                                  studentDetail.tbl_parstu_relation
                                    .tbl_parent &&
                                  studentDetail.tbl_parstu_relation.tbl_parent
                                    .par_surname &&
                                  studentDetail.tbl_parstu_relation.tbl_parent
                                    .par_surname !== ""
                                    ? studentDetail.tbl_parstu_relation
                                        .tbl_parent.par_surname
                                    : null}
                                </span>
                              </div>
                              <div class="contact-stu-value">
                                <label className="font-size-15 fw-400">
                                  Relationship
                                </label>
                                <span className="font-size-15 fw-700">
                                  {studentDetail &&
                                  studentDetail.tbl_parstu_relation &&
                                  studentDetail.tbl_parstu_relation
                                    .ps_relationship !== ""
                                    ? studentDetail.tbl_parstu_relation
                                        .ps_relationship
                                    : null}
                                </span>
                              </div>
                              <div class="contact-stu-value">
                                <label className="font-size-15 fw-400">
                                  Mobile
                                </label>
                                <span className="font-size-15 fw-700">
                                  {studentDetail &&
                                  studentDetail.tbl_parstu_relation &&
                                  studentDetail.tbl_parstu_relation
                                    .tbl_parent &&
                                  studentDetail.tbl_parstu_relation.tbl_parent
                                    .par_phone &&
                                  studentDetail.tbl_parstu_relation.tbl_parent
                                    .par_phone !== ""
                                    ? studentDetail.tbl_parstu_relation
                                        .tbl_parent.par_phone
                                    : null}
                                </span>
                              </div>
                              {/* <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                Work
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.tbl_parstu_relation &&
                                studentDetail.tbl_parstu_relation.tbl_parent &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_phone &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_phone !== ""
                                  ? studentDetail.tbl_parstu_relation.tbl_parent
                                      .par_phone
                                  : null}
                              </span>
                            </div> */}
                              <div class="contact-stu-value">
                                <label className="font-size-15 fw-400">
                                  Email
                                </label>
                                <span className="font-size-15 fw-700">
                                  {studentDetail &&
                                  studentDetail.tbl_parstu_relation &&
                                  studentDetail.tbl_parstu_relation
                                    .tbl_parent &&
                                  studentDetail.tbl_parstu_relation.tbl_parent
                                    .par_emailid &&
                                  studentDetail.tbl_parstu_relation.tbl_parent
                                    .par_emailid !== ""
                                    ? studentDetail.tbl_parstu_relation
                                        .tbl_parent.par_emailid
                                    : null}
                                </span>
                              </div>
                            </div>
                          </div>
                        </TabPane>

                        {/* <TabPane tabId="8">
            <Row>
              <Col sm="12">
                <p className="mb-0">
                  Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan DIY, art party locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh DIY salvia PBR, banh mi before they sold out farm-to-table VHS viral locavore cosby sweater. Lomo wolf viral, mustache readymade thundercats keffiyeh craft beer marfa ethical. Wolf salvia freegan, sartorial keffiyeh echo park vegan.
                </p>
              </Col>
            </Row>
          </TabPane> */}
                      </TabContent>
                    </div>
                  </OffcanvasBody>
                </Offcanvas>

                <Offcanvas
                  isOpen={isRightStuTopic}
                  direction="end"
                  toggle={toggleRightCanvasStuTopic}
                >
                  <OffcanvasHeader
                    toggle={toggleRightCanvasStuTopic}
                    className="border-bottom off-can-header cp-2"
                  >
                    <div
                      className="d-flex align-items-center"
                      onClick={toggleRightCanvasStuTopic}
                    >
                      <div className="short-ic">
                        <img className="bc-bg" src={chevleft} />
                      </div>
                      <p className="student_label_id font-size-12 ">Back</p>
                    </div>
                  </OffcanvasHeader>
                  <OffcanvasBody className="p-0">
                    <div className="alltopic-wrap">
                      <div className="white-contrast p-0">
                        <div
                          className=""
                          style={{ padding: "10px", paddingBottom: "0" }}
                        >
                          <label className="student_label2 font-size-12 fw-700 m-0">
                            View all topics
                          </label>
                        </div>
                        <div className="d-flex pd-10 align-items-center">
                          <div className="me-10">
                            <img src={sort} />
                          </div>
                          <div>
                            <Select
                              className="stud_level cp-wd me-20"
                              value={optionGroup.find(
                                option => option.value == selectedYearLevelTopic
                              )}
                              onChange={selectedOption => {
                                handleSelectYearLevelTopic(
                                  selectedOption["value"]
                                )
                              }}
                              options={[
                                { label: "All", value: "all" },
                                ...levelOption
                                  .sort((a, b) => a.localeCompare(b))
                                  .map(level => ({
                                    label: level,
                                    value: level,
                                  })),
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                          <div>
                            <Select
                              className="masterScore_level cp-wd"
                              value={optionMastryScore.find(
                                option => option.value == selectedMastryScore
                              )}
                              onChange={selectedOption => {
                                handleSelectMastryScore(selectedOption["value"])
                              }}
                              options={optionMastryScore}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </div>
                      </div>

                      {MistryTopic.length > 0 ? (
                        MistryTopic.map(activity => {
                          if (
                            activity.activity_type === 0 &&
                            (activity.fk_sub_id === 1 ||
                              activity.fk_sub_id === 2) &&
                            activity.activity_node.length > 0
                          ) {
                            return (
                              <div
                                className="bg-white"
                                style={{ borderBottom: "1px solid #b5c4d7" }}
                              >
                                <div className="inner-pd pd-10">
                                  <div className="justify-content-between d-flex align-items-center">
                                    <div>
                                      <h5
                                        className="font-size-18 fw-700 text-dark"
                                        style={{ lineHeight: "24px" }}
                                      >
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].node_name !=
                                          ""
                                          ? activity.activity_node[0].node_name
                                          : null}
                                      </h5>
                                      <p className="mb-0 font-size-15 fw-700 text-dark">
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].level_name &&
                                        activity.activity_node[0].level_name !=
                                          ""
                                          ? activity.activity_node[0].level_name
                                          : null}
                                      </p>{" "}
                                    </div>
                                    <strong
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleRightCanvasTopicGraph()
                                        setTopicId(activity.ms_topic_id)
                                        setNodeId(activity.fk_activity_node_id)
                                      }}
                                    >
                                      {" "}
                                      <i className=" fas fa-angle-right"></i>
                                    </strong>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Questions Completed
                                    </p>{" "}
                                    <p className="m-0 font-size-15 fw-700 text-dark">
                                      {activity.question_attended != "" &&
                                      activity.question_attended != ""
                                        ? parseInt(activity.question_attended)
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="justify-content-between d-flex mt-2">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Current mastery score
                                    </p>{" "}
                                    <p
                                      className={` m-0 ${
                                        activity.masteryscore >= 70
                                          ? "text-success"
                                          : activity.masteryscore >= 30 &&
                                            activity.masteryscore <= 69
                                          ? "text-warning"
                                          : activity.masteryscore <= 30 &&
                                            activity.masteryscore >= 1
                                          ? "text-danger"
                                          : ""
                                      }`}
                                    >
                                      {activity.masteryscore != "" &&
                                      activity.masteryscore != ""
                                        ? activity.masteryscore
                                        : "No Score"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 1  &&
                            activity.activity_node.length > 0
                          ) {
                            return (
                              <div
                                className="bg-white"
                                style={{ borderBottom: "1px solid #b5c4d7" }}
                              >
                                <div className="inner-pd pd-10">
                                  <div className="justify-content-between d-flex align-items-center">
                                    <div>
                                      <h5
                                        className="font-size-18 fw-700 text-dark"
                                        style={{ lineHeight: "24px" }}
                                      >
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].name != ""
                                          ? activity.activity_node[0].name
                                          : null}
                                      </h5>
                                      <p className="mb-0 font-size-15 fw-700 text-dark">
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].level_name &&
                                        activity.activity_node[0].level_name !=
                                          ""
                                          ? activity.activity_node[0].level_name
                                          : null}
                                      </p>{" "}
                                    </div>
                                    <strong
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleRightCanvasTopicGraph()
                                        setTopicId(activity.ms_topic_id)
                                        setNodeId(activity.fk_activity_node_id)
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-angle-right"></i>
                                    </strong>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Questions Completed
                                    </p>{" "}
                                    <p className="m-0 font-size-15 fw-700 text-dark">
                                      {activity.question_attended != "" &&
                                      activity.question_attended != ""
                                        ? parseInt(activity.question_attended)
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Current mastery score
                                    </p>{" "}
                                    <p
                                      className={`me-0 ${
                                        activity.masteryscore >= 70
                                          ? "text-success"
                                          : activity.masteryscore >= 30 &&
                                            activity.masteryscore <= 69
                                          ? "text-warning"
                                          : activity.masteryscore <= 30 &&
                                            activity.masteryscore >= 1
                                          ? "text-danger"
                                          : ""
                                      }`}
                                    >
                                      {activity.masteryscore != "" &&
                                      activity.masteryscore != ""
                                        ? activity.masteryscore
                                        : "No Score"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 2 &&
                            (activity.fk_sub_id === 1 ||
                              activity.fk_sub_id === 2) &&
                            activity.activity_node.length > 0
                          ) {
                            return (
                              <div
                                className="bg-white"
                                style={{ borderBottom: "1px solid #b5c4d7" }}
                              >
                                <div className="inner-pd pd-10">
                                  <div className="justify-content-between d-flex align-items-center">
                                    <div>
                                      <h5
                                        className="font-size-18 fw-700 text-dark"
                                        style={{ lineHeight: "24px" }}
                                      >
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].topic_name !=
                                          ""
                                          ? activity.activity_node[0].topic_name
                                          : null}
                                      </h5>
                                      <p className="mb-0 font-size-15 fw-700 text-dark">
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].level_name &&
                                        activity.activity_node[0].level_name !=
                                          ""
                                          ? activity.activity_node[0].level_name
                                          : null}
                                      </p>{" "}
                                    </div>
                                    <strong
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleRightCanvasTopicGraph()
                                        setTopicId(activity.ms_topic_id)
                                        setNodeId(activity.fk_activity_node_id)
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-angle-right"></i>
                                    </strong>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Questions Completed
                                    </p>{" "}
                                    <p className="m-0 font-size-15 fw-700 text-dark">
                                      {activity.question_attended != "" &&
                                      activity.question_attended != ""
                                        ? parseInt(activity.question_attended)
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="justify-content-between d-flex mt-2">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Current mastery score
                                    </p>{" "}
                                    <p
                                      className={`me-0 ${
                                        activity.masteryscore >= 70
                                          ? "text-success"
                                          : activity.masteryscore >= 30 &&
                                            activity.masteryscore <= 69
                                          ? "text-warning"
                                          : activity.masteryscore <= 30 &&
                                            activity.masteryscore >= 1
                                          ? "text-danger"
                                          : ""
                                      }`}
                                    >
                                      {activity.masteryscore != "" &&
                                      activity.masteryscore != ""
                                        ? activity.masteryscore
                                        : "No Score"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 3 &&
                            activity.activity_node.length > 0
                          ) {
                            return (
                              <div
                                className="bg-white"
                                style={{ borderBottom: "1px solid #b5c4d7" }}
                              >
                                <div className="inner-pd pd-10">
                                  <div className="justify-content-between d-flex align-items-center">
                                    <div>
                                      <h5
                                        className="font-size-18 fw-700 text-dark"
                                        style={{ lineHeight: "24px" }}
                                      >
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].name != ""
                                          ? activity.activity_node[0].name
                                          : null}
                                      </h5>
                                      <p className="mb-0 font-size-15 fw-700 text-dark">
                                        {activity.activity_node[0].level_name &&
                                        activity.activity_node[0].level_name !=
                                          ""
                                          ? activity.activity_node[0].level_name
                                          : null}
                                      </p>{" "}
                                    </div>
                                    <strong
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleRightCanvasTopicGraph()
                                        setTopicId(activity.ms_topic_id)
                                        setNodeId(activity.fk_activity_node_id)
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-angle-right"></i>
                                    </strong>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Questions Completed
                                    </p>{" "}
                                    <p className="m-0 font-size-15 fw-700 text-dark">
                                      {activity.question_attended != "" &&
                                      activity.question_attended != ""
                                        ? parseInt(activity.question_attended)
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Current mastery score
                                    </p>{" "}
                                    <p
                                      className={`me-0 ${
                                        activity.masteryscore >= 70
                                          ? "text-success"
                                          : activity.masteryscore >= 30 &&
                                            activity.masteryscore <= 69
                                          ? "text-warning"
                                          : activity.masteryscore <= 30 &&
                                            activity.masteryscore >= 1
                                          ? "text-danger"
                                          : ""
                                      }`}
                                    >
                                      {activity.masteryscore != "" &&
                                      activity.masteryscore != ""
                                        ? activity.masteryscore
                                        : "No Score"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 4 &&
                            activity.activity_node.length > 0
                          ) {
                            return (
                              <div
                                className="bg-white"
                                style={{ borderBottom: "1px solid #b5c4d7" }}
                              >
                                <div className="inner-pd pd-10">
                                  <div className="justify-content-between d-flex align-items-center">
                                    <div>
                                      <h5
                                        className="font-size-18 fw-700 text-dark"
                                        style={{ lineHeight: "24px" }}
                                      >
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].name != ""
                                          ? activity.activity_node[0].name
                                          : null}
                                      </h5>
                                      <p className="mb-0 font-size-15 fw-700 text-dark">
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].level_name &&
                                        activity.activity_node[0].level_name !=
                                          ""
                                          ? activity.activity_node[0].level_name
                                          : null}
                                      </p>{" "}
                                    </div>
                                    <strong
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleRightCanvasTopicGraph()
                                        setTopicId(activity.ms_topic_id)
                                        setNodeId(activity.fk_activity_node_id)
                                      }}
                                    >
                                      {" "}
                                      <i className=" fas fa-angle-right"></i>
                                    </strong>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Questions Completed
                                    </p>{" "}
                                    <p className="m-0 font-size-15 fw-700 text-dark">
                                      {activity.question_attended != "" &&
                                      activity.question_attended != ""
                                        ? parseInt(activity.question_attended)
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Current mastery score
                                    </p>{" "}
                                    <p
                                      className={`me-0 ${
                                        activity.masteryscore >= 70
                                          ? "text-success"
                                          : activity.masteryscore >= 30 &&
                                            activity.masteryscore <= 69
                                          ? "text-warning"
                                          : activity.masteryscore <= 30 &&
                                            activity.masteryscore >= 1
                                          ? "text-danger"
                                          : ""
                                      }`}
                                    >
                                      {activity.masteryscore != "" &&
                                      activity.masteryscore != ""
                                        ? activity.masteryscore
                                        : "No Score"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 5 &&
                            activity.activity_node.length > 0
                          ) {
                            return (
                              <div
                                className="bg-white"
                                style={{ borderBottom: "1px solid #b5c4d7" }}
                              >
                                <div className="inner-pd pd-10">
                                  <div className="justify-content-between d-flex align-items-center">
                                    <div>
                                      <h5
                                        className="font-size-18 fw-700 text-dark"
                                        style={{ lineHeight: "24px" }}
                                      >
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].name != ""
                                          ? activity.activity_node[0].name
                                          : null}
                                      </h5>
                                      <p className="mb-0 font-size-15 fw-700 text-dark">
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].level_name &&
                                        activity.activity_node[0].level_name !=
                                          ""
                                          ? activity.activity_node[0].level_name
                                          : null}
                                      </p>{" "}
                                    </div>
                                    <strong
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleRightCanvasTopicGraph()
                                        setTopicId(activity.ms_topic_id)
                                        setNodeId(activity.fk_activity_node_id)
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-angle-right"></i>
                                    </strong>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Questions Completed
                                    </p>{" "}
                                    <p className="m-0 font-size-15 fw-700 text-dark">
                                      {activity.question_attended != "" &&
                                      activity.question_attended != ""
                                        ? parseInt(activity.question_attended)
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Current mastery score
                                    </p>{" "}
                                    <p
                                      className={`me-0 ${
                                        activity.masteryscore >= 70
                                          ? "text-success"
                                          : activity.masteryscore >= 30 &&
                                            activity.masteryscore <= 69
                                          ? "text-warning"
                                          : activity.masteryscore <= 30 &&
                                            activity.masteryscore >= 1
                                          ? "text-danger"
                                          : ""
                                      }`}
                                    >
                                      {activity.masteryscore != "" &&
                                      activity.masteryscore != ""
                                        ? activity.masteryscore
                                        : "No Score"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 6 &&
                            activity.fk_sub_id === 1 &&
                            activity.activity_node.length > 0
                          ) {
                            return (
                              <div
                                className="bg-white"
                                style={{ borderBottom: "1px solid #b5c4d7" }}
                              >
                                <div className="inner-pd pd-10">
                                  <div className="justify-content-between d-flex align-items-center">
                                    <div>
                                      <h5
                                        className="font-size-18 fw-700 text-dark"
                                        style={{ lineHeight: "24px" }}
                                      >
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].video_name !=
                                          ""
                                          ? activity.activity_node[0].video_name
                                          : null}
                                      </h5>
                                      <p className="mb-0 font-size-15 fw-700 text-dark">
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].level_name &&
                                        activity.activity_node[0].level_name !=
                                          ""
                                          ? activity.activity_node[0].level_name
                                          : null}
                                      </p>{" "}
                                    </div>
                                    <strong
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleRightCanvasTopicGraph()
                                        setTopicId(activity.ms_topic_id)
                                        setNodeId(activity.fk_activity_node_id)
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-angle-right"></i>
                                    </strong>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Questions Completed
                                    </p>{" "}
                                    <p className="m-0 font-size-15 fw-700 text-dark">
                                      {activity.question_attended != "" &&
                                      activity.question_attended != ""
                                        ? parseInt(activity.question_attended)
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Current mastery score
                                    </p>{" "}
                                    <p
                                      className={`me-0 ${
                                        activity.masteryscore >= 70
                                          ? "text-success"
                                          : activity.masteryscore >= 30 &&
                                            activity.masteryscore <= 69
                                          ? "text-warning"
                                          : activity.masteryscore <= 30 &&
                                            activity.masteryscore >= 1
                                          ? "text-danger"
                                          : ""
                                      }`}
                                    >
                                      {activity.activity_node &&
                                      activity.activity_node.length > 0 &&
                                      activity.masteryscore != "" &&
                                      activity.masteryscore != ""
                                        ? activity.masteryscore
                                        : "No Score"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 6 &&
                            activity.fk_sub_id === 2 &&
                            activity.activity_node.length > 0
                          ) {
                            return (
                              <div
                                className="bg-white"
                                style={{ borderBottom: "1px solid #b5c4d7" }}
                              >
                                <div className="inner-pd pd-10">
                                  <div className="justify-content-between d-flex align-items-center">
                                    <div>
                                      <h5
                                        className="font-size-18 fw-700 text-dark"
                                        style={{ lineHeight: "24px" }}
                                      >
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].video_name !=
                                          ""
                                          ? activity.activity_node[0].video_name
                                          : null}
                                      </h5>
                                      <p className="mb-0 font-size-15 fw-700 text-dark">
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].level_name &&
                                        activity.activity_node[0].level_name !=
                                          ""
                                          ? activity.activity_node[0].level_name
                                          : null}
                                      </p>{" "}
                                    </div>
                                    <strong
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleRightCanvasTopicGraph()
                                        setTopicId(activity.ms_topic_id)
                                        setNodeId(activity.fk_activity_node_id)
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-angle-right"></i>
                                    </strong>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Questions Completed
                                    </p>{" "}
                                    <p className="m-0 font-size-15 fw-700 text-dark">
                                      {activity.question_attended != "" &&
                                      activity.question_attended != ""
                                        ? parseInt(activity.question_attended)
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Current mastery score
                                    </p>{" "}
                                    <p
                                      className={`me-0 ${
                                        activity.masteryscore >= 70
                                          ? "text-success"
                                          : activity.masteryscore >= 30 &&
                                            activity.masteryscore <= 69
                                          ? "text-warning"
                                          : activity.masteryscore <= 30 &&
                                            activity.masteryscore >= 1
                                          ? "text-danger"
                                          : ""
                                      }`}
                                    >
                                      {activity.masteryscore != "" &&
                                      activity.masteryscore != ""
                                        ? activity.masteryscore
                                        : "No Score"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          } else if (
                            activity.activity_type === 7 &&
                            (activity.fk_sub_id === 1 ||
                              activity.fk_sub_id === 2) &&
                            activity.activity_node.length > 0
                          ) {
                            return (
                              <div
                                className="bg-white"
                                style={{ borderBottom: "1px solid #b5c4d7" }}
                              >
                                <div className="inner-pd pd-10">
                                  <div className="justify-content-between d-flex align-items-center">
                                    <div>
                                      <h5
                                        className="font-size-18 fw-700 text-dark"
                                        style={{ lineHeight: "24px" }}
                                      >
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].file_name !=
                                          ""
                                          ? activity.activity_node[0].file_name
                                          : null}
                                      </h5>
                                      <p className="mb-0 font-size-15 fw-700 text-dark">
                                        {activity.activity_node &&
                                        activity.activity_node.length > 0 &&
                                        activity.activity_node[0].level_name &&
                                        activity.activity_node[0].level_name !=
                                          ""
                                          ? activity.activity_node[0].level_name
                                          : null}
                                      </p>{" "}
                                    </div>
                                    <strong
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleRightCanvasTopicGraph()
                                        setTopicId(activity.ms_topic_id)
                                        setNodeId(activity.fk_activity_node_id)
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-angle-right"></i>
                                    </strong>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Questions Completed
                                    </p>{" "}
                                    <p className="m-0 font-size-15 fw-700 text-dark">
                                      {activity.question_attended != "" &&
                                      activity.question_attended != ""
                                        ? parseInt(activity.question_attended)
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="justify-content-between mt-2 d-flex">
                                    <p className="font-size-15 fw-400 text-dark">
                                      Current mastery score
                                    </p>{" "}
                                    <p
                                      className={`me-0 ${
                                        activity.masteryscore >= 70
                                          ? "text-success"
                                          : activity.masteryscore >= 30 &&
                                            activity.masteryscore <= 69
                                          ? "text-warning"
                                          : activity.masteryscore <= 30 &&
                                            activity.masteryscore >= 1
                                          ? "text-danger"
                                          : ""
                                      }`}
                                    >
                                      {activity.masteryscore != "" &&
                                      activity.masteryscore != ""
                                        ? activity.masteryscore
                                        : "No Score"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          } else {
                            return // or any other JSX you want to render for other activity types
                          }
                        })
                      ) : (
                        <p className="text-center font-size-15 fw-400 text-dark mt-3">
                          No Data
                        </p>
                      )}
                    </div>
                  </OffcanvasBody>
                </Offcanvas>

                <Offcanvas
                  isOpen={isRightTopicGraph}
                  direction="end"
                  toggle={toggleRightCanvasTopicGraph}
                >
                  <OffcanvasHeader
                    toggle={toggleRightCanvasTopicGraph}
                    className="border-bottom off-can-header cp-2"
                  >
                    <div
                      className="d-flex align-items-center"
                      onClick={toggleRightCanvasTopicGraph}
                    >
                      <div className="short-ic">
                        <img className="bc-bg" src={chevleft} />
                      </div>
                      <p className="student_label_id font-size-12 ">Back</p>
                    </div>
                  </OffcanvasHeader>
                  <OffcanvasBody className="p-0">
                    <div className="alltopic-wrap white-contrast p-1">
                      <div>
                        <label className="student_label2 m-0 font-size-12 fw-400 mb-02">
                          {TopicGraphDeatils
                            ? TopicGraphDeatils.subject
                              ? TopicGraphDeatils.subject
                              : null
                            : null}
                        </label>
                        {TopicGraphDeatils ? (
                          TopicGraphDeatils.topic ? (
                            <>
                              <p className="mb-02 font-size-16 text-dark lh-sm">
                                {TopicGraphDeatils
                                  ? TopicGraphDeatils.topic
                                    ? TopicGraphDeatils.topic
                                    : null
                                  : null}
                              </p>

                              <label className="student_label2 mb-02 font-size-12 ms-0 fw-400 opacity-50">
                                Questions Completed
                              </label>
                              <p className="mb-02 font-size-12 ms-0 fw-500 text-dark">
                                {TopicGraphDeatils
                                  ? TopicGraphDeatils.total_question
                                    ? TopicGraphDeatils.total_question
                                    : null
                                  : null}
                              </p>
                            </>
                          ) : null
                        ) : null}
                      </div>
                      <div className="filter-section d-flex mb-4 flex-wrap">
                        <div className="me-12">
                          <label>Date</label>
                          <br />
                          <DateRangePicker
                            format="dd-MM-yyyy"
                            value={dateValue}
                            onChange={handleDateChange}
                            cleanable={false}
                            className="custom-dropdown"
                            style={{ zIndex: 5050 }}
                          />
                        </div>
                      </div>
                      {TopicGraphDeatils ? (
                        TopicGraphDeatils.topic ? (
                          <>
                            <div className="chart-wrapper">
                              <div className="d-flex justify-content-between">
                                <div className="">
                                  <div>
                                    <p className="mb-02 font-size-12 fw-400 text-dark">
                                      Mastery score over time
                                    </p>
                                    <p className="mb-02 mt-0 font-size-16 fw-400 text-dark">
                                      {TopicGraphDeatilsValueData.length > 0
                                        ? // ? TopicGraphDeatilsValueData.reduce(
                                          //     (total, currentValue) => total + currentValue,
                                          //     0
                                          //   )
                                          TopicGraphDeatilsValueData.join(",  ")
                                        : 0}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  style={{ marginBottom: "20px" }}
                                  className="me-2"
                                >
                                  {/* <DatePicker
                              placeholder={"Select Date"}
                              selected={startDate}
                              onChange={handleDateChange}
                              dateFormat="yyyy-MM"
                              showMonthYearPicker
                              className="justify-content-end"
                              onKeyDown={e => {
                                e.preventDefault()
                              }}
                            /> */}

                                  <div>
                                    {startDate != null && startDate != "" ? (
                                      <button
                                        className=""
                                        onClick={handelDateReset}
                                      >
                                        <i
                                          class="mdi  mdi-close-circle"
                                          style={{
                                            color: "#979797",
                                            fontSize: "20px",
                                          }}
                                        ></i>
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <Line data={data_area_chart} />
                            </div>
                            <br></br>
                          </>
                        ) : (
                          "Activity not available"
                        )
                      ) : null}
                    </div>
                  </OffcanvasBody>
                </Offcanvas>
              </div>
            </div>
          </div>

          <Card className="mb-3">
            <CardBody className="ut-pd">
              <div className="utilities-wrapper d-flex align-items-centerflex-wrap position-relative">
                <div className="status-wrapper position-absolute">
                  {" "}
                  {sessionData && sessionData.session ? (
                    sessionData.session.ses_status == 0 ? (
                      <span class="badge tb-primary inner-bd ">Upcoming</span>
                    ) : sessionData.session.ses_status == 1 &&
                      sessionData.session.ses_is_completed == 0 ? (
                      <span class="badge tb-warning inner-bd ">Ongoing</span>
                    ) : sessionData.session.ses_status == 1 &&
                      sessionData.session.ses_is_completed == 1 ? (
                      <span class="badge tb-success inner-bd ">Completed</span>
                    ) : null
                  ) : (
                    "-"
                  )}
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ marginRight: "1rem" }}
                >
                  <img
                    style={{ marginRight: "0.5rem" }}
                    width={36}
                    src={calendar}
                    alt=""
                  />
                  {/* <div className="ut_ic">
                    <i className="ion ion-ios-calendar"></i>
                  </div> */}
                  <div>
                    <p className="">Date</p>
                    <h6 className="mb-0 fw-medium text-dark">
                      {sessionData &&
                      sessionData.session &&
                      sessionData.session.ses_date
                        ? moment(
                            sessionData.session.ses_date,
                            moment.ISO_8601
                          ).format("DD MMM YYYY (ddd)")
                        : "-"}
                    </h6>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ marginRight: "1rem" }}
                >
                  {/* <div className="ut_ic">
                    <i className="ion ion-ios-timer"></i>
                  </div> */}
                  <img
                    style={{ marginRight: "0.5rem" }}
                    width={36}
                    src={watch}
                    alt=""
                  />
                  <div>
                    <p className="">Time</p>
                    <h6 className="mb-0 mb-0 fw-medium text-dark">
                      {sessionData &&
                      sessionData.session &&
                      sessionData.session.ses_start_time
                        ? moment(
                            sessionData.session.ses_start_time,
                            "HH:mm:ss"
                          ).format("hh:mm A")
                        : "-"}{" "}
                      -{" "}
                      {sessionData &&
                      sessionData.session &&
                      sessionData.session.ses_end_time
                        ? moment(
                            sessionData.session.ses_end_time,
                            "HH:mm:ss"
                          ).format("hh:mm A")
                        : "-"}
                    </h6>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ marginRight: "1rem" }}
                >
                  {/* <div className="ut_ic">
                    <i className="ion ion-md-stopwatch"></i>
                  </div> */}
                  <img
                    style={{ marginRight: "0.5rem" }}
                    width={36}
                    src={timer}
                    alt=""
                  />
                  <div>
                    <p className="">Duration</p>
                    <h6 className="mb-0 mb-0 fw-medium text-dark">
                      {sessionData &&
                      sessionData.session &&
                      sessionData.session.ses_start_time &&
                      sessionData.session.ses_end_time
                        ? calculateSessionDuration(
                            sessionData.session.ses_start_time,
                            sessionData.session.ses_end_time
                          )
                        : "-"}
                    </h6>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ marginRight: "1rem" }}
                >
                  <div
                    className="ut_ic custom-me"
                    style={{ marginRight: "0.5rem" }}
                  >
                    <i className="ion ion-md-bookmarks font-size-24"></i>
                  </div>
                  <div>
                    <p className="">Subject</p>
                    <h6 className="mb-0 mb-0 fw-medium text-dark">
                      {sessionData && sessionData != ""
                        ? getUniqueSubjectNames(sessionData).join(" & ") || "-"
                        : "-"}
                    </h6>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Nav tabs className="nav-tabs-custom mb-3">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1")
                }}
              >
                <span className="d-none d-sm-block">Lesson Plan</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2")
                }}
              >
                <span className="d-none d-sm-block">Homework</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "3",
                })}
                onClick={() => {
                  toggleCustom("3")
                }}
              >
                <span className="d-none d-sm-block">Previous Session</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "4",
                })}
                onClick={() => {
                  toggleCustom("4")
                }}
              >
                <span className="d-none d-sm-block">Students</span>
              </NavLink>
            </NavItem>
          </Nav>
          <Card>
            <CardBody className="p-1">
              <div className="session-details">
                <TabContent activeTab={customActiveTab} className="text-muted">
                  <TabPane tabId="1">
                    <div className="kanban-wrapper d-flex gap-1">
                      <MemoizedLessonPlanContent
                        sessionData={sessionData}
                        customActiveTab={customActiveTab}
                        getUniqueStudentSubjectNames={
                          getUniqueStudentSubjectNames
                        }
                        getUniqueStudentSubjectID={getUniqueStudentSubjectID}
                        CountTotalCoins={CountTotalCoins}
                        CountHours={CountHours}
                        CountTotalActivity={CountTotalActivity}
                        clearTimeout={clearTimeout}
                        setLoopRun={setLoopRun}
                        handleLinkClick={handleLinkClick}
                        toggleDropdown={toggleDropdown}
                        dropdownOpen={dropdownOpen}
                        onPreviewActivity={onPreviewActivity}
                        onDelectActivityPopup={onDelectActivityPopup}
                        onMoveActivityPopup={onMoveActivityPopup}
                        getUniqueTopicsNames={getUniqueTopicsNames}
                        selectedActivityKeys={selectedActivityKeys}
                        handleCheckboxActivityChange={
                          handleCheckboxActivityChange
                        }
                        activityStudentKey={activityStudentKey}
                        fetchMultipleUpcommingSessionData={
                          fetchMultipleUpcommingSessionData
                        }
                        handleSelectAllChange={handleSelectAllChange}
                        selectAllChecked={selectAllChecked}
                      />
                      {/* <LassonPlanContent sessionData={sessionData} /> */}
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="kanban-wrapper d-flex gap-1">
                      <MemoizedLessonPlanContent
                        sessionData={HomeSessionData}
                        customActiveTab={customActiveTab}
                        getUniqueStudentSubjectNames={
                          getUniqueStudentSubjectNames
                        }
                        getUniqueStudentSubjectID={getUniqueStudentSubjectID}
                        CountTotalCoins={CountTotalCoins}
                        CountHours={CountHours}
                        CountTotalActivity={CountTotalActivity}
                        clearTimeout={clearTimeout}
                        setLoopRun={setLoopRun}
                        handleLinkClick={handleLinkClick}
                        toggleDropdown={toggleDropdown}
                        dropdownOpen={dropdownOpen}
                        onPreviewActivity={onPreviewActivity}
                        onDelectActivityPopup={onDelectActivityPopup}
                        onMoveActivityPopup={onMoveActivityPopup}
                        getUniqueTopicsNames={getUniqueTopicsNames}
                        selectedActivityKeys={selectedActivityKeys}
                        handleCheckboxActivityChange={
                          handleCheckboxActivityChange
                        }
                        activityStudentKey={activityStudentKey}
                        fetchMultipleUpcommingSessionData={
                          fetchMultipleUpcommingSessionData
                        }
                        handleSelectAllChange={handleSelectAllChange}
                        selectAllChecked={selectAllChecked}
                      />
                      {/* <LassonPlanContent sessionData={HomeSessionData} /> */}
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="kanban-wrapper d-flex gap-1">
                      {/* <LassonPlanContent sessionData={sessionData} /> */}

                      {/* <PreviousSessionContent sessionData={PresessionData} /> */}
                      <MemoizedPreviousSessionContent
                        sessionData={PresessionData}
                        PreHomeSessionData={PreHomeSessionData}
                        customActiveTab={customActiveTab}
                        getUniqueStudentSubjectNames={
                          getUniqueStudentSubjectNames
                        }
                        CountTotalCoins={CountTotalCoins}
                        CountHours={CountHours}
                        CountTotalActivity={CountTotalActivity}
                        clearTimeout={clearTimeout}
                        setLoopRun={setLoopRun}
                        handleLinkClick={handleLinkClick}
                        toggleDropdown={toggleDropdown}
                        dropdownOpen={dropdownOpen}
                        onPreviewActivity={onPreviewActivity}
                        onDelectActivityPopup={onDelectActivityPopup}
                        getUniqueTopicsNames={getUniqueTopicsNames}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="4">
                    <TableComm
                      columns={columns}
                      defaultId={"sest_name"}
                      data={StudentData}
                      // onRowClick={handleStudentViewClick}  hiddenColumns={['visibility', 'student_key']}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

SessionDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SessionDetails)
