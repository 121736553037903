// components/ServerSideTable.js
import React, { useEffect, useState } from 'react';

import {
  Container,
  Table,
  Card,
  CardBody,
  Button,
  Label,
  Row,
  Col,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,

} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { SECURITY_KEY, BASE_URL } from "../../global";

const ServerSideTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState([null, null]);
  const [subject, setSubject] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);


  useEffect(() => {
    const fetchData = async () => {
      try {
        //const response = await axios.get('/api/data'); // Replace with your API endpoint
        const response = await axios.post(BASE_URL+'session/getAllSession', {

          draw: 1,
          start: 0,
          length: rowsPerPage,
          order: [{ column: 0, dir: 'asc' }],
          columns: [{ data: 'session_date' },
          { data: 'session_time' },
          { data: 'sest_name' },
          { data: 'total_student' },
          { data: 'subject' },
          { data: 'sest_type' },
          { data: 'ses_is_completed' }],
          search: { value: '' },
          startDate: date[0] || null,
          endDate: date[1] || null,

          subject: subject,
          type: type,
          status: status,

        }

        );

        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      dataField: 'subject',
      text: 'Subject',
      sort: true,
    },
    {
      dataField: 'sest_name',
      text: 'Sestion Name',
      sort: true,

      // filter: textFilter(),
    },
    // Add more columns as needed
  ];

  const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPage: 'First',
    prePage: 'Back',
    nextPage: 'Next',
    lastPage: 'Last',
    nextPage: 'First page',
    prePage: 'Pre page',
    firstPage: 'Next page',
    lastPage: 'Last page',



    // page: 2,   
    // sizePerPageList: [ {  
    //   text: '5', value: 5  
    // }, {  
    //   text: '10', value: 10  
    // }, {  
    //   text: 'All', value: []  
    // } ],   
    // sizePerPage: 5,   
    // pageStartIndex: 0,   
    // paginationSize: 5,    
    // prePage: 'Prev',   
    // nextPage: 'Next',   
    // firstPage: 'First',   
    // lastPage: 'Last',   
    // paginationPosition: 'top'    


  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <div className='custom-pg_header'>
            <div>
              <h6 className="page-title">Sessions</h6>
            </div>

          </div>

        </div>
        <Card>
          <CardBody>
            <div className="table-responsive">
              <BootstrapTable
                keyField="id"
                data={data}
                columns={columns}
                pagination={paginationFactory(paginationOptions)}
                filter={filterFactory()}
                // cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                striped
                hover
                condensed
                loading={loading}
                noDataIndication={() => 'No data found'}
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ServerSideTable;
