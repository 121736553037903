import React, { useEffect, useState, useMemo } from 'react';

import {
  Container,
  Card,
  CardBody,
  Button,
  Label,
  Row,
  Col,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,

} from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Table from './dataTableCommon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DateRangePicker, Stack } from 'rsuite';
import Select from "react-select";

import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { SECURITY_KEY, BASE_URL } from "../../global";

const ServerSideTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState([null, null]);
  const [subject, setSubject] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);



  const [selectedGroup, setselectedGroup] = useState(null);

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }


  const optionGroup = [
    {
      label: "Select Subject",
      options: [
        { label: "All", value: "All" },
        { label: "English", value: "English" },
        { label: "Maths", value: "Maths" },
        { label: "English & Maths", value: "English & Maths" }
      ]
    },

  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        //const response = await axios.get('/api/data'); // Replace with your API endpoint
        const response = await axios.post(BASE_URL+'session/getAllSession', {

          draw: 1,
          start: 0,
          length: rowsPerPage,
          order: [{ column: 0, dir: 'asc' }],
          columns: [{ data: 'session_date' },
          { data: 'session_time' },
          { data: 'sest_name' },
          { data: 'total_student' },
          { data: 'subject' },
          { data: 'sest_type' },
          { data: 'ses_is_completed' }],
          search: { value: '' },
          startDate: date[0] || null,
          endDate: date[1] || null,

          subject: subject,
          type: type,
          status: status,

        }

        );

        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

//   const columns = [
//     {
//       dataField: 'subject',
//       text: 'Subject',
//       sort: true,
//     },
//     {
//       dataField: 'sest_name',
//       text: 'Sestion Name',
//       sort: true,

//       // filter: textFilter(),
//     },
//     // Add more columns as needed
//   ];



const columns = React.useMemo(
      () => [{
        Header: 'Date',
        accessor: row => row.session_date,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        name: 'Time',
        Header: 'Time',
        // accessor: 'st_first_name',
        accessor: (row) => `${row.session_time} `,
  
      },
      {
        name: 'Sestion Name',
        Header: 'Sestion Name',
        accessor: (row) => `${row.sest_name}`,
      },
      {
        Header: 'Subject',
        accessor: row => row.subject == null || row.subject == '' ? '-' : row.subject,
        
      },
         
      
     
      {
        Header: 'Type',
        accessor: row => row.sest_type == null || row.sest_type == '' ? '-' : row.sest_type,
      },
       {
            Header: "Status",
            disableSortBy: true,
            accessor: 'pk_student_key',
            disableFilters: true,
      
            Cell: ({ row }) => (<><button className='btn status-btn'> ON GOING</button></>),
     
      },
      {
            Header: "#",
            disableSortBy: true,
            accessor: 'pk_student_keys',
            disableFilters: true,
      
            Cell: ({ row }) => (<><button className='btn'><i class="mdi mdi-chevron-right-circle-outline" style={{ color: '#979797', fontSize: '20px'}}></i></button></>),
     
      },
      {
        accessor: 'student_key', disableSortBy: true, disableFilters: true,           isVisible: false,
  
        selector: (row) => row.values.pk_student_key, show: false,
      },
      {
        accessor: 'visibility', disableSortBy: true, disableFilters: true,     isVisible: false,
  
        selector: (row) => row.visibility,  
  
      }
      ],
      []
    );
  


  
  const handleStudentViewClick = async (e) => {
      console.log(e);
      // if (parseInt(e.values.visibility) == 1) {
      //   navigate(`/view-student`, { state: { pk_student_key: `${e.values.pk_student_key}` } });
      // } else {
  
      //   toast.error("Sorry! Allow Only active Student.", {
      //     autoClose: 3000, // 3 seconds in this example
      //   });
  
      // }
  
  
    }


  const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPage: 'First',
    prePage: 'Back',
    nextPage: 'Next',
    lastPage: 'Last',
    nextPage: 'First page',
    prePage: 'Pre page',
    firstPage: 'Next page',
    lastPage: 'Last page',



    // page: 2,   
    // sizePerPageList: [ {  
    //   text: '5', value: 5  
    // }, {  
    //   text: '10', value: 10  
    // }, {  
    //   text: 'All', value: []  
    // } ],   
    // sizePerPage: 5,   
    // pageStartIndex: 0,   
    // paginationSize: 5,    
    // prePage: 'Prev',   
    // nextPage: 'Next',   
    // firstPage: 'First',   
    // lastPage: 'Last',   
    // paginationPosition: 'top'    


  };

  return (
    <div className="page-content">
      <Container fluid>

        
      <form className="app-search page-search">
            <div className="position-relative">
              <span className="fa fa-search"></span>
              <input
                type="text"
                className="form-control"
                placeholder={"Search for session name"}
              />
            </div>
          </form>
             
            <div className="table-responsive ">
            <div className='row'>

<div className='filter-section d-flex gap-3 mb-3'>

  <div>
    <label>Date</label>
    <br></br>
    <DateRangePicker />
  </div>

  <div className='filter-child'>
    <label>Subject</label>
    <Select className='cs-style'
      value={selectedGroup}
      onChange={() => {
        handleSelectGroup();
      }}
      options={optionGroup}
      classNamePrefix="select2-selection"
    />

  </div>

  <div className='filter-child'>
    <label>Type</label>
    <Select className='cs-style'
      value={selectedGroup}
      onChange={() => {
        handleSelectGroup();
      }}
      options={optionGroup}
      classNamePrefix="select2-selection"
    />

  </div>
  <div className='filter-child'>
    <label>Status</label>
    <Select className='cs-style'
      value={selectedGroup}
      onChange={() => {
        handleSelectGroup();
      }}
      options={optionGroup}
      classNamePrefix="select2-selection"
    />

  </div>
  <div className='me-1'>
    <Button
      className='mt-4'
      color='primary'>
      Clear Filter
    </Button>
  </div>

</div>

</div>

            <Table  loading={loading}  columns={columns} defaultId={'st_first_name' }  data={data}           
                    onRowClick={handleStudentViewClick}  hiddenColumns={['visibility', 'student_key']}
                   />
            </div>
         
      </Container>
    </div>
  );
};

export default ServerSideTable;
