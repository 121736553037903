import React from "react"

import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table"
// import { BeatLoader } from 'react-spinners';

// Define a default UI for filtering

import "bootstrap/dist/css/bootstrap.min.css"

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  // return (
  //   <span>
  //     <div class="col-sm-12">
  //       <div class="d-flex flex-wrap justify-content-end align-items-center mb-4">
  //         <div class="form-group has-search my-auto">
  //           <span
  //             class="fa fa-search form-control-feedback"
  //             aria-hidden="true"
  //           ></span>

  //           <input
  //             className="form-control mb-0"
  //             value={value || ""}
  //             onChange={e => {
  //               setValue(e.target.value)
  //               onChange(e.target.value)
  //             }}
  //             placeholder={`${count} records...`}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   </span>
  // )
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      className="form-control"
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}
function Table({ columns, data, onRowClick, loading, defaultId, pageIndexData }) {
  const [savedPageIndex, setSavedPageIndex] = React.useState(pageIndexData || 0);
  const customeStyle = {
    headRow: {
      style: {
        borderRadius: "var(--br-3xs)",
        backgroundColor: "var(--color-gainsboro-400)",
      },
    },
  }
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: savedPageIndex,
        pageSize: 10, // Adjust the initial page size as needed
        sortBy: [{ id: defaultId, desc: true }],
        hiddenColumns: ["visibility", "student_key"],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )
  const { pageIndex, pageSize } = state
  React.useEffect(() => {
    setSavedPageIndex(pageIndex); // Update savedPageIndex when pageIndex changes
  }, [pageIndex]);
  const startRow = pageIndex * pageSize + 1
  const endRow = Math.min((pageIndex + 1) * pageSize, data.length)
  const totalRows = data.length
  return (
    <div>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div className="overflow-x-auto mb-3">
        {loading ? (
          <div className="loader d-flex justify-content-center">
            {/* <BeatLoader
      color="#002645"
      loading={loading}
      size={20}
      visible={true}
      ariaLabel='falling-lines-loading'
    /> */}
          </div>
        ) : (
          <table className="table mb-2 main-table" {...getTableProps()}>
            <thead className="main-head rounded-1">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th className="cus-th cust-head">
                      <div class="cus-sort-th">
                        <div>
                          <div
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i
                                    rotation={180}
                                    class="ms-2 fas fa-arrow-down cus-sort"
                                  ></i>
                                ) : (
                                  <i class="ms-2 fas fa-arrow-up cus-sort"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>

                            {/* Render the sorting indicators */}
                          </div>
                          {/* Render the columns filter UI */}
                          {/* <div className="cus-mb-0">
                            {column.canFilter ? column.render("Filter") : null}
                          </div> */}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            { page && page.length > 0 ?
              page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    customStyles={customeStyle}
                    className="cursor-pointer list-cus-h cus-tr"
                    onClick={e => {
                      // Extract the column ID from the clicked element's data-col-id attribute
                      const clickedColumn = e.target.getAttribute("data-col-id")
                     
                      // Check if the clicked column is the one you want to skip row click for
                      if (clickedColumn !== null) {
                        onRowClick(row)
                      }
                    }}
                  >
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        data-col-id={cell.column.id}
                        className="cus-td"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                )
              })
              :
              (<tr><td colSpan={headerGroups[0].headers.length}><p className="text-center"> No data found</p></td></tr>)
              
            }
            </tbody>
          </table>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <p style={{ color: "#666", fontSize: 16 }} className="me-2 mt-2">
            Showing Entries{" "}
          </p>

          <div
            className="p-2 entry-value "
            style={{ color: "#666", fontSize: 16 }}
          >
            <select
              className="entries"
              value={pageSize}
              onChange={e => setPageSize(Number(e.target.value))}
            >
              {[5, 10, 15, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>{" "}
          </div>
        </div>

        <div className="page-align">
          <button onClick={() => previousPage()} className="btn"  style={{fontSize: '15'}}>
            {"<"}
          </button>{" "}
          {/* <span>
            <strong>{pageIndex + 1}</strong>{" "}
          </span> */}
           <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
            </strong>{" "}
          </span>
          <button
            onClick={() => nextPage()}
            disabled={pageIndex >= Math.ceil(pageSize) - 1}
            className="btn text-dark" style={{fontSize: '15'}}
          >
            {">"}
          </button>{" "}
        </div>
      </div>
      {/* <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <p style={{ color: "#666", fontSize: 16 }} className="me-2">
            Show Entries{" "}
          </p>

          <div
            className="p-2 entry-value "
            style={{ color: "#666", fontSize: 16 }}
          >
            <select
              className="entries"
              value={pageSize}
              onChange={e => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 30, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>{" "}
          </div>
        </div>

        <div className="page-align">
          <button onClick={() => previousPage()} className="btn"  style={{fontSize: '15'}}>
            {"<"}
          </button>{" "}
          <span>
            <strong>{pageIndex + 1}</strong>{" "}
          </span>
          <button
            onClick={() => nextPage()}
            disabled={pageIndex >= Math.ceil(pageSize) - 1}
            className="btn text-dark" style={{fontSize: '15'}}
          >
            {">"}
          </button>{" "}
        </div>
      </div> */}

      {/* <div className="cus-pag">
        <div className="text-end">
          <button
            onClick={() => gotoPage(0)}
            disabled={pageIndex === 0}
            className="cus-nxt"
          >
            {"<<"}
          </button>{" "}
          <button
            onClick={() => previousPage()}
            disabled={pageIndex === 0}
            className="cus-nxt"
          >
            {"<"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
            </strong>{" "}
          </span>
          <button
            onClick={() => nextPage()}
            disabled={pageIndex >= Math.ceil(data.length / pageSize) - 1}
            className="cus-nxt"
          >
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(Math.ceil(data.length / pageSize) - 1)}
            disabled={pageIndex >= Math.ceil(data.length / pageSize) - 1}
            className="cus-nxt"
          >
            {">>"}
          </button>{" "}
          <span>
            | Go to page:{" "}
            <input
              type="number"
              value={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: "50px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={e => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>{" "}
        </div>

        <div className="text-end">
          Showing {startRow}-{endRow} of {totalRows} entries
        </div>
      </div> */}
    </div>
  )
}

export default Table
